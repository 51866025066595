import React from 'react'
import {
    Box,Container,
    Typography,
    Grid
} from '@mui/material'
import { StyledIcons } from '../../base/commonStyles'
import PageMetaTags from '../../base/PageMetaTags'
import Layout from '../../template/Layout'
import variables from '../../settings/_variables.scss'
import { useNavigate } from 'react-router-dom'
// import DebuJanetImage from '../../assets/images/janet_debu.jpeg'
import Image4 from '../../assets/images/aboutUs2.webp'
import Mail from '../../assets/icons/MAIL.svg'

import Image1 from '../../assets/icons/Gruppe_695.svg'
import Image2 from '../../assets/icons/Gruppe_696.svg'

import Image3 from '../../assets/icons/Gruppe_697.svg'

import BACK from '../../assets/icons/BACK.svg'
import { Subtitle1Typography } from '../../base/commonStyles'
import { useSelector } from 'react-redux'
import { BuntonContactEmail } from '../../constants/TextContent'

const AboutUs = () => {
    const navigate = useNavigate()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const maxWidth = {
        xs: '500px',
        sm: '900px',
        md: '926px',
        lg: '926px'
    }
    return (
        <Layout>
            <PageMetaTags title={'About Bunton | Bunton'} />
            <Box sx={{ mb: '5px', mt: '40px', display: 'flex', alignItems: 'center' }}>
                <Container maxWidth={false}>
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                py: 0,
                                px: {
                                    xs: 0,
                                    sm: 2.5,
                                    md: 2.5
                                }
                            }}
                        >

                            <Box
                                sx={{
                                    margin: '0 auto'
                                }}
                            >
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className='cyan-heading-40px'
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    {'Why bunton?'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box sx={{maxWidth: '690px', margin: 'auto', textAlign:'center'}}>
                        <Typography
                            variant='paragraph'
                            className='para-font-15px'
                        >
                            Bunton is the new <strong>business community</strong> with online
                            job matching for women, who are ready to lead and  companies looking to fill leadership positions. We believe
                            that <strong>diversity</strong> in teams helps improving the performance of corporations along all parameters in a sustainable
                            way. This is how it works:
                         
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb: '30px',
                            marginTop: '51px'
                        }}
                    >
                        <Grid container sx={{ margin: '0 auto', maxWidth: { ...maxWidth } }}>
                            <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <StyledIcons
                                        component='img'
                                        sx={{ width: '170px', height: '170px', marginLeft: '20px' }}
                                        src={Image3}
                                    />
                                </Box>
                                <Box sx={{my: 2, mx: 0}}>
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-15px'
                                        sx={{
                                            textWrap: 'balance',
                                            lineHeight: '1.95',
                                            letterSpacing: '.36px'
                                        }}
                                    >
                                            We provide a simple, anonymous and secure space for female<br/>
                                            professionals to connect them with matching companies.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <StyledIcons
                                        sx={{ width: '170px', height: '170px', marginLeft: '20px' }}
                                        component='img'
                                        src={Image2}
                                    />
                                </Box>
                                <Box sx={{my: 2, mx: 0}}>
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-15px'
                                        sx={{
                                            textWrap: 'balance',
                                            lineHeight: '1.95',
                                            letterSpacing: '.36px'
                                        }}
                                    >
                                        Companies can easily find suitable employees who, based on<br/>
                                        their profile, are the best choice for the open positions.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <Box>
                                    <StyledIcons
                                        sx={{ width: '170px', height: '170px', marginLeft: '20px' }}
                                        component='img'
                                        src={Image1}
                                    />
                                </Box>
                                <Box sx={{my: 2, mx: 0}}>
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-15px'
                                        sx={{
                                            textWrap: 'balance',
                                            lineHeight: '1.95',
                                            letterSpacing: '.36px'
                                        }}
                                    >
                                        Bunton connects the two parties. The women executives have<br/>
                                        the option to initiate a conversation or reject the contact.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                margin: '0 auto'
                            }}
                        >
                            <Typography
                                variant='subtitle1'
                                gutterBottom
                                className='cyan-heading-40px'
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                {'Who we are'}
                            </Typography>
                        </Box>
                        <Box sx={{maxWidth: '690px', margin: 'auto', textAlign:'center'}}>
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: {
                                        sm: '11px',
                                        md: '13px',
                                        lg: '18px'
                                    },
                                    overflow: 'hidden',
                                    color: variables.BLUE,
                                    position: 'relative',
                                    textAlign: 'center'
                                }}
                            >
                                Bunton was founded in 2022 in Frankfurt by{' '}
                                <strong><a href='https://www.linkedin.com/in/janet-winkler-58998548/' target='_blank' rel='noreferrer'>Janet Winkler</a></strong> and{' '}
                                <strong><a href='https://www.linkedin.com/in/debjitdc/' target='_blank' rel='noreferrer'>Debjit Chaudhuri</a></strong> and is based on the
                                experiences and interactions with female executives &
                                entrepreneurs.
                            
                            </Typography>
                        </Box>
                        {/* <Box sx={{ textAlign: 'right' }}>
                            <Grid container direction={{xs:'column-reverse', sm: 'row', md: 'row'}}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    sx={{
                                        position: 'relative',
                                        left: {
                                            xs: '0px',
                                            sm: '20px',
                                            md: '20px',
                                            lg: '20px',
                                            xl: '20px'
                                        },
                                        zIndex: '9'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            border: '3px solid',
                                            maxWidth:{
                                              xs: '380px',
                                              sm: '380px',
                                              md: '267px',
                                              lg: '380px',
                                              xl: '380px'
                                            },
  
                                            borderRadius: {
                                                xs: '0px 0px 59px 59px',
                                                sm: '59px',
                                                md: '59px',
                                                lg: '59px',
                                                xl: '59px'
                                            },
                                            marginTop: {
                                                xs: '0px',
                                                sm: '38px',
                                                md: '38px',
                                                lg: '38px',
                                                xl: '38px'
                                            },
                                            backgroundColor: '#fefaf4',
                                            float: 'right',
                                            padding: {
                                                xs: '30px',
                                                sm: '54px',
                                                md: '54px',
                                                lg: '54px',
                                                xl: '54px'
                                            },
                                            textAlign: {
                                                xs: 'center',
                                                sm: 'right',
                                                md: 'right',
                                                lg: 'right',
                                                xl: 'right'
                                            }
                                        }}
                                    >
                                        <Typography
                                            variant='h4'
                                            sx={{
                                                fontSize: {
                                                    sm: '30px',
                                                    md: '40px',
                                                    lg: '40px'
                                                },
                                                color: variables.CYAN,
                                                fontWeight: '600'
                                            }}
                                        >
                                            Who we are
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontSize: {
                                                    sm: '11px',
                                                    md: '13px',
                                                    lg: '18px'
                                                },
                                                overflow: 'hidden',
                                                color: variables.BLUE,
                                                position: 'relative',
                                                textAlign: {
                                                    xs: 'center',
                                                    sm: 'right',
                                                    md: 'right',
                                                    lg: 'right',
                                                    xl: 'right'
                                                }
                                            }}
                                        >
                                            Bunton was founded in 2022 in Frankfurt by{' '}
                                            <strong>Janet Winkler</strong> and{' '}
                                            <strong>Debjit Chaudhuri</strong> and is based on the
                                            experiences and interactions with female executives &
                                            entrepreneurs.
                                        </Typography>
                                    </Box>
                                </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        sx={{
                                            position: 'relative',
                                            right: '20px'
                                        }}
                                    >
                                        <Box component='span' sx={{ float: 'left' }}>
                                            <Box
                                                component='img'
                                                src={DebuJanetImage}
                                                sx={{
                                                    borderRadius: {
                                                        xs: '80px 80px 0px 0px',
                                                        sm: '80px',
                                                        md: '80px',
                                                        lg: '80px',
                                                        xl: '80px'
                                                    },
                                                    maxWidth: '500px !important',
                                                    width: '100%',
                                                    height: {
                                                        xs: '250px',
                                                        sm: '300px',
                                                        md: '330px'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                            </Grid>
                        </Box> */}
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'right',
                            mt: '74px'
                        }}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                sx={{
                                    position: { xs: 'relative' },
                                    bottom: { xs: '-10px', sm: '' },
                                    left: { xs: '', sm: '20px' },
                                    zIndex: { xs: '99', sm: '1' }
                                }}
                            >
                                <Box component='div'>
                                    <Box
                                        component='img'
                                        src={Image4}
                                        sx={{
                                            borderRadius: {
                                                xs: '80px 80px 0px 0px',
                                                sm: '80px',
                                                md: '80px',
                                                lg: '80px',
                                                xl: '80px'
                                            },

                                            width: {
                                              xs: '100%',
                                              sm: '100%',
                                              md: '86%',
                                              lg: '500px'
                                            },
                                            height: {
                                                xs: 'auto',
                                                sm: 'auto',
                                                md: '100%',
                                                lg: '380px'
                                            }

                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                sx={{
                                    position: 'relative',
                                    right: {
                                        xs: '0px',
                                        sm: '20px',
                                        md: '20px',
                                        lg: '20px',
                                        xl: '20px'
                                    },
                                    zIndex: { xs: '1', sm: '9' }
                                }}
                            >
                                <Box
                                    sx={{
                                        border: '3px solid',
                                        borderRadius: {
                                            xs: '0px 0px 59px 59px',
                                            sm: '59px'
                                        },
                                        marginTop: {
                                          xs: '0px',
                                          sm: '82px',
                                          md: '82px',
                                          lg: '82px',
                                          xl: '82px'
                                        },
                                        maxWidth:{
                                          xs: '411px',
                                          sm: '411px',
                                          md: '333px',
                                          lg: '411px',
                                          xl: '411px'
                                        },
                                        backgroundColor: '#fefaf4',
                                        // float: 'right',
                                        padding: {
                                            xs: '30px',
                                            sm: '54px',
                                            md: '54px',
                                            lg: '54px',
                                            xl: '54px'
                                        },
                                        textAlign: {
                                            xs: 'center',
                                            sm: 'right',
                                            md: 'right',
                                            lg: 'right',
                                            xl: 'right'
                                        }
                                        // mr:"54px"
                                    }}
                                >
                                    <Typography
                                        variant='h4'
                                        sx={{
                                            fontSize: {
                                                sm: '30px',
                                                md: '40px',
                                                lg: '40px'
                                            },
                                            color: variables.CYAN,
                                            fontWeight: '600',
                                            textAlign: 'left'
                                        }}
                                    >
                                        What we stand for
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            fontSize: {
                                                sm: '11px',
                                                md: '13px',
                                                lg: '18px'
                                            },
                                            overflow: 'hidden',
                                            color: variables.BLUE,
                                            position: 'relative',
                                            // top: '-9px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        We aim to bring <strong>balance to the boardroom</strong> by
                                        including diverse leaders and helping diversify the
                                        management teams of corporations.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box sx={{ mb: '5px', mt: '79px', alignItems: 'center' }}>
                            <Typography
                                variant='subtitle1'
                                gutterBottom
                                className='cyan-heading-40px'
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                {'Work with us'}
                            </Typography>
                        </Box>

                        <Subtitle1Typography
                            sx={{
                                top: '0px',
                                margin: '0 auto',
                                textAlign: 'center',
                                lineHeight: '1.95',
                                letterSpacing: '.36px',
                                fontSize: '20px',
                                maxWidth: { ...maxWidth }
                            }}
                        >
                            <p class='p_tag'>
                                Get in touch with us and become a part of bunton’s journey to
                                {responsiveBreakpoint !== 'mob' && <br />}
                                diverse boardrooms and well balanced management boards.
                            </p>
                        </Subtitle1Typography>
                        <Box>
                            <Subtitle1Typography
                                sx={{
                                    top: '0px',
                                    textAlign: 'center',
                                    lineHeight: '1.95',
                                    letterSpacing: '.36px',
                                    fontSize: '20px',
                                    display: 'flex',
                                    width: 'fit-content',
                                    margin: '0 auto'
                                }}
                            >
                                <StyledIcons
                                    sx={{ marginTop: '22px' }}
                                    component='img'
                                    src={Mail}
                                />
                                <p class='p_tag'>
                                    <u>
                                        {BuntonContactEmail}
                                    </u>
                                </p>
                            </Subtitle1Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Layout>
    )
}
export default AboutUs
