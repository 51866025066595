import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOGIN_MUTATION } from '../../gql/queries';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { loginSuccess, loginFailure, loginCheckAuth } from '../../redux/actions/login'
import { updateProfileDataFromDB } from '../../redux/actions/profileDetail'
import { CURRENT_USER_QUERY } from '../../queries/registrationQueries';
import { clarity } from 'react-microsoft-clarity';
import { keys } from '../../constants';
import Loader from '../Loader';

const LINKEDIN_OAUTH2_STATE = 'linkedin_oauth2_state';

function parse(search) {
  const query = search.substring(1);
  const vars = query.split('&');
  const parsed = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair.length > 1) {
      parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }
  return parsed;
}
const cookies = new Cookies()
export function LinkedInCallback() {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { refetch } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
        if(data.currentUser && data.currentUser.id) {
            clarity.identify(data.currentUser.id, {
                name: data.currentUser.name,
                email: data.currentUser.email,
                userRole: data.currentUser.userRole,
            });
        }
        dispatch(loginCheckAuth(data))
        // saving education data in redux
        let educationData = []
        educationData['key'] = keys.education
        educationData['data'] = data.educationDetails ? data.educationDetails : []
        dispatch(updateProfileDataFromDB(educationData))

        // //saving experience data in redux
        let experienceData = []
        experienceData['key'] = keys.workExperience
        experienceData['data'] = data.experienceDetails ? data.experienceDetails : []
        dispatch(updateProfileDataFromDB(experienceData))

        // //saving language data in redux
        if (data?.currentUser?.candidate?.language) {
            let languageData = []
            languageData['key'] = keys.languages
            languageData['data'] = [{ language: data?.currentUser?.candidate?.language }]
            dispatch(updateProfileDataFromDB(languageData))
        }

        // //saving avatar data in redux
        if (data?.currentUser?.profileImage) {
            let avatarData = []
            avatarData['key'] = keys.selectAnAvatar
            avatarData['data'] = [{ profileImage: data?.currentUser?.profileImage }]
            dispatch(updateProfileDataFromDB(avatarData))
        }

        // //saving additional indo data in redux

        if (
            data?.currentUser?.candidate?.about ||
            (data?.currentUser?.candidate?.benefits &&
                data?.currentUser?.candidate?.benefits.length > 0)
        ) {
            let avatarData = []
            avatarData['key'] = keys.additionalInfos
            avatarData['data'] = [
                {
                    about: data?.currentUser?.candidate?.about,
                    benefits: data?.currentUser?.candidate?.benefits
                }
            ]
            dispatch(updateProfileDataFromDB(avatarData))
        }
    },
    onError(error) {
        dispatch(loginFailure(error.message))
    }
})


  const [loginMutation, { loading: loginLoading }] = useMutation(LOGIN_MUTATION, {
    onCompleted(data) {
        dispatch(loginSuccess(data))
        cookies.set('BUNTON_ACCESS_TOKEN', data.signIn.accessToken, { path: '/' })
        cookies.set('BUNTON_CLIENT_TOKEN', data.signIn.client, { path: '/' })
        cookies.set('BUNTON_EXPIRY', data.signIn.expiry, { path: '/' })
        cookies.set('BUNTON_UID', data.signIn.uid, { path: '/' })
        localStorage.removeItem('industry') 
        localStorage.removeItem('department')
        localStorage.removeItem('location')
        localStorage.removeItem('experience')
        localStorage.removeItem('name')
        localStorage.removeItem('pageType')
        refetch()
        if (data.signIn.lastLogoutAt) {
            navigate('/dashboard')
        } else {
            navigate('/profileSetup')
        }
    },
    onError(error) {
        // setApiError(error)
        // dispatch(loginFailure(error.message))
    }
})


  useEffect(() => {
    const params = parse(window.location.search)
    if (params.state !== localStorage.getItem(LINKEDIN_OAUTH2_STATE)) {
      setErrorMessage('State does not match');
    } else if (params.error) {
      const errorMessage =
        params.error_description || 'Login failed. Please try again.';
      window.opener &&
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: 'Linked In',
          },
          window.location.origin,
        );
      // Close tab if user cancelled login
      if (params.error === 'user_cancelled_login') {
        window.close();
      }
    }
    if (params.code) {
        if(window.opener) {
            window.opener.postMessage(
                { code: params.code, state: params.state, from: 'Linked In' },
                window.location.origin,
              )
        } else {
            const pageType = localStorage.getItem('pageType')
            if(pageType === 'registration') {
                const ind = localStorage.getItem('industry') 
                const dept = localStorage.getItem('department')
                const loca = localStorage.getItem('location')
                const exp = localStorage.getItem('experience')
                const name = localStorage.getItem('name')
                loginMutation({
                    variables: {
                        industry: ind,
                        department: dept,
                        location : loca,
                        experience: exp,
                        name: name,
                        provider: 'linkedin',
                        accessToken:params.code,
                        loginLocation: 'registration',
                        referrerSite: window.location.origin
                    }
                })
            } else {
                loginMutation({
                    variables: {
                        provider: 'linkedin',
                        accessToken:params.code,
                        loginLocation: 'login',
                        referrerSite: window.location.origin
                    }
                })
            }
        }
      
    }
  }, []);

  return errorMessage ? <div>{errorMessage}</div> : <Loader/>
}