import React from 'react'
import BaseModal from '../../template/BaseModal'
import { Box } from '@mui/material'
import ModalStatusSection from '../elements/ModalStatusSection'
import SuccessIcon from '../../assets/icons/success_green.svg'
import UploadResumeSection from './UploadResumeSection'

const ApplyJobSuccessModal = props => {
    const { open, handleClose, message, hasResume } = props
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            hideClose={hasResume}
            modalTitle={
                ''
            }
            boxSx={
                hasResume && {
                    minHeight: '100px',
                    minWidth: '250px',
                    width:'20%'
                }
            }
        >
            <Box>
                <ModalStatusSection
                    statusIcon={SuccessIcon}
                    statusText={message}
                    boxSx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0
                    }}
                    iconSx={
                        {
                            height: '45px',
                            width: '45px',
                            mb: 0,
                            mr: 1
                        }
                    }
                />
            </Box>
            {!hasResume && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <UploadResumeSection modalClose={handleClose}/>
                </Box>
            )}
        </BaseModal>
    )
}

export default ApplyJobSuccessModal
