import React from 'react'
import PageMetaTags from '../../../base/PageMetaTags'
import { useParams } from 'react-router-dom'
import AddJobComponent from './AddJobComponent'
import Layout from '../../../template/Layout'
import ScrollToTop from '../../../utils/ScrollToTop'

const AddJob = props => {
    let urlParams = useParams()
    const isEditPage = urlParams.jobId ? true : false

    return (
        <Layout>
            <ScrollToTop/>
            <PageMetaTags title={`${isEditPage ? 'Edit' : 'Add New'} Job | Bunton`} />
            {urlParams.companyId ? (
                <AddJobComponent companyId={urlParams.companyId} />
            ) : (
                <AddJobComponent />
            )}
        </Layout>
    )
}

export default AddJob
