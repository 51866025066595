import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import { gql, useQuery } from '@apollo/client'
import Button from '../Button'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import DefaultCompanyLogo from '../molecules/DefaultCompanyLogo'

const SUGGESTED_JOB_QUERY = gql`
    query SuggestedJobs(
        $afterCursor: String
        $keyword: String
        $industryIds: String
        $departmentIds: String
        $locationIds: String
        $experience: String
    ) {
        suggestedJobs(
            first: 3
            after: $afterCursor
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
        ) {
            edges {
                node {
                    id
                    jobTitle
                    experienceRequired
                    companyName
                    companyLogoUrl
                    states {
                        id
                        name
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const SuggestedJobsSideSection = props => {
    const {t} = useTranslation()
    const {jobDetails} = props
    const { loading, data, } = useQuery(SUGGESTED_JOB_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: ''
        },
        onCompleted(data) {
        },
        onError(error) {}
    })
    if (loading)
        return (
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <CircularProgress
                    sx={{
                        color: variables.BLUE
                    }}
                />
            </Box>
        )
    const jobsData = data.suggestedJobs.edges.map(edge => edge.node)
    const pageInfo = data.suggestedJobs.pageInfo
    return (
        <Box sx={{maxWidth: '360px', m: 'auto'}}>
            <Box sx={{borderBottom: `2px solid ${variables.CYAN}`, pb: 2}}>
                <Typography
                    variant='h3'
                    className='strong'
                > 
                    Suggestions for you
                </Typography>
            </Box>

            {jobsData &&
                jobsData.length > 0 &&
                jobsData.map(job => (
                    <Box
                        sx={{
                            my: 3
                        }}
                    >
                        <Grid container alignItems='center' columnSpacing={2}>
                            <Grid item xl={3} lg={3.5} md={4} sm={4} xs={4}>
                                <DefaultCompanyLogo
                                    imgSrc={job.companyLogoUrl}
                                    sx={{
                                        height: {
                                            sm: jobDetails? '70px': '40px',
                                            md: '50px'
                                        },
                                        width: {
                                            sm: jobDetails? '70px' : '40px',
                                            md: '50px'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={9} lg={8.5}
                                md={8}
                                sm={8}
                                xs={8}
                            >
                                <Box>
                                    <Typography
                                        variant='h5'
                                        className='small-size'
                                    >
                                        {job.companyName}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Link to={'/job-details/' + job.id}>
                                        <Typography
                                            variant='h5'
                                            className='small-size-strong'
                                        >
                                            {job.jobTitle}
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            {pageInfo && pageInfo.hasNextPage ? (
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        label={t('buttons.showall')}
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to={'/suggested-jobs'}
                        sx={{
                            padding: '10px 30px'
                        }}
                    />
                </Box>
            ) : null}
        </Box>
    )
}

export default SuggestedJobsSideSection
