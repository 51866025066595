import { Box, Container, FormControl, Grid, InputAdornment } from '@mui/material'
import React, { useEffect } from 'react'
import Button from '../Button'
import DropDown from '../elements/DropDown'
import TextInput from '../TextInput'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import FindJobIcon from '../../assets/icons/SEARCH.svg'
import Location from '../../assets/icons/LOCATION.svg'
import Industry from '../../assets/icons/INDUSTRY.svg'
import employee from '../../assets/icons/employee.svg'
import { companyStrength } from '../../utils/constantData'
import { useLocation, useNavigate } from 'react-router-dom'
import { renameNoPreferenceEntry } from '../../utils/commonFunctions'

const CompanySearch = (props) => {
    const {listAccess} = props 
    const location = useLocation()
    const state = location.state
    const { allStates, allIndustries } = useSelector(state => state.initial)
    const navigate = useNavigate()
    let allIndustriesData = renameNoPreferenceEntry(JSON.parse(JSON.stringify(allIndustries)))


    let allStatesData = JSON.parse(JSON.stringify(allStates))
    if (allStates && allStates.length > 0) {
        allStatesData = [{ id: '-1', name: 'No Preference' }].concat(allStates)
    }

    let companyStrengthData = JSON.parse(JSON.stringify(companyStrength))
    if (companyStrength && companyStrength.length > 0) {
        companyStrengthData = [{ id: '-1', name: 'No Preference' }].concat(companyStrength)
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            ...state
        }
    })

    const searchCompanies = data => {
        if (data.industryIds === '-1') {
            data.industryIds = ''
        }
        if (data.locationIds === '-1') {
            data.locationIds = ''
        }
        if (data.noOfEmployees === '-1') {
            data.noOfEmployees = ''
        }
        navigate(listAccess==='admin' ? '/admin-portal/companies' : '/companies', { state: { ...data, dashboard: false } })
    }

    useEffect(() => {
        reset(location.state)
    }, [location.state])

    return (
        <Container
            maxWidth='lg'
            disableGutters={{xs: true, sm: false}}
            sx={{
                my: {
                    xs: '20px',
                    sm: '30px',
                    md: '50px'
                },
                ...props.sx
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControl
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <TextInput
                                    type='text'
                                    value={value}
                                    name='keyword'
                                    placeholder='Find companies'
                                    formFieldError=''
                                    required={true}
                                    onChange={onChange}
                                    fullWidth={true}
                                    sx={{
                                        width: '100%',
                                        fontSize: '18px',
                                        padding: '0px'
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position='start'
                                                sx={{ marginLeft: '10px' }}
                                            >
                                                <Box sx={{ position: 'relative' }}>
                                                    <Box component='img' src={FindJobIcon} />{' '}
                                                </Box>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        )}
                        name='keyword'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3.6}
                    lg={3.6}
                >
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DropDown
                                startAdornment={<Box component='img' src={Location} />}
                                placeholder='Select Location'
                                lab='Location'
                                formControllerSx={{
                                    width: '100%'
                                }}
                                options={allStatesData}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                        name='locationIds'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3.6}
                    lg={3.6}
                >
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DropDown
                                startAdornment={<Box component='img' src={Industry} />}
                                placeholder='Select Industry'
                                lab='Industry'
                                formControllerSx={{
                                    width: '100%'
                                }}
                                options={allIndustriesData}
                                onChange={onChange}
                                value={value}
                                sx={{ left: '-10px' }}
                            />
                        )}
                        name='industryIds'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={3.6}
                    lg={3.6}
                >
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DropDown
                                startAdornment={<Box component='img' src={employee} />}
                                placeholder='Select employees'
                                lab='Employees'
                                formControllerSx={{
                                    width: '100%'
                                }}
                                options={companyStrengthData}
                                onChange={onChange}
                                value={value}
                                sx={{ left: '-10px' }}
                            />
                        )}
                        name='noOfEmployees'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={1}
                    lg={1}
                >
                    <Button
                        label='Go'
                        color='primary'
                        variant='contained'
                        size='large'
                        onClick={handleSubmit(searchCompanies)}
                        sx={{
                            boxShadow: 'none',
                            padding: '17px 29px',
                            width: {
                                xs: '100%'
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default CompanySearch
