import { Container } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import PageMetaTags from '../../base/PageMetaTags'
import Layout from '../../template/Layout'
import AdminDashboard from './AdminDashboard'


const AdminPortal = props => {
    return (
        <Layout>
            <PageMetaTags title='Admin Dashboard | Bunton' />
            <Container maxWidth='xl' sx={{my: 15}}>
                <AdminDashboard />
            </Container>
        </Layout>
    )
}

export default AdminPortal
