import { Box, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'


const EmptyListSection = props => {
    return <Box
    sx={{
        minHeight: '250px',
        borderRadius: '40px',
        paddingTop: '15px',
        backgroundColor: variables.CYAN1
    }}
>
    <Box
        sx={{
            margin: 'auto',
            width: '300px'
        }}
    >
        <Typography
            sx={{
                textAlign: 'center',
                fontSize: '20px',
                color: variables.BLUE,
                fontWeight: '600'
            }}
        >
            {props.message}
        </Typography>
    </Box>
</Box>
}

export default EmptyListSection