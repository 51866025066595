import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import UploadResumeModal from '../organisams/UploadResumeModal'

const UploadResumeNotification = props => {
    const [openUploadResumeModal, setOpenUploadResumeModal] = useState(false)
    return (
        <Box
            sx={{
                p: 2,
                borderRadius: '20px',
                maxWidth: '1200px',
                width: '100%',
                background: variables.BLUE,
                ...props.uploadNotificationSx
            }}
        >
            <Grid container spacing={2} alignItems='center'>
                <Grid
                    item
                    xl={9}
                    lg={9}
                    md={8}
                    sm={6}
                    xs={12}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            sm: 'left'
                        }
                    }}
                >
                    <Typography variant='h5' className='small-size-strong font-color-white'>
                        {props.uploadNotificationText ? props.uploadNotificationText :  'Upload Your Resume for Better Job Opportunities'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            sm: 'right'
                        }
                    }}
                >
                    <Button
                        label={props.uploadButtonLable ? props.uploadButtonLable :  'Upload Resume'}
                        color='secondary'
                        variant='contained'
                        size='large'
                        sx={{ p: '8px 20px', fontSize: '14px', ...props.uploadButtonSx }}
                        onClick={() => setOpenUploadResumeModal(true)}
                    />
                    {openUploadResumeModal && (
                        <UploadResumeModal
                            open={openUploadResumeModal}
                            onClose={() => setOpenUploadResumeModal(false)}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default UploadResumeNotification
