import { Box, Container, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import InformaticBox from '../elements/InformaticBox'
import BuntonIcon1 from '../../assets/icons/BUN_ICON_1.svg'
import BuntonIcon2 from '../../assets/icons/BUN_ICON_2.svg'
import BuntonIcon3 from '../../assets/icons/BUN_ICON_3.svg'
import ArrowRight from '../../assets/icons/ARROW_RIGHT.svg'
import Button from '../Button'
import { Link } from 'react-router-dom'
import ReactGA4 from 'react-ga4'

const CardStyle = styled(Box)`
    maxWidth: 100%;
    height: 350px;,
    border-radius: 60px;
} 
`

const cardsData = [
    {
        front: (
            <Box
                sx={{
                    p: 3,
                    borderRadius: '60px',
                    height: '212px',
                    pt: { xs: '90px', sm: '60px', md: '90px'}
                }}
            >
                <Box>
                    <Box component='img' src={BuntonIcon1} alt='' sx={{ height: '60px' }} />
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant='h3' className='extrabold'>
                        Vorstandspositionen finden Sie
                    </Typography>
                    <Typography
                        variant='h5'
                        className='small-size-16px poppins-light'
                        sx={{ mt: 1 }}
                    >
                        Ausschreibungen sind transparent & Unternehmen kontaktieren Sie direkt.
                    </Typography>
                </Box>
                <Box sx={{ p: 1, textAlign: 'end', mt: 1 }}>
                    <Box component='img' src={ArrowRight} sx={{ height: '18px', width: '18px' }} />
                </Box>
            </Box>
        ),
        back: (
            <Box sx={{ p: 3 }}>
                <Box>
                    <Typography variant='h3' className='extrabold'>
                        Executive Matching
                    </Typography>
                    <Box
                        component='ul'
                        sx={{ margin: 0, mt: { xs: 3, sm: 2, md: 3 }, paddingInlineStart: '16px' }}
                    >
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                                Matching anhand Ihrer
                                Qualifikationen, Auswahlkriterien &
                                Persönlichkeitsmerkmalen.
                            </Typography>
                        </Box>
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Direkter Zugang zu Unternehmen
                            </Typography>
                        </Box>
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Vertrauensvoller Umgang mit
                            ihren Daten durch Anonymisierung
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        label='Jetzt registrieren'
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to='/registration'
                        width='100%'
                        sx={{
                            mt: 2,
                            width: '100%',
                            fontSize: {
                                xs: '13px',
                                sm: '12px',
                                lg: '16px'
                            }
                        }}
                        onClick={() => {
                            ReactGA4.event({
                                category: 'Executive Matching',
                                action: 'Click',
                                label: 'Executive Matching'
                            })
                        }}
                    />
                </Box>
            </Box>
        )
    },
    {
        front: (
            <Box
                sx={{
                    p: 3,
                    borderRadius: '60px',
                    height: '212px',
                    pt: { xs: '90px', sm: '60px', md: '90px' }
                }}
            >
                <Box>
                    <Box component='img' src={BuntonIcon2} alt='' sx={{ height: '60px' }} />
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant='h3' className='extrabold'>
                    Ihre Stärken zählen
                    </Typography>
                    <Typography
                        variant='h5'
                        className='small-size-16px poppins-light'
                        sx={{ mt: 1 }}
                    >
                        Individuelle Fähigkeiten & Erfahrungen machen den Unterschied.
                    </Typography>
                </Box>
                <Box sx={{ p: 1, textAlign: 'end', mt: 1 }}>
                    <Box component='img' src={ArrowRight} sx={{ height: '18px', width: '18px' }} />
                </Box>
            </Box>
        ),
        back: (
            <Box sx={{ p: 3 }}>
                <Box>
                    <Typography variant='h3' className='extrabold'>
                    Recruiting auf
                    Augenhöhe
                    </Typography>
                    <Box
                        component='ul'
                        sx={{ margin: 0, mt: { xs: 3, sm: 2, md: 3 }, paddingInlineStart: '16px' }}
                    >
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Individuelle und proaktive
                            Ansprache zu passgenauen Jobs
                            </Typography>
                        </Box>
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Wertschätzende und
                            vertrauensvolle Interaktion
                            </Typography>
                        </Box>
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Skillbasiertes und kontinuierliches
                            Profil-Screening
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        label='Vorstandspositionen finden'
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to='/registration'
                        width='100%'
                        sx={{
                            mt: 2,
                            width: '100%',
                            fontSize: {
                                xs: '13px',
                                sm: '12px',
                                lg: '16px'
                            }
                        }}
                        onClick={() => {
                            ReactGA4.event({
                                category: 'board positions',
                                action: 'Click',
                                label: 'board positions'
                            })
                        }}
                    />
                </Box>
            </Box>
        )
    },
    {
        front: (
            <Box
                sx={{
                    p: 3,
                    borderRadius: '60px',
                    height: '212px',
                    pt: { xs: '90px', sm: '60px', md: '90px' }
                }}
            >
                <Box>
                    <Box component='img' src={BuntonIcon3} alt='' sx={{ height: '60px' }} />
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant='h3' className='extrabold'>
                    Kommunikation auf Sie abgestimmt
                    </Typography>
                    <Typography
                        variant='h5'
                        className='small-size-16px poppins-light'
                        sx={{ mt: 1 }}
                    >
                        Proaktive Ansprache und spezifische Profilentwicklung für ihren Erfolg.
                    </Typography>
                </Box>
                <Box sx={{ p: 1, textAlign: 'end', mt: 1 }}>
                    <Box component='img' src={ArrowRight} sx={{ height: '18px', width: '18px' }} />
                </Box>
            </Box>
        ),
        back: (
            <Box sx={{ p: 3 }}>
                <Box>
                    <Typography variant='h3' className='extrabold'>
                    Karriereentwicklung
                    auf allen Ebenen
                    </Typography>
                    <Box
                        component='ul'
                        sx={{ margin: 0, mt: { xs: 3, sm: 2, md: 3 }, paddingInlineStart: '16px' }}
                    >
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Exklusives Karrieregespräch mit
                            Vorständinnen
                            </Typography>
                        </Box>
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            K.I. optimierte Empfehlungen für
                            ihr Profil und Lebenslauf
                            </Typography>
                        </Box>
                        <Box component='li' sx={{ mt: 1 }}>
                            <Typography variant='h5' className='small-size poppins-light'>
                            Kuratierte Auswahl von
                            erfahrenen Karrierecoaches
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        label='Jetzt Profil anlegen'
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to='/registration'
                        width='100%'
                        sx={{
                            mt: 2,
                            width: '100%',
                            fontSize: {
                                xs: '13px',
                                sm: '12px',
                                lg: '16px'
                            }
                        }}
                        onClick={() => {
                            ReactGA4.event({
                                category: 'profile now',
                                action: 'Click',
                                label: 'profile now'
                            })
                        }}
                    />
                </Box>
            </Box>
        )
    }
]

const InformativeBoxSection = props => {
    return (
        <Container maxWidth='1520px' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={3} alignItems='center' sx={{ maxWidth: '1020px' }}>
                {cardsData.map((elem, index) => {
                    return (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                            <InformaticBox
                                frontCardComponent={<CardStyle>{elem.front}</CardStyle>}
                                backCardComponent={<CardStyle>{elem.back}</CardStyle>}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default InformativeBoxSection
