import { Container } from '@mui/material'
import React, { useState } from 'react'
import Layout from '../../template/Layout'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { ANONYMOUS_CHECK } from '../../queries/userDetailsQuery'
import Loader from '../../components/Loader'
import Profile from './Profile'

const UserProfile = props => {
    const { loginCheckAuthDetails, loginDetails } = useSelector(state => state.login)

    const location = useLocation()
    const params = useParams()
    const isMyProfilePage = location.pathname === '/my-profile'

    const userId = isMyProfilePage
        ? loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails.id
            ? loginDetails.id
            : 0
        : params.id

    const currentUserRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const [isAnonyomusProfile, setIsAnonymousProfile] = useState(false)
    const [isUserInvitedToApply, setIsUserInvitedToApply] = useState(false)
    const [isUserApplied, setIsUserApplied] = useState(false)

    const [isThereConnectionRequest, setIsThereConnectionRequest] = useState(false)
    const {
        loading: ananymousCheckLoading,
        data: ananymousCheckData,
        error: ananymousCheckError,
        refetch
    } = useQuery(ANONYMOUS_CHECK, {
        fetchPolicy: 'network-only',
        variables: {
            jobId: params.jobId ? params.jobId : 0,
            id: userId
        },

        onCompleted(data) {
            setIsAnonymousProfile(
                currentUserRole === 'employer' &&
                    data &&
                    data.user &&
                    data.user.userRole === 'user' &&
                    data.user.candidate &&
                    !data.user.candidate.isUserConnected
            )

            setIsUserInvitedToApply(
                currentUserRole === 'employer' && data && data.checkJobInvitation 
            )

            setIsUserApplied(
              currentUserRole === 'employer' && data && data.checkJobApplication 
          )


            setIsThereConnectionRequest(
                currentUserRole === 'user' &&
                    data &&
                    data.user &&
                    data.user.userRole === 'employer' &&
                    data.user.company &&
                    data.user.company.isConnectionRequested
            )
        }
    })

    if (ananymousCheckLoading) return <Loader />

    return (
        <Layout headerRequired={false}>
            <Container maxWidth={false} disableGutters>
                {ananymousCheckLoading ? (
                    <Loader />
                ) : (
                    <Profile
                        isAnonyomusProfile={isAnonyomusProfile}
                        isUserInvitedToApply={isUserInvitedToApply}
                        isUserApplied={isUserApplied}
                        isThereConnectionRequest={isThereConnectionRequest}
                        checkRefetch={refetch}
                    />
                )}
            </Container>
        </Layout>
    )
}

export default UserProfile 
