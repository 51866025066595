import { Box, Container } from '@mui/material'
import React from 'react'
import Layout from '../template/Layout'
import PageMetaTags from '../base/PageMetaTags'
import ContactUsList from '../components/organisams/ContactUsList'

const ContactUsRequest = props => {
    return (
        <Layout>
            <PageMetaTags title='Contact Request | Bunton' />
            <Container maxWidth='lg'>
                <Box>
                    <ContactUsList />
                </Box>
            </Container>
        </Layout>
    )
}

export default ContactUsRequest
