/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, createTheme, FormHelperText, TextField, ThemeProvider } from '@mui/material'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import variables from '../../settings/_variables.scss'
import { forwardRef } from 'react'

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: variables.CYAN
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: variables.CYAN
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '3px solid',
            borderColor: variables.CYAN,
            borderRadius: 72
        },
        '&:hover fieldset': {
            borderColor: variables.CYAN
        },
        '&.Mui-focused fieldset': {
            border: '3px solid ',
            borderColor: variables.CYAN,
            borderRadius: 72
        }
    }
})

const CssMultiLineTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: variables.CYAN
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: variables.CYAN
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '3px solid',
            borderColor: variables.CYAN,
            borderRadius: 40
        },
        '&:hover fieldset': {
            borderColor: variables.CYAN
        },
        '&.Mui-focused fieldset': {
            border: '3px solid ',
            borderColor: variables.CYAN,
            borderRadius: 40
        }
    }
})

const theme = createTheme({
    components: {
        MuiInputAdornment: {
            styleOverrides: {
                positionStart: {
                    marginLeft: '30px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    color: variables.BLUE,
                    fontSize: '18px',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                    '&::placeholder': {
                        opacity: 0.7
                    }
                }
            }
        }
    }
})

const TextInput = forwardRef(
    (
        {
            withoutLable=false,
            label = '',
            type = 'text',
            name = '',
            placeholder = '',
            onChange,
            required = false,
            formFieldError = '',
            disabled = false,
            variant = 'outlined',
            defaultValue='',
            multiline = false,
            rows = 1,
            value,
            size = 'medium',
            sx = {},
            InputProps = {},
            inputProps = {},
            onFocus,
            onBlur

        },
        ref
    ) => {
        return (
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        background: variables.WHITE1,
                        borderRadius: '50px',
                        width: '100%'
                    }}
                >
                    {!multiline ? <CssTextField
                        label={label}
                        variant={variant}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        required={required}
                        error={formFieldError ? true : false}
                        disabled={disabled}
                        value={value}
                        fullWidth
                        size='large'
                        sx={sx}
                        inputProps={inputProps}
                        InputProps={InputProps}
                        ref={ref}
                        defaultValue={defaultValue}
                    /> :
                    <CssMultiLineTextField
                        label={label}
                        variant={variant}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        required={required}
                        error={ formFieldError ? true : false}
                        disabled={disabled}
                        value={value}
                        multiline={multiline}
                        rows={rows}
                        fullWidth
                        size='large'
                        sx={sx}
                        inputProps={inputProps}
                        InputProps={InputProps}
                        ref={ref}
                        onFocus={onFocus}
                        defaultValue={defaultValue}
                    />
                    
                    
                    }
                </Box>
            </ThemeProvider>
        )
    }
)

export default TextInput
