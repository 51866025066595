export const ADD_PROFILE_DETAIL_SUCCESS = 'ADD_PROFILE_DETAIL_SUCCESS'
export const ADD_PROFILE_DETAIL_FAILURE = 'ADD_PROFILE_DETAIL_FAILURE'
export const PROFILE_DETAIL_LOADER = 'PROFILE_DETAIL_LOADER'
export const EDIT_PROFILE_DETAIL_SUCCESS = 'EDIT_PROFILE_DETAIL_SUCCESS'
export const DELETE_PROFILE_DETAIL_SUCCESS = 'DELETE_PROFILE_DETAIL_SUCCESS'
export const ADD_LANGUAGE_DATA_SUCCESS = 'ADD_LANGUAGE_DATA_SUCCESS'
export const ADD_AVATAR_DETAIL_SUCCESS = 'ADD_AVATAR_DETAIL_SUCCESS'
export const UPDATE_PROFILE_DATA_FROM_DB = 'UPDATE_PROFILE_DATA_FROM_DB'
export const DELETE_ALL_PROFILE_DETAILS = 'DELETE_ALL_PROFILE_DETAILS'
export const ADD_HAS_RESUME = 'ADD_HAS_RESUME'

