import { gql, useMutation, useQuery } from '@apollo/client'
import { Box, Container, Tabs, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MoreJobOffers from '../../../components/MoreJobOffers'
import { TabPanel } from '../../../utils/commonFunctions'
import Loader from '../../../components/Loader'
import PendingInvitations from '../../../components/PendingInvitations'
import CandidateDashboardAppliedList from '../../../components/CandidateDashboardAppliedList'
import StatusBox from '../../../components/StatusBox'
import AreyouhiringSection from '../../../components/AreyouhiringSection'
import CandidateLikedJobsList from '../../../components/organisams/CandidateLikedJobsList'
import EngagemnetQuestionsModal from '../../../components/organisams/EngagemnetQuestionsModal'
import { UPDATE_USER_LAST_QUESTION_ASKED } from '../../../gql/queries'
import DashboardHeaderSection from '../../../components/molecules/DashboardHeaderSection'
import CustomTab from '../../../components/elements/CustomTab'
import MandatoryQuestionsModal from '../../../components/organisams/MandatoryQuestionsModal'

const GET_APPLIED_JOBS_COUNT_QUERY = gql`
    query GetCandidateAppliedJobsCount($id: ID!) {
        getCandidateAppliedJobsCount(id: $id)
        getCandidateNotificationCount(id: $id)
        getLikedJobsCount(id: $id)
        engagementQuestionTrigger(id: $id)
    }
`

const CandidateDashboard = props => {
    const { loginCheckAuthDetails, loginDetails } = useSelector(state => state.login)
    const { profileDetail } = useSelector(state => state.profileDetail)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [listLoader, setListLoader] = useState(false)
    const navigate = useNavigate()
    const [notificationBadge, setNotificationBadgeData] = useState({})
    const [openEngagementModal, setOpenEngagementModal] = useState(false)

    const hasAnswered = loginCheckAuthDetails && loginCheckAuthDetails.hasAnswered
        ? loginCheckAuthDetails.hasAnswered
        : loginDetails && loginDetails.hasAnswered
        ? loginDetails.hasAnswered
        : false
    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const hasResume =
        profileDetail && profileDetail.uploadResume ? profileDetail.uploadResume : null
    const [mandatoryQuestionModal, setMandatoryQuestionModal] = useState(!hasAnswered)

    const {
        loading,
        data,
        refetch: refetchAppliedJobCount
    } = useQuery(GET_APPLIED_JOBS_COUNT_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: loginCheckAuthDetails.id ? loginCheckAuthDetails.id : loginDetails.id
        },
        onCompleted(data) {
            setOpenEngagementModal(data.engagementQuestionTrigger)
            setNotificationBadgeData(data.getCandidateNotificationCount)
            setListLoader(false)
        }
    })

    const [
        lastQuestionAskedMutation,
        { loading: mutationLoading, data: mutationData, error: mutationError }
    ] = useMutation(UPDATE_USER_LAST_QUESTION_ASKED, {
        onCompleted(data) {},
        onError(error) {
            console.log(error, 'error')
        }
    })
    const theme = useTheme()
    const [index, setIndex] = useState(
        localStorage.getItem('activeTab') ? parseInt(localStorage.getItem('activeTab')) : 0
    )

    const handleIndexChange = (event, index) => {
        setIndex(index)
    }

    useEffect(() => {
        localStorage.setItem('activeTab', index)
    }, [index])

    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')

    if (loading) return <Loader />
    const appliedJobsCount = data.getCandidateAppliedJobsCount
    const likedJobsCount = data.getLikedJobsCount
    return (
        <Container maxWidth={false} disableGutters={true}>
            <Container maxWidth='xl'>
                <Box sx={{ mt: { xs: 0, sm: 5 }, mb: { xs: 3, sm: 3 } }}>
                    <DashboardHeaderSection hasResume={hasResume}/>
                </Box>
                <Box>
                    <Tabs
                        onChange={handleIndexChange}
                        textColor='inherit'
                        value={false}
                        sx={{ '&.MuiTabs-root .MuiTabs-flexContainer': { flexWrap: 'wrap' } }}
                    >
                        {appliedJobsCount > 0 ? (
                            <CustomTab
                                notificationBadge={false}
                                elementIndex={0}
                                currentIndex={index}
                                tabTitle='Applied Jobs'
                                handleIndexChange={handleIndexChange}
                            />
                        ) : null}

                        <CustomTab
                            notificationBadge={
                                notificationBadge && notificationBadge.new_job_posts > 0
                            }
                            elementIndex={appliedJobsCount > 0 ? 1 : 0}
                            currentIndex={index}
                            tabTitle='Job offers'
                            tabRootSx={{ mr: { xs: 0, sm: 1 } }}
                            handleIndexChange={handleIndexChange}
                        />

                        <CustomTab
                            notificationBadge={
                                notificationBadge && notificationBadge.new_invites > 0
                            }
                            elementIndex={appliedJobsCount > 0 ? 2 : 1}
                            currentIndex={index}
                            tabTitle='Pending Invites'
                            handleIndexChange={handleIndexChange}
                        />

                        {likedJobsCount > 0 ? (
                            <CustomTab
                                notificationBadge={false}
                                elementIndex={appliedJobsCount > 0 ? 3 : 2}
                                currentIndex={index}
                                tabTitle='Favorites'
                                tabRootSx={{ mr: { xs: 0, sm: 1 } }}
                                handleIndexChange={handleIndexChange}
                            />
                        ) : null}

                        {responsiveBreakpoint !== 'mob' ? (
                            <CustomTab
                                notificationBadge={false}
                                elementIndex={appliedJobsCount > 0 ? 4 : 3}
                                currentIndex={index}
                                tabTitle='Find jobs'
                                onClick={() => {
                                    navigate('/job-list', {
                                        replace: true,
                                        state: { dashboard: true }
                                    })
                                }}
                            />
                        ) : null}
                    </Tabs>
                </Box>
                <Box sx={{ mt: 5 }}>
                    {appliedJobsCount > 0 ? (
                        <Box>
                            {
                                <TabPanel value={index} index={0} dir={theme.direction}>
                                    <CandidateDashboardAppliedList />
                                </TabPanel>
                            }

                            <TabPanel value={index} index={1} dir={theme.direction}>
                                <MoreJobOffers refetchAppliedJobCount={refetchAppliedJobCount} />
                            </TabPanel>
                            <TabPanel value={index} index={2} dir={theme.direction}>
                                <PendingInvitations
                                    setIsSuccess={setIsSuccess}
                                    setSuccessMessage={setSuccessMessage}
                                    refetchAppliedJobCount={refetchAppliedJobCount}
                                />
                            </TabPanel>
                            {likedJobsCount > 0 && (
                                <TabPanel value={index} index={3} dir={theme.direction}>
                                    <CandidateLikedJobsList
                                        refetchAppliedJobCount={refetchAppliedJobCount}
                                    />
                                </TabPanel>
                            )}
                        </Box>
                    ) : (
                        <Box>
                            <TabPanel value={index} index={0} dir={theme.direction}>
                                <MoreJobOffers refetchAppliedJobCount={refetchAppliedJobCount} />
                            </TabPanel>
                            <TabPanel value={index} index={1} dir={theme.direction}>
                                <Container
                                    maxWidth={false}
                                    disableGutters
                                    sx={{
                                        padding: {
                                            xs: '0px 20px',
                                            sm: '0px 40px',
                                            md: '0px 80px'
                                        }
                                    }}
                                >
                                    <PendingInvitations
                                        setIsSuccess={setIsSuccess}
                                        setSuccessMessage={setSuccessMessage}
                                        refetchAppliedJobCount={refetchAppliedJobCount}
                                    />
                                </Container>
                            </TabPanel>
                            {likedJobsCount > 0 && (
                                <TabPanel value={index} index={2} dir={theme.direction}>
                                    <CandidateLikedJobsList
                                        setIsSuccess={setIsSuccess}
                                        setSuccessMessage={setSuccessMessage}
                                    />
                                </TabPanel>
                            )}
                        </Box>
                    )}
                </Box>
            </Container>
            {['mob', 'tab'].includes(responsiveBreakpoint) ? null : (
                <Container
                    sx={{
                        mb: responsiveBreakpoint === 'mob' ? '50px' : null
                    }}
                    maxWidth={false}
                    disableGutters={true}
                >
                    <AreyouhiringSection text='For queries or assistance, reach out to us.' />
                </Container>
            )}
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
            {!mandatoryQuestionModal && openEngagementModal && (
                <EngagemnetQuestionsModal
                    open={openEngagementModal}
                    handleClose={() => {
                        setOpenEngagementModal(false)
                        lastQuestionAskedMutation({
                            variables: {
                                userId: loginCheckAuthDetails.id
                                    ? loginCheckAuthDetails.id
                                    : loginDetails.id
                            }
                        })
                    }}
                    userId={loginCheckAuthDetails.id ? loginCheckAuthDetails.id : loginDetails.id}
                />
            )}
            {
                mandatoryQuestionModal && <MandatoryQuestionsModal open={mandatoryQuestionModal} onCompleteClose={ () => setMandatoryQuestionModal(false)} onClose={() =>{}} userId={userId}/>
            }
        </Container>
    )
}

export default CandidateDashboard
