import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import variables from '../../settings/_variables.scss'
import Button from '../Button'

const UserRestriction = props => {
    return (
        <Container maxWidth='xl' sx={{mb: 10}}>
            <Box>
                <Box
                    sx={{
                        margin: 'auto',
                        textAlign: 'center',
                        marginTop: '50px'
                    }}
                >
                    <Typography
                    variant='h2'
                    className='extrabold'
                        sx={{
                            marginBottom: '40px'
                        }}
                    >
                        Register now to get full access.
                    </Typography>

                    <Button
                        label='Sign up'
                        component={Link}
                        to='/registration'
                        color='primary'
                        variant='contained'
                        size='large'
                        sx={{
                            padding: '13px 30px',
                            fontWeight: '600',
                            width:"167px",
                            height:"65px",
                            fontSize:"18px"
                        }}
                    />
                </Box>
            </Box>
        </Container>
    )
}

export default UserRestriction
