import { Box, Tab, Tabs, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { a11yProps, TabPanel } from '../../../utils/commonFunctions'
import { Link } from 'react-router-dom'
import AddIcon from '../../../assets/icons/NEEW.svg'
import CompanyJobList from '../../../components/organisams/CompanyJobList'

const CompanyProfile = props => {
    const { isEdit, company, isUserAuthenticated} = props
    const theme = useTheme()
    const [index, setIndex] = useState(0)
    const handleIndexChange = (event, index) => {
        setIndex(index)
    }
    const handleChangeIndex = index => {
        setIndex(index)
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems:'center'
                }}
            >
                <Tabs
                    value={index}
                    onChange={handleIndexChange}
                    textColor='inherit'
                    sx={{
                        width: '100%'
                    }}
                >
                    <Tab
                        label={<Typography variant='h3' className='section-heading extrabold'>Job Offers</Typography>}
                        disableFocusRipple={true}
                        disableRipple={true}
                        {...a11yProps(0)}
                        sx={{
                            pl: 0
                        }}
                    />
                </Tabs>
                {isEdit && index === 0 ? (
                    <Box>
                        <Link to='/add-job'>
                            <Tooltip title='Add New Job'>
                                <Box
                                    component='img'
                                    sx={{
                                        width: '22x',
                                        height: '22px'
                                    }}
                                    src={AddIcon}
                                />
                            </Tooltip>
                        </Link>
                    </Box>
                ) : null}
            </Box>
            <Box
                sx={{
                    mt: 4
                }}
            >
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={index} index={0} dir={theme.direction}>
                        {index === 0 && <Box>
                            <CompanyJobList company={company} isUserAuthenticated={isUserAuthenticated} isEdit={props.isEdit} containerSx={{maxHeight:'600px'}}/>
                        </Box>}
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </Box>
    )
}

export default CompanyProfile
