import { Box } from '@mui/material'
import { companyStrength, experiencesList } from './constantData'
import { LeftArrow, RightArrow } from '../components/IntrestingJobs/arrow'
import { StyledBenefitsChips } from '../base/commonStyles'
import { useSelector } from 'react-redux'

export const GetLoginDetails = () => {
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null
    return { userId: userId, userRole: userRole, isUserAuthenticated: isUserAuthenticated }
}

export const buntonPrivateCipher = (text, salt = 'BUNTONWEBAPP') => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0))
    const byteHex = n => ('0' + Number(n).toString(16)).substr(-2)
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)

    return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
}

export const buntonPrivateDecipher = (encoded, salt = 'BUNTONWEBAPP') => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0))
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)
    return encoded
        .match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('')
}

export const getFormattedObjectForData = initialData => {
    const iniData = [...initialData]
    iniData.map((data, index) => {
        const newData = { ...data }
        newData.checked = false
        newData.label = data.label ? data.label : data.name
        newData.value = data.id ? data.id : data.value
        iniData[index] = { ...newData }
    })
    return iniData
}

export const getIdFromStoreData = storedData => {
    const checkedData = storedData.filter(data => data.checked)
    const storeIds = []
    checkedData.map(cData => storeIds.push(cData.value))
    return storeIds
}

export const truncateDetails = (textToTruncate, textLimit = 20) => {
    return textToTruncate && textToTruncate.length > textLimit
        ? textToTruncate.substring(0, textLimit - 3) + '...'
        : textToTruncate
}

export const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

export const a11yProps = index => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    }
}

export const getCompanyIndustries = (industryArray, otherIndustry = '') => {
    let industries = null
    industryArray.map(industry => {
        if (industryArray.length === 1 || industries === null) {
            if(industry.name === 'Others' && otherIndustry) {
                industries = otherIndustry
            } else {
                industries = industry.name
            }
            
        } else {
            if (industries !== null) {
                if(industry.name === 'Others' && otherIndustry) {
                    industries = industries + ', ' + otherIndustry
                } else {
                    industries = industries + ', ' + industry.name
                }
                
            }
        }
    })
    return industries
}

export const getCompanyDepartments = departmentArray => {
    let departments = null
    departmentArray.map(department => {
        if (departmentArray.length === 1 || departments === null) {
            departments = department.name
        } else {
            if (departments !== null) {
                departments = departments + ', ' + department.name
            }
        }
    })
    return departments
}

export const getCompanyStates = stateArray => {
    let states = null
    stateArray.map(state => {
        if (stateArray.length === 1 || states === null) {
            states = state.name
        } else {
            if (states !== null) {
                states = states + ', ' + state.name
            }
        }
    })
    return states
}

export const getStringFromArray = (stringArray, otherFieldData = '') => {
    let data = null
    stringArray.map(string => {
        if (stringArray.length === 1 || data === null) {
            data = string.name
        } else {
            if (data !== null) {
                data = data + ', ' + string.name
            }
        }
        if (string.name === 'Others' && otherFieldData && otherFieldData !== '') {
            data = data + ' (' + otherFieldData + ')'
        }
    })
    return data
}

export const getExperienceLabel = value => {
    const expData = experiencesList.filter(exp => exp.value === value)
    return expData[0] ? expData[0].label : 'NA'
}

export const getEmployeesLabel = value => {
    const strengthData = companyStrength.filter(strength => strength.value === value)
    return strengthData[0] ? strengthData[0].label : 'NA'
}

export const reorderOthersOptionToLast = data => {
    let filteredObjects = data.filter(option => option.name !== 'Others')
    let otherObjects = data.filter(option => option.name === 'Others')
    return [...filteredObjects, ...otherObjects]
}

export const SliderFooterArrows = () => (
    <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', mt: 3 }}>
        <Box sx={{ mr: 5 }}>
            <LeftArrow />
        </Box>
        <Box>
            <RightArrow />
        </Box>
    </Box>
)

export const abrevatedLikesCount = number => {
    let s = ['', 'K', 'M', 'B', 'T']
    let sNum = Math.floor(('' + number).length / 3)
    let sVal = parseFloat((sNum !== 0 ? number / Math.pow(1000, sNum) : number).toPrecision(2))

    if (sVal % 1 !== 0) {
        sVal = sVal.toFixed(1)
    }

    return sVal + s[sNum]
}

export const removeNoPreferenceEntry = data => {
    return data.filter(entry => entry.name !== 'No Preference')
}

export const renameNoPreferenceEntry = data => {
    let filteredObjects = data.filter(option => option.name !== 'No Preference')
    let noprefObjects = data.filter(option => option.name === 'No Preference')
    let newArray = [...noprefObjects, ...filteredObjects]

    newArray = newArray.map(entry => {
        let entryData = { ...entry }
        if (entryData && entryData.name === 'No Preference') {
            entryData.name = 'All Industries'
        }
        return entryData
    })
    return newArray
    // return data
}

export const addHttpsToUrl = url => {
    var prefix = 'https://'
    if (url.substr(0, prefix.length) !== prefix) {
        url = prefix + url
    }
    return url
}

export const otherBenefitsChips = otherBenefits => {
    return otherBenefits.split(',').map(othBenefit =>
        othBenefit !== '' ? (
            <StyledBenefitsChips
                label={othBenefit}
                variant='outlined'
                sx={{
                    fontSize: '15px',
                    fontWeight: '400'
                }}
            />
        ) : null
    )
}


export const urlSlug = str =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');