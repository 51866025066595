import Layout from '../../template/Layout'
import { Box, Container, Grid, Typography} from '@mui/material'

import PageMetaTags from '../../base/PageMetaTags'

const TermsAndConditions = () => {

    return (
        <Layout>
            <PageMetaTags title='Terms And Conditions | Bunton' />
            <Container
                disableGutters={true}
                sx={{
                    padding: {
                        xs: '64px 20px',
                        sm: '64px 40px',
                        md: '64px 80px',
                        lg: '64px 80px',
                        xl: '64px 80px'
                    },
                    paddingBottom: '80px',
                    height: '100%'
                }}
            >
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box sx={{ marginBottom: '28px' }}>
                            <Typography variant='h2' className='strong'>Terms and Conditions</Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '25px',
                                    textAlign: 'center',
                                    fontWeight: '600'
                                }}
                            >
                                A. Allgemeineine Geschäftsbedingungen der Bunton GmbH (März 2024)
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    xs: 'auto',
                                    sm: 'auto',
                                    md: 'auto',
                                    lg: 'auto'
                                }
                            }}
                        >
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>1. Vertragsparteien</Typography>

                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    1.1. Diese Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“
                                    genannt) gelten für alle Verträge zwischen der Bunton GmbH,
                                    Deutschherrnufer 47, 60594 Frankfurt am Main, vertreten durch
                                    die Geschäftsführer, Janet Winkler, Debjit Datta Chaudhuri und
                                    dem Kunden und regeln mit der Erbringung von Leistungen in
                                    Zusammenhang stehenden Einzelheiten zwischen diesen Parteien.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    1.2. Die kommerziellen Angebote von Bunton im Sinne des
                                    Abschnitts B. dieser AGB richten sich ausschließlich an
                                    Unternehmer im Sinne des § 14 BGB und/oder an Personen, die als
                                    Kaufmann im Sinne des § 1 HGB oder als juristische Person des
                                    Privatrechts bzw. öffentlichen Rechts angesehen werden.
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>2. Vertragsschluss</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.1. Der Kunde erklärt sein Einverständnis in die allgemeinen
                                    Geschäftsbedingungen von Bunton,
                                    und gibt ein verbindliches Angebot für den Abschluss eines
                                    Vertrages ab. Der Vertragsschluss steht unter der Voraussetzung,
                                    dass der Kunde über die erforderliche Bonität zur Erfüllung des Vertrages verfügt und Bunton
                                    das Angebot annimmt. Das Angebot gilt als angenommen, sofern
                                    Bunton mit der Vertragsdurchführung beginnt und/oder der Kunde
                                    eine Bestellbestätigung erhält.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2. Schließen die Parteien am Telefon einen Vertrag, so erhält
                                    der Kunde eine schriftliche Bestätigung per E-Mail inkl. der
                                    telefonisch vereinbarten Bestimmungen und Konditionen. Weiterer
                                    Erklärungen der Parteien bedarf es nicht.
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>3. Leistungsbeschreibung</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    3.1. Die Leistungsbeschreibung der jeweils angebotenen Leistung
                                    ergibt sich aus Teil B dieser AGB.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    3.2. Der Vertrag berechtigt Bunton dazu, die Kundendaten und im
                                    Rahmen der Geschäftsbeziehung anfallende Daten für Trendanalysen
                                    und Übersichten zu nutzen und anonymisiert zu veröffentlichen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    3.3. Bunton ist berechtigt, die Gestaltung und
                                    Darstellungsformen seiner betriebenen Internetplattformen nach
                                    eigenem Ermessen jederzeit anzupassen. Bunton ist berechtigt,
                                    einzelne Funktionalitäten und Inhalte seiner Dienste zu
                                    verändern, zu ergänzen und/oder abzuschalten, solange dadurch nicht
                                    wesentliche Leistungspflichten von Bunton gegenüber dem Kunden
                                    beeinträchtigt werden.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Insofern ist Bunton insbesondere berechtigt, regelmäßige Tests
                                    auf seinen Webseiten durchzuführen. Dabei wird getestet, welche
                                    Darstellungsweisen, Produktänderungen oder Dienstleistungen von
                                    Nutzern und Kunden besser angenommen werden. In der Regel wird
                                    Bunton die Kunden nicht im Voraus über solche Tests informieren.
                                    Grundsätzlich hat der Kunde jedoch auch keinen Anspruch darauf,
                                    der Durchführung eines Tests zu widersprechen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    4. Zahlungsvereinbarungen, Kostenregelung und
                                    Zurückbehaltungsrecht
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    4.1. Die Rechnungsstellung erfolgt mit Vertragsschluss, es sei
                                    denn es ist individualvertraglich etwas anderes vereinbart. Alle
                                    Preise verstehen sich zuzüglich der gesetzlichen Umsatzsteuer.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    4.2. Soweit nicht etwas anderes vereinbart ist, wird der
                                    Zahlungsanspruch mit Vertragsschluss unmittelbar fällig und ist vom Kunden
                                    innerhalb einer Frist von 14 Tagen nach Rechnungsstellung zu
                                    zahlen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    4.3. Bunton ist berechtigt, bei Zahlungsverzug, Leistungen sowie
                                    Teile davon bis zur vollständigen Zahlung einzustellen. Die Geltendmachung weitere Anspruchs behält sich bunton vor.

                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    4.4. Eine Zahlung per Überweisung aus dem Ausland akzeptiert
                                    Bunton nur bei gleichzeitiger Übernahme aller anfallenden
                                    Bankgebühren seitens des Kunden.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    5. Grundlagen der Zusammenarbeit
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    5.1. Die Rechte des Kunden aus dem Vertrag auf Suche einer offenen Position sind unübertragbar
                                    und nicht abtretbar. Eine Vertragsübernahme durch Dritte bedarf
                                    der schriftlichen Zustimmung von Bunton.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    5.2. Der Kunde verpflichtet sich, Bunton alle Auskünfte und
                                    Unterlagen rechtzeitig zukommen zu lassen, die für die
                                    Erbringung der vertragsgegenständlichen Leistung erforderlich
                                    und zweckmäßig sind. Darüberhinausgehende Mitwirkungspflichten
                                    des Kunden, ergeben sich aus Teil B dieser AGB entsprechend der
                                    jeweiligen produktspezifischen Leistungsbeschreibung.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    5.3. Der Kunde stellt sicher, dass alle von ihm auf den
                                    Webseiten von Bunton veröffentlichten oder zur Veröffentlichung
                                    an Bunton übergebenen Inhalte, weder sittenwidrig, rechtswidrig,
                                    noch unlauter sind und Rechte Dritter nicht verletzen. Der Kunde
                                    bestätigt mit der Auftragserteilung insbesondere, dass er über
                                    die erforderlichen Nutzungsrechte verfügt, die notwendig sind,
                                    um eine Einstellung und Veröffentlichung des übermittelten
                                    Inhaltes zu veranlassen. Bunton ist nicht verpflichtet, die
                                    Inhalte oder sonstiges vom Kunden zur Verfügung gestelltes
                                    Material zu überprüfen oder aufzubewahren. Der Kunde wird Bunton
                                    durch eine Verletzung dieser Vorschrift entstehende Schäden unverzüglich ersetzen. Sollte ein Dritter Bunton wegen
                                    der Verletzung einer Pflicht im Sinne dieses Abschnittes in
                                    Anspruch nehmen, wird Bunton den Kunden hierüber informieren. In
                                    einem solchen Fall unterstützt der Kunde Bunton bei der Abwehr
                                    eines solchen Anspruches.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    5.4. Zur Weiterentwicklung unserer (Dienst-)Leistungen,
                                    insbesondere im Hinblick auf branchenspezifische Trends und die
                                    proaktive Reaktion auf Marktveränderungen, führt Bunton
                                    regelmäßig Tests durch. Dabei wird getestet, welche
                                    Darstellungsweisen, Produktänderungen oder Dienstleistungen von
                                    Nutzern und Kunden besser angenommen werden.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    5.5. Beide Parteien werden angemessene Anstrengungen
                                    unternehmen, um einen störungsfreien Betrieb ihrer jeweiligen
                                    Internetplattformen und -systeme zu gewährleisten. Es kann
                                    jedoch nicht garantiert werden, dass die Internetplattformen und
                                    -systeme keine Betriebsunterbrechungen erfahren. Insbesondere
                                    kann es aufgrund von Wartungsarbeiten zu Störungen und
                                    Betriebsunterbrechungen kommen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>6. Registrierung und Anmeldung</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Soweit sich der Kunde für die Nutzung eines Dienstes auf den von
                                    Bunton betriebenen Websites registriert, ist er verpflichtet,
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    6.1. sämtliche zur Anmeldung abgefragten Daten vollständig und
                                    wahrheitsgemäß anzugeben,
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    6.2. Zugangsdaten und Inhalte vor dem widerrechtlichen Zugriff
                                    Dritter angemessen zu schützen,
                                </Typography>                  
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    6.3. Bunton unverzüglich zu informieren, sofern der Kunde einen
                                    unbefugten Zugriff auf das registrierte Konto vermutet und/oder
                                    Anhaltspunkte für einen entsprechenden Zugriff bestehen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    7.Urheberrechte und sonstige Leistungsschutzrechte
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    7.1 Dieser Vertrag beinhaltet keine Übertragung von Eigentums-
                                    oder Nutzungsrechten, Lizenzen oder sonstigen Rechten an der
                                    Software auf den Kunden. Alle Rechte an der vom Kunden genutzten
                                    Software, an Kennzeichen, Titeln, Marken und Urheber- und
                                    sonstigen gewerblichen Rechten/Leistungsschutzrechten von Bunton
                                    verbleiben uneingeschränkt bei Bunton.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    7.2 Der Kunde räumt Bunton das notwendige, nicht-ausschließliche
                                    Nutzungsrecht an den geistigen Leistungsrechten des Kunden ein,
                                    soweit dies für die Erfüllung des Vertrages erforderlich ist.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    7.3. Bunton ist Hersteller und Urheber seiner Datenbanken im
                                    Sinne von § 87a (1), (2) UrhG und § 4 UrhG und ist Inhaber
                                    sämtlicher damit verbundener ausschließlicher Rechte. Sofern
                                    nicht anders vereinbart, verbleiben alle geistigen
                                    Eigentumsrechte des Kunden, die für die Erfüllung des Vertrages
                                    genutzt werden, bei dem Kunden.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>8. Referenzkundenmarketing</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Bunton ist berechtigt, den Kunden als Referenzkunden zu nennen.
                                    Hierzu wird für die Dauer der Vertragslaufzeit das Logo des
                                    Kunden sowohl unter www.Bunton.de, als auch im Rahmen sonstiger
                                    Marketingmaßnahmen, insbesondere im Rahmen von Präsentationen,
                                    Messematerialien und Broschüren, veröffentlicht. Zu diesem
                                    Zwecke räumt der Kunde Bunton das Nutzungsrecht an seinem Logo
                                    ein.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    9. Gewährleistung und Verjährung
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    9.1. Grundlage für die Leistungserbringung ist ausschließlich
                                    der schriftlich vereinbarte Leistungsumfang. Der Kunde hat die
                                    Leistungen unverzüglich nach Erhalt zu prüfen und etwaige Mängel
                                    unverzüglich gegenüber Bunton anzuzeigen. Unterlässt er dies,
                                    gelten die Leistungen als mangelfrei erbracht. Es sei denn, bunton hätte den Mangel arglistig verschwiegen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    9.2. Die Gewährleistungsansprüche des Kunden erstrecken sich
                                    nicht auf Mängel, die in einernur unerheblichen Abweichung von
                                    der vereinbarten Beschaffenheit bestehen oder in einer nur
                                    unerheblichen Beeinträchtigung von der Brauchbarkeit. Es sei denn, bunton hätte den Mangel arglistig verschwiegen.

                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    9.3. Sämtliche Gewährleistungsansprüche verjähren innerhalb
                                    eines Jahres. Die Verjährungsfrist beginnt mit dem Zeitpunkt,
                                    indem der Kunde Kenntnis von dem Mangel erlangt oder im Falle
                                    von grober Fahrlässigkeit hätte erlangen müssen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>10. Haftung</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    10.1. Bunton haftet nach den gesetzlichen Bestimmungen gleich aus welchem
                                    Rechtsgrund bei Vorsatz und grober Fahrlässigkeit.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    10.2. Bei einfacher Fahrlässigkeit haftet Bunton nur bei Verletzung einer
                                    Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung
                                    des Vertrags überhaupt ermöglicht und auf deren Einhaltung der
                                    Kunde regelmäßig vertrauen darf (so genannte Kardinalpflicht),
                                    und zwar beschränkt auf den Ersatz des vorhersehbaren und
                                    typischen Schadens. In allen übrigen Fällen ist eine Haftung –
                                    vorbehaltlich des nachfolgenden Absatzes - ausgeschlossen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    10.3. Die Haftung für Schäden aus der vorsätzlichen oder fahrlässigen Verletzung des Lebens, des
                                    Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz
                                    bleibt von den vorstehenden Haftungs-beschränkungen und
                                    -ausschlüssen unberührt.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    10.4. Soweit die Haftung von Bunton ausgeschlossen oder beschränkt ist, gilt das auch für die persönliche Haftung ihrer Mitarbeiter, Vertreter und Erfüllungsgehilfen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    10.5. Werden von Dritten Ansprüche („Schutzrechtsanspruch“)
                                    gegen den Kunden wegen der Verletzung von Patenten,
                                    Urheberrechten, Marken/Designs/Geschmacksmuster, geschäftlichen Bezeichnungen oder
                                    Geschäftsgeheimnissen durch eine Leistung von Bunton
                                    („Schutzrechtsverletzung“) erhoben, stellt Bunton den Kunden von
                                    allen Kosten (einschließlich angemessener
                                    Rechtsverteidigungskosten) und Forderungen frei, die ihm durch
                                    rechtskräftige Urteile zuständiger Gerichte oder von Bunton
                                    durch schriftliche Vergleiche entstehen, vorausgesetzt, dass (i)
                                    die Ursache für die Schutzrechtsverletzung von Bunton gesetzt wurde, beispielsweise im Fall der Veröffentlichung
                                    unzulässiger Inhalte nach Teil B., Ziffer 1.3.2., (ii) der Kunde
                                    Bunton schriftlich innerhalb von höchstens zwanzig (20)
                                    Arbeitstagen nach erstmaliger Anspruchsstellung unterrichtet,
                                    (iii) der Kunde angemessene
                                    Unterstützung und alle Informationen zur Verfügung stellt, damit
                                    Bunton Verpflichtungen hiernach wahrnehmen kann. Vorstehende
                                    Verpflichtung gilt nicht für Maßnahmen oder Erklärungen, denen
                                    Bunton nicht zuvor schriftlich zugestimmt hat und nicht, soweit
                                    der Kunde Verletzungshandlungen fortsetzt, nachdem ihm
                                    Änderungen mitgeteilt wurden, die eine Verletzung verhindert
                                    hätten.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Wird eine Schutzrechtsverletzung durch ein zuständiges Gericht
                                    festgestellt oder von Bunton für möglich gehalten, kann Bunton
                                    nach eigenem Ermessen und auf eigene Kosten entweder (i) die
                                    Leistungen so ersetzen oder ändern, dass keine
                                    Schutzrechtsverletzung mehr vorliegt, oder (ii) dem Kunden ein
                                    Nutzungsrecht an dem Schutzrecht verschaffen oder (iii) wenn
                                    Maßnahmen nach (i) oder (ii) nicht möglich oder nicht zumutbar
                                    sind, diesen Vertrag aus wichtigem Grund außerordentlich mit sofortiger Wirkung kündigen, wenn Bunton ein Festhalten am Vertrag unzumutbar ist.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    11. Geheimhaltung und Schutz von Personendaten
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.1. Bunton verpflichtet sich, alle als „vertraulich“
                                    gekennzeichneten Informationen, die Bunton von dem Kunden im
                                    Rahmen dieses Vertrages erhält, geheim zu halten. Diese Pflicht
                                    wird von Bunton auch nach Ablauf der Vertragslaufzeit erfüllt.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.2. Dem Kunden obliegt es, bei der Benutzung von IDs,
                                    Kennwörtern, Benutzernamen oder anderen
                                    Sicherheitsvorrichtungen, die im Zusammenhang mit den Services
                                    zur Verfügung gestellt werden, größtmögliche Sorgfalt walten zu
                                    lassen und jedwede Maßnahme zu ergreifen, welche den
                                    vertraulichen, sicheren Umgang mit den Daten gewährleistet und
                                    deren Bekanntgabe an Dritte verhindert. Für den Gebrauch seiner
                                    Kennwörter oder Benutzernamen durch Dritte wird der Kunde zur
                                    Verantwortung gezogen, falls er nicht nachhaltig darlegen kann,
                                    dass der Zugang zu solchen Daten nicht durch ihn selbst
                                    verursacht wurde und die Gründe dafür nicht von ihm beeinflusst,
                                    werden konnten. Der Kunde ist verpflichtet, Bunton unverzüglich
                                    über eine mögliche oder bereits bekannt gewordene, nicht
                                    autorisierte Verwendung seiner Zugangsdaten zu informieren. Bei
                                    Verletzung einer oder mehrerer der in diesen AGB genannten
                                    Verpflichtungen seitens des Kunden, insbesondere aber nicht
                                    ausschließlich der unter diesem Punkt aufgeführten, ist Bunton
                                    berechtigt, die Services ohne weitere Benachrichtigung zu
                                    beenden und von der Internetseite zu entfernen, ohne dabei auf
                                    irgendwelche Zahlungsverpflichtungen des Kunden zu verzichten.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.3. Die Vertragsparteien verpflichten sich, sämtliche
                                    Informationen, die sie mittelbar oder unmittelbar im
                                    Zusammenhang mit dem jeweiligen Vertrag sowie im Rahmen seiner
                                    Durchführung erhalten haben und die technischer, finanzieller
                                    oder sonst geschäftlicher oder vertraulicher Natur sind, geheim
                                    zu halten und nicht an Dritte weiterzugeben. Keine Dritten sind
                                    mit Bunton im Sinne von §§ 15 ff. AktG verbundene Unternehmen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.4. Geheimhaltungsverpflichtung gilt nicht für solche
                                    Informationen, die offenkundig sind oder die der Partei schon
                                    bekannt waren, oder die die Partei in gesetzlich zulässiger
                                    Weise von dritter Seite erhalten oder ohne Verstoß gegen
                                    Geheimhaltungsverpflichtungen selbst entwickelt hat. Die
                                    Nachweispflicht obliegt derjenigen Partei, die sich hierauf
                                    beruft. Diese
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.5. Diese Verpflichtungen zur umfassenden Geheimhaltung
                                    bleiben auch nach Beendigung des jeweiligen Vertrags bestehen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.6. Bunton ist berechtigt, personenbezogene Daten des Kunden
                                    unter Beachtung und Einhaltung der Bestimmungen der anwendbaren
                                    Datenschutzgesetze, -Richtlinien und sonstigen Vorschriften zu
                                    erheben, zu verarbeiten und zu speichern.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    11.7. Bunton gewährleistet darüber hinaus, dass alle
                                    Mitarbeiter*innen von Bunton auf das Datengeheimnis und die
                                    Wahrung besonderer Vertraulichkeit gemäß anwendbarer
                                    Datenschutz-, Telekommunikations- und sonstiger einschlägiger
                                    Gesetze verpflichtet wurden.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    12. Abmahnung, gerichtliche Entscheidung
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Ist der Kunde wegen einer Veröffentlichung bzw. deren Inhalt auf
                                    den Webseiten von Bunton abgemahnt worden, hat der Kunde bereits
                                    eine Unterlassungserklärung bezüglich bestimmter zur
                                    Veröffentlichung zur Verfügung gestellter oder selbst
                                    veröffentlichter Inhalte abgegeben oder wurde eine entsprechende
                                    einstweilige Verfügung, ein Urteil oder sonst eine gerichtliche
                                    Entscheidung oder behördliche Verfügung zugestellt, so ist der
                                    Kunde verpflichtet, Bunton unverzüglich schriftlich darüber zu
                                    informieren. Unterlässt der Kunde dies, so haftet Bunton nicht.
                                    Der Kunde ist dann dazu verpflichtet, Bunton von einer etwaigen
                                    Inanspruchnahme Dritter auf erstes Anfordern freizustellen und
                                    Bunton den daraus entstandenen Schaden zu ersetzen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>13. Laufzeit und Kündigung</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Der Vertrag tritt mit Zeitpunkt des Vertragsschlusses in Kraft,
                                    es sei denn, es ist abweichendes vereinbart. Der Vertrag endet
                                    automatisch mit Ablauf der vereinbarten Zeit, es sei denn,
                                    produktspezifisch ergibt sich etwas anderes aus{' '}
                                    <a
                                        href='https://www.stepstone.de/e-recruiting/allgemeine-geschaftsbedinungen/#b'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Teil B
                                    </a>{' '}
                                    dieser AGB.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>14. Anpassungen der AGB</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    Bunton behält sich vor, diese AGB anzupassen, insbesondere wenn dies
                                    erforderlich ist. Erforderlich ist eine Anpassung bei
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    14.1. Umsetzung von Änderungen des anwendbaren Rechts;
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    14.2. Korrektur redaktioneller Fehler;
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    14.3. Aufnahme neuer Produkte;
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    14.4. Präzisierung von Klauseln zur Erleichterung der Auslegung
                                    des Vertrages.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                Bunton teilt dem Kunden die Änderungen innerhalb von zwei Wochen vor Inkrafttreten der Änderungen in Textform mit.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                Ist die Änderung für den Kunden negativ, ist dieser berechtigt innerhalb von zwei Wochen nach Zugang der Änderungsmitteilung, den Vertrag außerordentlich zu kündigen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>15. Schlussbestimmungen</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    15.1. Diese AGB und die gesamten Rechtsbeziehungen der
                                    Vertragsparteien unterliegen dem Recht der Bundesrepublik
                                    Deutschland unter Ausschluss des UN-Kaufrechts (CISG) und des
                                    internationalen Privatrechts sowie dessen Rechtswahlklauseln
                                    oder Kollisionsnormen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    15.2. Sollten einzelne Bestimmungen ganz oder teilweise
                                    unwirksam, undurchführbar oder undurchsetzbar sein oder werden,
                                    so wird die Wirksamkeit, Durchführbarkeit oder Durchsetzbarkeit
                                    der übrigen Bestimmungen hierdurch nicht berührt.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    15.3. Ausschließlicher Gerichtsstand ist, soweit gesetzlich zulässig, Frankfurt am Main,
                                    Deutschland.
                                </Typography>
                            </Box>
                        </Box>

                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '25px',
                                    textAlign: 'center',
                                    fontWeight: '600'
                                }}
                            >
                                B. Besonderer Teil der Allgemeinen Geschäftsbedingungen
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    xs: 'auto',
                                    sm: 'auto',
                                    md: 'auto',
                                    lg: 'auto'
                                }
                            }}
                        >
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    1. Stellenanzeigen und Nutzung der Plattform
                                </Typography>
                                <Box>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>1.1.</Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.1. Bunton stellt dem Kunden Anzeigenplätze für die
                                        Veröffentlichung von Stellenanzeigen auf den Webseiten von
                                        Bunton zur Verfügung. Die Stellenanzeigen („Anzeigen“)
                                        werden im Namen des Kunden im Internet veröffentlicht.
                                    </Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.2. Bunton integriert bei den Stellenanzeigen einen
                                        Button, der mit „Jetzt Bewerben“, „Ich bin interessiert“
                                        oder ähnlich beschriftet ist. Über diese Verlinkung können
                                        Kandidat*innen mittels des dort hinterlegten
                                        Bewerbungsformulars ihre Kontaktdaten eintragen sowie
                                        Lebenslauf und weitere Bewerbungsunterlagen hochladen. Je
                                        nach Auswahl des Kunden kann dieser Button entweder auf eine
                                        vom Kunden benannte Webseite oder auf ein von Bunton auf
                                        ihren Plattformen betriebenes und standardisiertes
                                        Bewerbungsformular verlinken. Die im Bewerbungsformular
                                        abgefragten Daten werden durch Absenden der Bewerbung sodann
                                        in das Berwerbermanagementsystem des Kunden oder in das von
                                        Bunton dem Kunden zurVerfügung gestellte Kundencenter
                                        übermittelt, je nach dem, welches System der Kunde für die
                                        Bewerber*innenverwaltung nutzt.
                                    </Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.3. Soweit die Übertragung von Statusinformationen durch
                                        den Kunden an Bunton technisch eingerichtet und möglich ist,
                                        wird der Kunde Bunton Informationen zum Bewerbungsstatus der
                                        Kandidat*innen übermitteln. Der Kunde stellt Bunton nur die
                                        Statusinformationen solcher Kandidat*innen zur Verfügung,
                                        die sich beim Kunden über die Bunton Plattform beworben
                                        haben und die bei Bunton mit einem Nutzerkonto registriert
                                        sind. Statusinformationen sind Informationen zum
                                        Bewerbungsstatus von Kandidat*innen beim Kunden. Dies
                                        beinhaltet z.B. den Erhalt der Bewerbung, das Öffnen der
                                        Bewerbung oder die Ablehnung von Kandidat*innen. Bunton
                                        bleibt es unbenommen, eine anonymisierte Nutzung der Daten
                                        zur statistischen Auswertung vorzunehmen.
                                    </Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.4. Bunton übernimmt für angeliefertes Datenmaterial,
                                        Anzeigentexte oder diesbezügliche Speichermedien keine
                                        Verantwortung und ist insbesondere nicht verpflichtet, diese
                                        aufzubewahren oder an den Kunden zurückzugeben. Eine
                                        Löschung nach Vertragsende erfolgt nach den gesetzlichen
                                        Vorgaben.
                                    </Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.5. Stellenanzeigen werden je nach der vom Kunden
                                        gewählten Produktkategorie für den jeweils
                                        individualvertraglich vereinbarten Zeitraum
                                        („Schaltungsdauer“) auf der Website geschaltet. Solange
                                        dieAnzeigen innerhalb der Vertragslaufzeit geschaltet
                                        werden, werden sie für die Gesamtdauer der vereinbarten
                                        Schaltungsdauer veröffentlicht. Nach Ablauf der
                                        Schaltungsdauer kann eine Stellenanzeige verlängert werden,
                                        soweit dies innerhalb der Vertragslaufzeit erfolgt. Die
                                        Verlängerung der Veröffentlichung einer Stellenanzeige gilt
                                        als erneute kostenpflichtige Schaltung auf Grundlage des
                                        zwischen Bunton und dem Kunden bestehenden Vertrags.
                                    </Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.6. Stellenanzeigen können von registrierten Nutzer*innen
                                        in deren Account für einen Zeitraum von maximal sechs
                                        Monaten gespeichert werden und können deshalb auch über die
                                        Schaltungsdauer nach Teil A Ziffer 1.1.5 hinaus für
                                        registrierte Nutzer*innen sichtbar sein.
                                    </Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.1.7. Die Schaltung der Stellenanzeige kann nur innerhalb
                                        der vereinbarten Vertragslaufzeit erfolgen. Mit Ablauf der
                                        Vertragslaufzeit erlischt das Recht des Kunden auf Nutzung
                                        der Anzeigenplätze, die innerhalb der Vertragslaufzeit nicht
                                        genutzt wurden. Die Anzahl der vereinbarten Stellenanzeigen
                                        kann nur innerhalb der vereinbarten Vertragslaufzeit
                                        abgerufen werden.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>1.2.</Typography>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.2.1. Bunton behält sich vor, vom Kunden erteilte Aufträge
                                        nicht auszuführen, oder im Hinblick auf bereits
                                        veröffentlichte Stellenanzeigen, diese wieder zu entfernen,
                                        soweit die zu veröffentlichenden Inhalte gegen gesetzliche
                                        Vorgaben, behördliche Verbote, Rechte Dritter, gegen die
                                        guten Sitten oder gegen die AGB von Bunton verstoßen. Das
                                        Gleiche gilt, soweit im Auftrag des Kunden Links auf
                                        Leistungselemente gesetzt werden, die unmittelbar oder
                                        mittelbar auf Seiten mit unzulässigen Inhalten führen. Die
                                        Zahlungspflicht des Kunden bleibt hiervon unberührt. Bunton
                                        ist zur Entfernung solcher unzulässigen Inhalte nur im
                                        Rahmen der gesetzlichen Bestimmungen sowie auf Aufforderung
                                        des Kunden verpflichtet. Soweit Bunton wegen unzulässiger
                                        Inhalte oder sonstigen Gesetzesverstöße in Anspruch genommen
                                        wird, die vom Kunden zu vertreten sind, stellt der Kunde
                                        Bunton auf erstes Anfordern frei. Die Freistellung umfasst
                                        die erforderlichen Rechtsverfolgungskosten.
                                    </Typography>
                                    <Box>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2. Bei Verstößen gegen die nachfolgenden Regelungen
                                            behält sich Bunton die Rechte im Sinne von B. 1.3.1. –
                                            insbesondere das Recht, Stellenanzeigen zu entfernen –
                                            ausdrücklich vor:
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.1. Wird eine selbständige Tätigkeit oder freie
                                            Mitarbeit ausgeschrieben, so muss diese deutlich als
                                            solche im Text bezeichnet sein. Die Werbung für Klub-
                                            oder Vereinsmitgliedschaften ist nicht gestattet.
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.2. Die auf der Website geschaltete Stellenanzeige
                                            muss für Kandidat*innen zugänglich und verfügbar sein,
                                            damit diese sie ansehen und sich bewerben können. Sollte
                                            Bunton Kenntnis davon erlangen, dass eine Stellenanzeige
                                            nicht mehr existiert oder für Kandidat*innen nicht
                                            zugänglich ist, behält sich Bunton insbesondere das
                                            Recht vor, die Stellenanzeige ohne vorherige
                                            Benachrichtigung des Kunden zu entfernen.
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.3. Webseiten, die Bunton zur Verlinkung benannt
                                            oder zugesendet werden, müssen den gesetzlichen
                                            Mindestanforderungen entsprechen, und insbesondere ein
                                            Impressum aufweisen, das den gesetzlichen und den von
                                            der Rechtsprechung entwickelten Grundsätzen entspricht.
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.4. Zulässige Links sind nur als sogenannte „no
                                            follow“ Links zulässig, das heißt, sie sind so
                                            einzustellen, dass sie von Suchmaschinen nicht zur
                                            Berechnung der Linkpopularität herangezogen werden
                                            sollen.
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.5. Sämtliche Inhalte einer Anzeige müssen für
                                            Nutzer*innen direkt sichtbar sein. Soweit sie nicht
                                            explizit von Bunton als Teil besonderer Anzeigenprodukte
                                            angeboten werden, sind eigene Tracking Codes des Kunden
                                            und interaktive Elemente, die bspw. durch Klicks oder
                                            Mouse Over steuerbar sind, unzulässig. Ausgenommen
                                            hiervon sind Links auf andere Seiten und
                                            E-Mail-Adressen, die den Anforderungen dieser Ziffer im
                                            Übrigen genügen. In jedem Fall müssen Links so gestaltet
                                            sein, dass erkennbar ist, wenn sie auf externe Seiten
                                            verlinken.
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.6. Die Vorgaben des Allgemeinen
                                            Gleichbehandlungsgesetzes müssen eingehalten werden.
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.2.7. Auch wenn die vorstehenden Anforderungen
                                            eingehalten sind, dürfen neben den auf die Position oder
                                            Tätigkeit bezogenen Inhalte keine der Stellensuche
                                            sachfremden Inhalte veröffentlicht werden, wie
                                            beispielsweise Gewinnspiele, Veranstaltungen ohne
                                            Karrierebezug, reine Werbeaktionen etc.
                                        </Typography>
                                    </Box>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.2.3. Bunton übermittelt Nachrichten des Kunden an den
                                        Bewerber nur als Bote. Im Fall von rechtswidrig
                                        unterbliebenen oder rechtswidrigen Nachrichten des Kunden an
                                        Kandidat*innen, stellt der Kunde Bunton auf erstes Anfordern
                                        von allen Ansprüchen Dritter frei und Bunton behält sich
                                        vor, die Kontaktdaten des Kunden gegenüber betroffenen
                                        Kandidat*innen offenzulegen.
                                    </Typography>
                                    <Box>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.4. Bunton ist bemüht, den Rücklauf auf
                                            Stellenanzeigen des Kunden stetig zu optimieren sowie
                                            die Quantität und Qualität der abrufbaren Gesuche zu
                                            erhöhen. Hierzu gehört auch:
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.4.1. Das Eingehen von Kooperationen in allen Medien
                                            (einschließlich Online, Offline, TV, Mobil und
                                            Bewegbildprodukte sowie neue Nutzungsarten). Der Kunde
                                            erklärt sich damit einverstanden, dass die
                                            Leistungselemente online und/oder offline in Print, Ton
                                            oder Bild von Bunton veröffentlicht werden dürfen,
                                            einschließlich in Print- oder Online Medien von
                                            Kooperationspartnern. Bei allen Kooperationen wird
                                            seitens Bunton auf Image und Qualität des
                                            Kooperationspartners geachtet;
                                        </Typography>
                                        <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                            1.2.4.2. Bunton behält sich zu jeder Zeit vor, die
                                            Kategorisierung, Rubrizierung oder Darstellungsweise von
                                            Stellenanzeigen nach eigenem Ermessen anzupassen oder zu
                                            ändern. Der Kunde hat kein Recht auf Veröffentlichung
                                            seiner Stellenanzeigen in einer bestimmten, von ihm
                                            ausgewählten Kategorie, Rubrik oder Darstellungsweise.
                                        </Typography>
                                    </Box>
                                    <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                        1.2.5. Wir weisen darauf hin, dass Bunton nicht genehmigte
                                        Veröffentlichungen von Stellenanzeigen seitens Dritter nicht
                                        verhindern kann. Bunton ist jedoch bemüht, derartige
                                        Veröffentlichungen innerhalb der rechtlichen und technischen
                                        Möglichkeiten, auch im Namen des Kunden, zu unterbinden.
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>2.2.</Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2.1. Der Kunde hat in Übereinstimmung mit den rechtlichen
                                    Vorschriften, Gesetzen zum Schutze Dritter und den guten Sitten
                                    zu handeln.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2.2. Der Kunde verpflichtet sich, insbesondere persönliche
                                    Daten von Kandidat*innen nicht weiterzugeben, soweit dies nicht
                                    zur Besetzung einer konkret freien Stelle erforderlich ist,
                                    diese vertraulich zu behandeln und sämtliche
                                    datenschutzrechtlichen Bestimmungen einzuhalten. Die Daten von
                                    Kandidat*innen dürfen nur im Zusammenhang mit der Besetzung
                                    einer konkret freien Stelle verarbeitet und die Kandidat*innen
                                    nur zu diesem Zweck kontaktiert werden. Bunton geht davon aus,
                                    dass eine Speicherung für maximal Monate, auch unter
                                    Berücksichtigung eventueller Abwehr von AGG-Ansprüchen
                                    erforderlich ist, so dass der Kunde sich verpflichtet, jegliche
                                    bei sich gespeicherte und von Bunton erhaltene Daten der
                                    betroffenen Personen spätestens 12 Monate nach Zugriff auf die
                                    Daten zu löschen. Bunton behält sich vor, bei Zuwiderhandlung
                                    den Zugang des Kunden zu blockieren.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2.3. Sollte Bunton von Kandidat*innen aufgefordert werden,
                                    ihre personenbezogene Daten zu löschen, und setzt Bunton den
                                    Kunden hiervon in Kenntnis, so hat dieser alle Kopien, Dateien
                                    oder Daten, die zu bestimmten Kandidat*innen gehören,
                                    unverzüglich zu löschen, es sei denn der Kunde hat ein Recht,
                                    diese Daten zu behalten.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2.4. Die Angaben der Kandidat*innen werden ausschließlich von
                                    den Kandidat*innen selbst vorgenommen, sodass Bunton deren
                                    Vollständigkeit, Richtigkeit, Sorgfalt oder Verfügbarkeit nicht
                                    gewährleisten kann. Ebenso wenig gewährleistet Bunton eine
                                    bestimmte Anzahl von Antworten oder Bewerbungen auf
                                    Stellenanzeigen.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2.5. Dem Kunden ist bewusst, dass für den Datentransfer aus
                                    dem Geltungsbereich der Europäischen Union oder des EWR hinaus
                                    besondere Regeln gelten. Der Kunde wird daher personenbezogene
                                    Daten nur unter den Voraussetzungen der Art. 44-49 DSGVO in
                                    Drittstaaten übermitteln.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2.6. Der Kunde stellt Bunton von allen Verlusten, Kosten,
                                    Forderungen, Schadensersatzleistungen und andere Aufwendungen
                                    frei, die Bunton aufgrund einer Nichteinhaltung der Pflichten
                                    des Kunden entstehen.
                                </Typography>
                            </Box>
                        </Box>

                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '25px',
                                    textAlign: 'center',
                                    fontWeight: '600'
                                }}
                            >
                                C. Verarbeitung personenbezogener Daten im Auftrag des Kunden durch
                                Bunton – Auftragsverarbeitungsvertrag (AVV)
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    xs: 'auto',
                                    sm: 'auto',
                                    md: 'auto',
                                    lg: 'auto'
                                }
                            }}
                        >
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    1. Pflichten des Kunden als Auftraggeber
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    1.1 Der Kunde ist gemäß Art. 4 Nr. 7 DSGVO Verantwortlicher im
                                    datenschutzrechtlichen Sinne für die von Bunton vertragsgemäß
                                    erhobenen und verarbeiteten personenbezogenen Daten.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    1.2 Der Kunde informiert Bunton unverzüglich und vollständig,
                                    wenn er bei der Prüfung der Auftragsergebnisse Fehler oder
                                    Unregelmäßigkeiten bzgl. datenschutzrechtlicher Bestimmungen
                                    feststellt.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    1.3 Der Kunde führt ein Verzeichnis für Verarbeitungstätigkeiten
                                    gemäß Art. 30 (1) DSGVO.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h3' className='strong' sx={{my: 1}}>
                                    2. Pflichten von Bunton als Auftragnehmer
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.1 Bunton informiert den Kunden unverzüglich, wenn Bunton der
                                    Auffassung ist, dass eine Weisung vom Kunden gegen anwendbare
                                    Gesetze verstößt. Bunton darf die Umsetzung der Weisung so lange
                                    aussetzen, bis diese vom Kunden als zulässig bestätigt wurde
                                    oder abgeändert wurde.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.2. Bunton hält die Bestimmungen dieser
                                    Auftragsverarbeitungsvereinbarung und einschlägige anwendbare
                                    Datenschutzrechte, insbesondere der DSGVO, ein.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.3. Bunton stellt dem Kunden die für das Verzeichnis von
                                    Verarbeitungstätigkeiten nach Art. 30 (1) DSGVO notwendigen
                                    Angaben zur Verfügung und führt gemäß Art. 30 (2) bis (5) DSGVO
                                    ein eigenes Verzeichnis zu allen Kategorien von im Auftrag von
                                    Kunden durchgeführten Tätigkeiten der Verarbeitung.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.4. Alle Personen, die auftragsgemäß auf im Auftrag des Kunden
                                    verarbeitete personenbezogene Daten zugreifen können, sind gemäß
                                    Art. 28 (3) lit. b DSGVO zur Vertraulichkeit zu verpflichten und
                                    über die sich aus diesem Auftrag ergebenden besonderen
                                    Datenschutzpflichten sowie die bestehende Weisungs- bzw.
                                    Zweckbindung zu belehren.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.5. Bunton ist zur Bestellung eines betrieblichen
                                    Datenschutzbeauftragten verpflichtet. Dessen jeweils aktuelle
                                    Kontaktdaten sind auf der Homepage von Bunton leicht zugänglich
                                    hinterlegt.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.6. Bunton gewährleistet den Schutz der Rechte betroffener
                                    Personen und unterstützt den Kunden im notwendigen Umfang bei
                                    der Beantwortung von Anträgen auf Wahrnehmung von
                                    Betroffenenrechten gemäß Art. 12 bis 23 DSGVO. Bunton informiert
                                    den Kunden unverzüglich, falls sich ein Betroffener zum Zwecke
                                    der Auskunft, Berichtigung, Löschung oder Einschränkung der
                                    Verarbeitung seiner personenbezogenen Daten unmittelbar an
                                    Bunton wendet.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.7. Bunton unterrichtet den Kunden unverzüglich in Textform bei
                                    Störungen des Betriebsablaufes, bei Verdacht auf
                                    Datenschutzverletzungen gemäß Art. 4 Nr. 12 DSGVO im
                                    Zusammenhang mit der Datenverarbeitung oder anderen
                                    Unregelmäßigkeiten bei der Verarbeitung der Daten für den
                                    Kunden. Bunton hat im Benehmen mit dem Kunden angemessene
                                    Maßnahmen zur Sicherung der Daten sowie zur Minderung möglicher
                                    nachteiliger Folgen für Betroffene zu ergreifen, soweit die
                                    Datenschutzverletzung in der Verantwortung von Bunton lag.
                                </Typography>
                                <Typography variant='h5' className='small-size' sx={{mb:2}}>
                                    2.8. Bei Ermittlungen der Datenschutzbehörde bei Bunton, ist der
                                    Kunde – soweit diese Ermittlungen den Vertragsgegenstand
                                    betreffen – unverzüglich zu informieren.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default TermsAndConditions
