import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProfileCompletionLoader from '../../components/ProfileCompletionLoader'
import { BUTTON_DATA } from '../../constants'
import { Link } from 'react-router-dom'
import JobSearch from '../../components/JobSearch'
import UploadResumeNotification from './UploadResumeNotification'
import Button from '../Button'
import UploadResumeModal from '../organisams/UploadResumeModal'

const DashboardHeaderSection = props => {
    const { employedDashboard, hasResume } = props
    const [openUploadResumeModal, setOpenUploadResumeModal] = useState(false)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const initialProgress = 20
    const nextProgress = 14
    const resumeProgress = 10
    const [progress, setProgress] = useState(initialProgress)
    const { profileDetail } = useSelector(state => state.profileDetail)
    useEffect(() => {
        let tempProgress = initialProgress
        BUTTON_DATA?.map(item => {
            if (profileDetail[item.key] !== null && profileDetail[item.key].length > 0 && item.key !== 'uploadResume') {
                tempProgress = tempProgress + nextProgress
            }
        })
        if(hasResume) {
            tempProgress = tempProgress + resumeProgress
        }
        setProgress(tempProgress)
    }, [profileDetail])

    return (
        <>
            <Grid container columnSpacing={{xs: 0, sm: 4}} rowSpacing={{xs: 4, sm: 0}} direction={{xs: 'column-reverse', sm: 'row'}}>
                <Grid item 
                    xs={12} 
                    sm={12} 
                    md={!employedDashboard && progress < 100 ? 6 : 12} 
                    lg={!employedDashboard && progress < 100 ? 6 : 12} 
                    xl={!employedDashboard && progress < 100 ? 6 : 12}
                >
                    <Typography variant='h1' className='pageHeading'>Dashboard</Typography>
                </Grid>
                {!employedDashboard ? 
                    progress < 100 ? 
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{display:{
                                xs:'block',
                                sm: 'flex'
                            }, justifyContent:{
                                xs:'center',
                                sm: 'start',
                                md: 'end'
                            }, my: {
                                xs: 0, sm: 2, md: 0
                            }}}>
                                
                                <Link to={'/my-profile'} style={{width:'100%', maxWidth:'363px'}}>
                                    <ProfileCompletionLoader progress={progress} link='/my-profile' />
                                </Link>
                                {!hasResume && <Box sx={{ml: {xs: 0, sm: 2}, my: {
                                    xs: 3, sm: 0
                                }}}>
                                <Button
                                    label='Upload Resume'
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{ p: '8px 20px', fontSize: '14px', ...props.uploadButtonSx }}
                                    onClick={() => setOpenUploadResumeModal(true)}
                                />
                                </Box>}
                                {openUploadResumeModal && (
                                    <UploadResumeModal
                                        open={openUploadResumeModal}
                                        onClose={() => setOpenUploadResumeModal(false)}
                                    />
                                )}
                            </Box>
                        </Grid>
                    : null
                : null}                
            </Grid>
            
            {!employedDashboard && responsiveBreakpoint === 'mob' ? (
                <Box>
                    <JobSearch />
                </Box>
            ) : null}
        </>
    )
}

export default DashboardHeaderSection
