import { styled } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'


const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: variables.WHITE1,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: variables.CYAN
    }),
    '& .CustomStepIcon-completedCircle': {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: variables.CYAN,
        border: `2px solid ${variables.CYAN}`
    },
    '& .CustomStepIcon-circle': {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        border: `2px solid ${variables.CYAN}`
    }
}))

const RegistrationStepper = (props) =>  {
    const { active, completed, className } = props

    return (
        <CustomStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <div className='CustomStepIcon-completedCircle' />
            ) : (
                <div className='CustomStepIcon-circle' />
            )}
        </CustomStepIconRoot>
    )
}

export default RegistrationStepper