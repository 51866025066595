import {
    SAVE_PROFESSIONS,
    SAVE_INDUSTRIES,
    SAVE_DEPARTMENTS,
    SAVE_STATES,
    SAVE_BENEFITS,
    SAVE_JOB_TYPES,
    RESPONSIVE_BREAKPOINT
} from './actionTypes'

export const saveProfessions = data => ({
    type: SAVE_PROFESSIONS,
    payload: data
})
export const saveIndustries = data => ({
    type: SAVE_INDUSTRIES,
    payload: data
})

export const saveDepartments = data => ({
    type: SAVE_DEPARTMENTS,
    payload: data
})

export const saveStates = data => ({
    type: SAVE_STATES,
    payload: data
})

export const saveBenefits = data => ({
    type: SAVE_BENEFITS,
    payload: data
})

export const saveJobTypes = data =>({
    type: SAVE_JOB_TYPES,
    payload: data
})

export const responsiveBreakpoint = data => ({
    type: RESPONSIVE_BREAKPOINT,
    payload: data
})
