import variables from '../../../settings/_variables.scss'
export const styles = {
    container: {
        backgroundColor: variables.WHITE1,
        height: '100vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    header: {
        mb: '5px',
        mt: '40px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flex: 1,
        fontSize: '20px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px',
        justifyContent: 'center'
    },
    backIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        ml: {
            xs: '16px',
            sm: '30px',
            md: '80px',
            lg: '80px',
            xl: '80px'
        }
    },
    searchContainer: {
        // width: '50vw',
        width: {
            xs: '100vw',
            sm: '80vw',
            md: '50vw',
            lg: '50vw',
            xl: '50vw'
        },
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column',
        mt: '28px'
    },
    textInput: {
        width: '100%'
    },
    listWrapper: {
        height: {
            xs: '60vh',
            sm: '45vh',
            md: '60vh',
            lg: '60vh',
            xl: '60vh'
        },
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // mt: '16px',
        overflow: 'auto',
        justifyContent: 'center'
    },
    listContainer: {
        width: {
            xs: '80%',
            sm: '36%',
            md: '36%',
            lg: '36%',
            xl: '36%'
        },
        height: '100%'
    },
    listItem: { mt: '10px', borderRadius: '30px' },
    listItemButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '& .MuiTouchRipple-root span': {
            backgroundColor: 'transparent'
        }
    },
    button: {
        alignSelf: 'center',
        my: '25px',
        width: '122px',
        boxShadow: 'none',
        fontFamily: variables.poppinsRegular,
        position: 'absolute',
        bottom: 0
    }
}
