import { Box, FormControlLabel, styled, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import { useMutation } from '@apollo/client'
import { MANAGE_NOTIFICATION_PREFERENCE_MUTATION } from '../../queries/settingsQueries'

const IOSSwitch = styled(props => (
    <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: variables.BLUE,
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: variables.BLUE,
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}))

const NotificationListRow = props => {
    const { notification, index, userId } = props
    const { userPreference } = notification
    const [currentStatus, setCurrentStatus] = useState(
        userPreference && userPreference.want_notification
            ? userPreference.want_notification
            : false
    )

    const [managePrefMutationloading, { loading, data, error }] = useMutation(
        MANAGE_NOTIFICATION_PREFERENCE_MUTATION,
        {
            onCompleted(data) {
                // setExportLoading(false)
                // setShowToast(true)
            },
            onError(error) {}
        }
    )

    return (
        <Box
            key={index}
            sx={{
                background: variables.WHITE,
                borderRadius: '30px',
                p: 2,
                mb: 2,
                opacity: currentStatus ? 1 : 0.8,
                ...props.rowSx
            }}
        >
            <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} checked={currentStatus} />}
                label={<Box>
                    <Typography>{notification.title}</Typography>
                    <Typography variant='h5' className='small-size poppins-light'>{notification.description}</Typography>
                </Box>}
                onChange={() => {
                    setCurrentStatus(!currentStatus)
                    managePrefMutationloading({
                        variables: {
                            data: btoa(
                                JSON.stringify({
                                    user_id: userId,
                                    response: !currentStatus,
                                    notification_id: notification.id
                                })
                            )
                        }
                    })
                }}
            />
        </Box>
    )
}

export default NotificationListRow
