import {
    ADD_PROFILE_DETAIL_FAILURE,
    ADD_PROFILE_DETAIL_SUCCESS,
    PROFILE_DETAIL_LOADER,
    EDIT_PROFILE_DETAIL_SUCCESS,
    DELETE_PROFILE_DETAIL_SUCCESS,
    ADD_LANGUAGE_DATA_SUCCESS,
    ADD_AVATAR_DETAIL_SUCCESS,
    UPDATE_PROFILE_DATA_FROM_DB,
    DELETE_ALL_PROFILE_DETAILS,
    ADD_HAS_RESUME
} from './actionTypes'

export const setProfileLoader = () => ({
    type: PROFILE_DETAIL_LOADER
})

export const addProfileDetailSuccess = data => ({
    type: ADD_PROFILE_DETAIL_SUCCESS,
    payload: data
})

export const editProfileDetailSuccess = data => ({
    type: EDIT_PROFILE_DETAIL_SUCCESS,
    payload: data
})

export const deleteProfileDetailSuccess = data => ({
    type: DELETE_PROFILE_DETAIL_SUCCESS,
    payload: data
})

export const addAvatarDetailSuccess = data => ({
    type: ADD_AVATAR_DETAIL_SUCCESS,
    payload: data
})

export const addLanguageDataSuccess = data => ({
    type: ADD_LANGUAGE_DATA_SUCCESS,
    payload: data
})

export const addProfileDetailFailure = data => ({
    type: ADD_PROFILE_DETAIL_FAILURE,
    payload: data
})

export const updateProfileDataFromDB = data => ({
    type: UPDATE_PROFILE_DATA_FROM_DB,
    payload: data
})

export const deleteAllProfileDetails = data => ({
    type: DELETE_ALL_PROFILE_DETAILS,
    payload: data
})

export const addHasResume = data => ({
    type: ADD_HAS_RESUME,
    payload: data
})


