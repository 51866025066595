import React from 'react'
import { MetaTags } from 'react-meta-tags'

const PageMetaTags = props => {
    return (
        <>
            <MetaTags>
                <title>{props.title}</title>
                {/* <meta name='description' content='Some description.' />
                <meta property='og:title' content='MyApp' />
                <meta property='og:image' content='path/to/image.jpg' /> */}
                {props.children}
            </MetaTags>
        </>
    )
}

export default PageMetaTags
