import { useMutation } from '@apollo/client'
import { Box, Container, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Placeholder, TextStrings } from '../constants'
import StatusBox from '../components/StatusBox'
import { Button } from '../components'
import { FormHelperText } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import PasswordValidationSection from '../components/organisams/PasswordValidationSection'
import ControlledTextInput from '../components/molecules/ControlledTextInput'
import { CHANGE_PASSWORD_MUTATION } from '../queries/commonQueries'
import { PASSWORD_REGEX } from '../utils/constantData'
import { PASSWORDMESSAGE } from '../constants/constants'

const ChangePassword = props => {
    const navigate = useNavigate()
    const [apiError, setApiError] = useState('')

    const [isSuccess, setIsSuccess] = useState(false)
    const [changePasswordMutation, { data }] = useMutation(CHANGE_PASSWORD_MUTATION, {
        onCompleted(data) {
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
                navigate(`/`)
            }, 3000)
        },
        onError(error) {
            setApiError(error)
            // dispatch(addProfileDetailFailure(error.message))
        }
    })
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)

    const handleClickShowCurrentPassword = () => setShowCurrentPassword(show => !show)

    const handleMouseDownPasswordCurrent = event => {
        event.preventDefault()
    }

    const [showNewPassword, setShowNewPassword] = useState(false)

    const handleClickShowNewPassword = () => setShowNewPassword(show => !show)

    const handleMouseDownPasswordNew = event => {
        event.preventDefault()
    }

    const [showRepeatPassword, setShowRepeatPassword] = useState(false)

    const handleClickShowRepeatPassword = () => setShowRepeatPassword(show => !show)

    const handleMouseDownPasswordRepeat = event => {
        event.preventDefault()
    }

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        }
    })

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    const onSubmit = data => {
        setApiError('')
        changePasswordMutation({
            variables: {
                userId: props.userId, 
                newPassword: data.newPassword,
                currentPassword: data.currentPassword
            }
        })
    }

    let newPassword = watch('newPassword')

    const setValidationRules = {
        required: {
            value: true,
            message: ErrorMessage.emptyPassword
        },
        minLength: {
            value: 6,
            message: ErrorMessage.validPassword
        }
    }

    const showPasswordIcon = (passwordState, showFn, mouseDownFn) => {
        return (
            <InputAdornment position='end'>
                <IconButton
                    aria-label='toggle password visibility'
                    onClick={showFn}
                    onMouseDown={mouseDownFn}
                >
                    {passwordState ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <Container>
            <Container maxWidth='xl' disableGutters>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                >
                    <Box sx={{ maxWidth: '500px', width: '100%', m: 'auto', my: 4 }}>
                        <Box>
                            <Typography variant='h3' className='section-heading'>
                                {TextStrings.changePassword}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 7
                            }}
                        >
                            <Box>
                                <Box>
                                    <ControlledTextInput
                                        control={control}
                                        rules={{
                                            ...setValidationRules
                                        }}
                                        name='currentPassword'
                                        placeholder={Placeholder.currentPassword}
                                        type={showCurrentPassword ? 'text' : 'password'}
                                        errors={
                                            errors &&
                                            errors.currentPassword &&
                                            errors.currentPassword.message
                                        }
                                        required={true}
                                        fullWidth={true}
                                        formContolSx={{ width: '100%', mt:2 }}
                                        InputProps={{
                                            endAdornment: showPasswordIcon(
                                                showCurrentPassword,
                                                handleClickShowCurrentPassword,
                                                handleMouseDownPasswordCurrent
                                            )
                                        }}
                                        withoutLable={true}
                                        
                                    />
                                </Box>

                                <Box >
                                    <ControlledTextInput
                                        control={control}
                                        rules={{
                                            ...setValidationRules,
                                            pattern: {
                                                value: PASSWORD_REGEX,
                                                message: PASSWORDMESSAGE
                                            }
                                        }}
                                        label={TextStrings.newPassword}
                                        type={showNewPassword ? 'text' : 'password'}
                                        name='newPassword'
                                        placeholder={Placeholder.newPassword}
                                        errors={
                                            errors &&
                                            errors.newPassword &&
                                            errors.newPassword.message
                                        }
                                        required={true}
                                        fullWidth={true}
                                        formContolSx={{ width: '100%', mt:2 }}
                                        InputProps={{
                                            endAdornment: showPasswordIcon(
                                                showNewPassword,
                                                handleClickShowNewPassword,
                                                handleMouseDownPasswordNew
                                            )
                                        }}
                                        withoutLable={true}
                                    />
                                </Box>

                                <Box >
                                    <ControlledTextInput
                                        control={control}
                                        rules={{
                                            ...setValidationRules,
                                            validate: value => {
                                                return (
                                                    value === newPassword ||
                                                    'The passwords do not match'
                                                )
                                            }
                                        }}
                                        label={TextStrings.confirmPassword}
                                        type={showRepeatPassword ? 'text' : 'password'}
                                        name='confirmPassword'
                                        placeholder={Placeholder.confirmPassword}
                                        errors={
                                            errors &&
                                            errors.confirmPassword &&
                                            errors.confirmPassword.message
                                        }
                                        required={true}
                                        fullWidth={true}
                                        formContolSx={{ width: '100%', mt:2 }}
                                        InputProps={{
                                            endAdornment: showPasswordIcon(
                                                showRepeatPassword,
                                                handleClickShowRepeatPassword,
                                                handleMouseDownPasswordRepeat
                                            )
                                        }}
                                        withoutLable={true}
                                    />
                                </Box>
                                <Box>
                                    <PasswordValidationSection value={newPassword} />
                                </Box>
                                {apiError && apiError?.message ? (
                                    <FormHelperText 
                                        sx={{ textAlign: 'center' }}
                                        id='my-helper-text'
                                        error={true}
                                    >
                                        <strong>{apiError?.message}</strong>
                                    </FormHelperText>
                                ) : null}

                                <Box>
                                    <Button
                                        label='Submit'
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        onClick={handleSubmit(onSubmit)}
                                        type='submit'
                                        sx={{
                                            px: 6,
                                            my: 2
                                        }}
                                        classes='sign_in'
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            {isSuccess ? <StatusBox statusText='Password has been changed!' /> : null}
        </Container>
    )
}

export default ChangePassword
