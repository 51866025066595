import React from 'react'

import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Box } from '@mui/material'
import variables from '../../settings/_variables.scss'
import ArrowLeft from '../../assets/icons/ARROW_LEFT.svg'
import ArrowRight from '../../assets/icons/ARROW_RIGHT.svg'

export function LeftArrow() {
    const visibility = React.useContext(VisibilityContext)
    return (
        <Arrow disabled={visibility.isFirstItemVisible} onClick={() => visibility.scrollPrev()}>
            <Box component='img' src={ArrowLeft} />
        </Arrow>
    )
}

export function RightArrow() {
    const visibility = React.useContext(VisibilityContext)

    return (
        <Arrow disabled={visibility.isLastItemVisible} onClick={() => visibility.scrollNext()}>
            <Box component='img' src={ArrowRight} />
        </Arrow>
    )
}

function Arrow({ children, disabled, onClick }) {
    return (
        <Box
            disabled={disabled}
            onClick={onClick}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                right: '1%',
                opacity: disabled ? '0' : '1',
                userSelect: 'none',
                color: variables.BLUE
            }}
        >
            {children}
        </Box>
    )
}
