import { Box, Container, styled } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import HeaderStaticImage from '../assets/images/basic-page-image.webp'
import CompanyHeaderImage from '../assets/images/start2.webp'

const StyledBasicHeaderContainer = styled(Container)`
    max-height: 376px;
    display: block;
    position: relative;
    top: 0px;
    bottom: 100%;
    overflow: hidden;
`

const BasicPageHeader = props => {
    const location = useLocation()
    return (
        <>
            {location.pathname.includes('dashboard') ? null : (
                <StyledBasicHeaderContainer maxWidth={false} disableGutters>
                    {location.pathname.includes('companies') ? (
                        <Box
                            component='img'
                            src={CompanyHeaderImage}
                            sx={{
                                width: '100%',
                                position: 'relative',
                                top: {
                                    xs: '40px',
                                    sm: '10px',
                                    md: '-2px',
                                    lg: '-86px',
                                    xl: '-176px'
                                }
                            }}
                        />
                    ) : (
                        <Box
                            component='img'
                            src={HeaderStaticImage}
                            sx={{
                                width: '100%',
                                position: 'relative',
                                top: {
                                    xs: '0px',
                                    sm: '10px',
                                    md: '-37px',
                                    lg: '-130px',
                                    xl: '-247px'
                                }
                            }}
                        />
                    )}
                </StyledBasicHeaderContainer>
            )}
        </>
    )
}

export default BasicPageHeader
