import { Box, Typography } from '@mui/material'
import React from 'react'

const ModalStatusSection = props => {
    const { statusIcon, statusText, statusType } = props

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                ...props.sectionSx
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    p: 3,
                    ...props.boxSx
                }}
            >
                {statusIcon && (
                    <Box
                        component='img'
                        src={props.statusIcon}
                        sx={{
                            width: '60px',
                            height: '60px',
                            mb: 2,
                            ...props.iconSx
                        }}
                    />
                )}
                <Typography variant='h2' className='font-size-28px extrabold' sx={{textAlign: {
                    xs:'center',
                    sm: 'left'
                },
                ...props.textSx}}>
                    {statusText}
                </Typography>
            </Box>
        </Box>
    )
}

export default ModalStatusSection
