import { Box, Container } from '@mui/material'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import AdminHeader from './AdminHeader'
import AdminNavigation from './Navigation/AdminNavigation'
import Footer from '../components/organisams/footer'
import ResponsiveAppBar from '../components/organisams/ResponsiveAppBar'

const AdminLayout = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    return (
        <Container
            maxWidth={false}
            disableGutters={true}
            sx={{
                backgroundColor: 'rgba(255, 250, 244, 1)',
               mt: 15
            }}
        >
            {/* <AdminNavigation /> */}
            {/* {responsiveBreakpoint !== 'mob' ? <Container
                maxWidth='xl'
                disableGutters
                sx={{
                    padding: '53px 80px 57px 80px',
                    width: 'max-content'
                }}
            >
                <AdminHeader />
            </Container> : null} */}
            <ResponsiveAppBar/>
            <Box sx={{mt: 10}}>
                <Container
                    maxWidth={false}
                    disableGutters={true}
                    sx={{
                        my: 10,
                        paddingBottom: {
                            sm: '30px',
                            md: '60px'
                            // padding: '0px 80px'
                        }
                    }}
                >
                    {/* <Header /> */}
                    <Fragment>{props.children}</Fragment>
                </Container>
                
            </Box>
            <Box sx={{width: '100%'}}>
                <Footer />
            </Box>
        </Container>
    )
}

export default AdminLayout
