/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
    Box,
    Container,
    FormControl,
    FormHelperText,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { ErrorMessage, FormLabels, Placeholder, TextStrings, keys } from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import DownArrow from '../../../assets/icons/ARROW DOWN.svg'
import { useMutation } from '@apollo/client'
import { MANAGE_EDUCATION_MUTATION } from '../../../gql/queries'
import { useDispatch, useSelector } from 'react-redux'
import {
    addProfileDetailFailure,
    addProfileDetailSuccess,
    editProfileDetailSuccess
} from '../../../redux/actions/profileDetail'
import { getYearList } from '../../../utils/utilities'
import { styles } from './styles'
import Loader from '../../../components/Loader'
import { useEffect, useState } from 'react'
import Layout from '../../../template/Layout'
import PageMetaTags from '../../../base/PageMetaTags'
import moment from 'moment'
import ControlledTextInput from '../../../components/molecules/ControlledTextInput'
import CustomFormLabel from '../../../components/elements/CustomFormLabel'

const EducationDetail = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch() 
    let { detailId } = useParams()
    const location = useLocation()
    const { profileDetail } = useSelector(state => state.profileDetail)
    const yearList = getYearList(moment().year(), 1980)
    const [apiError, setApiError] = useState('')
    let certificateDegreeName = ''
    let major = ''
    let yearOfCompletion = ''
    let editableDeatilId = ''

    let editableForm = false
    if (profileDetail[keys.education] && profileDetail[keys.education].length > 0 && detailId) {
        let formDetailData = profileDetail[keys.education].find(o => o.id == detailId)
        if (formDetailData && Object.keys(formDetailData).length > 0) {
            editableForm = true
            certificateDegreeName = formDetailData.certificateDegreeName
            major = formDetailData.major
            yearOfCompletion = formDetailData.yearOfCompletion
            editableDeatilId = formDetailData.id
        }
    }

    const [manageEducationMutation, { data, error, loading, reset }] = useMutation(
        MANAGE_EDUCATION_MUTATION,
        {
            onCompleted(data) {
                let formData = []
                formData['key'] = keys.education
                formData['data'] = data.manageEducation.educationDetails
                if (data.manageEducation.actionType === 'update') {
                    dispatch(editProfileDetailSuccess(formData))
                } else {
                    dispatch(addProfileDetailSuccess(formData))
                }
                if (
                    location.pathname === '/add-new-education' ||
                    location.pathname.includes('edit-education')
                ) {
                    navigate('/my-profile', { state: { activeIndex: 1 } })
                } else {
                    navigate(-1)
                }
            },
            onError(error) {
                setApiError(error)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    /**
     * @method onSubmit
     * @description saving education data
     */
    const onSubmit = data => {
        setApiError('')
        let formData = []
        formData['key'] = keys.education
        if (editableForm) {
            formData['data'] = {
                id: editableDeatilId,
                certificateDegreeName: data.certificateDegreeName,
                major: data.major,
                yearOfCompletion: `${data.yearOfCompletion}`,
                actionType: 'update'
            }
        } else {
            formData['data'] = {
                certificateDegreeName: data.certificateDegreeName,
                major: data.major,
                yearOfCompletion: `${data.yearOfCompletion}`,
                actionType: 'create'
            }
        }
        manageEducationMutation({
            variables: {
                ...formData.data
            }
        })
    }

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid }
    } = useForm({
        defaultValues: {
            certificateDegreeName: certificateDegreeName,
            major: major,
            yearOfCompletion: yearOfCompletion
        }
    })

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    return (
        <Layout>
            <PageMetaTags
                title={`${
                    location.pathname.includes('profileSetup') ? 'Profile Setup | ' : ''
                }Bunton`}
            />
            <Container maxWidth='xl'>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                    <Box
                        onClick={() => {
                            if (
                                location.pathname === '/add-new-education' ||
                                location.pathname.includes('edit-education')
                            ) {
                                navigate('/my-profile', { state: { activeIndex: 1 } })
                            } else {
                                navigate(-1)
                            }
                        }}
                    >
                        <Icons src={BACK} />
                    </Box>
                    <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                        {`${
                            editableForm ? TextStrings.edit : TextStrings.add
                        } ${TextStrings.education.toLowerCase()}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        maxWidth: '650px',
                        width: {
                            xs: '90%',
                            sm: '80%'
                        },
                        m: '0 auto'
                    }}
                >
                    <Box sx={{ mt: 3 }}>
                        <CustomFormLabel
                            formLabel={FormLabels.certificateDegreeName}
                            formFieldError={
                                errors &&
                                errors.certificateDegreeName &&
                                errors.certificateDegreeName.message
                            }
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyCertificateDegreeName
                                }
                            }}
                            name='certificateDegreeName'
                            type='text'
                            placeholder={Placeholder.certificateDegreeName}
                            errors={
                                errors &&
                                errors.certificateDegreeName &&
                                errors.certificateDegreeName.message
                            }
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <CustomFormLabel
                            formLabel={FormLabels.major}
                            formFieldError={errors && errors.major && errors.major.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyMajor
                                }
                            }}
                            name='major'
                            type='text'
                            placeholder={Placeholder.major}
                            errors={errors && errors.major && errors.major.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Box sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '50%'
                                    }
                                }}
                            >
                                <CustomFormLabel
                                    formLabel={FormLabels.yearOfCompletion}
                                    formFieldError={
                                        errors &&
                                        errors.yearOfCompletion &&
                                        errors.yearOfCompletion.message
                                    }
                                />
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={value}
                                                onChange={onChange}
                                                input={
                                                    <OutlinedInput
                                                        sx={styles.selectTextInputStyle}
                                                    />
                                                }
                                                renderValue={selected => {
                                                    if (selected.length === 0) {
                                                        return (
                                                            <Box style={styles.placeholderStyle}>
                                                                Year of completion
                                                            </Box>
                                                        )
                                                    }

                                                    return selected
                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                IconComponent={_props => {
                                                    const rotate = _props.className
                                                        .toString()
                                                        .includes('iconOpen')
                                                    return (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                pointerEvents: 'none',
                                                                right: 10,
                                                                height: '15px',
                                                                width: '15px',
                                                                transform: rotate
                                                                    ? 'rotate(180deg)'
                                                                    : 'none'
                                                            }}
                                                        >
                                                            <Icons src={DownArrow} />
                                                        </div>
                                                    )
                                                }}
                                            >
                                                <MenuItem disabled value=''>
                                                    <Box style={styles.placeholderStyle}>
                                                        Year of completion
                                                    </Box>
                                                </MenuItem>
                                                {yearList.map(item => (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    name='yearOfCompletion'
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ErrorMessage.emptyYearOfCompletion
                                        }
                                    }}
                                />
                            </Box>
                            {/* {errors &&
                            errors.yearOfCompletion &&
                            errors.yearOfCompletion.message ? (
                                <FormHelperText id='my-helper-text'>
                                    {errors.yearOfCompletion.message}
                                </FormHelperText>
                            ) : null} */}
                            {apiError && apiError?.message ? (
                                <FormHelperText id='my-helper-text' error={true}>
                                    <strong>{apiError?.message}</strong>
                                </FormHelperText>
                            ) : null}
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            label={editableForm ? TextStrings.saveChanges : TextStrings.save}
                            color='primary'
                            variant='contained'
                            size='large'
                            // disabled={!isDirty || !isValid}
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                            sx={styles.button}
                        />
                    </Box>
                </Box>
                {loading && <Loader />}
            </Container>
        </Layout>
    )
}

export default EducationDetail
