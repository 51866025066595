import {
    Backdrop,
    Box,
    CircularProgress,
    Fade,
    Grid,
    Modal,
    ThemeProvider,
    Typography
} from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import { Controller, useForm } from 'react-hook-form'
import ModalCloseButton from '../elements/ModalCloseButton'
import Button from '../Button'
import { useMutation } from '@apollo/client'
import { SEND_CUSTOM_MESSAG_MUTATION } from '../../queries/commonQueries'
import { theme } from '../../settings/theme'

import EmailIcon from '@mui/icons-material/Email'
import WYSIWYGEditor from '../WYSIWYGEditor'
import _ from 'lodash'
import ControlledTextInput from '../molecules/ControlledTextInput'
import { ErrorMessage } from '../../constants'
import { stripHtml } from 'string-strip-html'
import CustomFormLabel from '../elements/CustomFormLabel'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '60%',
        md: '60%',
        lg: '50%'
    },
    height: '500px',
    bgcolor: variables.WHITE1,
    boxShadow: 10,
    p: 4,
    borderRadius: '65px'
}

const SendCustomMessageModal = props => {
    const { open, handleClose, checkedBoxes, showToastMessage, clearAllSelected } = props
    const [isSaving, setIsSaving] = useState(false)
    const [loader, setLoader] = useState(false)
    var keys = Object.keys(checkedBoxes)

    var filteredUsers = keys.filter(function (key) {
        return checkedBoxes[key]
    })

    let initialFormState = {
        subject: '',
        description: ''
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        register
    } = useForm({
        defaultValues: initialFormState
    })

    const [sendMessageMutation, { loading, data, error }] = useMutation(
        SEND_CUSTOM_MESSAG_MUTATION,
        {
            onCompleted(data) {
                setIsSaving(false)
                setLoader(false)
                reset()
                handleClose()
                clearAllSelected()
                showToastMessage(
                    true,
                    filteredUsers.length > 1
                        ? 'E-mails sent successfully'
                        : 'E-mail sent successfully'
                )
            },
            onError(error) {
                console.log(error, 'error')
            }
        }
    )

    const formSubmit = async data => {
        setIsSaving(true)
        setLoader(true)
        try {
            let formData = {}
            formData = {
                messageSubject: data.subject,
                messageBody: data.description
            }
            sendMessageMutation({
                variables: {
                    userIds: filteredUsers,
                    ...formData
                }
            })
        } catch (err) {
            // setApiError(err)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={() => {
                    reset()
                    handleClose()
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                disableScrollLock={true}
                slotProps={{
                    backdrop: {
                        sx: {
                            background: variables.CYAN,
                            opacity: '0.7 !important'
                        },
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            maxHeight: '500px',
                            display: loader ? 'flex' : 'block',
                            ...style
                        }}
                    >
                        {loader ? (
                            <Box sx={{ margin: 'auto', textAlign: 'center', alignItems: 'center' }}>
                                <CircularProgress sx={{ color: variables.BLUE }} />
                                <Typography sx={{ mt: 3 }}>
                                    Please wait while we are sending the{' '}
                                    {filteredUsers.length > 1 ? 'messages' : 'message'}.
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ height: '100%' }}>
                                <Box>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item md={10} sm={10} xs={10} lg={10} xl={10}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <EmailIcon
                                                    sx={{
                                                        color: variables.BLUE,
                                                        fontSize: '40px',
                                                        mr: 2
                                                    }}
                                                />
                                                <Box>
                                                    <Typography
                                                        variant='h2'
                                                        className='modal-heading'
                                                    >
                                                        Send a Message
                                                    </Typography>
                                                    <Typography
                                                        variant='h5'
                                                        className='small-size-16px'
                                                    >
                                                        Please write a message to send to all
                                                        selected users.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} sm={2} xs={2}>
                                            <ModalCloseButton
                                                handleClose={() => {
                                                    reset()
                                                    handleClose()
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ my: 4 }}>
                                    <Box>
                                        <Box>
                                            <CustomFormLabel
                                                labelSx={{mt: 3}}
                                                formLabel='Message Subject*'
                                                formFieldError={
                                                    errors &&
                                                    errors.subject &&
                                                    errors.subject.message
                                                }
                                            />

                                            <ControlledTextInput
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            ErrorMessage.customNotificationSubject
                                                    },
                                                    validate: value => {
                                                        return (
                                                            !!value.trim() ||
                                                            ErrorMessage.customNotificationSubject
                                                        )
                                                    }
                                                }}
                                                name='subject'
                                                type='text'
                                                errors={
                                                    errors &&
                                                    errors.subject &&
                                                    errors.subject.message
                                                }
                                                required={true}
                                                fullWidth={true}
                                                formContolSx={{ width: '100%' }}
                                            />
                                        </Box>
                                        <Box>
                                            <CustomFormLabel
                                                labelSx={{mt: 2}}
                                                formLabel='Message Body*'
                                                formFieldError={
                                                    errors &&
                                                    errors.description &&
                                                    errors.description.message
                                                }
                                            />
                                            <Controller
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        required: v =>
                                                            (v && stripHtml(v).result.length > 0) ||
                                                            ErrorMessage.emptyCustomNotificationBody
                                                    }
                                                }}
                                                defaultValue=''
                                                render={({ field }) => (
                                                    <WYSIWYGEditor
                                                        name='description'
                                                        {...field}
                                                        register={register}
                                                        editorStyle={{ maxHeight: '100px' }}
                                                    />
                                                )}
                                                name='description'
                                            />
                                            {/* {errors &&
                                            errors.description &&
                                            errors.description.message ? (
                                                <FormHelperText id='my-helper-text'>
                                                    {errors.description.message}
                                                </FormHelperText>
                                            ) : null} */}
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                                        <Button
                                            label={
                                                isSaving ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <CircularProgress
                                                            size={20}
                                                            sx={{ mr: 2 }}
                                                        />{' '}
                                                        Sending E-mail
                                                    </Box>
                                                ) : (
                                                    'Send E-mail'
                                                )
                                            }
                                            color='primary'
                                            variant='contained'
                                            disabled={isSaving || _.isEmpty(checkedBoxes)}
                                            size='large'
                                            sx={{ mr: 2, py: 2, px: 4 }}
                                            onClick={handleSubmit(formSubmit)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </ThemeProvider>
    )
}

export default SendCustomMessageModal
