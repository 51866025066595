import { useMutation } from '@apollo/client'
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Snackbar,
    Typography
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import PageMetaTags from '../base/PageMetaTags'
import variables from '../settings/_variables.scss'
import { ErrorMessage, Placeholder, TextStrings } from '../constants'
import StatusBox from '../components/StatusBox'
import { Button } from '../components'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { PASSWORD_REGEX } from '../utils/constantData'
import { PASSWORDMESSAGE } from '../constants/constants'
import PasswordValidationSection from '../components/organisams/PasswordValidationSection'
import BuntonPageHeadingLogo from '../components/elements/BuntonPageHeadingLogo'
import ControlledTextInput from '../components/molecules/ControlledTextInput'
import { RESET_MUTATION } from '../queries/commonQueries'

const ResetPassword = props => {
    const navigate = useNavigate()

    const params = useParams()
    const [isSuccess, setIsSuccess] = useState(false)
    const [isValidatingLoading, setIsValidatingLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)
    const [verificationNotification, setVerificationNotification] = useState(false)

    const handleClickShowPassword = () => setShowPassword(show => !show)

    const handleMouseDownPassword = event => {
        event.preventDefault()
    }

    const handleClickShowRepeatPassword = () => setShowRepeatPassword(show => !show)

    const handleMouseDownPasswordRepeat = event => {
        event.preventDefault()
    }
    const [resetMutation, { data }] = useMutation(RESET_MUTATION, {
        onCompleted(data) {
            setIsSuccess(true)
            setIsValidatingLoading(false)
            setTimeout(() => {
                setIsSuccess(false)
                navigate(`/login`)
            }, 3000)
        }
    })
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        }
    })

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        setVerificationNotification(true)
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    const onSubmit = data => {
        setIsValidatingLoading(true)
        resetMutation({
            variables: {
                userId: atob(params.key),
                newPassword: data.newPassword
            }
        })
    }

    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setVerificationNotification(false)
    }

    let newPassword = watch('newPassword')

    const setValidationRules = {
        required: {
            value: true,
            message: ErrorMessage.emptyPassword
        },
        minLength: {
            value: 6,
            message: ErrorMessage.validPassword
        }
    }

    const showPasswordIcon = (passwordState, showFn, mouseDownFn) => {
        return (
            <InputAdornment position='end'>
                <IconButton
                    aria-label='toggle password visibility'
                    onClick={showFn}
                    onMouseDown={mouseDownFn}
                >
                    {passwordState ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <Container maxWidth='xl' disableGutters>
            <PageMetaTags title='Reset Password | Bunton' />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={verificationNotification}
                autoHideDuration={3000}
                onClose={handleNotificationClose}
            >
                <Box
                    sx={{
                        backgroundColor: variables.BLUE,
                        width: '300px',
                        py: 1,
                        px: 2,
                        borderRadius: '20px'
                    }}
                >
                    <Grid container columnSpacing={1} alignItems='center' color={variables.WHITE}>
                        <Grid item md={1}>
                            <CheckCircleIcon sx={{ width: '20px', mt: '6px' }} />
                        </Grid>
                        <Grid item md={11}>
                            <strong>Your e-mail has been verified.</strong>
                        </Grid>
                    </Grid>
                </Box>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    minHeight:'600px'
                }}
            >
                <Box sx={{ maxWidth: '500px', width: '80%' }}>
                    <BuntonPageHeadingLogo />
                    <Box>
                        <Box>
                            <Box>
                                <Typography variant='h3' className='section-heading'>
                                    {TextStrings.setNewPassword}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                mt: 4
                            }}
                        >
                            <Box>
                                <ControlledTextInput
                                    control={control}
                                    rules={{
                                        ...setValidationRules,
                                        pattern: {
                                            value: PASSWORD_REGEX,
                                            message: PASSWORDMESSAGE
                                        }
                                    }}
                                    name='newPassword'
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={Placeholder.newPassword}
                                    errors={
                                        errors && errors.newPassword && errors.newPassword.message
                                    }
                                    required={true}
                                    fullWidth={true}
                                    formContolSx={{ width: '100%' }}
                                    label={TextStrings.newPassword}
                                    InputProps={{
                                        endAdornment: showPasswordIcon(
                                            showPassword,
                                            handleClickShowPassword,
                                            handleMouseDownPassword
                                        )
                                    }}
                                    withoutLable={true}
                                />
                                <ControlledTextInput
                                    control={control}
                                    rules={{
                                        ...setValidationRules,
                                        validate: value => {
                                            return (
                                                value === newPassword ||
                                                'The passwords do not match'
                                            )
                                        }
                                    }}
                                    name='confirmPassword'
                                    type={showRepeatPassword ? 'text' : 'password'}
                                    placeholder={Placeholder.confirmPassword}
                                    errors={
                                        errors &&
                                        errors.confirmPassword &&
                                        errors.confirmPassword.message
                                    }
                                    required={true}
                                    fullWidth={true}
                                    formContolSx={{ width: '100%', mt: 2 }}
                                    label={TextStrings.confirmPassword}
                                    InputProps={{
                                        endAdornment: showPasswordIcon(
                                            showRepeatPassword,
                                            handleClickShowRepeatPassword,
                                            handleMouseDownPasswordRepeat
                                        )
                                    }}
                                    withoutLable={true}
                                />
                            </Box>

                            <Box>
                                <PasswordValidationSection value={newPassword} />
                            </Box>

                            <Box>
                                <Button
                                    label={
                                        isValidatingLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: variables.BLUE
                                                }}
                                            />
                                        ) : (
                                            'Submit'
                                        )
                                    }
                                    disabled={isValidatingLoading}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    onClick={handleSubmit(onSubmit)}
                                    type='submit'
                                    sx={{
                                        px: 6,
                                        my: 2
                                    }}
                                    classes='sign_in'
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {isSuccess ? <StatusBox statusText='Password has been changed!' /> : null}
        </Container>
    )
}

export default ResetPassword
