import React from 'react'
import { useTranslation } from 'react-i18next';
import { supportedLngs } from '../../i18n/config';
import { Box, Button } from '@mui/material';
import variables from '../../settings/_variables.scss'

const LanguageSwitcher = props => {
  const { i18n } = useTranslation();
  return (<>
      <Box>
        {
          i18n.resolvedLanguage !== 'en' ? 
            <Button
              onClick={(e) =>  i18n.changeLanguage('en')}
              sx={{p: 2, background: variables.WHITE, borderRadius: '50px', '&:hover': {
                background: variables.WHITE
              }, ...props.sx}}
            >
              <Box component='img' src={supportedLngs['en'].flag} sx={{height: '38px', width: '38px', ...props.flagSx}}/>
              </Button>

          : <Button
          onClick={(e) =>  i18n.changeLanguage('de')}
          sx={{p: 2, background: variables.WHITE, borderRadius: '50px', '&:hover': {
            background: variables.WHITE
          }, ...props.sx}}
        >
          <Box component='img' src={supportedLngs['de'].flag} sx={{height: '38px', width: '38px', ...props.flagSx}}/>
          </Button>

        }
      </Box>
      </>
  );
}

export default LanguageSwitcher