import { Box, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { APPLY_JOB_MUTATION } from '../../queries/jobPostQueries'
import Button from '../Button'
import Icons from '../Icons'
import variables from '../../settings/_variables.scss'

import ApplyIcon from '../../assets/icons/ApplyIcon.svg'
import StarIcon from '@mui/icons-material/Star'
import ApplyJobSuccessModal from './ApplyJobSuccessModal'

const ApplyJobButton = props => {
    const { applyLabel, jobId, candidateId, hasCandidateApplied } = props
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [hasApplied, setHasApplied] = useState(hasCandidateApplied)
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [hasResume, setHasResume] = useState(true)

    const [applyJobMutation] = useMutation(APPLY_JOB_MUTATION, {
        onCompleted(data) {
            setHasApplied(true)
            setSuccessMessage('Thank You for Applying!')
            setLoading(false)
            setIsSuccess(true)
            setHasResume(data.applyJob.hasResume)
            if(data.applyJob.hasResume) {
                setTimeout(() => {
                    setIsSuccess(false)
                }, 3000)
            }
        }
    })

    return (
        <Box>
            <Box
                sx={{
                    textAlign: {
                        xs: 'center',
                        md: 'right'
                    }
                }}
            >
                {hasApplied ? (
                    <Button
                        label='Applied'
                        color='secondary'
                        variant='contained'
                        size='large'
                        className='strong'
                        sx={{
                            padding: '10px 30px',
                            color: '#fff',
                            cursor: 'default'
                        }}
                        endIcon={
                            <StarIcon
                                style={{
                                    color: 'white'
                                }}
                            />
                        }
                    />
                ) : (
                    <Button
                        disabled={loading}
                        label={
                            loading ? (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                />
                            ) : applyLabel ? (
                                applyLabel
                            ) : (
                                t('buttons.apply')
                            )
                        }
                        color='secondary'
                        variant='contained'
                        size='large'
                        className='strong'
                        sx={{
                            padding: '10px 30px'
                        }}
                        endIcon={
                            <Icons
                                src={ApplyIcon}
                                sx={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        }
                        onClick={() => {
                            setLoading(true)
                            applyJobMutation({
                                variables: {
                                    jobId: jobId,
                                    userId: candidateId
                                }
                            })
                        }}
                    />
                )}
            </Box>
            {isSuccess && <ApplyJobSuccessModal open={isSuccess} handleClose={() => setIsSuccess(false)} message={successMessage} hasResume={hasResume}/>}
        </Box>
    )
}

export default ApplyJobButton
