import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import { getStringFromArray, truncateDetails } from '../../utils/commonFunctions'
import { Link } from 'react-router-dom'
import DefaultCompanyLogo from '../molecules/DefaultCompanyLogo'
import Location from '../../assets/icons/LOCATION.svg'
import { StyledIcons } from '../../base/commonStyles'
import Department from '../../assets/icons/DEPARTMENT.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LikeButton from '../molecules/LikeButton'

const JobPostCard = props => {
    const { t } = useTranslation()
    const { data, index, orientation } = props
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null
    return (
        <>
            <Container
                key={index}
                maxWidth={false}
                disableGutters
                sx={{
                    background: variables.CYAN1,
                    borderRadius: '50px',
                    p: '20px',
                    mr: { xs: orientation === 'vertical' ? 0 : 2, sm: 2 },
                    mb: { xs: orientation === 'vertical' ? 2 : 0, sm: 0 },
                    minWidth: '300px',
                    width: {
                        xs: orientation === 'vertical' ? '100%' : '300px',
                        sm: '332px'
                    },
                    height: '337px',
                    minHeight: '337px'
                }}
            >
                <Grid container columns={2}>
                    <Grid item md={1} xs={1}>
                        <DefaultCompanyLogo
                            imgSrc={data.companyLogoUrl}
                            sx={{
                                width: '75px',
                                height: '75px',
                                borderRadius: '70px',
                                margin: '0 auto'
                            }}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} sx={{ textAlign: 'right' }}>
                        <LikeButton
                            jobPostId={data.id}
                            jobCreateDate={data.createdAt}
                            userId={userId}
                            role={userRole}
                            totalLikes={data.likes}
                            isLiked={data.isLiked}
                            isUserAuthenticated={isUserAuthenticated}
                            refetchAppliedJobCount={props.refetchAppliedJobCount}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ maxHeight: '118px', height: '100%', mt: 1, mb: '20px' }}>
                    <Box sx={{ mb: 1 }}>
                        <Typography variant='h5' className='small-size-16px'>
                            {truncateDetails(data.companyName, 20)}
                        </Typography>
                        <Typography variant='h3' className='small-size-18px strong' sx={{ mt: 1 }}>
                            {truncateDetails(data.jobTitle, 20)}
                        </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex' }}>
                            <StyledIcons component='img' src={Location} />
                            <Typography variant='h5' className='small-size-16px'>
                                {truncateDetails(getStringFromArray(data.states), 20)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 1 }}>
                            <StyledIcons component='img' src={Department} />
                            <Typography variant='h5' className='small-size-16px'>
                                {truncateDetails(getStringFromArray(data.departments), 20)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        label={t('buttons.moreinfo')}
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to={'/job-details/' + data.id}
                        sx={{
                            px: '73px'
                        }}
                    />
                </Box>
            </Container>
        </>
    )
}
export default JobPostCard
