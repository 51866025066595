import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'

const DataLoadingLoader = props => {
    // return <Box sx={{ position: 'absolute',
    //     top: 0,
    //     zIndex: '9999',
    //     height: '100%',
    //     width: '100%',
    //     left: 0,
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     background: 'rgba(255, 255, 255, 0.5)'}}>
    //         <CircularProgress sx={{color: variables.BLUE}}/>
    // </Box>
    let emptyArray = Array(props.showLoadingRow).fill(0)
    return <Box sx={{
        height: '100%',
        maxWidth:'800px', m: 'auto', ...props.sx}}>
            <Stack spacing={1} sx={{}}>
                {
                    emptyArray.map((value, index) => {
                        return <Skeleton variant="rounded"  height={60} key={index}/>
                    })
                }
            </Stack>
    </Box>
}

export default DataLoadingLoader