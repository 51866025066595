import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_LOADER,
    LOGIN_CHECK_AUTH,
    LOGOUT_SUCCESS,
    SET_AVATAR
} from './actionTypes'

export const loginAction = data => ({
    type: LOGIN,
    payload: data
})
export const setLoginLoader = () => ({
    type: LOGIN_LOADER
})

export const loginSuccess = data => ({
    type: LOGIN_SUCCESS,
    payload: data
})

export const loginFailure = data => ({
    type: LOGIN_FAILURE,
    payload: data
})

export const loginCheckAuth = data => ({
    type: LOGIN_CHECK_AUTH,
    payload: data
})

export const logoutSuccess = data => ({
    type: LOGOUT_SUCCESS,
    payload: data
})

export const setAvatar = data => ({
    type: SET_AVATAR,
    payload: data
})
