import { gql } from '@apollo/client'

export const TRACK_SHARING_MUTATION = gql`
    mutation ShareToOther(
        $userId: ID!
        $jobId: ID
        $name: String!
        $email: String!
        $message: String
        $section: String!
    ) {
        shareToOther(
            userId: $userId
            jobId: $jobId
            name: $name
            email: $email
            message: $message
            section: $section
        ) {
            id
        }
    }
`

export const EXPORT_CSV_MUTATION = gql`
    mutation ExportCsv($requestType: String!) {
        exportCsv(requestType: $requestType)
    }
`

export const SEND_CUSTOM_MESSAG_MUTATION = gql`
    mutation SendCustomMessage($userIds: [ID!]!, $messageSubject: String!, $messageBody: String!) {
        sendCustomMessage(
            userIds: $userIds
            messageSubject: $messageSubject
            messageBody: $messageBody
        )
    }
`

export const SEND_JOB_REVIEW_MUTATION = gql`
    mutation SendJobReviewMessage($jobId: ID!, $reviewerName: String!, $reviewerEmail: String!, $personalizedMessage: String!) {
        sendJobReviewMessage(
            jobId: $jobId
            reviewerName: $reviewerName
            reviewerEmail: $reviewerEmail
            personalizedMessage: $personalizedMessage
        )
    }
`

export const FORGOT_PASSWORD_MUTATION = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(email: $email) {
            email
            userId
        }
    }
`

export const VALIDATE_MUTATION = gql`
    mutation ValidateOtp($userId: ID!, $otp: String!) {
        validateOtp(userId: $userId, otp: $otp) {
            id
        }
    }
`

export const RESENT_OTP_MUTATION = gql`
    mutation SendOtp($userId: ID!) {
        sendOtp(userId: $userId) {
            id
        }
    }
`

export const RESET_MUTATION = gql`
    mutation ResetPassword($userId: ID!, $newPassword: String!) {
        resetPassword(userId: $userId, newPassword: $newPassword) {
            id
        }
    }
`

export const CHANGE_PASSWORD_MUTATION = gql`
mutation ChangePassword($userId: ID!, $newPassword: String!, $currentPassword: String!) {
    changePassword(
        userId: $userId
        newPassword: $newPassword
        currentPassword: $currentPassword
    ) {
        id
    }
}
`


export const GET_PROFESSIONAL_NETWORK_LIST_QUERY = gql`
query GetProfessionalNetowrkList{
    getProfessionalNetworkList{
        id
        name
    }
}
`