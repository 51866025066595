import { useQuery } from '@apollo/client'
import React from 'react'
import { GET_NOTIFICATION_TYPE_LIST_QUERY } from '../../queries/settingsQueries'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import NotificationListRow from '../molecules/NotificationListRow'
import DataLoadingLoader from '../molecules/DataLoadingLoader'

const NotificationSettings = props => {
    const { loginCheckAuthDetails, loginDetails } = useSelector(
        state => state.login
    )
    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null
    const {loading, data, error} = useQuery(GET_NOTIFICATION_TYPE_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            data: btoa(JSON.stringify({"userId":userId}))
        }
    })
    if(loading) return <DataLoadingLoader showLoadingRow={3}/>
    const notificationList = data.getNotificationType
    return <Box sx={{ m: 'auto'}}>
        {notificationList && notificationList.length > 0 && notificationList.map((notification, index) => <NotificationListRow notification={notification} userId={userId} />)}
        
    </Box>
}

export default NotificationSettings
