import { Box, Container, Link, Grid, Typography, CircularProgress } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PageMetaTags from '../base/PageMetaTags'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import { HomePageButtons } from './HomePage'
import ArrowLeft from '../assets/icons/ARROW_LEFT.svg'
import { useState } from 'react'


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const ClientContract = props => {
    const [numPages, setNumPages] = useState()

    function onDocumentLoadSuccess(pdfData) {
        setNumPages(pdfData._pdfInfo.numPages)
    }
    const pdfUrl = 'https://buntonwebappstorage.blob.core.windows.net/documents/Anzeigenvertrag_MUSTER.pdf'
    return (
        <Container sx={{ py: 4, height: '100%' }}>
            <PageMetaTags title='Why Bunton | Bunton' />
            <Box maxWidth='xl' sx={{ m: 'auto' }}>
                {/* <Box sx={{ textAlign: 'center' }}>
                    <RouterLink to='/'>
                        <Box
                            component='img'
                            src={LOGO_WITH_TEXT}
                            sx={
                                responsiveBreakpoint === 'mob'
                                    ? {
                                          width: '150px'
                                      }
                                    : {
                                          width: '240px',
                                          height: '65px'
                                      }
                            }
                        />
                    </RouterLink>
                </Box> */}
                <Grid container sx={{ display: 'flex', my: 2 }} alignItems='center' spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                        <Typography
                            variant='body2'
                            sx={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                position: 'relative',
                                textAlign: {
                                    xs: 'center',
                                    sm: 'left'
                                },
                                cursor: 'pointer'
                            }}
                        >
                            <RouterLink to='/'>
                                <Box component='img' src={ArrowLeft} sx={{ mr: 1 }} /> Go to Bunton
                                website
                            </RouterLink>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                        sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'right'
                            }
                        }}
                    >
                        <HomePageButtons
                            label={'Download'}
                            component={Link}
                            href={pdfUrl}
                            download
                            target='_blank'
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{ cursor: 'pointer' }}
                        />
                    </Grid>
                </Grid>
                
                <Box
                    sx={{
                        m: 0,
                        py: { xs: 5, md: 10 },
                        maxWidth: '1080px',
                        maxHeight: '1080px',
                        "@media print": {
                            m: 0,
                            p: 0,
                        }
                    }}
                    height={'100%'}
                    className='client-contract'
                >
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {/* {<Page
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            loading={<CircularProgress />}
                            scale={2}
                        />} */}
                        {[1,2,3,4].map(page => (
                            <Box sx={{my: 2, "@media print": {
                            m: 0
                        }}}>
                                <Page pageNumber={page} renderTextLayer={false}
                                renderAnnotationLayer={false}
                                loading={<CircularProgress />}
                                // scale={2}
                                />
                            </Box>
                        ))}
                        
                    </Document>
                </Box>
                {/* <Box sx={{ mt: 3, p: 3, textAlign: 'center', width: '100%' }}>
                    <Button
                        label=''
                        color='secondary'
                        variant='contained'
                        size='large'
                        onClick={() => {
                            if(pageNumber > 1) {
                                setPageNumber(pageNumber - 1)
                            }
                        }} 
                        disabled={pageNumber === 1}
                        sx={{mr: 2}}
                    >
                        <NavigateBeforeIcon/>
                    </Button>
                    {pageNumber} of {numPages}
                    <Button
                        label=''
                        sx={{ml: 2}}
                        color='secondary'
                        variant='contained'
                        size='large'
                        onClick={() => {
                            if(pageNumber < numPages) {
                                setPageNumber(pageNumber + 1)
                            }
                        }} 
                        disabled={pageNumber === numPages}
                    >
                        <NavigateNextIcon/>
                    </Button>
                </Box> */}
            </Box>
        </Container>
    )
}

export default ClientContract
