import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import variables from '../../settings/_variables.scss'
import PendingInvitationRow from './PendingInvitationRow'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { SliderFooterArrows } from '../../utils/commonFunctions'
import SliderLoadMoreCard from '../molecules/SliderLoadMoreCard'

const GET_CONNECTION_REQUEST_QUERY = gql`
    query GetInvitationRequests($after: String, $id: ID!) {
        getInvitationRequests(first: 10, after: $after, id: $id) {
            edges {
                node {
                    id
                    jobPost {
                        id
                        jobTitle
                        companyLogoUrl
                        otherDepartment
                        otherIndustry
                        likes
                        isLiked
                        createdAt
                        industries {
                            id
                            name
                        }
                        departments {
                            id
                            name
                        }
                        states {
                            id
                            name
                        }
                        companyName
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const PendingInvitations = props => {
    const { setIsSuccess, setSuccessMessage, refetchAppliedJobCount } = props
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } =
        useSelector(state => state.login)
    const [listLoader, setListLoader] = useState(false)

    const { loading, data, fetchMore, refetch } = useQuery(GET_CONNECTION_REQUEST_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: loginCheckAuthDetails.id ? loginCheckAuthDetails.id : loginDetails.id
        },
        onCompleted(data) {
            setListLoader(false)
        }
    })
   

    if (loading)
		return (
			<Box
				sx={{
					textAlign: 'center'
				}}
			>
				<CircularProgress
					sx={{
						color: variables.BLUE
					}}
				/>
			</Box>
		)
    const connectionRequests = data && data.getInvitationRequests.edges.map(edge => edge.node)

    const pageInfo = data && data.getInvitationRequests.pageInfo
    return (
        <Box
            sx={{
                marginTop: '50px'
            }}
        >
            {connectionRequests && connectionRequests.length > 0 ? (
                <Box component='div'>
                    <ScrollMenu
                        Footer={SliderFooterArrows}
                        scrollContainerClassName="candidate-dashboard--pending_invites_scroll_container"
                    >
                        {connectionRequests.map(request => (
                            <PendingInvitationRow
                                data={request.jobPost}
                                refetch={refetch}
                                setIsSuccess={setIsSuccess}
                                setSuccessMessage={setSuccessMessage}
                                refetchAppliedJobCount={refetchAppliedJobCount}
                            />
                        ))}
                        {isUserAuthenticated && pageInfo && pageInfo.hasNextPage ? (
                            <SliderLoadMoreCard
                                text='Load More Invites'
                                listLoader={listLoader}
                                onClick={() => {
                                    if (pageInfo.hasNextPage) {
                                        setListLoader(true)
                                        fetchMore({
                                            variables: {
                                                after: pageInfo.endCursor ? pageInfo.endCursor : ''
                                            }
                                        })
                                    }
                                }}
                            />
                        ) : null}
                    </ScrollMenu>
                </Box>
            ) : (
                <Box
                    sx={{
                        minHeight: '250px',
                        borderRadius: '40px',
                        paddingTop: '15px',
                        backgroundColor: variables.CYAN1
                    }}
                >
                    <Box
                        sx={{
                            margin: 'auto',
                            width: '300px'
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '20px',
                                color: variables.BLUE,
                                fontWeight: '600'
                            }}
                        >
                            no invitations to apply at the moment.
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default PendingInvitations
