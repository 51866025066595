import { Box, FormControlLabel, Radio, Typography, styled } from '@mui/material'
import React from 'react'
import CheckIcon from '../../assets/icons/CHECK.svg'
import variables from '../../settings/_variables.scss'

const StyledChip = styled(FormControlLabel)`
    padding: 0px 16px;
    margin: 16px 16px 0 0;
    background-color: ${variables.CYAN1};
    border-color: ${variables.CYAN1};
    border-radius: 50px;
`

const StyledCheckedBox = styled(Box)`
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.CYAN};
`

const StyledCheckedBoxUnchecked = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.WHITE1};
`

const CheckBoxChip = props => {
    return (
        <StyledChip
            value={props.value}
            {...props.register(props.parentName)}
            control={
                <Radio
                    color='default'
                    checkedIcon={
                        <StyledCheckedBox>
                            <Box
                                component='img'
                                src={CheckIcon}
                                sx={{ position: 'relative', top: '25%', height: '12px' }}
                            />
                        </StyledCheckedBox>
                    }
                    icon={<StyledCheckedBoxUnchecked />}
                />
            }
            label={<Typography sx={{ pr: 1 }}>{props.label}</Typography>}
        />
    )
}

export default CheckBoxChip
