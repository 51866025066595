import { Badge } from '@mui/material'
import React from 'react'

const NotificationBadge = props => {
    return (
        <Badge color='primary' invisible={!props.showBadge} badgeContent={props.content} overlap='circular' sx={{width: '100%', ...props.badgeSx}}>
            {props.children}
        </Badge>
    )
}

export default NotificationBadge
