import variables from '../../../settings/_variables.scss'
export const styles = {
    container: {
        backgroundColor: variables.WHITE1,
        height: '100vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    header: {
        mb: '5px',
        mt: '40px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flex: 1,
        fontSize: '20px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px',
        justifyContent: 'center'
    },
    backIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        ml: {
            xs: '16px',
            sm: '30px',
            md: '80px',
            lg: '80px',
            xl: '80px'
        }
    },
    mainIconContainer: {
        width: '100%',
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column',
        mt: '28px',
        flex: 1
    },
    mainAvatarContainer: {
        height: '66vh',
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto'
    },
    avatarContainer: {
        display: 'flex',
        height: '48vh',
        overflow: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },

    newAvatarContainer: {
        display: 'grid',
        alignItems: 'center',
        flexWrap: 'wrap',
        // minWidth: '420px'
        minWidth: {
            xs: '313px',
            sm: '420px',
            md: '420px',
            lg: '420px',
            xl: '420px'
        }
    },

    avatarBorder: {
        width: '118px',
        height: '118px',
        ml: '15px',
        mt: '15px'
    },
    saveButton: {
        alignSelf: 'center',
        my: '25px',
        width: '122px',
        boxShadow: 'none',
        fontFamily: variables.poppinsRegular,
        position: 'absolute',
        bottom: 0
    }
}
