import {
    PROFILE_DETAIL_LOADER,
    ADD_PROFILE_DETAIL_FAILURE,
    ADD_PROFILE_DETAIL_SUCCESS,
    EDIT_PROFILE_DETAIL_SUCCESS,
    DELETE_PROFILE_DETAIL_SUCCESS,
    ADD_LANGUAGE_DATA_SUCCESS,
    ADD_AVATAR_DETAIL_SUCCESS,
    UPDATE_PROFILE_DATA_FROM_DB,
    DELETE_ALL_PROFILE_DETAILS,
    ADD_HAS_RESUME
} from '../actions/profileDetail/actionTypes'

const initialState = {
    loading: false,
    profileDetail: { edu: [], exp: [], lang: [], avatar: [], additionalInfos: [], uploadResume: false },
    error: null
}

const setProfileDetailLoader = (state, action) => {
    return {
        ...state,
        loading: true
    }
}

const addProfileDetailSuccess = (state, action) => {
    return {
        loading: false,
        error: null,
        profileDetail: {
            ...state.profileDetail,
            [action.payload.key]: state.profileDetail[action.payload.key].concat(
                action.payload.data
            )
        }
    }
}

const editProfileDetailSuccess = (state, action) => {
    const updatedProfileDetail = state?.profileDetail[action.payload.key].map(item => {
        if (item.id === action.payload.data.id) {
            item = action.payload.data
        }
        return item
    })
    return {
        ...state,
        profileDetail: {
            ...state.profileDetail,
            [action.payload.key]: updatedProfileDetail
        }
    }
}

const addLanguageData = (state, action) => {
    return {
        ...state,
        profileDetail: {
            ...state.profileDetail,
            [action.payload.key]: [action.payload.data]
        }
    }
}

const addAvatarDetail = (state, action) => {
    return {
        ...state,
        profileDetail: {
            ...state.profileDetail,
            [action.payload.key]: [action.payload.data]
        }
    }
}

const deleteProfileDetailSuccess = (state, action) => {
    const updatedProfileDetail = state?.profileDetail[action.payload.key].filter(
        item => item.id !== action.payload.data.id
    )
    return {
        ...state,
        profileDetail: {
            ...state.profileDetail,
            [action.payload.key]: updatedProfileDetail
        }
    }
}

const addProfileDetailFailure = (state, action) => {
    return {
        ...state,
        loading: false,
        error: action.payload
    }
}

const updateProfileDataFromDB = (state, action) => {
    return {
        ...state,
        profileDetail: {
            ...state.profileDetail,
            [action.payload.key]: action.payload.data
        }
    }
}

const deleteAllProfileDetails = (state, action) => {
    return {
        ...initialState
    }
}

const addHasResume = (state, action) => {
    return {
        ...state,
        profileDetail: {
            ...state.profileDetail,
            uploadResume: action.payload.data
        }
    }
}

const profileDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_DETAIL_LOADER:
            return setProfileDetailLoader(state, action)
        case ADD_PROFILE_DETAIL_FAILURE:
            return addProfileDetailFailure(state, action)
        case ADD_PROFILE_DETAIL_SUCCESS:
            return addProfileDetailSuccess(state, action)
        case EDIT_PROFILE_DETAIL_SUCCESS:
            return editProfileDetailSuccess(state, action)
        case DELETE_PROFILE_DETAIL_SUCCESS:
            return deleteProfileDetailSuccess(state, action)
        case ADD_LANGUAGE_DATA_SUCCESS:
            return addLanguageData(state, action)
        case ADD_AVATAR_DETAIL_SUCCESS:
            return addAvatarDetail(state, action)
        case UPDATE_PROFILE_DATA_FROM_DB:
            return updateProfileDataFromDB(state, action)
        case DELETE_ALL_PROFILE_DETAILS:
            return deleteAllProfileDetails(state, action)
        case ADD_HAS_RESUME:
            return addHasResume(state, action)
        default:
            return state
    }
}

export default profileDetailReducer
