import { useQuery } from '@apollo/client'
import { Box, CircularProgress, Container } from '@mui/material'
import React, { useState } from 'react'
import { GET_ACTIVE_JOBS_QUERY } from '../../queries/companyQueries'
import DataLoadingLoader from '../molecules/DataLoadingLoader'
import ProfileJobList from '../ProfileJobList'
import variables from '../../settings/_variables.scss'
import LoadMoreLink from '../elements/LoadMoreLink'

const CompanyJobList = props => {
    const { company, isUserAuthenticated, showCompanyLogo } = props
    const [activeJobs, setActiveJobs] = useState(null)
    const [allActiveJobsPageInfo, setAllActiveJobsPageInfo] = useState(null)
    const [listLoader, setListLoader] = React.useState(false)
    const {
        loading,
        refetch: refetchActiveJobs,
        fetchMore: fetchMoreActiveJobs
    } = useQuery(GET_ACTIVE_JOBS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: '',
            id: company.id
        },
        onCompleted(data) {
            setListLoader(false)
            setAllActiveJobsPageInfo(data.getCompaniesActiveJobs.pageInfo)
            setActiveJobs(data.getCompaniesActiveJobs.edges.map(edge => edge.node))
        }
    })
    if (loading) return <DataLoadingLoader />

    return (
        <Container maxWidth='xl' sx={{...props.containerSx}} disableGutters>
            {activeJobs && activeJobs.length > 0 ? (
                activeJobs.map(jobData => (
                    <ProfileJobList
                        data={jobData}
                        company={company}
                        refetch={refetchActiveJobs}
                        isEdit={props.isEdit}
                        showCompanyLogo={showCompanyLogo}
                    />
                ))
            ) : (
                <Box
                    sx={{
                        backgroundColor: variables.CYAN1,
                        minHeight: '200px',
                        padding: '24px',
                        borderRadius: '30px',
                        textAlign: 'center'
                    }}
                >
                    No Jobs added
                </Box>
            )}
            {isUserAuthenticated && allActiveJobsPageInfo.hasNextPage ? (
                listLoader ? (
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <CircularProgress
                            size={20}
                            sx={{
                                color: variables.BLUE
                            }}
                        />
                    </Box>
                ) : (
                    <LoadMoreLink
                        onClick={() => {
                            if (allActiveJobsPageInfo.hasNextPage) {
                                setListLoader(true)
                                fetchMoreActiveJobs({
                                    variables: {
                                        afterCursor: allActiveJobsPageInfo.endCursor
                                            ? allActiveJobsPageInfo.endCursor
                                            : ''
                                    }
                                })
                            }
                        }}
                    />
                )
            ) : null}
        </Container>
    )
}

export default CompanyJobList
