import { Box, CircularProgress, Container } from '@mui/material'
import React from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import '../IntrestingJobs/intrestingjobs.scss'
import { gql, useQuery } from '@apollo/client'
import variables from '../../settings/_variables.scss'
import JobPostCard from '../organisams/JobPostCard'
import { SliderFooterArrows } from '../../utils/commonFunctions'
import { useSelector } from 'react-redux'
import SliderLoadMoreCard from '../molecules/SliderLoadMoreCard'
import { useNavigate } from 'react-router-dom'

const INTRESTING_JOBS_QUERY = gql`
    query IntrestingJobs {
        intrestingJobs(first: 6) {
            nodes {
                id
                jobTitle
                companyName
                companyLogoUrl
                isLiked
                likes
                createdAt
                states{
                    id
                    name
                }
                departments{
                    id
                    name
                }
            }
        }
    }
`
const MoreJobOffers = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { loading, data, error } = useQuery(INTRESTING_JOBS_QUERY, {
        fetchPolicy: 'network-only',
    })
    const navigate = useNavigate()
    if (loading)
        return (
            <Box
                sx={{
                    textAlign: 'center',
                    minHeight: '100px',
                    paddingTop: '30px'
                }}
            >
                <CircularProgress
                    sx={{
                        color: variables.BLUE
                    }}
                />
            </Box>
        )
    if (error) return `Error! ${error.message}`

    const intrestingJobs = data.intrestingJobs.nodes
    return (
        <Container maxWidth={false} disableGutters={true}>
            <Box component='div'>
                <ScrollMenu
                    Footer={SliderFooterArrows}
                    scrollContainerClassName="candidate-dashboard--pending_invites_scroll_container"
                >
                    {intrestingJobs.map((elem, index) => {
                        return <JobPostCard data={elem} index={index} key={index} orientation={responsiveBreakpoint === 'mob' ? 'vertical': 'horizontal' } refetchAppliedJobCount={props.refetchAppliedJobCount}/>
                    })}
                    <SliderLoadMoreCard
                        text='View More Jobs'
                        onClick={() => {
                            navigate('/job-list')
                        }}
                    />
                </ScrollMenu>
            </Box>
        </Container>
    )
}
export default MoreJobOffers
