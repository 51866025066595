/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { BUTTON_DATA, keys, TextStrings } from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PLUS_BLACK from '../../../assets/icons/PLUS_BLACK.svg'
import { styles } from './styles'
import Layout from '../../../template/Layout'

import EducationRow from './educationRow'
import ExperienceRow from './experienceRow'
import { useState } from 'react'
import ManageExperienceModal from '../../../components/organisams/ManageExperienceModal'
import ManageEducationModal from '../../../components/organisams/ManageEducationModal'
import EducationList from '../../../components/EducationList'
import ExperienceList from '../../../components/ExperienceList'

const ProfileSetupStepTwo = props => {
    const navigate = useNavigate()
    const [manageExpModalOpen, setManageExpModalOpen] = useState(false)
    const [manageEduModalOpen, setManageEduModalOpen] = useState(false)
    let { key } = useParams()
    let buttonDetails = BUTTON_DATA.find(o => o.key === key)
    const { profileDetail } = useSelector(state => state.profileDetail)
    
    const isEducation = key === keys.education

    return (
        <Layout>
            <Box sx={styles.container}>
                <Box sx={styles.header}>
                    <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                        {buttonDetails?.label}
                    </Typography>
                    <Box
                        sx={styles.backIconContainer}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <Icons src={BACK} />
                    </Box>
                </Box>
                <Box sx={styles.formContainer}>
                    <Button
                        label={`${TextStrings.add} ${
                            isEducation ? buttonDetails?.label.toLowerCase() : TextStrings.aPosition
                        }`}
                        color='secondary'
                        variant='contained'
                        size='large'
                        onClick={() => {
                            isEducation
                                ? setManageEduModalOpen(true)
                                : setManageExpModalOpen(true)
                        }}
                        sx={styles.button}
                        startIcon={<Icons src={PLUS_BLACK} />}
                    />
                    <Box sx={{mt: 3, width: '100%'}}>
                        {profileDetail[key] &&
                            profileDetail[key].map(elem => {
                                if (isEducation) {
                                    return <EducationList isEdit={true} data={elem} />
                                } else {
                                    return <ExperienceList isEdit={true} data={elem} />
                                }
                            })}
                    </Box>
                </Box>
                {
                    isEducation ? <ManageEducationModal open={manageEduModalOpen} onClose={() => setManageEduModalOpen(false)}/>: <ManageExperienceModal open={manageExpModalOpen} onClose={() => setManageExpModalOpen(false)}/>
                }
            </Box>
        </Layout>
    )
}

export default ProfileSetupStepTwo
