/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Box, FormHelperText, IconButton, Typography } from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { keys, TextStrings, AVATAR } from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import variables from '../../../settings/_variables.scss'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import {
    addAvatarDetailSuccess,
    addProfileDetailFailure
} from '../../../redux/actions/profileDetail'

import './styles.scss'
import { useMutation } from '@apollo/client'
import { MANAGE_USER_PROFILE_IMAGE_MUTATION } from '../../../gql/queries'
import { styles } from './styles'
import { useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import Loader from '../../../components/Loader'
import { setAvatar } from '../../../redux/actions/login'
import { useSelector } from 'react-redux'
import PageMetaTags from '../../../base/PageMetaTags'
const AvatarDetail = props => {
    const navigate = useNavigate()
    const {responsiveBreakpoint} = useSelector(state => state.initial)

  
    const dispatch = useDispatch()
    const [selectedAvatar, setSelectedAvatar] = useState()
    const [apiError, setApiError] = useState('')

    const formRef = useRef(null)

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (selectedAvatar) {
                    formRef.current.click()
                }
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [selectedAvatar])

    /**
     * @method onSaveClick
     * @description saving avatar selected data
     */
    const onSaveClick = () => {
        setApiError('')
        let formData = []
        formData['key'] = keys.selectAnAvatar
        formData['data'] = { profileImage: selectedAvatar, actionType: 'create' }
        manageProfileImageMutation({
            variables: {
                ...formData.data
            }
        })
        // dispatch(addAvatarDetailSuccess(formData))
        // navigate(-1)
    }

    /** api integration graphql */
    const [manageProfileImageMutation, { data, error, loading }] = useMutation(
        MANAGE_USER_PROFILE_IMAGE_MUTATION,
        {
            onCompleted(data) {
                let formData = []
                formData['key'] = keys.selectAnAvatar
                formData['data'] = { profileImage: data.manageProfileImage.user.profileImage }

                dispatch(addAvatarDetailSuccess(formData))
                dispatch(setAvatar(data.manageProfileImage.user.profileImage))
                navigate(-1)
            },
            onError(error) {
                setApiError(error)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    const location = useLocation()
    return (
        <Box sx={styles.container}>
            <PageMetaTags
                title={`${
                    location.pathname.includes('profileSetup') ? 'Profile Setup | ' : ''
                }Bunton`}
            />
            <Box sx={styles.header}>
                <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                    {`${TextStrings.selectAnAvatar}`}
                </Typography>
                <Box
                    sx={styles.backIconContainer}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <Icons src={BACK} />
                </Box>
            </Box>
            <Box sx={styles.mainIconContainer}>
                <Box sx={styles.mainAvatarContainer}>
                    <Box sx={styles.newAvatarContainer} className='avatar-list'>
                        <ImageList
                            cols={responsiveBreakpoint === 'mob' ? 2 : 3}
                            rowHeight={responsiveBreakpoint === 'mob' ? 130 : 164}
                        >
                            {AVATAR.map(item => (
                                <ImageListItem key={item.name}>
                                    <IconButton
                                        onClick={() => setSelectedAvatar(item.name)}
                                        sx={[styles.avatarBorder]}
                                    >
                                        <Icons
                                            sx={{
                                                height: '114px',
                                                width: '114px',
                                                borderRadius: '70px',
                                                border:
                                                    selectedAvatar === item.name
                                                        ? `6px solid ${variables.BLUE}`
                                                        : `3px solid ${variables.CYAN}`
                                            }}
                                            src={require(`../../../assets/icons/AVATAR/${item.name}.png`)}
                                        />
                                        {/* {item.name == 'AVATAR_ONE' ? (
                                            <Icons
                                                src={require(`../../../assets/icons/AVATAR/${item.name}.svg`)}
                                            />
                                        ) : (
                                            <Icons
                                                sx={{
                                                    height: '114px',
                                                    width: '114px',
                                                    borderRadius: '70px'
                                                }}
                                                src={require(`../../../assets/icons/AVATAR/${item.name}.png`)}
                                            />
                                        )} */}
                                    </IconButton>
                                </ImageListItem>
                            ))}
                        </ImageList>

                        {/*                       
                        {AVATAR.map(item => (
                            <IconButton
                                onClick={() => setSelectedAvatar(item.name)}
                                sx={[
                                    styles.avatarBorder,
                                    {
                                        border:
                                            selectedAvatar === item.name
                                                ? `5px solid ${variables.BLUE}`
                                                : `3px solid ${variables.CYAN}`
                                    }
                                ]}
                            >
                                <Icons
                                    src={require(`../../../assets/icons/AVATAR/${item.name}.png`)}
                                />
                            </IconButton>
                        ))} */}
                    </Box>
                </Box>
                {apiError && apiError?.message ? (
                    <FormHelperText 
                        id='my-helper-text'
                        error={true}
                    >
                        <strong>{apiError?.message}</strong>
                    </FormHelperText>
                ) : null}
                <Button
                    ref={formRef}
                    label={TextStrings.save}
                    onClick={onSaveClick}
                    color='primary'
                    disabled={!selectedAvatar}
                    variant='contained'
                    size='large'
                    type='submit'
                    sx={styles.saveButton}
                />
            </Box>
            {loading && <Loader />}
        </Box>
    )
}

export default AvatarDetail
