import React from 'react'
import PageMetaTags from '../../../base/PageMetaTags'
import JobList from '../../../components/JobList'
import Layout from '../../../template/Layout'
import { Box, Container } from '@mui/material'

const Jobs = props => {
    return (
        <Layout>
            <PageMetaTags title='Jobs List | Bunton' />
            <Container maxWidth='lg' sx={{mb: 5}}>
                <Box>
                    <JobList listAccess='admin' />
                </Box>
            </Container>
        </Layout>
    )
}

export default Jobs
