import { Box, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import EmptyListSection from '../elements/EmptyListSection'
import LoadMoreLink from '../elements/LoadMoreLink'
import { useQuery } from '@apollo/client'
import Loader from '../Loader'
import variables from '../../settings/_variables.scss'
import EngagementQuestionsListRow from '../molecules/EngagementQuestionsListRow'
import { GET_QUESTIONS_LIST_QUERY } from '../../queries/engagementQuestionsQueries'

const EngagementQuestionList = props => {
    const [listLoader, setListLoader] = useState(false)
    const { loading, error, data, fetchMore, refetch } = useQuery(GET_QUESTIONS_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: ''
        },
        onCompleted(data) {
			setListLoader(false)
		},
        onError(error) {}
    })
    if (loading) return <Loader />
    const questionsData = data.getQuestionData.edges.map(edge => edge.node)
    const pageInfo = data.getQuestionData.pageInfo
    return <Box>
    <Box
        sx={{
            my: 6
        }}
    >
        {questionsData && questionsData.length > 0 ? (
            questionsData.map((request, index) => (
                <EngagementQuestionsListRow data={request} key={index} />
            ))
        ) : (
            <EmptyListSection message='no contact request at the moment.' />
        )}
    </Box>
    {pageInfo && pageInfo.hasNextPage ? (
        listLoader ? (
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <CircularProgress
                    size={20}
                    sx={{
                        height: '20px',
                        width: '20px',
                        color: variables.BLUE
                    }}
                />
            </Box>
        ) : (
            <LoadMoreLink
                onClick={() => {
                    if (pageInfo.hasNextPage) {
                        setListLoader(true)
                        fetchMore({
                            variables: {
                                afterCursor: pageInfo.endCursor ? pageInfo.endCursor : ''
                            }
                        })
                    }
                }}
            />
        )
    ) : null}
</Box>
}

export default EngagementQuestionList