import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HomePageButtons, SectionContainer } from '../../pages/HomePage'
import { Link } from 'react-router-dom'
import variables from '../../settings/_variables.scss'

const JoinBuntonSection = props => {
    const {t} = useTranslation()
    return <SectionContainer maxWidth={false} disableGutters sx={{borderTop: `2px solid ${variables.CYAN}`, 
        p: {
            xs: '50px',
            lg: '70px',
            xl: '100px'
    }}}>
           
           <Grid container alignItems='center' maxWidth='xl' sx={{m: 'auto', textAlign: 'center'}}>
               <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'center'}} >
                <Box sx={{maxWidth: '1119px', m: 'auto'}}>
                   <Typography variant='h1' className='section-heading'>
                   {t('joinbunton.heading')}
                   </Typography>
                </Box>
                <Box sx={{maxWidth: '1017px', m: 'auto', display: {xs: 'none', md: 'block'}}}>
                    <Typography variant='h3' className='small-size-18px' sx={{mt: 6, lineHeight: '1.5'}}>
                            <Trans i18nKey="joinbunton.details" >
                                {t('joinbunton.details')}
                            </Trans>
                        </Typography>
                    </Box>
               </Grid>
               <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'center', mt: 6}}>
                   <HomePageButtons
                       label={t('joinbunton.registerbutton')}
                       component={Link}
                       to='/registration'
                       color='primary'
                       variant='contained'
                       size='large'
                   />
               </Grid>
           </Grid>
    </SectionContainer>
}

export default JoinBuntonSection