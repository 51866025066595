import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { Button } from '../../../components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutSuccess } from '../../../redux/actions/login'
import Cookies from 'universal-cookie'
import { Popover } from '@mui/material'
import variables from '../../../settings/_variables.scss'
import ProfileIcon from '../../../assets/icons/profile.svg'
import DefaultProfilIcon from '../../../assets/images/avatar.png'
import BuntonIcon from '../../../assets/images/MiniLogo.svg'
import LogoutIcon from '../../../assets/icons/logout.svg'
import { truncateDetails } from '../../../utils/commonFunctions'
import { deleteAllProfileDetails } from '../../../redux/actions/profileDetail'

import { getAvatrURL } from '../../../utils/utilities'
import { LOGOUT_MUTATION } from '../../../queries/signInSignOutQueries'
import ShareJobButton from '../../../components/elements/ShareJobButton'
import ShareModal from '../../../components/organisams/ShareModal'
import ThankyouModal from '../../../components/organisams/ThankyouModal'
import { useTranslation } from 'react-i18next'

const cookies = new Cookies()

const LoggedInUserNavigation = props => {
    const {t} = useTranslation()
    const { loginCheckAuthDetails, loginDetails } = useSelector(state => state.login)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    const [logoutMutation, { data }] = useMutation(LOGOUT_MUTATION, {
        onCompleted(data) {
            dispatch(logoutSuccess(data))
            dispatch(deleteAllProfileDetails(data))
            cookies.remove('BUNTON_ACCESS_TOKEN')
            cookies.remove('BUNTON_CLIENT_TOKEN')
            cookies.remove('BUNTON_EXPIRY')
            cookies.remove('BUNTON_UID')
            navigate('/login')
        }
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [showShareModal, setShowShareModal] = useState(false)
    const [thanksModal ,setThanksModal] = useState(false)
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const changePasswordPath = `/change-password/${btoa(userId)}`
    return (
        <React.Fragment>
            <Grid container spacing={1} direction='row-reverse'>
            <Grid item >
                    <Box sx={{ display: 'flex', float: 'right' }}>
                        <Tooltip title='Account settings'>
                            <Box
                                className={
                                    open
                                        ? 'loggedin-navigation loggedin-navigation--container_open'
                                        : 'loggedin-navigation'
                                }
                                component='div'
                            >
                                <Box
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup='true'
                                    onClick={handleClick}
                                    className=''
                                    sx={{
                                        display: 'flex',
                                        width: '186px',
                                        height: '50px'
                                    }}
                                >
                                    <Box
                                        component='span'
                                        className=''
                                        sx={{
                                            fontSize: '15px',
                                            width: '80%',
                                            textAlign: 'center',
                                            fontWeight: '600',
                                            padding: '13px 6px',
                                            color: variables.BLUE,
                                            fontFamily: variables.poppinsBlack
                                        }}
                                    >
                                        {truncateDetails(loginCheckAuthDetails.name, 16)}
                                    </Box>
                                    <Box
                                        sx={{
                                            paddingRight: '6px',
                                            paddingTop: '5px'
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                objectFit: 'contain',
                                                backgroundColor: '#fff'
                                            }}
                                            alt='Natacha'
                                            src={
                                                ['user', 'employer'].includes(userRole)
                                                    ? loginCheckAuthDetails.profileImage
                                                        ? userRole === 'employer'
                                                            ? loginCheckAuthDetails.company ? loginCheckAuthDetails.company.companyLogoUrl : null
                                                            : userRole === 'user'
                                                            ? getAvatrURL(
                                                                loginCheckAuthDetails.profileImage
                                                            )
                                                            : DefaultProfilIcon
                                                        : DefaultProfilIcon
                                                    : BuntonIcon
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Tooltip>
                    </Box>

                    <Popover
                        id='long-menu'
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transitionDuration={{ appear: 400, enter: 400, exit: 0 }}
                        PaperProps={{
                            style: {
                                width: '186px',
                                borderRadius: '0 0 30px 30px',
                                backgroundColor: `${variables.CYAN}`,
                                boxShadow: 'none',
                                transitionTimingFunction: 'linear'
                            }
                        }}
                    >
                        {['user', 'employer'].includes(userRole) ? (
                            <>
                                <MenuItem
                                    sx={{
                                        padding: '25px 8px',
                                        fontSize: '15px',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Link to='/my-profile'>
                                        <Typography
                                            component='span'
                                            sx={{
                                                width: '100%',
                                                fontSize: '15px'
                                            }}
                                        >
                                            {t('menu.options.myprofile')}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={ProfileIcon}
                                            sx={{
                                                width: '20px',
                                                height: '20px',
                                                marginLeft: '15px'
                                            }}
                                        />
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        // margin: '50px 0px',
                                        // padding: '25px 40px',
                                        // float: 'right',
                                        // fontSize: '15px'
                                        padding: '25px 8px',
                                        fontSize: '15px',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Link to={changePasswordPath}>
                                        <Typography
                                            component='span'
                                            sx={{
                                                width: '100%',
                                                whiteSpace: 'break-spaces'
                                            }}
                                        >
                                            {t('menu.options.changepassword')}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={ProfileIcon}
                                            sx={{
                                                width: '20px',
                                                height: '20px',
                                                marginLeft: '15px'
                                            }}
                                        />
                                    </Link>
                                </MenuItem>
                            </>
                        ) : null}

                        {
                            userRole === 'admin' ? 
                            <MenuItem
                                sx={{
                                    padding: '25px 8px',
                                    fontSize: '15px',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Link to='/admin-portal/settings'>
                                    <Typography
                                        component='span'
                                        sx={{
                                            width: '100%',
                                            fontSize: '15px'
                                        }}
                                    >
                                        {t('menu.options.settings')}
                                    </Typography>
                                    <Box
                                        component='img'
                                        src={ProfileIcon}
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            marginLeft: '15px'
                                        }}
                                    />
                                </Link>
                            </MenuItem>
                            : null
                        }

                        <MenuItem
                            onClick={() => {
                                logoutMutation()
                            }}
                            sx={{
                                // margin: '50px 0px',
                                // padding: '25px 40px',
                                // textAlign: 'right',
                                // fontSize: '15px'
                                padding: '25px 8px',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Link to={() => {}}>
                                <Typography
                                    component='span'
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    {t('menu.options.logout')}
                                </Typography>
                                <Box
                                    component='img'
                                    src={LogoutIcon}
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '15px'
                                    }}
                                />
                            </Link>
                        </MenuItem>
                    </Popover>
                </Grid>
                {responsiveBreakpoint !== 'tab' && 'user' === userRole ? (
                    <>
                        <Grid item >
                            <Link to={'/job-list'}>
                                <Button
                                    label='Find Jobs'
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        padding: '13px 30px',
                                        fontSize: '14px',

                                        fontWeight: '600',
                                        marginLeft: '15px',
                                        marginRight: '18px'
                                    }}
                                />
                            </Link>
                        </Grid>
                    </>
                ) : null}
                {location.pathname === '/dashboard' && <Grid item >
                    <ShareJobButton
                        color='secondary'
                        variant='contained'
                        iconEnable={false}
                        textEnable={true}
                        onClick={() => {
                            setShowShareModal(true)
                        }}
                    />
                    <ShareModal
                        open={showShareModal}
                        handleClose={() => setShowShareModal(false)}
                        shareJob={false}
                        userId={userId}
                        showThankYou={() => {
                            setThanksModal(true)
                            setTimeout(() => {
                                setThanksModal(false)
                            }, 3000)
                        }}
                    />
                    <ThankyouModal
                        message='Thank you for sharing!'
                        open={thanksModal}
                        handleClose={() => setThanksModal(false)}
                    />
                </Grid>}
            </Grid>
        </React.Fragment>
    )
}

export default LoggedInUserNavigation
