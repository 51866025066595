import { combineReducers } from 'redux'
import initialDataReducer from './initialDataReducer'
// import { routerReducer } from "react-router-redux";

import loginReducer from './loginReducer'
import profileDetailReducer from './profileDetailReducer'
import registrationReducer from './registrationReducer'

const rootReducer = combineReducers({
    // routing: routerReducer,
    initial: initialDataReducer,
    login: loginReducer,
    registration: registrationReducer,
    profileDetail: profileDetailReducer
})

export default rootReducer
