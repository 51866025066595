import React, { useState } from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, CircularProgress, MobileStepper } from '@mui/material'
import variables from '../../settings/_variables.scss'
import { useSelector } from 'react-redux'
import { experiencesList } from '../../utils/constantData'
import ControlledCheckboxGroup from '../molecules/ControlledCheckboxGroup'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '../../constants'
import CustomFormLabel from '../elements/CustomFormLabel'
import ControlledRadioGroup from '../molecules/ControlledRadioGroup'
import Button from '../Button'
import Icons from '../Icons'
import ArrowRight from '../../assets/icons/ARROW.svg'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { UPDATE_MANDATORY_QUESTIONS_MUTATION } from '../../queries/registrationQueries'
import SuccessIcon from '../../assets/icons/success_green.svg'
import ModalStatusSection from '../elements/ModalStatusSection'

const MandatoryQuestionsModal = props => {
    const { allStates, allIndustries, allDepartments } = useSelector(state => state.initial)

    const steps = [
        {
            question: 'What is your current department? You can select 2.',
            options: [...allDepartments],
            multiSelect: true,
            otherField: true,
            allowedAnswers: 2,
            key: 'department',
            errorKey: 'emptyDepartment',
            maxErrorKey: 'regMaxNumberOfDepartment'
        },
        {
            question: 'How many years of experience do you have?',
            options: experiencesList,
            multiSelect: false,
            otherField: false,
            ansToShow: '',
            allowedAnswers: 1,
            inputType: 'radio',
            errorKey: 'emptyExperience',
            key: 'experience'
        },
        {
            question: 'What industries do you prefer to work in? Select up to 2.',
            options: [...allIndustries],
            multiSelect: true,
            otherField: true,
            allowedAnswers: 2,
            key: 'industry',
            errorKey: 'emptyIndustries',
            maxErrorKey: 'regMaxNumberOfIndustry'
        },
        {
            question: 'Where do you like to work? Select up to 3 locations.',
            options: [...allStates],
            allowedAnswers: 3,
            multiSelect: true,
            otherField: false,
            errorKey: 'emptyLocation',
            key: 'location',
            maxErrorKey: 'regMaxNumberOfLocation'
        }
    ]
    const [showStatus, setShowStatus] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [otherFieldName, setOtherFieldName] = useState(
        steps[activeStep].otherField
            ? `other${steps[activeStep].key.replace(/^./, steps[activeStep].key[0].toUpperCase())}`
            : ''
    )
    const maxSteps = steps.length
    const { open, handleClose, userId } = props

    const [updateMandatoryQuestionMutation, {loading, data, error}] = useMutation(UPDATE_MANDATORY_QUESTIONS_MUTATION, {
        onCompleted(data) {
            setShowStatus(true)
            setTimeout(() => {
                window.location.pathname = '/profileSetup'
                // props.onCompleteClose()
            }, 3000)
        }
    })

    const submitData  = async (data) =>{
        let formData = {
            experience: data.experience,
            location: data.location.join(),
            industry: data.industry.join(),
            otherIndustry: data.otherIndustry,
            department: data.department.join(),
            otherDepartment: data.otherDepartment,
        }
        updateMandatoryQuestionMutation({
            variables:{
                userId: userId,
                ...formData
            }
        })
    }

    let initialFormState = {
        experience: '',
        location: '',
        department: '',
        otherDepartment: '',
        industry: '',
        otherIndustry: ''
    }

    const {
        control,
        handleSubmit,
        trigger,
        getValues,
        getFieldState,
        setValue,
        formState: { errors },
        register
    } = useForm({
        defaultValues: initialFormState
    })

    function handleMutliSelect(checkedName, fieldName) {
        const newNames = getValues(fieldName)?.includes(checkedName)
            ? getValues(fieldName)?.filter(name => name !== checkedName)
            : [...(getValues(fieldName) ?? []), checkedName]

        return newNames
    }

    const handleNext = async key => {
        const resolvedPromise = await trigger(key)
        if (resolvedPromise) {
            if (steps[activeStep].otherField) {
                const resolveOtherPromise = await trigger(otherFieldName)
                if (resolveOtherPromise) {
                    setOtherFieldName(
                        `other${steps[activeStep+1].key.replace(
                            /^./,
                            steps[activeStep+1].key[0].toUpperCase()
                        )}`
                    )
                    setActiveStep(prevActiveStep => prevActiveStep + 1)
                }
            } else {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
        }
    }

    const handlePrev = () => {
        if (activeStep > 0 && steps[activeStep-1] && steps[activeStep-1].otherField) {
            // setOtherFieldName(
            //     `other${steps[activeStep-1].key.replace(
            //         /^./,
            //         steps[activeStep-1].key[0].toUpperCase()
            //     )}`
            // )
            setActiveStep(prevActiveStep => prevActiveStep - 1)
        } else {
            setActiveStep(prevActiveStep => prevActiveStep - 1)
        }
    }
    const loadingCss = loading ? {
        position: 'relative',
        height: '84%'
    } : {}
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            modalTitle={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '80%' }}>
                        <MobileStepper
                            variant='dots'
                            steps={maxSteps}
                            position='static'
                            activeStep={activeStep}
                            nextButton={false}
                            backButton={false}
                            sx={{
                                '& .MuiMobileStepper-dots': {
                                    m: 0
                                }
                            }}
                        />
                    </Box>
                    {!loading && !showStatus && <Button
                        label='Logout'
                        component={Link}
                        to='/logout'
                        color='primary'
                        variant='contained'
                        size='large'
                        sx={{ p: '8px 40px' }}
                    />}
                </Box>
            }
            titleSx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            boxSx={{
                width: {
                    xs: '80%',
                    sm: '70%',
                    md: '60%',
                    lg: '50%'
                },
                maxHeight: {
                    xs:'400px',
                    sm: '480px'
                },
                overflowY: 'none',
                height: '80%'
            }}
            contentBoxSx={{
                px: 0,
                ...loadingCss
            }}
            hideClose={true}
        >
            {loading ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <CircularProgress size={50} sx={{ color: variables.BLUE }} />
                </Box>
            ) : showStatus ? <ModalStatusSection statusIcon={SuccessIcon} statusText='Thank You for Your Response'/> : (
                <>
                    <Box
                        sx={{
                            maxHeight: {
                                xs: '230px',
                                sm: '300px'
                            },
                            height: {
                                xs: '70%',
                                sm: '100%'
                            },
                            p: 2,
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            // maxWidth: '700px',
                            width: 'auto'
                        }}
                    >
                        <Box key={activeStep} {...register(steps[activeStep].key)}>
                            <CustomFormLabel
                                formLabel={steps[activeStep].question}
                                formFieldError={
                                    errors &&
                                    errors[`${steps[activeStep].key}`] &&
                                    errors[`${steps[activeStep].key}`].message
                                }
                                labelSx={{ m: 0, mb: 2 }}
                                {...register(steps[activeStep].key)}
                            />
                            {steps[activeStep].inputType === 'radio' ? (
                                <ControlledRadioGroup
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ErrorMessage[`${steps[activeStep].errorKey}`]
                                        }
                                    }}
                                    register={register}
                                    name={steps[activeStep].key}
                                    options={steps[activeStep].options}
                                    errors={
                                        errors &&
                                        errors[`${steps[activeStep].key}`] &&
                                        errors[`${steps[activeStep].key}`].message
                                    }
                                />
                            ) : (
                                <ControlledCheckboxGroup
                                    control={control}
                                    register={register}
                                    rules={{
                                        validate: {
                                            minimumOne: value => {
                                                return (
                                                    value.length > 0 ||
                                                    ErrorMessage[`${steps[activeStep].errorKey}`]
                                                )
                                            },
                                            maxSelect: value => {
                                                return (
                                                    value.length <= steps[activeStep].allowedAnswers ||
                                                    ErrorMessage[steps[activeStep].maxErrorKey].replace('MAX_NUM', steps[activeStep].allowedAnswers)
                                                )
                                            }
                                        }
                                    }}
                                    otherFieldRules={
                                        steps[activeStep].otherField
                                            ? {
                                                required: {
                                                    value: true,
                                                    message: ErrorMessage.emptyOthers
                                                }
                                            }
                                            : {}
                                    }
                                    name={steps[activeStep].key}
                                    options={steps[activeStep].options}
                                    errors={
                                        errors &&
                                        errors[`${steps[activeStep].key}`] &&
                                        errors[`${steps[activeStep].key}`].message
                                    }
                                    otherFieldErrors={
                                        steps[activeStep].otherField &&
                                        errors &&
                                        errors[`${otherFieldName}`] &&
                                        errors[`${otherFieldName}`].message
                                    }
                                    handleMutliSelect={
                                        steps[activeStep].multiSelect ? handleMutliSelect : null
                                    }
                                    resetField={() => setValue(otherFieldName, '')}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box sx={{
                        position: {
                            xs: 'relative',
                            sm: 'absolute'
                        },
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        py: 2,
                        justifyContent: 'center',
                        display: {
                            xs: 'block',
                            sm : 'flex'
                        },
                    }}>
                        <MobileStepper
                                variant='dots'
                                steps={maxSteps}
                                position='static'
                                activeStep={activeStep}
                                nextButton={
                                    activeStep === maxSteps - 1 ? <Button
                                    label='Submit'
                                    color='primary'
                                    variant='contained'
                                    // endIcon={
                                    //     <Icons
                                    //         sx={{ width: '15px', height: '15px' }}
                                    //         src={ArrowRight}
                                    //     />
                                    // }
                                    size='large'
                                    sx={{
                                        padding: '8px 40px',
                                        ml: 1
                                    }}
                                    onClick={async() => {
                                        const resolvePromise1 = await trigger(steps[activeStep].key)
                                        if(resolvePromise1){
                                            handleSubmit(submitData)()
                                        }
                                    }}
                                />:<Button
                                        label='Next'
                                        color='primary'
                                        variant='contained'
                                        endIcon={
                                            <Icons
                                                sx={{ width: '15px', height: '15px' }}
                                                src={ArrowRight}
                                            />
                                        }
                                        size='large'
                                        sx={{
                                            padding: '8px 40px',
                                            ml: 1
                                        }}
                                        onClick={() => {
                                            handleNext(steps[activeStep].key)
                                        }}
                                        disabled={activeStep === maxSteps - 1}
                                    />
                                }
                                backButton={
                                    <Button
                                        label='Prev'
                                        color='primary'
                                        variant='contained'
                                        startIcon={
                                            <Icons
                                                sx={{
                                                    width: '15px',
                                                    height: '15px',
                                                    transform: 'rotateY(180deg)'
                                                }}
                                                src={ArrowRight}
                                            />
                                        }
                                        size='large'
                                        sx={{
                                            padding: '8px 40px',
                                            mr: 1
                                        }}
                                        onClick={() => {
                                            handlePrev()
                                        }}
                                        disabled={activeStep === 0}
                                    />
                                }
                                sx={{
                                    '& .MuiMobileStepper-dots': {
                                        display: 'none'
                                    }
                                }}
                            />
                    </Box>
                </>
            )}
        </BaseModal>
    )
}

export default MandatoryQuestionsModal
