import { Box, Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import PageMetaTags from '../../base/PageMetaTags'
import CompaniesList from '../../components/CompaniesList'
import CompanySearch from '../../components/CompanySearch'
import JobSearch from '../../components/JobSearch'
import Layout from '../../template/Layout'

const PublicCompanyList = props => {
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    return (
        <Layout headerRequired={responsiveBreakpoint === 'mob' ? false : true}>
            <PageMetaTags title='Companies | Bunton' />
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    marginTop:
                        userRole !== 'admin' && responsiveBreakpoint !== 'mob' ? '-190px' : '0px',
                    height: 'inherit'
                }}
            >
                {userRole !== 'admin' && responsiveBreakpoint !== 'mob' ? (
                    <Box>
                        <CompanySearch />
                    </Box>
                ) : null}

                <Box>
                    <CompaniesList {...props} />
                </Box>
            </Container>
        </Layout>
    )
}

export default PublicCompanyList
