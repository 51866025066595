import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import PageMetaTags from '../../base/PageMetaTags'
import JobSearch from '../../components/JobSearch'
import SuggestedJobList from '../../components/SuggestedJobList'
// import JobSearch from '../../components/JobSearch'
import SuggestedJobSearch from '../../components/SuggestedJobSearch'
import BasicPageHeader from '../../template/BasicPageHeader'
import Layout from '../../template/Layout'

const SuggestedJobs = props => {
    const location = useLocation()
    const state = location.state
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null
    return (
        <Layout
            headerRequired={
                ['employer'].includes(userRole) || responsiveBreakpoint === 'mob' ? false : true
            }
        >
            <PageMetaTags title='Suggested Jobs | Bunton' />
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    marginTop:
                        ['employer'].includes(userRole) || responsiveBreakpoint === 'mob'
                            ? '0px'
                            : '-190px',
                    height: 'inherit',
                    paddingBottom: '80px'
                }}
            >
                {/* <Box>
                    <SuggestedJobSearch />
                </Box> */}
                {responsiveBreakpoint !== 'mob' ? (
                    <Container maxWidth={false} sx={{margin: {
                        xs: '0px 10px 50px 10px',
                        sm: '58px 40px 45px 40px',
                        md: '58px 80px 90px 80px',
                        lg: '58px 100px 90px 100px',
                        xl: '58px 130px 90px 130px'
                    }, width: 'auto'}}>
                        <JobSearch redirectTo={'/suggested-jobs'} />
                    </Container>
                ) : null}
                <Box>
                    <SuggestedJobList />
                </Box>
            </Container>
        </Layout>
    )
}

export default SuggestedJobs
