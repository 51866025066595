import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import Layout from '../template/Layout';
import { Button } from '../components';
import { gql, useMutation } from '@apollo/client';

const WEEKLY_EMAIL_MUTATION = gql`
    mutation SendWeeklyEmail {
        sendWeeklyEmail 
    } 
`

const AdminSettings = props => {
    const [weeklyEmailMutation, { data }] = useMutation(WEEKLY_EMAIL_MUTATION, {
        onCompleted(data) {
            
        }
    })
    return <Layout>
        <Container maxWidth='xl' disableGutters>
            <Box>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item md={7}>
                        Trigger a weekly mail to all candidates that includes latest 10 jobs    
                    </Grid>  
                    <Grid item md={5}>
                    <Button
                        label='Go'
                        color='primary'
                        variant='contained'
                        size='large'
                        onClick={() => { 
                            weeklyEmailMutation()
                        }}
                        sx={{
                            padding: '13px 30px',
                            fontSize: '14px',
                            fontWeight: '600',
                            width: '153px'
                        }}
                    />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </Layout>
}

export default AdminSettings