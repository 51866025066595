import { Box, Container, FormControl, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import DropDown from '../elements/DropDown'
import TextInput from '../TextInput'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import FindJobIcon from '../../assets/icons/SEARCH_BLUE.svg'
import SearchIconWhite from '../../assets/icons/Search-White.svg'
import Location from '../../assets/icons/LOCATION.svg'
import Industry from '../../assets/icons/INDUSTRY.svg'
import Department from '../../assets/icons/DEPARTMENT.svg'
import Experience from '../../assets/icons/EXPERIENCE.svg'
import { experiencesList } from '../../utils/constantData'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledStatusChip } from '../../base/commonStyles'
import { useEffect } from 'react'
import variables from '../../settings/_variables.scss'
import { useTranslation } from 'react-i18next'
import { renameNoPreferenceEntry, truncateDetails } from '../../utils/commonFunctions'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const JobSearch = ({buttonColor, redirectTo = '/job-list' }) => {
    const {t} = useTranslation()
    // const dispatch = useDispatch()
    // const { jobSearchDetails, jobSearchInProgress, jobSearchError } = useSelector(
    //     state => state.jobSearch
    // )
    const { responsiveBreakpoint, allJobTypes } = useSelector(state => state.initial)
    const location = useLocation()
    const state = location.state
    const { allStates, allIndustries, allDepartments } = useSelector(state => state.initial)
    let allIndustriesData = renameNoPreferenceEntry(JSON.parse(JSON.stringify(allIndustries)))

    let allStatesData = JSON.parse(JSON.stringify(allStates))
    if (allStates && allStates.length > 0) {
        allStatesData = [{ id: '-1', name: 'No Preference' }].concat(allStates)
    }
    let allDepartmentsData = JSON.parse(JSON.stringify(allDepartments))
    if (allDepartments && allDepartments.length > 0) {
        allDepartmentsData = [{ id: '-1', name: 'No Preference' }].concat(allDepartments)
    }
    let experiencesListData = JSON.parse(JSON.stringify(experiencesList))
    if (experiencesList && experiencesList.length > 0) {
        experiencesListData = [{ id: '-1', name: 'No Preference' }].concat(experiencesList)
    }

    const [jobType, setJobType] = useState(state && state.jobType && state.jobType !== '' ? state.jobType : null)

    const navigate = useNavigate()

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            ...state
        }
    })

    const searchJob = data => {
        if (data.industryIds === '-1') {
            data.industryIds = ''
        }
        if (data.departmentIds === '-1') {
            data.departmentIds = ''
        }
        if (data.locationIds === '-1') {
            data.locationIds = ''
        }
        if (data.experience === '-1') {
            data.experience = ''
        }
        if(jobType !== null ) {
            data.jobType = jobType
        }
        navigate(redirectTo, { state: { ...data, dashboard: false } })
    }

    useEffect(() => {
        reset(location.state)
    }, [location.state])

    const responsivePath = ['/', '/dashboard'].includes(location.pathname)

    return (
        <Container
            maxWidth='lg'
            disableGutters={{xs: true, sm: false}}
            sx={{
                my: {
                    xs: '20px',
                    sm: '30px',
                    md: '50px'
                }
            }}
        >
            <Box
                component='div'
                sx={{
                    margin:'0 auto'
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={responsivePath ? 10 : 12} sm={12} md={8} lg={8}>
                        <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <TextInput
                                        type='text'
                                        value={value}
                                        name='keyword'
                                        placeholder={['mob'].includes(responsiveBreakpoint) ? truncateDetails(t('findjobs'), 20) : t('findjobs')}
                                        formFieldError=''
                                        required={true}
                                        onChange={onChange}
                                        fullWidth={true}
                                        sx={{
                                            width: '100%',
                                            fontSize: '18px',
                                            padding: '0px'
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <Box component='img' src={FindJobIcon} sx={{height: '18px', width:'18px', ml: 1, mt: {
                                                    xs: 0,
                                                    sm: '-3px'
                                                }}}/>
                                            )
                                        }}
                                    />
                                </FormControl>
                            )}
                            name='keyword'
                        />
                    </Grid>
                    {
                        !['mob', 'tab'].includes(responsiveBreakpoint) ? <Grid
                        item
                        xs={
                            ['mob', 'tab'].includes(responsiveBreakpoint) && responsivePath
                                ? 10
                                : 12
                        }
                        sm={5}
                        md={4}
                        lg={4}
                        
                    >
                        <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DropDown
                                    startAdornment={<Box component='img' src={Location} />}
                                    placeholder={t('selectlocation')}
                                    formControllerSx={{
                                        width: '100%'
                                    }}
                                    lab={t('location')}
                                    options={allStatesData}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            name='locationIds'
                        />
                    </Grid> : null
                    }
                    {['mob', 'tab'].includes(responsiveBreakpoint) && responsivePath  ? (
                        <>
                            <Grid
                                item
                                xs={2}
                                sm={1}
                            >
                                <IconButton
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    onClick={handleSubmit(searchJob)}
                                    sx={{
                                        background: variables.BLUE,
                                        p:2
                                    }}
                                >
                                    <Box component='img' src={SearchIconWhite} sx={{height: '30px', width: '30px'}}/>
                                </IconButton>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
                {['mob', 'tab'].includes(responsiveBreakpoint) && responsivePath ? 
                        null : 
                    <Grid container columns={10} spacing={1} sx={{mt: 1}}>
                        <Grid
                            item
                            xs={10}
                            sm={5}
                            md={3}
                            lg={3}
                        >
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DropDown
                                        startAdornment={<Box component='img' src={Industry} />}
                                        placeholder={t('selectindustry')}
                                        lab={t('industry')}
                                        options={allIndustriesData}
                                        onChange={onChange}
                                        value={value}
                                        formControllerSx={{
                                            width: '100%'
                                        }}
                                    />
                                )}
                                name='industryIds'
                            />
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            sm={5}
                            md={3}
                            lg={3}
                        >
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DropDown
                                        startAdornment={<Box component='img' src={Department} />}
                                        placeholder={t('selectdepartment')}
                                        lab={t('department')}
                                        options={allDepartmentsData}
                                        onChange={onChange}
                                        value={value}
                                        formControllerSx={{
                                            width: '100%'
                                        }}
                                    />
                                )}
                                name='departmentIds'
                            />
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            sm={5}
                            md={3}
                            lg={3}
                            
                        >
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DropDown
                                        startAdornment={<Box component='img' src={Experience} />}
                                        placeholder={t('selectexperience')}
                                        lab={t('experience')}
                                        options={experiencesListData}
                                        onChange={onChange}
                                        value={value}
                                        formControllerSx={{
                                            width: '100%'
                                        }}
                                    />
                                )}
                                name='experience'
                            />
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            sm={4}
                            md={1}
                            lg={1}
                            textAlign='right'
                        >
                            <IconButton
                                color='primary'
                                variant='contained'
                                size='large'
                                onClick={handleSubmit(searchJob)}
                                sx={{
                                    background: variables.CYAN,
                                    p:2,
                                    width: '100%',
                                    borderRadius: '30px',
                                    '&:hover': {
                                        background: variables.CYAN,
                                    }
                                }}
                            >
                                <Box component='img' src={FindJobIcon} sx={{height: '30px', width: '30px'}}/>
                                {/* <SearchIcon sx={{color: variables.BLUE, fontSize: '20px',height: '30px', width: '30px'}}/> */}
                            </IconButton>
                            {/* <Button
                                label={t('searchbutton')}
                                color={buttonColor ? buttonColor : 'primary'}
                                variant='contained'
                                size='large'
                                onClick={handleSubmit(searchJob)}
                                sx={{
                                    boxShadow: 'none',
                                    padding: '17px 29px',
                                    width: '100%'
                                }}
                            /> */}
                        </Grid>
                    </Grid>}
                {location.pathname === '/' ? null : ['mob', 'tab'].includes(responsiveBreakpoint)  ? null : (
                    <Grid
                        container
                        sx={{
                            marginTop: '15px'
                        }}
                    >
                        {allJobTypes.map(type => (
                            <StyledStatusChip
                                label={jobType === type.id ? <Box sx={{display:'flex', alignItems: 'center'}}>
                                    <CheckCircleIcon sx={{ width: '20px', mr: 1 }} />
                                    <Typography>
                                        {type.name}
                                    </Typography>
                                </Box>: <Typography>
                                    {type.name}
                                </Typography>}
                                variant='outlined'
                                sx={{
                                    fontSize: '15px',
                                    marginRight: '15px',
                                    mt: '10px'
                                }}
                                onClick={(e) => {
                                    // e.preventDefault()
                                    if(jobType === type.id){
                                        setJobType(null)
                                        navigate(redirectTo, { state: { ...{}, dashboard: false } })
                                    } else {
                                        setJobType(type.id)
                                        navigate(redirectTo, { state: { ...{jobType: type.id}, dashboard: false } })
                                    }
                                }}
                            />
                        ))}
                    </Grid>
                )}
            </Box>
        </Container>
    )
}

export default JobSearch
