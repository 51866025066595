import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Image1 from '../../assets/images/start1.webp'
import Image2 from '../../assets/images/start2.webp'
import Image3 from '../../assets/images/start3.webp'
import Image4 from '../../assets/images/start4.webp'
// import Image5 from '../../assets/images/businesswoman-ceo-freelancer-teacher-working-on-la-2022-01-21-19-24-18-utc@3x.webp'
// import Image6 from '../../assets/images/diverse-corporate-team-working-together-in-modern-2021-09-04-08-15-42-utc@3x.webp'
// import ImagePlaceholder from '../../assets/images/placeholder.png'
import BuntonLogo from '../../assets/icons/LOGO_WITH_TEXT.svg'
import HeaderImages from '../../components/HeaderImages'
import variables from '../../settings/_variables.scss'
import { useSelector } from 'react-redux'

const Header = () => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    return (
        <Container
            maxWidth={false}
            disableGutters={false}
            sx={{
                paddingLeft: {
                    xs: '30px',
                    sm: '40px',
                    md: '80px',
                    lg: '80px',
                    xl: '80px'
                },
                paddingTop: {
                    xs: '0',
                    sm: '64px',
                    md: '64px',
                    lg: '64px',
                    xl: '64px'
                },
                paddingBottom:{
                    xs: '50px',
                    sm: '80px',
                    md: '80px',
                    lg: '80px',
                    xl: '80px'
                }, 
            }}
        >
            <Grid container direction={{xs: 'column-reverse', sm: 'row', md: 'row'}} rowSpacing={5}>
                <Grid item md={6} sm={6} xs={12}>
                {responsiveBreakpoint === 'mob' ? null : (
                    <Box>
                        <Box
                            component='img'
                            src={BuntonLogo}
                            sx={{
                                width: {
                                    xs: '150px',
                                    sm: '190px',
                                    md: '210px',
                                    lg: '231px'
                                }
                            }}
                        />
                    </Box>
                )}
                    <Box sx={{mt: {xs: 0, sm: 5, md: 5}}}>
                        <Box>
                            <Typography
                                variant='h4'
                                sx={{
                                    fontSize: {
                                        sm: '28px',
                                        md: '44px',
                                        lg: '50px'
                                    },
                                    color: variables.CYAN,
                                    fontWeight: '600'
                                }}
                            >
                                Executive positions
                                <br />
                                for women in the <br />
                                corporate world.
                            </Typography>
                        </Box>
                        <Box sx={{mt: 2}}>
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: {
                                        sm: '11px',
                                        md: '13px',
                                        lg: '15px'
                                    },
                                    color: variables.BLUE
                                }}
                            >
                                Bunton provides an easy, anonymous, and safe space for women to
                                introduce <br />
                                themselves to companies looking to diversify their management.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item md={5} sm={5} xs={5}>
                            <HeaderImages
                                src={Image1}
                                sx={{
                                    float: 'right',
                                    width: {
                                        xs: '209px',
                                        sm: '170px',
                                        md: '200px',
                                        lg: '249px'
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={7} sm={7} xs={7}>
                            <HeaderImages
                                src={Image2}
                                sx={{
                                    width: {
                                        xs: '243px',
                                        sm: '200px',
                                        md: '223px',
                                        lg: '309px'
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 0.5}}>
                        <Grid item md={8} sm={8} xs={8}>
                            <HeaderImages
                                src={Image3}
                                sx={{
                                    float: 'right',
                                    width: {
                                        xs: '243px',
                                        sm: '200px',
                                        md: '223px',
                                        lg: '309px'
                                    },
                                }}
                            />
                        
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                            <HeaderImages
                                src={Image4}
                                sx={{
                                    width: {
                                        xs: '209px',
                                        sm: '170px',
                                        md: '200px',
                                        lg: '249px'
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Header
