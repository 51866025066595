import { Box, Chip, Container, FormControl, InputLabel, styled, Typography } from '@mui/material'
import variables from '../settings/_variables.scss'
import { Button } from '../components'
import Icons from '../components/Icons'

export const TableHeading = styled(Typography)`
    font-size: 30px;
    font-weight: 600;
    color: ${variables.BLUE};
`

export const TableSubHeading = styled(Typography)`
    font-size: 18px;
    text-align: right;
    color: ${variables.BLUE};
`

export const Subtitle1Typography = styled(Typography)`
    font-size: 18px;
    color: ${variables.BLUE};
    position: relative;
    font-weight: 600;
    top: -15px;
    .h4 {
        font-size: 15px;
    }
    .p_tag {
        font-size: 15px;
        font-weight: 400;
    }
`

export const Subtitle2Typography = styled(Typography)`
    font-size: 15px;
    color: ${variables.BLUE};
    position: relative;
    top: 2px;
    margin: -3px 0px 32px 0px;
`

export const SubHeading = styled(Typography)`
    font-size: 20px;
    color: ${variables.BLUE};
    margin-top: 15px;
    font-weight: 600;
    margin-bottom: 15px;
`

export const StyledListRow = styled(Container)`
    min-height: 118px;
    background-color: ${variables.CYAN2};
    margin-bottom: 16px;
    border-radius: 50px;
    padding: 30px;
`

export const CompanyJobLogoStyle = styled(Box)`
    width: 88px;
    height: 88px;
    border: 5px solid #fff;
    border-radius: 50px;
`

export const StyledIconBox = styled(Box)`
    width: 42px;
    height: 42px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
`

export const StyledIcon = styled(Box)`
    height: 20px;
    width: 20px;
    top: 25%;
    position: relative;
`

export const StyleInputLabels = styled(InputLabel)`
    font-size: 18px;
    font-weight: 600;
    color: ${variables.BLUE};
`

export const StyleFormControl = styled(FormControl)`
    width: 100%;
    height: 60px;
    font-size: 18px;
`

export const StyledLoadMoreSection = styled(Box)`
    text-align: center;
    margin-top: 35px;
    color: ${variables.BLUE};
    font-size: 18px;
    cursor: pointer;
`

export const StyledStatusChip = styled(Chip)`
    font-size: 18px;
    color: ${variables.BLUE};
    background-color: ${variables.CYAN1};
    border: 0;
    padding: 10px 20px;
    height: 42px;
    border-radius: 50px;
    @media only screen and (max-width: 600px) {
        font-size: 14px;
        padding: 10px;
    }
`

export const StyledSocialLoginButton = styled(Button)`
    background-color: ${variables.WHITE};
    border: 3px solid ${variables.CYAN};
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: none;
`

export const StyledTabHeading = styled(Typography)`
    font-size: 25px;
    font-weight: 600;
    color: ${variables.BLUE};
    text-transform: none;
    font-family: ${variables.poppinsBlack};
`

export const StyledTextIconBox = styled(Box)`
    display: flex;
    align-items: center;
`

export const StyledIcons = styled(Icons)`
    height: 18px;
    width: 18px;
    margin-right: 15px;
`
export const StyledExtraDetails = styled(Typography)`
    font-szie: 15px;
`
export const StyledBenefitsChips = styled(StyledStatusChip)`
    margin-right: 15px;
    margin-top: 10px;
`
