import { Box, Typography } from '@mui/material'
import React from 'react'
import DonwArrow from '../../assets/icons/ARROW DOWN.svg'
import { StyledLoadMoreSection } from '../../base/commonStyles'
import variables from '../../settings/_variables.scss'

const LoadMoreLink = props => {
    return (
        <StyledLoadMoreSection {...props}>
            <Box component='span'>
                <Typography
                    component='span'
                    sx={{
                        fontSize: '18px',
                        color: variables.BLUE
                    }}
                >
                    Load more
                </Typography>
                <Box
                    component='img'
                    src={DonwArrow}
                    sx={{
                        marginLeft: '15px'
                    }}
                />
            </Box>{' '}
        </StyledLoadMoreSection>
    )
}

export default LoadMoreLink
