import variables from '../../../settings/_variables.scss'
export const styles = {
    container: {
        backgroundColor: variables.WHITE1,
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    header: {
        mb: '5px',
        mt: '40px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flex: 1,
        fontSize: '20px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px',
        justifyContent: 'center'
    },
    backIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        ml: {
            xs: '16px',
            sm: '30px',
            md: '80px',
            lg: '80px',
            xl: '80px'
        }
    },
    formContainer: {
        width: {
            xs: '80vw',
            sm: '80vw',
            md: '60vw',
            lg: '50vw',
            xl: '50vw'
        },
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column'
    },
    formLabel: {
        fontSize: '18px',
        fontWeight: 600,
        color: variables.BLUE,
        ml: '30px',
        mb: '10px'
    },
    textInput: {
        width: '100%'
    },
    formControl: { width: '100%', mt: '26px' },
    departmentContainer: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row',
            md: 'row',
            lg: 'row',
            xl: 'row'
        }
    },
    dateContainer: {
        width: '100%',
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-between'
    },
    buttonContainer: { flex: 1, alignSelf: 'center' },
    button: {
        alignSelf: 'center',
        my: '25px',
        px: '30px',
        ml: '20px',
        width: 'auto',
        boxShadow: 'none',
        fontFamily: variables.poppinsRegular
    },
    selectTextInputStyle: { border: '3px solid', borderColor: variables.CYAN, borderRadius: 72 },
    placeholderStyle: {
        fontSize: '18px',
        color: variables.PLACEHOLDER,
        fontFamily: variables.poppinsRegular
    }
}
