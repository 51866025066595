import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import variables from '../../settings/_variables.scss'

const NoResultsFound = props => {
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                width: '100%',
                minHeight: '300px',
                backgroundColor: variables.CYAN2,
                borderRadius: '40px',
                backdropFilter: 'blur(5px)'
            }}
        >
            <Box
                sx={{
                    padding: '24px',
                    textAlign: 'center'
                }}
            >
                <Typography>{props.content}</Typography>
            </Box>
        </Container>
    )
}

export default NoResultsFound
