/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import {
    Box,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from '@mui/material'
import { Button, TextInput } from '../../../components'
import Icons from '../../../components/Icons'
import {
    ErrorMessage,
    FormLabels,
    Placeholder,
    TextStrings,
    keys
} from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import DownArrow from '../../../assets/icons/ARROW DOWN.svg'
import { useMutation } from '@apollo/client'
import { MANAGE_EXPERIENCE_MUTATION } from '../../../gql/queries'
import { useDispatch, useSelector } from 'react-redux'
import {
    addProfileDetailFailure,
    addProfileDetailSuccess,
    editProfileDetailSuccess
} from '../../../redux/actions/profileDetail'
import {
    convertDateFromUTC,
    DATE_FORMATS,
    getMonthList,
    getYearList,
    getMonthNumberFromMonthName
} from '../../../utils/utilities'
import CheckboxIcon from '../../../assets/icons/CHECKBOX.svg'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { styles } from './styles'
import Loader from '../../../components/Loader'
import Layout from '../../../template/Layout'
import moment from 'moment'
import PageMetaTags from '../../../base/PageMetaTags'
import ControlledTextInput from '../../../components/molecules/ControlledTextInput'
import CustomFormLabel from '../../../components/elements/CustomFormLabel'

const ExperienceDetail = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { detailId } = useParams()
    const location = useLocation()
    const { profileDetail } = useSelector(state => state.profileDetail)
    const { allIndustries, allDepartments } = useSelector(state => state.initial)
    const [apiError, setApiError] = useState('')
    const [showDepartmentOther, setShowDepartmentOther] = useState(false)
    const [showIndustryOther, setShowIndustryOther] = useState(false)
    let jobTitle = ''
    let companyName = ''
    let departmentId = ''
    let industryId = ''
    let startMonth = ''
    let startYear = ''
    let endMonth = ''
    let endYear = ''
    let otherDepartment = ''
    let otherIndustry = ''
    let isCurrentJob = false

    let editableDeatilId = ''

    let editableForm = false

    if (
        profileDetail[keys.workExperience] &&
        profileDetail[keys.workExperience].length > 0 &&
        detailId
    ) {
        let formDetailData = profileDetail[keys.workExperience].find(
            o => o.id == detailId.toString()
        )

        if (formDetailData && Object.keys(formDetailData).length > 0) {
            editableForm = true
            // let startDateArray = formDetailData.startDate.split('-')
            // let endDateArray = formDetailData.endDate.split('-')
            let startDateResp = convertDateFromUTC(
                formDetailData.startDate,
                DATE_FORMATS.YEAR_MONTH_FORMAT
            )
            let endDateResp = convertDateFromUTC(
                formDetailData.endDate,
                DATE_FORMATS.YEAR_MONTH_FORMAT
            )
            let startDateArray = startDateResp.split('-')
            let endDateArray = endDateResp.split('-')
            companyName = formDetailData.companyName
            jobTitle = formDetailData.jobTitle
            industryId = formDetailData.industryId
            departmentId = formDetailData.departmentId
            otherDepartment = formDetailData.otherDepartment
            otherIndustry = formDetailData.otherIndustry
            startMonth = startDateArray[1]
            startYear = startDateArray[0]
            endMonth = endDateArray[1]
            endYear = endDateArray[0]
            isCurrentJob = formDetailData.isCurrentJob
            editableDeatilId = formDetailData.id
        }
    }

    const [currentlyWorkingHere, setCurrentlyWorkingHere] = useState(isCurrentJob)

    /** api integration graphql */
    const [manageExperienceMutation, { data, error, loading, reset }] = useMutation(
        MANAGE_EXPERIENCE_MUTATION,
        {
            onCompleted(data) {
                let formData = []
                formData['key'] = keys.workExperience
                formData['data'] = data.manageExperience.experienceDetails
                if (data.manageExperience.actionType === 'update') {
                    dispatch(editProfileDetailSuccess(formData))
                } else {
                    dispatch(addProfileDetailSuccess(formData))
                }
                if (
                    location.pathname === '/add-new-experience/exp' ||
                    location.pathname.includes('edit-experience')
                ) {
                    navigate('/my-profile', { state: { activeIndex: 0 } })
                } else {
                    navigate(-1)
                }
            },
            onError(error) {
                setApiError(error)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    /**
     * @method onSubmit
     * @description saving education data
     */
    const onSubmit = data => {
        setApiError('')
        let formData = []
        formData['key'] = keys.workExperience
        if (editableForm) {
            formData['data'] = {
                id: editableDeatilId,
                jobTitle: data.jobTitle,
                companyName: data.companyName,
                departmentId: data.departmentId,
                otherDepartment: data.otherDepartment,
                otherIndustry: data.otherIndustry,
                industryId: data.industryId,
                startDate: `${data.startMonth}-${data.startYear}`,
                endDate: `${data.endMonth}-${data.endYear}`,
                actionType: 'update',
                isCurrentJob: data.isCurrentJob
            }
        } else {
            formData['data'] = {
                jobTitle: data.jobTitle,
                companyName: data.companyName,
                departmentId: data.departmentId,
                industryId: data.industryId,
                otherDepartment: data.otherDepartment,
                otherIndustry: data.otherIndustry,
                startDate: `${data.startMonth}-${data.startYear}`,
                endDate: `${data.endMonth}-${data.endYear}`,
                actionType: 'create',
                isCurrentJob: data.isCurrentJob
            }
        }
        manageExperienceMutation({
            variables: {
                ...formData.data
            }
        })
        // if (editableForm) {
        //     dispatch(editProfileDetailSuccess(formData))
        // } else {
        //     dispatch(addProfileDetailSuccess(formData))
        // }
        // navigate(-1)
    }

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    }
    const monthList = getMonthList()
    const yearList = getYearList(moment().year(), 1980)
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid },
        resetField,
        setValue,
        getValues,
        clearErrors,
        watch,
        register
    } = useForm({
        defaultValues: {
            jobTitle: jobTitle,
            companyName: companyName,
            departmentId: departmentId,
            otherDepartment: otherDepartment, 
            otherIndustry: otherIndustry,
            industryId: industryId,
            startMonth: startMonth,
            startYear: startYear,
            endMonth: endMonth,
            endYear: endYear,
            isCurrentJob: isCurrentJob
        }
    })

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    let startYearWatch = watch('startYear')
    let startMonthWatch = watch('startMonth')
    let endMonthWatch = watch('endMonth')
    let endYearWatch = watch('endYear')

    const handleCurrentlyWorking = value => {
        setCurrentlyWorkingHere(!value)
        clearErrors('endYear')
        clearErrors('endMonth')
        if (currentlyWorkingHere) {
            setValue('endYear', <Box style={styles.placeholderStyle}> Year</Box>)
            setValue('endMonth', <Box style={styles.placeholderStyle}> Month</Box>)
        }
    }

    const validateDate = (sM, sY, eM, eY) => {
        const startdate = `${sY}-${getMonthNumberFromMonthName(sM)}-01`
        let enddate = ''
        let monthDifference = ''
        let selectedEndDate = ''
        const month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]
        const date = new Date()
        const selectedStartDate = sM + '-' + sY
        const currDate = month[date.getMonth()] + '-' + date.getFullYear()

        if (eM && eY && !currentlyWorkingHere) {
            enddate = `${eY}-${getMonthNumberFromMonthName(eM)}-01`
            monthDifference = moment(enddate).diff(moment(startdate), 'months')
            selectedEndDate = eM + '-' + eY
            let momentEndDate = moment(enddate).format('MM-DD-YYYY')
            let momentStartDate = moment(startdate).format('MM-DD-YYYY')
            if (momentEndDate == 'Invalid date') {
                return ErrorMessage.dateError
            }

            if (monthDifference < 1) {
                return ErrorMessage.dateError
            }

            if (
                (moment(`01-${selectedEndDate}`).format('YYYY-MM-DD') >
                    moment(`01-${currDate}`).format('YYYY-MM-DD') ||
                    moment(`01-${selectedStartDate}`).format('YYYY-MM-DD') >
                        moment(`01-${currDate}`).format('YYYY-MM-DD')) &&
                !isNaN(Number(eY))
            ) {
                return ErrorMessage.upcomingDateError
            }
        }
        // else if(!currentlyWorkingHere && sM && sY && (!eY || !eM))
        // {
        //   return ErrorMessage.dateError
        // }
        else if (
            moment(`01-${selectedStartDate}`).format('YYYY-MM-DD') >
            moment(`01-${currDate}`).format('YYYY-MM-DD')
        ) {
            return ErrorMessage.upcomingDateError
        } else {
            clearErrors('endYear')
            clearErrors('endMonth')
        }
    }

    return (
        <Layout>
            <PageMetaTags title={`${location.pathname.includes('profileSetup') ? 'Profile Setup | ' : '' }Bunton`} />
            <Container maxWidth='xl' >
                <Box sx={{display: 'flex', alignItems: 'center', my: 3}}>
                    <Box
                        onClick={() => {
                            if (
                                location.pathname === '/add-new-experience/exp' ||
                                location.pathname.includes('edit-experience')
                            ) {
                                navigate('/my-profile', { state: { activeIndex: 0 } })
                            } else {
                                navigate(-1)
                            }
                        }}
                    >
                        <Icons src={BACK} />
                    </Box>
                    <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                        {`${
                            editableForm ? TextStrings.edit : TextStrings.add
                        } ${TextStrings.workExperience.toLowerCase()}`}
                    </Typography>
                    
                </Box>
                <Box sx={{
                    maxWidth: '800px',
                    width: '80%',
                    m: '0 auto'
                }}>
                    <Box>
                        <CustomFormLabel
                        labelSx={{mt:2}}
                            formLabel={FormLabels.jobTitle}
                            formFieldError={errors && errors.jobTitle && errors.jobTitle.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyJobTitle
                                }
                            }}
                            name='jobTitle'
                            placeholder={Placeholder.jobTitle}
                            type='text'
                            errors={errors && errors.jobTitle && errors.jobTitle.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{width: '100%'}}
                        />
                    </Box>
                    <Box sx={{mt: 3}}>
                        <CustomFormLabel
                        labelSx={{mt:2}}
                            formLabel={FormLabels.companyName}
                            formFieldError={errors && errors.companyName && errors.companyName.message}
                        />
                        
                        <ControlledTextInput
                            control={control}
                            name='companyName'
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyCompany
                                }
                            }}
                            placeholder={Placeholder.companyName}
                            type='text'
                            errors={errors && errors.jobTitle && errors.jobTitle.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{width: '100%'}}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12} xs={12} >
                            <Box>
                                <Box>
                                    <CustomFormLabel
                                        labelSx={{mt:2}}
                                        formLabel={FormLabels.departmentId}
                                        formFieldError={errors && errors.departmentId && errors.departmentId.message}
                                    />
                                    <Controller
                                        {...register("departments")}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    
                                                    displayEmpty
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                        let departmentName = allDepartments.find(
                                                            o => o.id === e.target.value.toString()
                                                        )?.name
                                                        if(departmentName === 'Others') {
                                                            setShowDepartmentOther(true)
                                                        } else {
                                                            setShowDepartmentOther(false)
                                                            resetField('otherDepartment')
                                                        }
                                                    }}
                                                    input={<OutlinedInput sx={styles.selectTextInputStyle} />}
                                                    renderValue={selected => {
                                                        if (selected.length === 0) {
                                                            return (
                                                                <Box style={styles.placeholderStyle}>
                                                                    Select department
                                                                </Box>
                                                            )
                                                        }

                                                        if(selected) {
                                                            let departmentName = allDepartments.find(
                                                                o => o.id === selected.toString()
                                                            )?.name
                                                            departmentName === 'Others' ? setShowDepartmentOther(true) : setShowDepartmentOther(false)
                                                            return allDepartments.find(
                                                                o => o.id === selected.toString()
                                                            )?.name
                                                        }
                                                        return selected
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    IconComponent={_props => {
                                                        const rotate = _props.className
                                                            .toString()
                                                            .includes('iconOpen')
                                                        return (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    pointerEvents: 'none',
                                                                    right: 10,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    transform: rotate
                                                                        ? 'rotate(180deg)'
                                                                        : 'none'
                                                                }}
                                                            >
                                                                <Icons src={DownArrow} />
                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    <MenuItem disabled value=''>
                                                        <Box style={styles.placeholderStyle}>
                                                            Select department
                                                        </Box>
                                                    </MenuItem>
                                                    {allDepartments.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                {/* {errors &&
                                                errors.departmentId &&
                                                errors.departmentId.message ? (
                                                    <FormHelperText id='my-helper-text'>
                                                        {errors.departmentId.message}
                                                    </FormHelperText>
                                                ) : null} */}
                                            </FormControl>
                                        )}
                                        name='departmentId'
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyDepartment
                                            }
                                        }}
                                    />
                                </Box>
                                {(showDepartmentOther) && <Box>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={styles.formControl}>
                                                <TextInput
                                                    type='text'
                                                    name='otherDepartment'
                                                    placeholder={Placeholder.othersField}
                                                    onChange={onChange}
                                                    formFieldError={
                                                        errors && errors.otherDepartment && errors.otherDepartment.message
                                                    }
                                                    value={value}
                                                    sx={styles.textInput}
                                                />
                                            </FormControl>
                                        )}
                                        name='otherDepartment'
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyOthers
                                            }
                                        }}
                                    />
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <Box>
                                <CustomFormLabel
                                labelSx={{mt:2}}
                                    formLabel={FormLabels.industryId}
                                    formFieldError={errors && errors.industryId && errors.industryId.message}
                                />
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl
                                            sx={{
                                                width:'100%'
                                            }}
                                        >
                                            <Select
                                                {...register("industries")}
                                                displayEmpty
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    let industryName = allIndustries.find(
                                                        o => o.id === e.target.value.toString()
                                                    )?.name
                                                    if(industryName === 'Others') {
                                                        setShowIndustryOther(true)
                                                    } else {
                                                        setShowIndustryOther(false)
                                                        resetField('otherIndustry')
                                                    }
                                                }}
                                                input={<OutlinedInput sx={styles.selectTextInputStyle} />}
                                                renderValue={selected => {
                                                    if (selected.length === 0) {
                                                        return (
                                                            <Box style={styles.placeholderStyle}>
                                                                Select industry
                                                            </Box>
                                                        )
                                                    }

                                                    if(selected) {
                                                        let industryName = allIndustries.find(
                                                            o => o.id === selected.toString()
                                                        )?.name
                                                        industryName === 'Others' ? setShowIndustryOther(true) : setShowIndustryOther(false)
                                                        return allIndustries.find(
                                                            o => o.id === selected.toString()
                                                        )?.name
                                                    }
                                                    return selected

                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                IconComponent={_props => {
                                                    const rotate = _props.className
                                                        .toString()
                                                        .includes('iconOpen')
                                                    return (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                pointerEvents: 'none',
                                                                right: 10,
                                                                height: '15px',
                                                                width: '15px',
                                                                transform: rotate
                                                                    ? 'rotate(180deg)'
                                                                    : 'none'
                                                            }}
                                                        >
                                                            <Icons src={DownArrow} />
                                                        </div>
                                                    )
                                                }}
                                            >
                                                <MenuItem disabled value=''>
                                                    <Box style={styles.placeholderStyle}>
                                                        Select industry
                                                    </Box>
                                                </MenuItem>
                                                {allIndustries.map(item => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            {/* {errors && errors.industryId && errors.industryId.message ? (
                                                <FormHelperText id='my-helper-text'>
                                                    {errors.industryId.message}
                                                </FormHelperText>
                                            ) : null} */}
                                        </FormControl>
                                    )}
                                    name='industryId'
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ErrorMessage.emptyIndustry
                                        }
                                    }}
                                />
                            </Box>
                            {(showIndustryOther) && <Box>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={styles.formControl}>
                                                <TextInput
                                                    type='text'
                                                    name='otherIndustry'
                                                    placeholder={Placeholder.othersField}
                                                    onChange={onChange}
                                                    formFieldError={
                                                        errors && errors.otherIndustry && errors.otherIndustry.message
                                                    }
                                                    value={value}
                                                    sx={styles.textInput}
                                                />
                                            </FormControl>
                                        )}
                                        name='otherIndustry'
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyOthers
                                            }
                                        }}
                                    />
                                </Box>}
                        </Grid> 
                    </Grid>
                    
                    <Box
                        sx={[
                            styles.dateContainer,
                            {
                                mt: 3,
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'column',
                                    md: 'row',
                                    lg: 'row',
                                    xl: 'row'
                                }
                            }
                        ]}
                    >
                        <Box sx={{ flex: 1 }}>
                        <CustomFormLabel
                        labelSx={{mt:2}}
                                        formLabel={FormLabels.startDate}
                                        formFieldError={(errors && errors.startYear && errors.startYear.message) ||
                                            (errors && errors.startMonth && errors.startMonth.message)}
                                    />
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                    input={
                                                        <OutlinedInput
                                                            sx={styles.selectTextInputStyle}
                                                        />
                                                    }
                                                    renderValue={selected => {
                                                        if (selected.length === 0) {
                                                            return (
                                                                <Box
                                                                    style={styles.placeholderStyle}
                                                                >
                                                                    Month
                                                                </Box>
                                                            )
                                                        }

                                                        return selected
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    IconComponent={_props => {
                                                        const rotate = _props.className
                                                            .toString()
                                                            .includes('iconOpen')
                                                        return (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    pointerEvents: 'none',
                                                                    right: 10,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    transform: rotate
                                                                        ? 'rotate(180deg)'
                                                                        : 'none'
                                                                }}
                                                            >
                                                                <Icons src={DownArrow} />
                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    <MenuItem disabled value=''>
                                                        <Box style={styles.placeholderStyle}>
                                                            Month
                                                        </Box>
                                                    </MenuItem>
                                                    {monthList.map(item => (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        name='startMonth'
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyStartMonth
                                            },
                                            validate: value => {
                                                if (value && startYearWatch) {
                                                    return validateDate(value, startYearWatch)
                                                }
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        ml: '20px',
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <Select
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                    input={
                                                        <OutlinedInput
                                                            sx={styles.selectTextInputStyle}
                                                        />
                                                    }
                                                    renderValue={selected => {
                                                        if (selected.length === 0) {
                                                            return (
                                                                <Box
                                                                    style={styles.placeholderStyle}
                                                                >
                                                                    Year
                                                                </Box>
                                                            )
                                                        }

                                                        return selected
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    IconComponent={_props => {
                                                        const rotate = _props.className
                                                            .toString()
                                                            .includes('iconOpen')
                                                        return (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    pointerEvents: 'none',
                                                                    right: 10,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    transform: rotate
                                                                        ? 'rotate(180deg)'
                                                                        : 'none'
                                                                }}
                                                            >
                                                                <Icons src={DownArrow} />
                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    <MenuItem disabled value=''>
                                                        <Box style={styles.placeholderStyle}>
                                                            Year
                                                        </Box>
                                                    </MenuItem>
                                                    {yearList.map(item => (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        name='startYear'
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyStartYear
                                            },
                                            validate: value => {
                                                if (startMonthWatch && value) {
                                                    return validateDate(startMonthWatch, value)
                                                }
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* {(errors && errors.startYear && errors.startYear.message) ||
                            (errors && errors.startMonth && errors.startMonth.message) ? (
                                <FormHelperText id='my-helper-text'>
                                    {errors?.startYear?.message || errors?.startMonth?.message}
                                </FormHelperText>
                            ) : null} */}
                            {apiError && apiError?.message ? (
                                <FormHelperText 
                                    id='my-helper-text'
                                    error={true}
                                >
                                    <strong>{apiError?.message}</strong>
                                </FormHelperText>
                            ) : null}
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                ml: {
                                    xs: '0px',
                                    sm: '0px',
                                    md: '20px',
                                    lg: '20px',
                                    xl: '20px'
                                },
                                mt: {
                                    xs: '20px',
                                    sm: '20px',
                                    md: '0px',
                                    lg: '0px',
                                    xl: '0px'
                                }
                            }}
                        >
                            <CustomFormLabel
                            labelSx={{mt:2}}
                                        formLabel={FormLabels.endDate}
                                        formFieldError={(errors && errors.endYear && errors.endYear.message) ||
                                            (errors && errors.endMonth && errors.endMonth.message)}
                                    />
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    disabled={currentlyWorkingHere}
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                    input={
                                                        <OutlinedInput
                                                            sx={styles.selectTextInputStyle}
                                                        />
                                                    }
                                                    renderValue={selected => {
                                                        if (
                                                            !selected ||
                                                            selected == 'Invalid date' ||
                                                            selected.length === 0 ||
                                                            currentlyWorkingHere
                                                        ) {
                                                            return (
                                                                <Box
                                                                    style={styles.placeholderStyle}
                                                                >
                                                                    Month
                                                                </Box>
                                                            )
                                                        }
                                                        return selected
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    IconComponent={_props => {
                                                        const rotate = _props.className
                                                            .toString()
                                                            .includes('iconOpen')
                                                        return (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    pointerEvents: 'none',
                                                                    right: 10,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    transform: rotate
                                                                        ? 'rotate(180deg)'
                                                                        : 'none'
                                                                }}
                                                            >
                                                                <Icons src={DownArrow} />
                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    <MenuItem disabled value=''>
                                                        <Box style={styles.placeholderStyle}>
                                                            Month
                                                        </Box>
                                                    </MenuItem>
                                                    {monthList.map(item => (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        name='endMonth'
                                        rules={{
                                            required: {
                                                value: !currentlyWorkingHere,
                                                message: ErrorMessage.emptyEndMonth
                                            },
                                            validate: value => {
                                                if (
                                                    startMonthWatch &&
                                                    startYearWatch &&
                                                    value &&
                                                    endYearWatch
                                                ) {
                                                    return validateDate(
                                                        startMonthWatch,
                                                        startYearWatch,
                                                        value,
                                                        endYearWatch
                                                    )
                                                }
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        ml: '20px',
                                        // backgroundColor: 'pink',
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    disabled={currentlyWorkingHere}
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                    input={
                                                        <OutlinedInput
                                                            sx={styles.selectTextInputStyle}
                                                        />
                                                    }
                                                    renderValue={selected => {
                                                        if (
                                                            !selected ||
                                                            selected == 'Invalid date' ||
                                                            selected.length === 0 ||
                                                            currentlyWorkingHere
                                                        ) {
                                                            return (
                                                                <Box
                                                                    style={styles.placeholderStyle}
                                                                >
                                                                    Year
                                                                </Box>
                                                            )
                                                        }
                                                        return selected == 'Invalid date'
                                                            ? null
                                                            : selected
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    IconComponent={_props => {
                                                        const rotate = _props.className
                                                            .toString()
                                                            .includes('iconOpen')
                                                        return (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    pointerEvents: 'none',
                                                                    right: 10,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    transform: rotate
                                                                        ? 'rotate(180deg)'
                                                                        : 'none'
                                                                }}
                                                            >
                                                                <Icons src={DownArrow} />
                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    <MenuItem disabled value=''>
                                                        <Box style={styles.placeholderStyle}>
                                                            Year
                                                        </Box>
                                                    </MenuItem>
                                                    {yearList.map(item => (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        name='endYear'
                                        rules={{
                                            required: {
                                                value: !currentlyWorkingHere,
                                                message: ErrorMessage.emptyEndYear
                                            },
                                            validate: value => {
                                                if (
                                                    startYearWatch &&
                                                    startMonthWatch &&
                                                    endMonthWatch &&
                                                    value
                                                ) {
                                                    return validateDate(
                                                        startMonthWatch,
                                                        startYearWatch,
                                                        endMonthWatch,
                                                        value
                                                    )
                                                }
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* {(errors && errors.endYear && errors.endYear.message) ||
                            (errors && errors.endMonth && errors.endMonth.message) ? (
                                <FormHelperText id='my-helper-text'>
                                    {errors?.endYear?.message || errors?.endMonth?.message}
                                </FormHelperText>
                            ) : null} */}
                        </Box>
                    </Box>
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Fragment>
                                <FormGroup sx={{ alignSelf: 'flex-end' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                onClick={() => {
                                                    handleCurrentlyWorking(value)
                                                }}
                                                // onClick={() => {setCurrentlyWorkingHere(!value)}}
                                                icon={
                                                    <Icons
                                                        src={CheckboxIcon}
                                                        style={{
                                                            marginRight: '0px'
                                                        }}
                                                    />
                                                }
                                                checkedIcon={<RadioButtonCheckedIcon />}
                                            />
                                        }
                                        label={TextStrings.currentlyWorking}
                                    />
                                </FormGroup>
                            </Fragment>
                        )}
                        name='isCurrentJob'
                    />

                    <Box sx={{textAlign:'center'}}>
                        <Button
                            label={editableForm ? TextStrings.saveChanges : TextStrings.save}
                            color='primary'
                            variant='contained'
                            size='large'
                            // disabled={!isDirty || !isValid}
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                            sx={styles.button}
                        />
                    </Box>
                </Box>
                {loading && <Loader />}
            </Container>
        </Layout>
    )
}

export default ExperienceDetail
