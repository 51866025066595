import moment from 'moment'

const containerName = `devuploads`
const sasToken = process.env.REACT_APP_STORAGESASTOKEN
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME

export const DATE_FORMATS = {
    YEAR_MONTH_FORMAT: 'YYYY-MMMM',
    YEAR_FORMAT: 'YYYY-MMMM',
    YEAR_MONTH_NUMBER_FORMAT: 'YYYY-MM'
}

export const getMonthList = () => {
    return moment.months()
}

export const getYearList = (max, min) => {
    let arr = []
    if (min <= max) {
        for (let i = max; i >= min; i--) arr.push(i)
    }
    return arr
}

export const getYearListArray = (max, min) => {
    let arr = []
    if (min <= max) {
        for (let i = max; i >= min; i--) arr.push({ id: i, name: i })
    }
    return arr
}

export const getMonthNumberFromMonthName = monthName => {
    return moment().month(monthName).format('MM')
}

export const convertDateFromUTC = (date, toFormat) => {
    return moment.utc(date).format(toFormat)
}

export const getImageURL = imageName => {
    return imageName
        ? `https://${storageAccountName}.blob.core.windows.net/${containerName}/${imageName}`
        : ''
}

export const getAvatrURL = imageName => {
    return imageName
        ? `https://${storageAccountName}.blob.core.windows.net/${containerName}/${imageName}.png`
        : ''
}
