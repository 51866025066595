import Layout from '../../../template/Layout'
import { Box, Container, Grid} from '@mui/material'
import {useNavigate} from 'react-router-dom' 

import {
    TableHeading,
    SubHeading,
    Subtitle1Typography,
    Subtitle2Typography
} from '../../../base/commonStyles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import PageMetaTags from '../../../base/PageMetaTags'
import Icons from '../../../components/Icons'
import BACK from '../../../assets/icons/BACK.svg'
const PrivacyPolicy = () => {
  const navigate = useNavigate()

    return (
        <Layout>
            <PageMetaTags title='Privacy Policy | Bunton' />
            <Container
                disableGutters={true}
                sx={{
                    padding: {
                        xs: '64px 20px',
                        sm: '64px 40px',
                        md: '64px 80px',
                        lg: '64px 80px',
                        xl: '64px 80px'
                    },
                    paddingBottom: '80px',
                    height: '100%'
                }}
            >
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box sx={{ display: 'flex', marginBottom: '28px' }}>
                            <TableHeading sx={{}}>Privacy Policy</TableHeading>
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    xs: 'auto',
                                    sm: 'auto',
                                    md: 'auto',
                                    lg: 'auto'
                                }
                            }}
                        >
                            <Box>
                                <SubHeading>
                                    <h2>1.Privacy</h2>
                                </SubHeading>
                                <Subtitle1Typography>General Information</Subtitle1Typography>
                                <Subtitle2Typography>
                                    The following notes provide a simple overview of what happens to
                                    your personal data when you visit this website. Personal data is
                                    all data with which you can be personally identified. Detailed
                                    information on the subject of data protection can be found in
                                    our data protection declaration listed under this text.
                                </Subtitle2Typography>
                                <Subtitle1Typography
                                    sx={{
                                        margin: '-3px 0px 32px 0px'
                                    }}
                                >
                                    Data collection on this website
                                    <h4 class='h4'>
                                        Who is responsible for data collection on this website?
                                    </h4>
                                    <p class='p_tag'>
                                        {' '}
                                        The data processing on this website is carried out by the
                                        website operator. You can find their contact details in the
                                        corporate information of this website.
                                    </p>
                                    <h4 class='h4'>How do we collect your data?</h4>
                                    <p class='p_tag'>
                                        On the one hand, your data is collected when you communicate
                                        it to us. This can be data that you enter in a contact form.
                                    </p>
                                    <p class='p_tag'>
                                        Other data is automatically recorded by our IT systems when
                                        you visit the website. This is primarily technical data
                                        (e.g. internet browser, operating system or time of the page
                                        view). This data is collected automatically as soon as you
                                        enter this website.
                                    </p>
                                    <h4 class='h4'>What do we use your data for?</h4>
                                    <p class='p_tag'>
                                        Part of the data is collected to ensure that the website is
                                        provided without errors. Other data can be used to analyze
                                        your user behavior.
                                    </p>
                                    <h4 class='h4'>What rights do you have regarding your data?</h4>
                                    <p class='p_tag'>
                                        You have the right to receive information about the origin,
                                        recipient and purpose of your stored personal data free of
                                        charge at any time. You also have the right to request the
                                        correction or deletion of this data.
                                    </p>
                                    <p class='p_tag'>
                                        You can contact us at any time at the address given in the
                                        corporate information if you have any further questions on
                                        the subject of data protection. You also have the right to
                                        lodge a complaint with the competent supervisory authority.
                                    </p>
                                    <p class='p_tag'>
                                        You also have the right, under certain circumstances, to
                                        request that the processing of your personal data be
                                        restricted. Details can be found in the data protection
                                        declaration under “Right to restriction of processing”.
                                    </p>
                                    Analysis tools and third-party tools
                                    <p class='p_tag'>
                                        When you visit this website, your surfing behavior can be
                                        statistically evaluated. This is mainly done with cookies
                                        and so-called analysis programs. The analysis of your
                                        surfing behavior is usually anonymous; surfing behavior
                                        cannot be traced back to you.
                                    </p>
                                    <p class='p_tag'>
                                        You can object to this analysis or prevent it by not using
                                        certain tools. Detailed information on these tools and your
                                        options to object can be found in the following data
                                        protection declaration.
                                    </p>
                                </Subtitle1Typography>
                            </Box>

                            <Box>
                                <SubHeading>
                                    <h2>2. Hosting</h2>
                                </SubHeading>
                                <Subtitle1Typography>External hosting</Subtitle1Typography>
                                <Subtitle1Typography>
                                    <p class='p_tag'>
                                        This website is hosted by an external service provider
                                        (hoster). Personal data collected on this website is stored
                                        on the host’s servers. This can be for example IP addresses,
                                        contact requests, meta and communication data, contract
                                        data, contact details, names, website access and other data
                                        generated via a website.
                                    </p>
                                    <p class='p_tag'>
                                        The use of the information by the hoster is limited to the
                                        purpose of fulfilling the contract with our potential and
                                        existing customers (Art. 6 Para. 1 lit. b DSGVO) and in the
                                        interest of a secure, fast and efficient provision of our
                                        online offer by the professional provider (Art. 6 Para 1
                                        lit. f GDPR).Our hoster will only process your data to the
                                        extent necessary to fulfill its performance obligations and
                                        will follow our instructions in relation to this data.
                                    </p>
                                    Signing of a data processing agreement
                                    <p class='p_tag'>
                                        In order to ensure data protection-compliant processing, we
                                        have signed a data processing agrrement with our hoster.
                                    </p>
                                </Subtitle1Typography>
                            </Box>

                            <Box>
                                <SubHeading>
                                    <h2>3. General information and mandatory information</h2>
                                </SubHeading>
                                <Subtitle1Typography>Privacy</Subtitle1Typography>
                                <Subtitle1Typography>
                                    <p class='p_tag'>
                                        The operators of these pages take the protection of your
                                        personal data very seriously. We treat your personal data
                                        confidentially and in accordance with the statutory data
                                        protection regulations and this data protection declaration.
                                        If you use this website, various personal data will be
                                        collected. Personal data is data with which you can be
                                        personally identified. This data protection declaration
                                        explains what data we collect and what we use it for. It
                                        also explains how and for what purpose this happens. We
                                        would like to point out that data transmission on the
                                        Internet (e.g. when communicating by e-mail) can have
                                        security gaps. A complete protection of the data against
                                        access by third parties is not possible.
                                    </p>
                                    Note on the responsible body
                                    <p class='p_tag'>
                                        The responsible body for data processing on this website is:
                                    </p>
                                    <p class='p_tag'>bunton GmbH</p>
                                    <p class='p_tag'>Deutschherrnufer 47</p>
                                    <p class='p_tag'>D-60549 Frankfurt am Main</p>
                                    <p class='p_tag'>Telefon: +49 176 728 53 156</p>
                                    <p class='p_tag'>E-Mail: welcome@bunton.de</p>
                                    <p class='p_tag'>
                                        The responsible body is a natural or legal person who, alone
                                        or together with others, decides on the purposes and means
                                        of processing personal data (e.g. names, e-mail addresses,
                                        etc.).
                                    </p>
                                    Revocation of your consent to data processing
                                    <p class='p_tag'>
                                        Many data processing operations are only possible with your
                                        express consent. You can revoke consent that you have
                                        already given at any time. An informal message by e-mail to
                                        us is sufficient. The legality of the data processing that
                                        took place up until the revocation remains unaffected by the
                                        revocation.
                                    </p>
                                    Right to object to data collection in special cases and to
                                    direct advertising
                                    <p class='p_tag'>
                                        (Art. 21 GDPR) IF THE DATA PROCESSING IS BASED ON ART. 6
                                        PARA. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO OBJECT TO
                                        THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS
                                        ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO
                                        PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL
                                        BASIS ON WHICH A PROCESSING IS BASED CAN BE FOUND IN THIS
                                        DATA PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER
                                        PROCESS YOUR CONCERNED PERSONAL DATA UNLESS WE CAN PROVE
                                        COMPREHENSIVE GROUNDS FOR THE PROCESSING THAT OVERRIDE YOUR
                                        INTERESTS, RIGHTS AND FREEDOM OBJECTION ACCORDING TO ARTICLE
                                        21 (1) GDPR). IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT
                                        ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE
                                        PROCESSING OF YOUR PERSONAL DATA FOR SUCH ADVERTISING
                                        PURPOSES; THIS ALSO APPLIES TO PROFILING TO THE EXTENT
                                        RELATED TO SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR
                                        PERSONAL DATA WILL NO LONGER BE USED FOR DIRECT ADVERTISING
                                        PURPOSES (OBJECTION ACCORDING TO ART. 21 (2) GDPR).
                                    </p>
                                    Right of appeal to the competent supervisory authority
                                    <p class='p_tag'>
                                        In the event of violations of the GDPR, those affected have
                                        the right to lodge a complaint with a supervisory authority,
                                        in particular in the EU Member State of their habitual
                                        residence, their place of work or the place of the alleged
                                        violation. The right to lodge a complaint is without
                                        prejudice to any other administrative or judicial remedy.
                                    </p>
                                    Right to data portability
                                    <p class='p_tag'>
                                        You have the right to receive the data that we process
                                        automatically on the basis of your consent or in fulfillment
                                        of a contract handed over to you or to a third party in a
                                        commonly used, machine-readable format. If you request the
                                        direct transfer of the data to another person responsible,
                                        this will only be done to the extent that it is technically
                                        feasible.
                                    </p>
                                    SSL or TLS encryption
                                    <p class='p_tag'>
                                        For security reasons and to protect the transmission of
                                        confidential content, such as orders or inquiries that you
                                        send us as the site operator, this site uses SSL or TLS
                                        encryption. You can recognize an encrypted connection by the
                                        fact that the address line of the browser changes from
                                        “http://” to “https://” and by the lock symbol in your
                                        browser line. If SSL or TLS encryption is activated, the
                                        data that you transmit to us cannot be read by third
                                        parties.
                                    </p>
                                    Information, deletion and correction
                                    <p class='p_tag'>
                                        Within the framework of the applicable legal provisions, you
                                        have the right to free information about your stored
                                        personal data, its origin and recipient and the purpose of
                                        the data processing and, if necessary, a right to correction
                                        or deletion of this data at any time. You can contact us at
                                        any time at the address given in the corporate information
                                        if you have any further questions on the subject of personal
                                        data.
                                    </p>
                                    Right to restriction of processing
                                    <p class='p_tag'>
                                        You have the right to request the restriction of the
                                        processing of your personal data. You can contact us at any
                                        time at the address given in the corporate information. The
                                        right to restriction of processing exists in the following
                                        cases:
                                    </p>
                                    <p class='p_tag'>
                                        <List sx={{ listStyle: 'disc', marginLeft: '38px' }}>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                If you dispute the accuracy of your personal data
                                                stored by us, we usually need time to check this.
                                                For the duration of the examination, you have the
                                                right to request that the processing of your
                                                personal data be restricted.
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                If the processing of your personal data happened/is
                                                happening unlawfully, you can request the
                                                restriction of data processing instead of deletion.
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                If we no longer need your personal data, but you
                                                need it to exercise, defend or assert legal claims,
                                                you have the right to demand that the processing of
                                                your personal data be restricted instead of being
                                                deleted.
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                If you have lodged an objection in accordance with
                                                Art. 21 Para. 1 GDPR, your interests and ours must
                                                be weighed up. As long as it has not yet been
                                                determined whose interests prevail, you have the
                                                right to demand that the processing of your personal
                                                data be restricted. If you have restricted the
                                                processing of your personal data, this data – apart
                                                from its storage – may only be processed and used
                                                with your consent or to assert, exercise or defend
                                                legal claims or to protect the rights of another
                                                natural or legal person or for reasons of important
                                                public interest of the European Union or a Member
                                                State.
                                            </ListItem>
                                        </List>
                                    </p>
                                </Subtitle1Typography>
                            </Box>
                            <Box>
                                <SubHeading variant='body2'>
                                    <h2>4. Data collection on this website</h2>
                                </SubHeading>
                                <Subtitle1Typography>Cookies</Subtitle1Typography>
                                <Subtitle1Typography>
                                    <p class='p_tag'>
                                        Some of the websites use so-called cookies. Cookies do not
                                        damage your computer and do not contain viruses. Cookies
                                        serve to make our offer more user-friendly, effective and
                                        secure. Cookies are small text files that are stored on your
                                        computer and saved by your browser.
                                    </p>
                                    <p class='p_tag'>
                                        Most of the cookies we use are so-called “session cookies”.
                                        They are automatically deleted after your visit. Other
                                        cookies remain stored on your end device until you delete
                                        them. These cookies enable us to recognize your browser on
                                        your next visit.
                                    </p>
                                    <p class='p_tag'>
                                        You can set your browser so that you are informed about the
                                        setting of cookies and only allow cookies in individual
                                        cases, exclude the acceptance of cookies for certain cases
                                        or in general and activate the automatic deletion of cookies
                                        when the browser is closed. If cookies are deactivated, the
                                        functionality of this website may be restricted.
                                    </p>
                                    <p class='p_tag'>
                                        Cookies that are required to carry out the electronic
                                        communication process or to provide certain functions you
                                        want (e.g. shopping cart function) are stored on the basis
                                        of Article 6 Paragraph 1 Letter f GDPR. The website operator
                                        has a legitimate interest in the storage of cookies for the
                                        technically error-free and optimized provision of its
                                        services. If a corresponding consent was requested (e.g.
                                        consent to the storage of cookies), the processing takes
                                        place exclusively on the basis of Article 6 Paragraph 1 lit.
                                        a GDRP; the consent can be revoked at any time. Insofar as
                                        other cookies (e.g. cookies for analyzing your surfing
                                        behavior) are stored, these are treated separately in this
                                        data protection declaration.
                                    </p>
                                    Server log files
                                    <p class='p_tag'>
                                        The provider of the pages automatically collects and stores
                                        information in so-called server log files, which your
                                        browser automatically transmits to us. These are:
                                    </p>
                                    <p class='p_tag'>
                                        <List sx={{ listStyle: 'disc', marginLeft: '38px' }}>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Browser type and browser version
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                operating system used
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Referrer URL
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Host name of the accessing computer
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Time of the server request
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                IP address
                                            </ListItem>
                                        </List>
                                    </p>
                                    <p class='p_tag'>
                                        This data is not merged with other data sources.
                                    </p>
                                    <p class='p_tag'>
                                        This data is collected on the basis of Article 6 (1) (f)
                                        GDPR. The website operator has a legitimate interest in the
                                        technically error-free presentation and optimization of his
                                        website – the server log files must be recorded for this
                                        purpose.
                                    </p>
                                    Contact form
                                    <p class='p_tag'>
                                        If you send us inquiries via the contact form, your details
                                        from the inquiry form, including the contact details you
                                        provided there, will be stored by us for the purpose of
                                        processing the inquiry and in the event of follow-up
                                        questions. We do not pass on this data without your consent.
                                    </p>
                                    <p class='p_tag'>
                                        This data is processed on the basis of Article 6 (1) (b)
                                        GDPR, if your request is related to the fulfillment of a
                                        contract or is necessary to carry out pre-contractual
                                        measures. In all other cases, the processing is based on our
                                        legitimate interest in the effective processing of inquiries
                                        addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your
                                        consent (Art. 6 Para. 1 lit. a GDPR) it was requested.
                                    </p>
                                    <p class='p_tag'>
                                        The data you enter in the contact form will remain with us
                                        until you ask us to delete it, revoke your consent to
                                        storage or the purpose for data storage no longer applies
                                        (e.g. after your request has been processed). Mandatory
                                        legal provisions – in particular retention periods – remain
                                        unaffected.
                                    </p>
                                    Inquiry by e-mail, telephone or fax
                                    <p class='p_tag'>
                                        If you contact us by e-mail, telephone or fax, your inquiry
                                        including all resulting personal data (name, enquiry) will
                                        be stored and processed by us for the purpose of processing
                                        your request. We do not pass on this data without your
                                        consent.
                                    </p>
                                    <p class='p_tag'>
                                        This data is processed on the basis of Article 6 (1) (b)
                                        GDPR, if your request is related to the fulfillment of a
                                        contract or is necessary to carry out pre-contractual
                                        measures. In all other cases, the processing is based on
                                        your consent (Art. 6 Para. 1 lit. a GDPR) and/or on our
                                        legitimate interests (Art. 6 Para. 1 lit. f GDPR), since we
                                        have a legitimate interest in the effective processing of
                                        inquiries addressed to us.
                                    </p>
                                    <p class='p_tag'>
                                        The data you sent to us via contact requests will remain
                                        with us until you request deletion, revoke your consent to
                                        storage or the purpose for data storage no longer applies
                                        (e.g. after your request has been processed). Mandatory
                                        legal provisions – in particular statutory retention periods
                                        – remain unaffected.
                                    </p>
                                </Subtitle1Typography>
                            </Box>
                            <Box>
                                <SubHeading variant='body2'>
                                    <h2>5. Analysis Tools and Advertising</h2>
                                </SubHeading>
                                <Subtitle1Typography>Google Analytics</Subtitle1Typography>
                                <Subtitle1Typography>
                                    <p class='p_tag'>
                                        This website uses functions of the web analysis service
                                        Google Analytics. The provider is Google Ireland Limited
                                        (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
                                    </p>
                                    <p class='p_tag'>
                                        Google Analytics uses so-called “cookies”. These are text
                                        files that are stored on your computer and that enable an
                                        analysis of your use of the website. The information
                                        generated by the cookie about your use of this website is
                                        usually transmitted to a Google server in the USA and stored
                                        there.
                                    </p>
                                    <p class='p_tag'>
                                        The storage of Google Analytics cookies and the use of this
                                        analysis tool are based on Article 6 Paragraph 1 lit. f
                                        GDPR. The website operator has a legitimate interest in
                                        analyzing user behavior in order to optimize both its
                                        website and its advertising. If a corresponding consent was
                                        requested (e.g. consent to the storage of cookies), the
                                        processing takes place exclusively on the basis of Article 6
                                        Paragraph 1 lit. the consent can be revoked at any time.
                                    </p>
                                    IP anonymization
                                    <p class='p_tag'>
                                        We have activated the IP anonymization function on this
                                        website. As a result, your IP address will be shortened by
                                        Google within member states of the European Union or in
                                        other contracting states of the Agreement on the European
                                        Economic Area before it is transmitted to the USA. Only in
                                        exceptional cases will the full IP address be sent to a
                                        Google server in the USA and shortened there. On behalf of
                                        the operator of this website, Google will use this
                                        information to evaluate your use of the website, to compile
                                        reports on website activity and to provide other services
                                        related to website activity and internet usage to the
                                        website operator. The IP address transmitted by your browser
                                        as part of Google Analytics will not be merged with other
                                        Google data.
                                    </p>
                                    Browser plug-in
                                    <p class='p_tag'>
                                        You can prevent the storage of cookies by setting your
                                        browser software accordingly; we would like to point out to
                                        you however that in this case you will may not be able to
                                        use all functions of this website in full. You can also
                                        prevent Google from collecting the data generated by the
                                        cookie and related to your use of the website (including
                                        your IP address) and from processing this data by Google by
                                        downloading the browser plug-in available under the
                                        following link and install it:
                                        https://tools.google.com/dlpage/gaoptout?hl=de.
                                    </p>
                                    Objection against data collection
                                    <p class='p_tag'>
                                        You can prevent Google Analytics from collecting your data
                                        by clicking on the following link. An opt-out cookie will be
                                        set to prevent your data from being collected on future
                                        visits to this website: Disable Google Analytics. You can
                                        find more information on how Google Analytics handles user
                                        data in the Google data protection declaration:
                                        https://support.google.com/analytics/answer/6004245?hl=de.
                                    </p>
                                    Data processing
                                    <p class='p_tag'>
                                        We have signed a data processing agreement with Google and
                                        fully implement the strict requirements of the German data
                                        protection authorities when using Google Analytics.
                                    </p>
                                    Demographic characteristics in Google Analytics
                                    <p class='p_tag'>
                                        This website uses the “demographic characteristics” function
                                        of Google Analytics. This allows reports to be created that
                                        contain statements about the age, gender and interests of
                                        the site visitors. This data comes from interest-based
                                        advertising from Google and visitor data from third-party
                                        providers. This data cannot be assigned to a specific
                                        person. You can deactivate this function at any time via the
                                        ad settings in your Google account or generally prohibit the
                                        collection of your data by Google Analytics as described in
                                        the point “Objection to data collection”.
                                    </p>
                                    Storage duration
                                    <p class='p_tag'>
                                        Data stored by Google at the user and event level that is
                                        linked to cookies, user IDs (e.g. User ID) or advertising
                                        IDs (e.g. DoubleClick cookies, Android advertising ID) are
                                        anonymized after 14 months or deleted. You can find details
                                        on this under the following link:{' '}
                                        <a href='https://support.google.com/analytics/answer/7667196?hl=de'>
                                            https://support.google.com/analytics/answer/7667196?hl=de
                                        </a>
                                    </p>
                                </Subtitle1Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default PrivacyPolicy
