import {
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    REGISTRATION_LOADER
} from '../actions/registration/actionTypes'

const initialState = {
    registrationInProgress: false,
    registrationDetails: null,
    registrationError: null
}

const setRegistrationLoader = (state, action) => {
    return {
        ...state,
        registrationInProgress: true
    }
}

const registrationSuccess = (state, action) => {
    return {
        ...state,
        registrationInProgress: false,
        registrationError: null,
        registrationDetails: action.payload.createUser
    }
}

const registrationFailure = (state, action) => {
    return {
        ...state,
        registrationInProgress: false,
        registrationDetails: null,
        registrationError: action.payload
    }
}

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTRATION_LOADER:
            return setRegistrationLoader(state, action)
        case REGISTRATION_SUCCESS:
            return registrationSuccess(state, action)
        case REGISTRATION_FAILURE:
            return registrationFailure(state, action)
        default:
            return state
    }
}

export default registrationReducer
