import { Avatar, Box, Container, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import AvatarDefault from '../../assets/images/avatar.png'
import { DateDefaultFromat } from '../../constants/constants'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import { getAvatrURL } from '../../utils/utilities'
import CandidateResponseModal from '../organisams/CandidateResponseModal'

const CandidateResponseListRow = props => {
    const { data } = props
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    return (
        <Container variant='table-rows'>
            <Grid container spacing={1} alignItems='center'>
                <Grid item lg={2} md={2} sm={3} xs={12} >
                    <Avatar
                        variant='user-images'
                        src={
                            data.candidateAvatar && data.candidateAvatar
                                ? getAvatrURL(data.candidateAvatar)
                                : AvatarDefault
                        }
                        sx={{
                            height: {
                                xs: '70px',
                                md: '110px'
                            },
                            width: {
                                xs: '70px',
                                md: '110px'
                            },
                            m: 'auto'
                        }}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} sx={{textAlign:{
                    xs:'center',sm: 'left'
                }}}>
                    <Box sx={{ mb: 1.5 }}>
                        <Typography
                            variant='h4'
                            className='table-row-heading'
                            sx={{ mb: 1 }}
                        >
                            {data.name}
                        </Typography>
                        <Typography variant='h5' className='small-size'>
                            {data.email}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={3} xs={12} sx={{textAlign:{
                    xs:'center',md: 'right'
                }}}>
                    <Button
                        label={
                            <Typography
                                variant='h5'
                                className='small-size-14px extrabold'
                                sx={{
                                    color: `${variables.WHITE1} !important`,
                                    fontWeight: 'bold'
                                }}
                            >
                                {`View ${data.candidateResponses.length > 1 ? 'Responses': 'Response'}`}
                            </Typography>
                        }
                        onClick={() => setShowDetailsModal(true)}
                        color='primary'
                        variant='contained'
                        size='large'
                        sx={{
                            padding: '13px 23px',
                            fontSize: '14px',
                            fontWeight: '600'
                        }}
                    />
                </Grid>
            </Grid>
            <CandidateResponseModal
                open={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                data={data}
            />

        </Container>
    )
}

export default CandidateResponseListRow
