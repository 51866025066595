import variables from '../../../settings/_variables.scss'
export const styles = {
    container: {
        backgroundColor: variables.WHITE1,
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    header: {
        mb: '36px',
        mt: '40px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        flex: 1,
        fontSize: '20px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px',
        justifyContent: 'center'
    },
    backIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        ml: {
            xs: '16px',
            sm: '30px',
            md: '80px',
            lg: '80px',
            xl: '80px'
        }
    },
    formContainer: {
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column',
        width: {
            xs: '100vw',
            sm: '100vw',
            md: '70vw',
            lg: '70vw',
            xl: '70vw'
        },
        alignItems: 'center',
        paddingBottom: '20px'
    },
    button: {
        width: {
            xs: '50vw',
            sm: '50vw',
            md: '28vw',
            lg: '28vw',
            xl: '28vw'
        },
        paddingLeft: '30px',
        boxShadow: 'none',
        color: variables.BLUE,
        mt: '15px',

        justifyContent: 'flex-start'
    },
    listWrapper: {
        maxHeight: '60vh',
        overflow: 'auto',
        width: {
            xs: '78%',
            sm: '80%',
            md: '100%',
            lg: '100%',
            xl: '100%'
        },
        mt: '32px',
        backgroundColor: variables.CYAN2,
        borderRadius: '60px'
    },
    listContainer: {
        display: 'flex',
        mx: '15px',
        my: '15px',
        alignItems: 'center'
    },
    titleContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        pl: '25px'
    },
    titleContainerExperience: {
        flex: 1,
        display: 'flex',
        // flexDirection: 'row',
        flexDirection: {
            xs: 'column',
            sm: 'row',
            md: 'row',
            lg: 'row',
            xl: 'row'
        },
        pl: '25px'
    },
    detailsTitle: {
        fontSize: '16px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px'
    },
    detailsSubTitle: {
        // fontSize: '16px',
        fontSize: '20px',
        fontWeight: '600',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px'
    },
    date: {
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px'
    },
    industryTitle: {
        fontSize: '16px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px',
        ml: '15px'
    },
    iconContainer: { mx: '20px', display: 'flex', flexDirection: 'row' },
    deleteIcon: { pl: '10px' },
    experienceDescContainer: { flex: 1.8 },
    industryContainer: {
        flex: 1.2,
        display: 'flex',
        alignItems: 'center'
    }
}
