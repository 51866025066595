import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import HiringImage from '../../assets/images/are-you-hiring.webp'
import Button from '../Button'
import { Link } from 'react-router-dom'


const AreyouhiringSection = (props) => {
    return (<Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    position: 'relative',
                    marginTop: '90px',
                    mb: {
                        xs: '70px',
                        sm: '40px'
                    },
                    maxHeight: '345px',
                    height: {
                        xs: '176px',
                        sm: '200px',
                        md: '250px',
                        lg: '345px'
                    },
                }}
            >
                <Box sx={{position: 'absolute', width: '100%', zIndex: 1}}>
                    <Box
                        component='img'
                        src={HiringImage}
                        sx={{
                            width: '100%',
                            maxHeight: '345px',
                            height: {
                                xs: '176px',
                                sm: '200px',
                                md: '250px',
                                lg: '345px'
                            },
                            opacity: '0.3',
                            objectFit: 'cover'
                        }}
                    />
                </Box>
                <Box sx={{height: '100%', width: 'fit-content', zIndex: 2, position: 'relative', alignItems: 'center', margin: 'auto', display: 'flex', textAlign: 'center'}}>
                    <Typography
                        variant='h1'
                        className='section-heading'
                    >
                        {props.text ? props.text : "Want to Empower Women in Leadership?"}
                    </Typography>
                    
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'fit-content',
                        textAlign: 'center'
                    }}
                >
                    <Link to={'/contact-us'}>
                        <Button
                            label='Contact us'
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{
                                paddingLeft: '50px',
                                paddingRight: '50px',
                                border: '5px solid #FFFAF4',
                                boxShadow: 'none',
                                    position: 'relative',
                                    top: '-31px',
                                    zIndex: 2,
                            }}
                        />
                    </Link>
                </Box>
            </Container>
    )
}

export default AreyouhiringSection
