import React, { useEffect, useState } from 'react'
import Layout from '../template/Layout'
import { Box, Container, Tab, Tabs } from '@mui/material'
import PageMetaTags from '../base/PageMetaTags'
import variables from '../settings/_variables.scss'
import { a11yProps, TabPanel } from '../utils/commonFunctions'
import ChangePassword from './ChangePassword'
import NotificationSettings from '../components/organisams/NotificationSettings'
import { useSelector } from 'react-redux'

const UserSettings = props => {
    const [index, setIndex] = useState(0)
    const { loginCheckAuthDetails, loginDetails } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const handleIndexChange = (event, index) => {
        setIndex(index)
    }

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null
    useEffect(() => {
        localStorage.setItem('activeTab', index)
    }, [index])
    return (
        <Layout>
            <PageMetaTags title='User Settings | Bunton' />
            <Container maxWidth='xl' sx={{my: '40px'}}>
                {/* <Box sx={{ mt: { xs: 0, sm: 5 }, mb: { xs: 3, sm: 5 } }}>
                    <Typography variant='h1' className='pageHeading'>User Settings</Typography>
                </Box> */}
                <Box sx={{ flexGrow: 1, display: {
                    xs:'block', md: 'flex'
                }, maxWidth:'1200px', m:'auto' }}>
                    <Tabs
                        orientation={['tab'].includes(responsiveBreakpoint) ? 'horizontal' : 'vertical'}
                        value={index}
                        onChange={handleIndexChange}
                        aria-label='User Settings'
                        scrollButtons={true}
                        sx={{
                            alignItems:'center',
                            width: {
                                xs: '100%', md: '20%', lg: '25%'
                            },
                            p:2,
                            px:{
                                xs: 0,
                                md: 2
                            }
                        }}
                    >
                        {/* <Tab
                            label='Account Settings'
                            sx={{
                                cursor: 'pointer',
                                fontFamily:
                                    index === 0
                                        ? variables.poppinsExtraBold
                                        : variables.poppinsRegular,
                                fontWeight: index === 0 ? '600' : '',
                                // backgroundColor: variables.CYAN1,
                                color: variables.BLUE,
                                '&.MuiTab-root.Mui-selected' :{
                                    color: variables.BLUE,
                                    border: `1px solid ${variables.BLUE}`
                                }
                            }}
                            {...a11yProps(0)}
                            disableFocusRipple={true}
                            disableRipple={true}
                        /> */}
                        <Tab
                            label='Password Settings'
                            sx={{
                                m: 'auto',
                                width:{
                                    xs:'100%',
                                    sm: 'auto'
                                },
                                cursor: 'pointer',
                                fontFamily:
                                    index === 0
                                        ? variables.poppinsExtraBold
                                        : variables.poppinsRegular,
                                fontWeight: index === 0 ? '600' : '',
                                // backgroundColor: variables.CYAN1,,
                                textTransform:'none',
                                borderRadius: '30px',
                                color: variables.BLUE,
                                '&.MuiTab-root.Mui-selected' :{
                                    color: variables.BLUE,
                                    border: `1px solid ${variables.BLUE}`,
                                    background: variables.CYAN1
                                }
                            }}
                            {...a11yProps(0)}
                            disableFocusRipple={true}
                            disableRipple={true}
                        />
                        {['user'].includes(userRole) && <Tab
                            label='Notification Settings'
                            sx={{
                                m: 'auto',
                                width:{
                                    xs:'100%',
                                    sm: 'auto'
                                },
                                cursor: 'pointer',
                                fontFamily:
                                    index === 1
                                        ? variables.poppinsExtraBold
                                        : variables.poppinsRegular,
                                fontWeight: index === 1 ? '600' : '',
                                // backgroundColor: variables.CYAN1,
                                textTransform:'none',
                                borderRadius: '30px',
                                color: variables.BLUE,
                                '&.MuiTab-root.Mui-selected' :{
                                    color: variables.BLUE,
                                    border: `1px solid ${variables.BLUE}`,
                                    background: variables.CYAN1
                                }
                            }}
                            {...a11yProps(1)}
                            disableFocusRipple={true}
                            disableRipple={true}
                        />}
                    </Tabs>
                    <Box sx={{width:{
                        xs: '90%',
                        md: '75%'
                    }, p:2, px:2, m: 'auto', border: `1px solid ${variables.BLUE}`, background:variables.CYAN1, borderRadius: '30px'}}>
                        {/* <TabPanel value={index} index={0}>
                            {index === 0 ? 'Account Settings' : ''}
                        </TabPanel> */}
                        <TabPanel value={index} index={0}>
                            {index === 0 ? <ChangePassword userId={userId}/> : null}
                        </TabPanel>
                        <TabPanel value={index} index={1}>
                            {index === 1 ? <NotificationSettings /> : null}
                        </TabPanel>
                    </Box>
                </Box>
            </Container>
        </Layout>
    )
}

export default UserSettings
