import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import PageMetaTags from '../../base/PageMetaTags'
import Layout from '../../template/Layout'
import PageNotFound from '../../assets/images/404.svg'
import variables from '../../settings/_variables.scss'

const ErrorPages = props => {
    const { errorCode } = props
    return (
        <Layout headerRequired={false}>
            <PageMetaTags
                title={`${errorCode === 404 ? 'Page Not Found' : errorCode === 403 ? 'Unauthorized Access' : 'Something went wrong!'} | Bunton`}
            />
            <Container maxWidth='xl' sx={{ p: {
                xs: '50px',
                md: '75px'
            }}}>
                <Grid container columnSpacing={{xs: 0, lg: 10}} rowSpacing={{xs: 10, lg: 0}} alignItems='center'>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box sx={{textAlign: {
                            xs: 'center',
                            lg: 'left'
                        }}}>
                            <Typography
                                variant='h1'
                                className='extrabold'
                                sx={{fontSize: {
                                    xs: '50px',
                                    md: '85px',
                                },color: `${variables.BLUE} !important`}}
                            >
                                Sorry,
                            </Typography>
                            <Typography
                                variant='h1'
                                className='section-heading'
                            >
                                something went wrong.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={12}
                        sm={12} 
                        xs={12}
                        sx={{textAlign: 'center'}}
                    >
                        <Box >
                            <Box component='img' src={PageNotFound} sx={{objectFit: 'cover' ,width: '100%'}}/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default ErrorPages
