import { useQuery } from '@apollo/client'
import { Box, Container, Tabs, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { TabPanel } from '../../../utils/commonFunctions'
import Loader from '../../../components/Loader'
import DashboardJobsCandidates from '../DashboardJobsCandidates'
import DashboardHeaderSection from '../../../components/molecules/DashboardHeaderSection'
import { JOB_LIST_QUERY } from '../../../queries/jobListQueries'
import CustomTab from '../../../components/elements/CustomTab'
import CompanyJobList from '../../../components/organisams/CompanyJobList'
import ErrorPages from '../../ErrorPages'

const EmployerDashboard = props => {
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const navigate = useNavigate()
    const { company } = loginCheckAuthDetails
    const [listLoader, setListLoader] = React.useState(false)

    const theme = useTheme()
    const [index, setIndex] = useState(
        localStorage.getItem('activeTab') ? parseInt(localStorage.getItem('activeTab')) : 0
    )

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const {
        loading: candidateLoading,
        error: candidateError,
        data: candidateData,
        fetchMore,
        refetch: candidateRefecth
    } = useQuery(JOB_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            first: 8,
            applicants: true,
            afterCursor: '',
            companyUserId: userId
        },
        onCompleted(data) {
            setListLoader(false)
        },
        onError(error) {}
    })

    const handleIndexChange = (event, index) => {
        if (index !== 0) {
            candidateRefecth({
                afterCursor: ''
            })
        }
        setIndex(index)
    }

    const handleChangeIndex = index => {
        setIndex(index)
    }

    useEffect(() => {
        localStorage.setItem('activeTab', index)
    }, [index])

    if (candidateLoading) return <Loader />
    if (candidateError) return <ErrorPages/>

    const candidateJobsData = candidateData.jobsListings.edges.map(edge => edge.node)
    const candidatePageInfo = candidateData.jobsListings.pageInfo

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Container maxWidth='xl'>
                <Box sx={{ mt: { xs: 0, sm: 5 }, mb: { xs: 3, sm: 5 } }}>
                    <DashboardHeaderSection employedDashboard={true} />
                </Box>
                <Box
                    sx={{
                        mt: '30px'
                    }}
                >
                    <Tabs
                        onChange={handleIndexChange}
                        textColor='inherit'
                        value={false}
                        sx={{ '&.MuiTabs-root .MuiTabs-flexContainer': { flexWrap: 'wrap' } }}
                    >
                        <CustomTab
                            notificationBadge={false}
                            elementIndex={0}
                            currentIndex={index}
                            tabTitle='Active Jobs'
                            handleIndexChange={handleIndexChange}
                        />
                        <CustomTab
                            notificationBadge={false}
                            elementIndex={1}
                            currentIndex={index}
                            tabTitle='Applicants'
                            handleIndexChange={handleIndexChange}
                            tabRootSx={{ mr: { xs: 0, sm: 1 } }}
                        />
                        <CustomTab
                            notificationBadge={false}
                            elementIndex={2}
                            currentIndex={index}
                            tabTitle='Add New Job Offer'
                            handleIndexChange={handleIndexChange}
                            onClick={() => {
                                navigate('/add-job', {
                                    replace: true,
                                    state: { dashboard: true }
                                })
                            }}
                            tabRootSx={{
                                width: {
                                    xs: '100%',
                                    sm: 'auto'
                                }
                            }}
                        />
                    </Tabs>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={index}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={index} index={0} dir={theme.direction}>
                            <Box
                                sx={{
                                    marginTop: '50px',
                                    maxWidth: '1200px',
                                    m: 'auto'
                                }}
                            >
                                <CompanyJobList
                                    company={company}
                                    isUserAuthenticated={isUserAuthenticated}
                                    isEdit={true}
                                    showCompanyLogo={true}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value={index} index={1} dir={theme.direction}>
                            {candidateJobsData && (
                                <Box>
                                    <DashboardJobsCandidates
                                        jobsData={candidateJobsData}
                                        pageInfo={candidatePageInfo}
                                        refetch={candidateRefecth}
                                        fetchMore={fetchMore}
                                        listLoader={listLoader}
                                        setListLoader={setListLoader}
                                    />
                                </Box>
                            )}
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </Container>
        </Container>
    )
}

export default EmployerDashboard