import React from 'react'
import { useSelector } from 'react-redux'
import PageMetaTags from '../../base/PageMetaTags'
import Layout from '../../template/Layout'
import CandidateDashboard from './CandidateDashboard'
import './dashboard.scss'
import EmployerDashboard from './EmployerDashboard'
import ErrorPages from '../ErrorPages'

const Dashboard = props => {
    const { loginDetails, loginCheckAuthDetails } = useSelector(state => state.login)
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null
    return (
        <Layout>
            <PageMetaTags title='Dashboard | Bunton' />
            {userRole === 'user' ? (
                <CandidateDashboard />
            ) : userRole === 'employer' ? (
                <EmployerDashboard />
            ) : (
                <ErrorPages errorCode={404} />
            )}
        </Layout>
    )
}

export default Dashboard
