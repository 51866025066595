import { AppBar, Box, createTheme, ThemeProvider, Toolbar, Typography } from '@mui/material'
import React from 'react'
import LoggedInUserNavigation from '../LoggedInUserNavigation'
import BuntonLogo from '../../../assets/images/Logo.svg'
import variables from '../../../settings/_variables.scss'
import { useSelector } from 'react-redux'
import MobileMenu from '../MobileMenu'

const navTheme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                positionFixed: {
                    height: '101px',
                    display: 'grid',
                    boxShadow: 'none',
                    top: '0px'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    margin: {
                        xs: '0 20px',
                        sm: '0 40px',
                        md: '0 80px',
                        lg: '0 80px'
                    },
                    borderRadius: '0 0 40px 40px',
                    background: 'rgba(255, 250, 244, 0.5)'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    top: '70px !important'
                }
            }
        }
    }
})

const navBoxContainer = {
    padding: '0px 80px',
    height: '101px',
    background: 'rgba(0, 0, 0, 0)',
    // backgroundColor: variables.WHITE1,
    borderRadius: '0px 0px 40px 40px',
    flexGrow: 1
    // opacity: 0.5
}

const AdminNavigation = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    return (
        <ThemeProvider theme={navTheme}>
            <Box sx={navBoxContainer}>
                <AppBar
                    position='fixed'
                    color='transparent'
                    sx={{
                        top: '0px !important'
                    }}
                >
                    <Toolbar>
                        <Box>
                            <Box
                                component='img'
                                src={BuntonLogo}
                                sx={{
                                    width: '140px'
                                }}
                            />
                            <Typography
                                component='span'
                                sx={{
                                    fontSize: {xs:'15px', md:'23px'},
                                    color: variables.CYAN,
                                    position: 'relative',
                                    top: '-13px',
                                    marginLeft: '16px'
                                }}
                            >
                                Admin portal
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '45%',
                                position: 'absolute',
                                left: '50%',
                                textAlign: 'right'
                            }}
                        >
                            {responsiveBreakpoint === 'mob' ? <MobileMenu/>: <LoggedInUserNavigation />}
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        </ThemeProvider>
    )
}

export default AdminNavigation
