import React, { Fragment } from 'react'
import { Box, LinearProgress, linearProgressClasses, styled, Typography } from '@mui/material'

import { styles } from './styles'
import variables from '../../settings/_variables.scss'
import { TextStrings } from '../../constants'

const LinearProgressBar = props => {
    const { progress } = props
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 40,
        borderRadius: 25,
        border: `3px solid ${variables.CYAN}`,
        backgroundColor: variables.WHITE1,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: variables.WHITE1
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 20,
            backgroundColor: props.progress === 100 ? variables.CYAN : variables.ORANGE
        }
    }))
    return (
        <Fragment>
            <Box sx={styles.progressContainer}>
                <BorderLinearProgress variant='determinate' value={progress} />
            </Box>

            <Typography sx={styles.labelContainer}>
                <Typography sx={styles.labelTextBold}>{`${progress}%`}</Typography>
                <Typography variant='title' color='inherit' noWrap>
                    &nbsp;
                </Typography>
                <Typography sx={styles.labelText}>{TextStrings.complete}</Typography>
            </Typography>
        </Fragment>
    )
}

export default LinearProgressBar
