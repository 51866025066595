import { Box, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { StyledListRow } from '../../../base/commonStyles'
import { Link } from 'react-router-dom'
import Industry from '../../../assets/icons/INDUSTRY.svg'
import ExperienceIcon from '../../../assets/icons/EXPERIENCE.svg'
import Arrow from '../../../assets/icons/ARROW.svg'
import Icons from '../../../components/Icons'
import AvatarDefault from '../../../assets/images/avatar.png'
import {
    getExperienceLabel,
    getStringFromArray,
    truncateDetails
} from '../../../utils/commonFunctions'
import { getAvatrURL } from '../../../utils/utilities'
import { useSelector } from 'react-redux'
import { HomePageButtons } from '../../../pages/HomePage'

const StyledIcons = styled(Box)`
    height: 18px;
    width: 18px;
    margin-right: 15px;
`
const StyledBox = styled(Box)`
    width: 110px;
    height: 110px;
    border-radius: 100px;
    border: 5px solid #ffffff;
    margin: 0 auto;
`

const CandidateListRow = props => {
    const { data } = props
    const { responsiveBreakpoint } = useSelector(state => state.initial)

    const renderDetailIcon = userId => {
        return (
            <Grid
                item
                xs={12}
                md={4}
                sm={4}
                lg={4}
                xl={4}
                sx={{
                    textAlign: {
                        xs: 'center',
                        md:'right'
                    }
                }}
            >
                <HomePageButtons
                    component={Link}
                    to={'/candidate-details/' + userId}
                    color='primary'
                    variant='contained'
                    size='large'
                    sx={{p: "14px 30px", textAlign: 'center'}}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{fontSize: '16px', fontWeight: '900'}}>
                            View Profile
                        </Typography>
                        <Icons src={Arrow} height={20} sx={{ml: 2}}/>
                    </Box>
                </HomePageButtons>
            </Grid>
        )
    }
    return (
        <StyledListRow
            maxWidth={false}
            disableGutters
            sx={{
                minHeight: '130px',
                padding: '20px',
                borderRadius: {
                    xs: '50px',
                    md: '80px'
                }
            }}
        >
            <Grid container columnSpacing={{xs: 0, sm:2}} rowSpacing={{xs: 2, sm:0}} alignItems='center'>
                <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                    <StyledBox component='img' src={ data.user && data.user.profileImage ? getAvatrURL(data.user.profileImage) : AvatarDefault} sx={{width: {
                            xs:'70px',
                            lg: '90px'
                        }, height: {
                            xs:'70px',
                            lg: '90px'
                        }}}/>
                </Grid>
                
                <Grid
                    item
                    xs={12}
                    sm={5}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    <Box>
                        <Typography
                            variant='h3'
                            className='strong'
                        >
                            {data.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant='h3'
                            className='small-size-18px'
                        >
                            {data.title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            my: 1,
                            display: {
                                xs: 'block',
                                md: 'flex'
                            }
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center', mb: {
                            xs: 1, md: 0
                        }, mr: {
                            xs: 0, md: 1
                        }}}>
                            <StyledIcons component='img' src={Industry} />
                            <Typography variant='h5' className='small-size'>
                                {['mob', 'tab'].includes(responsiveBreakpoint) ? getStringFromArray(data.industries) : truncateDetails(getStringFromArray(data.industries), 20)}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', alignItems: 'center', mx: {
                                xs: 0,
                                md: 1
                            },
                            my :{
                                xs: 1,
                                md: 0
                            }}}>
                            <StyledIcons component='img' src={ExperienceIcon} />
                            <Typography variant='h5' className='small-size'>
                                {getExperienceLabel(data.experience)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                {renderDetailIcon(data.userId)}
            </Grid>
        </StyledListRow>
    )
}

export default CandidateListRow
