import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material'
import React from 'react'
import Footer from '../components/organisams/footer'
import JoinBuntonSection from '../components/organisams/JoinBuntonSection'
import InformativeBoxSection from '../components/organisams/InformativeBoxSection'
import BannerRegistrationSection from '../components/molecules/BannerRegistrationSection'
import LandingPageJoinUs from '../components/organisams/LandingPageJoinUs'
import CandidateReviewSection from '../components/organisams/CandidateReviewSection'
import CompaniesNetwork from '../components/organisams/CompaniesNetwork'
import LandingPageHeader from '../components/organisams/LandingPageHeader'
import variables from '../settings/_variables.scss'
import { Link, useLocation } from 'react-router-dom'
import BuntonLogo from '../assets/images/Logo.svg'
import JobsIcon from '../assets/icons/DEPARTMENT.svg'
import CompaniesIcon from '../assets/icons/COMPANIES.svg'
import ContactIcon from '../assets/icons/CONTACT.svg'
import { NavMenuIcon } from '../components/organisams/ResponsiveAppBar'
import { useTranslation } from 'react-i18next'
import { HomePageButtons } from './HomePage'
import MobileMenu from '../template/Navigation/MobileMenu'
import ReactGA4 from 'react-ga4'


const CandidateHomePage = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const publicOptions = [
        {
            menuItem: 'Jobs',
            traslationKey: 'jobs',
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: 'Companies',
            traslationKey: 'companies',
            link: '/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: 'Contact',
            traslationKey: 'contactus',
            link: '/contact-us',
            menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        },
    ]
    return <Container maxWidth={false} disableGutters>
        <Box sx={{display:'flex'}}>
            <AppBar component="nav" sx={{background: variables.CYAN}}>
                <Toolbar>
                    <Box component={Link} to='/' sx={{flexGrow: 1}}>
                        <Box
                            component='img'
                            src={BuntonLogo}
                            sx={{
                                width: '140px',
                                display: 'block',
                            }}
                        />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' }, alignItems:'center' }}>
                        {publicOptions.map((option, index) => (
                        <HomePageButtons
                        label={
                            <Typography
                                variant='h5'
                                className='small-size-16px semi-strong'
                                sx={{
                                    fontWeight: '600 !important',
                                    '&:hover': {
                                        color: `${variables.WHITE1} !important`
                                    }
                                }}
                            >
                                {t(`menu.options.${option.traslationKey}`)}
                            </Typography>
                        }
                        component={Link}
                        to={option.link}
                        color='primary'
                        variant='contained'
                        size='large'
                        sx={{
                            py: 2,
                            px: 4,
                            alignItems: 'flex-start',
                            textTransform: 'none',
                            background: [option.link].includes(
                                location.pathname
                            )
                                ? variables.WHITE1
                                : 'transparent',
                            '&:hover': {
                                background: 'transparent'
                            }
                        }}
                        startIcon={option.menuIcon}
                    />
                        ))}
                        <HomePageButtons
                            label={
                                <Typography
                                    variant='h5'
                                    className='small-size-16px semi-strong'
                                    sx={{
                                        fontWeight: '600 !important',
                                        '&:hover': {
                                            color: `${variables.WHITE1} !important`
                                        }
                                    }}
                                >
                                    {t(`menu.options.register`)}
                                </Typography>
                            }
                            component={Link}
                            to='/registration'
                            color='primary'
                            variant='contained'
                            size='large'
                            onClick={() => {
                                ReactGA4.event({
                                    category: 'Menu Registration',
                                    action: 'Click',
                                    label: 'Menu'
                                })
                            }}
                            sx={{
                                py: 1,
                                px: 4,
                                alignItems: 'flex-start',
                                textTransform: 'none',
                                background: variables.WHITE1,
                                '&:hover': {
                                    background: variables.BLUE,
                                    color: variables.WHITE
                                },
                                '&:target': {
                                    color: variables.WHITE
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{display:{xs:'block', md: 'none'}}}>
                        <MobileMenu
                            responsiveMenu={true}
                            containerSx={{
                                background: variables.WHITE1,
                                borderRadius: '50px',
                                height: '50px',
                                width: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} disableGutters sx={{mt:7}}>
                <LandingPageHeader/>
                <Container maxWidth='1520px' disableGutters>
                    <InformativeBoxSection/>
                    <BannerRegistrationSection/>
                    <LandingPageJoinUs/>
                    <CandidateReviewSection/>
                    <CompaniesNetwork/>
                </Container>
                <JoinBuntonSection/>
            </Container>
        </Box>
        <Footer/>
    </Container>
}

export default CandidateHomePage