import { Button } from '../components'
import { Box, CircularProgress, FormHelperText, Typography, Container } from '@mui/material'

import { useForm } from 'react-hook-form'
import { ErrorMessage, Placeholder, Regex, TextStrings } from '../constants'
import variables from '../settings/_variables.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import PageMetaTags from '../base/PageMetaTags'
import { useMutation } from '@apollo/client'
import ResetPassword from './ResetPassword'
import { useEffect, useState } from 'react'
import StatusBox from '../components/StatusBox'
import ControlledTextInput from '../components/molecules/ControlledTextInput'
import { FORGOT_PASSWORD_MUTATION } from '../queries/commonQueries'
import BuntonPageHeadingLogo from '../components/elements/BuntonPageHeadingLogo'

const ForgotPassword = () => {
    const [apiError, setApiError] = useState('')
    const location = useLocation()
    const navigate = useNavigate()
    const [submitLoading, setSubmitLoading] = useState(false)
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: ''
        }
    })
    const [isSuccess, setIsSuccess] = useState(false)

    const isForgotPassword = location.pathname === '/forgot-password'

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION, {
        onCompleted(data) {
            setSubmitLoading(false)
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
                navigate(`/validate-email/${btoa(data.forgotPassword.userId)}`)
            }, 3000)
        },
        onError(error) {
            setApiError(error)
            setIsSuccess(false)
            setSubmitLoading(false)
            // dispatch(addProfileDetailFailure(error.message))
        }
    })

    const onSubmit = data => {
        setSubmitLoading(true)
        forgotPasswordMutation({
            variables: {
                email: data.email
            }
        })
    }

    return (
        <Container maxWidth='xl' disableGutters>
            <PageMetaTags
                title={isForgotPassword ? 'Forgot Password? | Bunton' : 'Reset Password? | Bunton'}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    minHeight:'600px'
                }}
            >
                <Box>
                    <BuntonPageHeadingLogo />
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                margin: '0 auto'
                            }}
                        >
                            <Typography variant='h3' className='section-heading'>
                                {isForgotPassword
                                    ? TextStrings.forgotPassword
                                    : TextStrings.resetPassword}
                            </Typography>
                        </Box>
                    </Box>
                    {isForgotPassword ? (
                        <Container
                            maxWidth='md'
                            disableGutters
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    m: '0 auto',
                                    mt: 5,
                                    maxWidth: '600px',
                                    width: '80%'
                                }}
                            >
                                <ControlledTextInput
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ErrorMessage.emptyEmail
                                        },
                                        pattern: {
                                            value: Regex.emailRegex,
                                            message: ErrorMessage.validEmail
                                        }
                                    }}
                                    name='email'
                                    type='email'
                                    placeholder={Placeholder.enterEmail}
                                    errors={errors && errors.email && errors.email.message}
                                    required={true}
                                    fullWidth={true}
                                    formContolSx={{ width: '100%' }}
                                    label={TextStrings.email}
                                    withoutLable={true}
                                />
                                <Typography
                                    align='center'
                                    variant='h3'
                                    className='extrabold'
                                    sx={{
                                        my: 4
                                    }}
                                >
                                    {TextStrings.forgotPasswordInst}
                                </Typography>

                                {apiError && apiError.message ? (
                                    <FormHelperText
                                        sx={{ textAlign: 'center' }}
                                        id='my-helper-text'
                                    >
                                        {apiError.message}
                                    </FormHelperText>
                                ) : null}

                                <Box>
                                    <Button
                                        disabled={submitLoading}
                                        label={
                                            submitLoading ? (
                                                <CircularProgress
                                                    size={20}
                                                    sx={{
                                                        color: variables.BLUE
                                                    }}
                                                />
                                            ) : (
                                                'Send'
                                            )
                                        }
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        onClick={handleSubmit(onSubmit)}
                                        type='submit'
                                        sx={{
                                            px: 6,
                                            my: 2
                                        }}
                                        classes='sign_in'
                                    />
                                </Box>
                            </Box>
                        </Container>
                    ) : (
                        <Box>
                            <ResetPassword />
                        </Box>
                    )}
                </Box>
            </Box>
            {isSuccess ? <StatusBox statusText='Successfully sent!' /> : null}
        </Container>
    )
}

export default ForgotPassword
