import { Box, Container } from '@mui/material'
import React, { useState } from 'react'
import Layout from '../template/Layout'
import PageMetaTags from '../base/PageMetaTags'
import UsersList from '../components/organisams/UsersList'

const Users = props => {
    const [userType, setUserType] = useState('candidate')
    return <Layout>
        <PageMetaTags title='Users | Bunton' />
        <Container maxWidth='lg' sx={{mb: 10}} >
            <Box>
                <UsersList selectedUserType={userType}/>
            </Box>
        </Container>
    </Layout>
}

export default Users