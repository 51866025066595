export const companyStrength = [
    { label: 'less than 50 employees', name: 'less than 50 employees', value: 'less than 50' },
    { label: '50 - 99 employees', name: '50 - 99 employees', value: '50 - 99' },
    { label: '100 - 249 employees', name: '100 - 249 employees', value: '100 - 249' },
    { label: '250 - 499 employees', name: '250 - 499 employees', value: '250 - 499' },
    { label: 'more than 500', name: 'more than 500', value: 'more than 500' }
]

export const experiencesList = [
    { name: '0 - 5 years', label: '0 - 5 years', value: '0-5' },
    { name: '6 - 10 years', label: '6 - 10 years', value: '6-10' },
    { name: '11 - 15 years', label: '11 - 15 years', value: '11-15' },
    { name: '16 - 20 years', label: '16 - 20 years', value: '16-20' },
    { name: '21 - 25 years', label: '21 - 25 years', value: '21-25' },
    { name: 'more than 25 years', label: 'more than 25 years', value: '>25' }
]

export const companyType = [
    { name: 'Register Company and User', label: 'Register Company and User', value: 'companyUser' },
    { name: 'Register Company Only', label: 'Register Company Only', value: 'companyOnly' }
]

export const moreOptionsList = [{ label: 'Delete Profile', value: 'delete profile' }]

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
// export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/