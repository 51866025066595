import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCookieConsentContext } from '@use-cookie-consent/react'
import React from 'react'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const CookieBanner = () => {
    const { consent, acceptAllCookies, declineAllCookies, acceptCookies } = useCookieConsentContext(
        {
            defaultConsent: false
        }
    )
    const theme = useTheme()
    const mob = useMediaQuery(theme.breakpoints.down('sm'))

    return Object.keys(consent).length > 0 && cookies.get('buntonCheck') === 'true' ? null : (
        <Box
            sx={{
                background: variables.BLUE,
                position: 'fixed',
                zIndex: '9',
                width: mob ? '100%' : '350px',
                left: 'auto',
                right: {
                    xs: 0,
                    sm: '30px'
                },
                bottom: {
                    xs: 0,
                    sm: 1
                },
                borderRadius: {
                    xs: 0,
                    sm: '20px'
                },
                display: 'block'
            }}
        >
            <Box sx={{ p: 1.5 }}>
                <Box sx={{ mb: 1 }}>
                    <Typography variant='h5' className='small-size-12px font-color-white'>
                        To provide the best experiences, we use technologies like cookies to store
                        and/or access device information. Consenting to these technologies will
                        allow us to process data such as browsing behavior or unique IDs on this
                        site.
                    </Typography>
                </Box>
                <Grid container spacing={1} >
                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            label='Accept All'
                            sx={{ p: '8px 14px', alignItems: 'center', fontSize: '12px' }}
                            onClick={() => {
                                cookies.set('buntonCheck', true)
                                acceptAllCookies()
                            }}
                            variant='cookieButtons'
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            label='Essentials Only'
                            sx={{ p: '8px 14px', alignItems: 'center', fontSize: '12px' }}
                            onClick={() => {
                                cookies.set('buntonCheck', true)
                                acceptCookies({ necessary: true })
                            }}
                            variant='cookieButtons'
                        />
                    </Grid>
                </Grid>
                {/* <button >Accept all</button>
        <button >
          Accept third-party
        </button>
        <button onClick={() => acceptCookies({ firstParty: true })}>
          Accept first-party
        </button>
        <button onClick={declineAllCookies}>Reject all</button> */}
            </Box>
        </Box>
    )
}

export default CookieBanner
