import variables from '../../settings/_variables.scss'
export const styles = {
    mainContainer: {
        // backgroundColor: variables.WHITE1,
        height: '100vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    header: {
        mb: '30px',
        mt: '40px',
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerLeft: {
        width: '100px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    headerCenter: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center'
    },
    logo: {
        width: '240px',
        height: '65px'
    },
    headerRight: {
        width: '100px'
    },
    title: {
        display: 'flex',
        flex: 1,
        fontSize: '20px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular,
        mb: '0px',
        justifyContent: 'center'
    },
    backIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        ml: '80px'
    }
}
