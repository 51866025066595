import {
    Backdrop,
    Box,
    CircularProgress,
    Collapse,
    Fade,
    Grid,
    Modal,
    Snackbar,
    ThemeProvider,
    Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import variables from '../../settings/_variables.scss'
import { Share } from '@mui/icons-material'
import ShareModalButton from '../elements/ShareModalButton'
import MailIcon from '../../assets/icons/WHITE-MAIL.svg'
import LinkIcon from '../../assets/icons/WHITE-LINK.svg'

import Department from '../../assets/icons/DEPARTMENT.svg'
import Industry from '../../assets/icons/INDUSTRY.svg'
import LocationIcon from '../../assets/icons/LOCATION.svg'
import Experience from '../../assets/icons/EXPERIENCE.svg'

import CheckIcon from '../../assets/icons/CHECK.svg'
import ControlledTextInput from '../molecules/ControlledTextInput'
import { useForm } from 'react-hook-form'
import { ErrorMessage, Regex } from '../../constants'
import ModalCloseButton from '../elements/ModalCloseButton'
import Button from '../Button'
import { useMutation } from '@apollo/client'
import { TRACK_SHARING_MUTATION } from '../../queries/commonQueries'
import { theme } from '../../settings/theme'
import { StyledIcons, StyledListRow } from '../../base/commonStyles'
import { useSelector } from 'react-redux'
import {
    getCompanyIndustries,
    getExperienceLabel,
    getStringFromArray,
    truncateDetails
} from '../../utils/commonFunctions'
import DefaultCompanyLogo from '../molecules/DefaultCompanyLogo'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '60%',
        md: '60%',
        lg: '50%'
    },
    bgcolor: variables.WHITE1,
    boxShadow: 10,
    p: 4,
    borderRadius: '65px'
}

const ShareModal = props => {
    const { open, handleClose, jobDetails, shareJob, userId } = props
    const { loginDetails } = useSelector(state => state.login)
    const [showShareViaEmailModal, setShowShareViaEmailModal] = useState(false)

    const [isSaving, setIsSaving] = useState(false)
    let initialFormState = {
        name: '',
        email: '',
        message: ''
    }
    const {
        control,
        handleSubmit,
        reset,
        resetField,
        formState: { errors },
        register,
        setFocus
    } = useForm({
        defaultValues: initialFormState
    })

    const [
        trackSharingMutation,
        { loading: trackSharingLoading, data: trackSharingData, error: trackSharingError }
    ] = useMutation(TRACK_SHARING_MUTATION, {
        onCompleted(data) {
            setIsSaving(false)
            setShowShareViaEmailModal(false)
            reset()
            handleClose()
            props.showThankYou()
        },
        onError(error) {
            console.log(error, 'error')
        }
    })

    const shareDetails = async data => {
        trackSharingMutation({
            variables: {
                section: shareJob ? 'job' : 'site',
                jobId: jobDetails ? jobDetails.id : '',
                userId: loginDetails && loginDetails.id ? loginDetails.id : userId,
                name: data.name,
                email: data.email,
                message: data.message
            }
        })
    }

    const JobDetailsSection = ({ data }) => {
        const jobIndustriesString =
            data && data.industries
                ? truncateDetails(getCompanyIndustries(data.industries), 50)
                : ''
        const locationString =
            data && data.states ? truncateDetails(getStringFromArray(data.states), 50) : null
        const departmentsString =
            data && data.departments
                ? truncateDetails(getStringFromArray(data.departments, data.otherDepartment), 50)
                : null
        return (
            <StyledListRow
                maxWidth={false}
                disableGutters
                sx={{
                    minHeight: '130px'
                }}
            >
                <Grid
                    container
                    sx={{
                        padding: {
                            xs: 2.5,
                            sm: 2
                        }
                    }}
                >
                    <Grid item xs={12} sm={5} md={3} sx={{ position: 'relative' }}>
                        <DefaultCompanyLogo
                            imgSrc={data && data.companyLogoUrl ? data.companyLogoUrl : null}
                            // sx={{ ...responsiveLogoPosition }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        md={9}
                        sx={{
                            marginTop: {
                                xs: 2,
                                sm: '15px',
                                md: '9px'
                            }
                        }}
                    >
                        <Box>
                            <Typography variant='h5' className='small-size'>
                                {data && data.company ? data.company.companyName : ''}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    color: variables.BLUE,
                                    fontWeight: '600'
                                }}
                            >
                                {data && data.jobTitle ? data.jobTitle : ''}
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            {jobIndustriesString ? (
                                <Grid item md={6} sm={6} xs={12}>
                                    <Box sx={{ display: 'flex' }}>
                                        <StyledIcons component='img' src={Industry} />
                                        <Typography>{jobIndustriesString}</Typography>
                                    </Box>
                                </Grid>
                            ) : null}
                            {data && data.experienceRequired && (
                                <Grid item md={6} sm={6} xs={12}>
                                    <Box sx={{ display: 'flex' }}>
                                        <StyledIcons component='img' src={Experience} />
                                        <Typography>
                                            {data && data.experienceRequired
                                                ? getExperienceLabel(data.experienceRequired)
                                                : ''}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            {locationString && (
                                <Grid item md={6} sm={6} xs={12}>
                                    <Box sx={{ display: 'flex' }}>
                                        <StyledIcons component='img' src={LocationIcon} />
                                        <Typography>{locationString}</Typography>
                                    </Box>
                                </Grid>
                            )}
                            {departmentsString && (
                                <Grid item md={6} sm={6} xs={12}>
                                    <Box sx={{ display: 'flex' }}>
                                        <StyledIcons component='img' src={Department} />
                                        <Typography>{departmentsString}</Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        {/* <Box
                    sx={{
                        marginTop: '18px',
                        display:
                            responsiveBreakpoint !== 'mob'
                                ? 'flex'
                                : 'block'
                    }}
                >
                    
                    
                </Box> */}
                    </Grid>
                </Grid>
            </StyledListRow>
        )
    }

    const [copyOpen, setCopyOpen] = useState(false)

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (props.open) {
                    handleSubmit(shareDetails)()
                }
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [props])
    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                disableScrollLock={true}
                slotProps={{
                    backdrop: {
                        sx: {
                            background: variables.CYAN,
                            opacity: '0.7 !important'
                        },
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box sx={{ height: 'auto', ...style }}>
                        <Box>
                            <Box>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item md={10} sm={10} xs={10}>
                                        <Box sx={{ display: 'flex' }} alignItems='center'>
                                            <Share
                                                sx={{ height: '33px', width: '33px', mr: '20px' }}
                                            />
                                            <Typography variant='h2' className='modal-heading'>
                                                Share{shareJob ? ' Job' : ' Website'}
                                                {showShareViaEmailModal ? ' via e-mail' : ''}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} sm={2} xs={2}>
                                        <ModalCloseButton
                                            handleClose={() => {
                                                setShowShareViaEmailModal(false)
                                                reset()
                                                handleClose()
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Box sx={{ overflowY: 'auto', maxHeight: '70vH', px: 2 }}>
                                    {jobDetails ? (
                                        <Box sx={{ mt: 3 }}>
                                            <JobDetailsSection data={jobDetails} />
                                        </Box>
                                    ) : null}
                                    <Collapse in={!showShareViaEmailModal}>
                                        <Box sx={{ mt: 4 }}>
                                            <Grid container spacing={5}>
                                                <Grid item md={6} xs={12} sm={12}>
                                                    <ShareModalButton
                                                        title='via e-mail'
                                                        icon={
                                                            <Box
                                                                component='img'
                                                                src={MailIcon}
                                                                sx={{ height: '77px' }}
                                                            />
                                                        }
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            setShowShareViaEmailModal(true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12} sm={12}>
                                                    <ShareModalButton
                                                        title='copy URL'
                                                        icon={
                                                            <Box
                                                                component='img'
                                                                src={LinkIcon}
                                                                sx={{ height: '77px' }}
                                                            />
                                                        }
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            setCopyOpen(true)
                                                            navigator.clipboard.writeText(
                                                                window.location.toString()
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Collapse>
                                    <Collapse in={showShareViaEmailModal}>
                                        <Box>
                                            <Box sx={{ mt: 4 }}>
                                                <Box sx={{ mb: 1 }}>
                                                    <Box {...register('name')}>
                                                        <ControlledTextInput
                                                            control={control}
                                                            rules={{
                                                                required: {
                                                                    value: true,
                                                                    message:
                                                                        ErrorMessage.shareEmptyName
                                                                },
                                                                validate: title => {
                                                                    return (
                                                                        title.trim().length > 2 ||
                                                                        'Please enter valid name'
                                                                    )
                                                                }
                                                            }}
                                                            name='name'
                                                            type='text'
                                                            placeholder='name of receiver*'
                                                            errors={
                                                                errors &&
                                                                errors.name &&
                                                                errors.name.message
                                                            }
                                                            required={true}
                                                            fullWidth={true}
                                                            formContolSx={{ width: '100%' }}
                                                            inputProps={{
                                                                sx: {
                                                                    fontSize: '15px'
                                                                }
                                                            }}
                                                            withoutLable={true}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Box {...register('message')}>
                                                        <ControlledTextInput
                                                            control={control}
                                                            rules={{
                                                                required: {
                                                                    value: true,
                                                                    message: ErrorMessage.emptyCustomNotificationBody
                                                                }
                                                            }}
                                                            name='message'
                                                            placeholder='your message'
                                                            type='text'
                                                            multiline={true}
                                                            rows={5}
                                                            errors={errors &&
                                                                errors.message &&
                                                                errors.message.message}
                                                            required={true}
                                                            fullWidth={true}
                                                            formContolSx={{ width: '100%' }}
                                                            inputProps={{
                                                                sx: {
                                                                    fontSize: '15px',
                                                                    p: 0
                                                                }
                                                            }}
                                                            withoutLable={true}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={9} sm={9} xs={12}>
                                                            <Box {...register('email')}>
                                                                <ControlledTextInput
                                                                    control={control}
                                                                    rules={{
                                                                        required: {
                                                                            value: true,
                                                                            message:
                                                                                ErrorMessage.emptyCompanyEmail
                                                                        },
                                                                        pattern: {
                                                                            value: Regex.emailRegex,
                                                                            message:
                                                                                ErrorMessage.validEmail
                                                                        }
                                                                    }}
                                                                    name='email'
                                                                    placeholder='enter e-mail address*'
                                                                    type='text'
                                                                    errors={
                                                                        errors &&
                                                                        errors.email &&
                                                                        errors.email.message
                                                                    }
                                                                    required={true}
                                                                    fullWidth={true}
                                                                    formContolSx={{ width: '100%' }}
                                                                    inputProps={{
                                                                        sx: {
                                                                            fontSize: '15px'
                                                                        }
                                                                    }}
                                                                    withoutLable={true}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} sm={3} xs={12}>
                                                            <Button
                                                                label={
                                                                    isSaving ? (
                                                                        <>
                                                                            <CircularProgress
                                                                                size={20}
                                                                                sx={{
                                                                                    color: variables.BLUE
                                                                                }}
                                                                            />{' '}
                                                                            Sending
                                                                        </>
                                                                    ) : (
                                                                        'Send'
                                                                    )
                                                                }
                                                                disabled={isSaving}
                                                                color='primary'
                                                                variant='contained'
                                                                size='large'
                                                                onClick={handleSubmit(shareDetails)}
                                                                sx={{
                                                                    width: '100%'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Collapse>
                                </Box>
                            </Box>

                            <Snackbar
                                open={copyOpen}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                onClose={() => setCopyOpen(false)}
                                autoHideDuration={2000}
                                sx={{
                                    background: variables.BLUE,
                                    position: 'fixed',
                                    bottom: '-20px !important',
                                    borderRadius: '65px',
                                    py: 2,
                                    px: 3
                                }}
                            >
                                <Box sx={{ display: 'flex' }}>
                                    <Box
                                        component='img'
                                        src={CheckIcon}
                                        sx={{ height: '20px', mr: 2 }}
                                    />
                                    <Typography sx={{ color: variables.WHITE }}>
                                        URL copied to clipboard.
                                    </Typography>
                                </Box>
                            </Snackbar>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </ThemeProvider>
    )
}

export default ShareModal
