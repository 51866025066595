import { Box } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'
import PageMetaTags from '../../base/PageMetaTags'
import JobList from '../../components/JobList'
import JobSearch from '../../components/JobSearch'
import Layout from '../../template/Layout'

const JobListing = props => {
    const { loginDetails, loginCheckAuthDetails } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null 
    return (
        <Layout
            headerRequired={
                ['employer'].includes(userRole) ? false : true
            }
            containerSx={responsiveBreakpoint === 'mob' ? {
                mt: 3
            } : {}}
        >
            <PageMetaTags title='Jobs | Bunton' />
            <Container
                maxWidth='xl'
                sx={{
                    marginTop:
                        ['employer'].includes(userRole) ? 5 : responsiveBreakpoint === 'mob' ? '0px'
                            : '-190px',
                    height: 'inherit',
                    mb: 5
                }}
            >
                {!['employer'].includes(userRole) && responsiveBreakpoint !== 'mob' ? (
                    <JobSearch />
                ) : null}
                <Box className='testBoxContainer'>
                    <JobList />
                </Box>
            </Container>
        </Layout>
    )
}

export default JobListing
