import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import { gql, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import DefaultCompanyLogo from '../molecules/DefaultCompanyLogo'

const MORE_JOB_QUERY = gql`
    query MoreJobs($afterCursor: String) {
        moreJobsListings(first: 3, after: $afterCursor) {
            edges {
                node {
                    id
                    jobTitle
                    experienceRequired
                    companyName
                    companyLogoUrl
                    industries {
                        id
                        name
                    }
                    states {
                        id
                        name
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const MoreJobsSideSection = props => {
    const { loading, data } = useQuery(MORE_JOB_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: ''
        },
        onCompleted(data) {},
        onError(error) {}
    })
    if (loading)
        return (
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <CircularProgress
                    sx={{
                        color: variables.BLUE
                    }}
                />
            </Box>
        )

    const jobsData = data.moreJobsListings.edges.map(edge => edge.node)
    const pageInfo = data.moreJobsListings.pageInfo
    return (
        <Box sx={{maxWidth: '360px', m: 'auto'}}>
            <Box sx={{ borderBottom: `2px solid ${variables.CYAN}`, pb: 2 }}>
                <Typography variant='h3' className='strong'>
                    More jobs on bunton
                </Typography>
            </Box>
            {jobsData &&
                jobsData.length > 0 &&
                jobsData.map(job => (
                    <Box
                        sx={{
                            my: 3
                        }}
                    >
                        <Grid container alignItems='center' columnSpacing={2}>
                            <Grid item xl={3} lg={3.5} md={4} sm={4} xs={4}>
                                <DefaultCompanyLogo
                                    imgSrc={job.companyLogoUrl}
                                    sx={{
                                        height: {
                                            sm: '40px',
                                            md: '50px'
                                        },
                                        width: {
                                            sm: '40px',
                                            md: '50px'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xl={9} lg={8.5} md={8} sm={8} xs={8}>
                                <Box>
                                    <Typography variant='h5' className='small-size'>
                                        {job.companyName}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant='h5' className='small-size-strong'>
                                        <Link to={'/job-details/' + job.id}> {job.jobTitle} </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
        </Box>
    )
}

export default MoreJobsSideSection
