import { Box, CircularProgress,  Typography } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '../../assets/icons/DELETE.svg'
import CloseIcon from '@mui/icons-material/Close';
import Button from '../Button'
import SuccessIcon from '../../assets/icons/success_green.svg'
import BaseModal from '../../template/BaseModal'
import ModalStatusSection from '../elements/ModalStatusSection';


const DeleteEntityBox = props => {
    const { open, entityId, deleteMutation, closeDelete, deleteLoading, showStatus } = props
    return (
        <BaseModal
            open={open}
            onClose={closeDelete}
            modalTitle=''
            boxSx={{
                width: {
                    xs: '300px',
                    sm: '400px'
                },
                maxHeight: {
                    xs:'200px',
                    sm: '300px'
                },
                overflowY: 'none'
            }}
        >
            {showStatus ? (
                <ModalStatusSection
                    statusIcon={SuccessIcon}
                    statusText='User Deleted Successfully.'
                    textSx={{
                        textAlign:'center'
                    }}
                />
            ) : (
                <>
                    <Box
                        sx={{
                            height: '80px',
                            width: '80px',
                            margin: '0 auto',
                            backgroundColor: `${variables.ORANGE}`,
                            borderRadius: '50px',
                            textAlign:'center',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}
                    >
                        <Box
                            component='img'
                            src={DeleteIcon}
                            sx={{
                                width: '37px',
                                height: '37px',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            mt: 3,
                            textAlign:'center'
                        }}
                    >
                        <Typography
                            variant='h3'
                            className='small-size-18px strong'
                        >
                            Do you really want to delete this?
                        </Typography>
                        {deleteLoading ? (
                            <CircularProgress sx={{ color: variables.BLUE }} />
                        ) : (
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <Box>
                                    <Button
                                        label='Yes'
                                        color='primary'
                                        variant='contained'
                                        endIcon={
                                            <CheckIcon sx={{ width: '20px',height: '20px' }} />
                                        }
                                        size='large'
                                        sx={{
                                            padding: '8px 40px',
                                            fontWeight: '600',
                                            m: 1,
                                            '&:hover': {
                                                backgroundColor: '#FFFAF4',
                                                border: `2px solid ${variables.BLUE}`,
                                                color: variables.BLUE
                                            }
                                        }}
                                        onClick={() => {
                                            deleteMutation({
                                                variables: {
                                                    id: entityId,
                                                    actionType: 'delete',
                                                    status: props.entityStatus
                                                }
                                            })
                                        }}
                                    />
                                    <Button
                                        label='No'
                                        color='primary'
                                        variant='contained'
                                        endIcon={
                                            <CloseIcon sx={{ height: '20px', width:"20px" }} />
                                        }
                                        size='large'
                                        sx={{
                                            m: 1,
                                            fontWeight: '600',
                                            padding: '8px 40px',
                                            '&:hover': {
                                                backgroundColor: '#FFFAF4',
                                                border: `2px solid ${variables.BLUE}`,
                                                color: variables.BLUE
                                            }
                                        }}
                                        onClick={() => closeDelete()}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </BaseModal>
    )
}

export default DeleteEntityBox
