import { Icon } from '@mui/material'
import React from 'react'

const Icons = props => {
    return (
        <Icon
            sx={{
                width: 'auto',
                height: 'auto',
                display: 'inline-flex',
                ...props.sx
            }}
            color='black'
        >
            <img src={props.src} style={props.style} className={props.className} />
        </Icon>
    )
}

export default Icons
