import { REGISTRATION_SUCCESS, REGISTRATION_FAILURE, REGISTRATION_LOADER } from './actionTypes'

export const setRegistrationLoader = () => ({
    type: REGISTRATION_LOADER
})

export const registrationSuccess = data => ({
    type: REGISTRATION_SUCCESS,
    payload: data
})

export const registrationFailure = data => ({
    type: REGISTRATION_FAILURE,
    payload: data
})
