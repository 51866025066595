import variables from '../../../settings/_variables.scss'
export const styles = {
    container: {
        backgroundColor: variables.WHITE1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    formContainer: {
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column',
        width: {
            xs: '100vw',
            sm: '100vw',
            md: '38vw',
            lg: '38vw',
            xl: '38vw'
        }
    },


    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        mt: '9px',
        ml: '15px'
    },
    labelTextBold: {
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular
    },
    labelText: {
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsRegular
    },
    buttonContainer: {
        mt: '38px',
        width: '100%',
        mb: '10px'
    },
    editDetailButton: {
        width: '100%',
        paddingLeft: '30px',
        paddingRight: {
            xs: '100px',
            sm: '200px',
            md: '200px',
            lg: '200px',
            xl: '200px'
        },
        boxShadow: 'none',
        color: variables.BLUE,
        mt: '15px',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
            letterSpacing: '0em'
        },
        '&:active':{
          boxShadow:'none'
        },
        '& .MuiTouchRipple-root span': {
            backgroundColor: 'transparent'
        }
    },
    addDetailButton: {
        width: '100%',
        paddingLeft: '30px',
        paddingRight: '0px',
        boxShadow: 'none',
        color: variables.BLUE,
        mt: '15px',
        justifyContent: 'flex-start'
    },
    setUpLaterButton: {
        width: '100%',
        boxShadow: 'none',
        color: variables.BLUE,
        mt: '15px',
        fontSize: '18px',
        fontFamily: variables.poppinsRegular,
        textTransform: 'none'
    },
    progressCompleteButton: {
        paddingLeft: '50px',
        paddingRight: '50px',

        alignSelf: 'center',
        width: 'auto',
        boxShadow: 'none',
        border: `2px solid ${variables.BLUE}`,
        fontFamily: variables.poppinsRegular,
        '&:hover': {
            backgroundColor: variables.BLUE,
            letterSpacing: '0em'
        },
        fontWeight: '600'
    },
    goToProfile: {
        color: variables.BLUE,
        fontSize: '13px',
        alignSelf: 'center'
    }
}
