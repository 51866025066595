import React, { useState } from 'react'
import Layout from '../template/Layout'
import { Box, CircularProgress, Container, Grid, Snackbar, Tabs, Typography, useTheme } from '@mui/material'
import PageMetaTags from '../base/PageMetaTags'
import CandidateResponseList from '../components/organisams/CandidateResponseList'
import CustomTab from '../components/elements/CustomTab'
import { TabPanel } from '../utils/commonFunctions'
import EngagementQuestionList from '../components/organisams/EngagementQuestionList'
import variables from '../settings/_variables.scss'
import DownloadIcon from '@mui/icons-material/Download'
import { Button } from '../components'
import { useMutation, useQuery } from '@apollo/client'
import { EXPORT_CSV_MUTATION } from '../queries/commonQueries'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { GET_QUESTIONS_COUNT_DATA } from '../queries/engagementQuestionsQueries'
import Loader from '../components/Loader'

const EngagementQuestionCandidateResponses = props => {
    const [index, setIndex] = useState(0)
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const handleIndexChange = (event, index) => {
        setIndex(index)
    }

    const {loading, data, error} = useQuery(GET_QUESTIONS_COUNT_DATA, {
		onCompleted(data) {
			console.log(data)
		},
		onError(error) {}
	})

    const [exportCsvMutation, { loading: saveLoading }] = useMutation(EXPORT_CSV_MUTATION, {
        onCompleted(data) {
            setDownloadExcel(false)
            setToastMessage('Email sent to admin.')
            setShowToast(true)
        },
        onError(error) {}
    })
    const theme = useTheme()
    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowToast(false)
    }

    const showToastMessage = (showToast, message) => {
        setShowToast(showToast)
        setToastMessage(message)
    }
    if(loading) {
        return <Loader/>
    }
    const {getQuestionResponseCount, getCandidateResponseDataCount} = data
    return (
        <Layout>
            <PageMetaTags title='Engagement Questions Responses | Bunton' />
            {<Container maxWidth='lg'>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={showToast}
                    autoHideDuration={2000}
                    onClose={handleToastClose}
                >
                    <Box
                        sx={{
                            backgroundColor: variables.BLUE,
                            maxWidth: '300px',
                            p: 2,
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            color: variables.WHITE,
                            textAlign: 'center',
                            width: '100%'
                        }}
                    >
                        <CheckCircleIcon sx={{ width: '20px', mr: 2 }} />
                        <strong>{` ${toastMessage}`}</strong>
                    </Box>
                </Snackbar>
                <Grid container spacing={{ xs: 1, sm: 3 }} alignItems='center'>
                    <Grid item md={4} lg={4} xl={4} xs={6} sm={4}>
                        <Typography variant='h2' className='strong'>
                            Engagement Questions
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={8}
                        lg={8}
                        xl={8}
                        sm={8}
                        xs={6}
                        sx={{ textAlign: 'right', display: 'flex', justifyContent: 'end' }}
                    >
                        <Button
                            label={
                                downloadExcel ? (
                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                        <CircularProgress
                                            size={20}
                                            sx={{
                                                color: variables.BLUE,
                                                mr: {
                                                    xs: 0,
                                                    md: 2
                                                }
                                            }}
                                        />{' '}
                                        <Typography
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block'
                                                },
                                                fontSize: '16px',
                                                fontWeight: '900'
                                            }}
                                        >
                                            Exporting to CSV
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <DownloadIcon
                                            sx={{
                                                color: variables.WHITE,
                                                fontSize: {
                                                    xs: '25px',
                                                    md: '20px'
                                                },
                                                mr: {
                                                    xs: 0,
                                                    md: 1
                                                }
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block'
                                                },
                                                fontSize: '16px',
                                                fontWeight: '900'
                                            }}
                                        >
                                            Export to CSV
                                        </Typography>
                                    </Box>
                                )
                            }
                            color='primary'
                            variant='contained'
                            disabled={downloadExcel}
                            size='large'
                            sx={{ p: { xs: 1, sm: 2 } }}
                            onClick={e => {
                                e.preventDefault()
                                setDownloadExcel(true)
                                exportCsvMutation({
                                    variables: {
                                        requestType: 'engagement-questions'
                                    }
                                })
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{my: 3}}>
                    <Tabs
                        onChange={handleIndexChange}
                        textColor='inherit'
                        value={false}
                        sx={{ '&.MuiTabs-root .MuiTabs-flexContainer': { flexWrap: 'wrap' } }}
                    >
                        <CustomTab
                            elementIndex={0}
                            currentIndex={index}
                            tabTitle={`Question wise (${getQuestionResponseCount})`}
                            tabRootSx={{ mr: { xs: 0, sm: 1 } }}
                            handleIndexChange={handleIndexChange}
                        />

                        <CustomTab
                            elementIndex={1}
                            currentIndex={index}
                            tabTitle={`Candidate wise (${getCandidateResponseDataCount})`}
                            handleIndexChange={handleIndexChange}
                        />
                    </Tabs>
                    <Box>
                        <TabPanel value={index} index={0} dir={theme.direction}>
                            <EngagementQuestionList />
                        </TabPanel>
                        <TabPanel value={index} index={1} dir={theme.direction}>
                            <CandidateResponseList />
                        </TabPanel>
                    </Box>
                </Box>
            </Container>}
        </Layout>
    )
}

export default EngagementQuestionCandidateResponses
