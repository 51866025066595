import React from 'react'
import { gql } from '@apollo/client'

export const GET_QUESTIONS_QUERY = gql`
    query GetQuestions($id: ID!) {
        getEngagemnetQuestions(id: $id) {
            id
            question
            engagementQuestionAnswers {
                id
                answer
            }
        }
    }
`

export const CANDIDATE_RESPONSE_MUTATION = gql`
    mutation CandidateEngagementQuestionResponse($userId: ID!, $questionId: ID!, $answerId: ID!) {
        candidateEngagementQuestionResponse(
            userId: $userId
            questionId: $questionId
            answerId: $answerId
        ) {
            id
        }
    }
`

export const GET_CANDIDATE_RESPONSE_QUERY = gql`
    query GetCandidateResponseData($afterCursor: String) {
        getCandidateResponseData(first: 8, after: $afterCursor) {
            edges {
                node {
                    id
                    name
                    email
                    candidateAvatar
                    candidateResponses {
                        id
                        createdAt
                        engagementQuestion {
                            id
                            question
                        }
                        engagementQuestionAnswer {
                            id
                            answer
                        }
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
        getCandidateResponseDataCount
    }
`

export const GET_QUESTIONS_LIST_QUERY = gql`
    query GetQuestionData($afterCursor: String) {
        getQuestionData(first: 8, after: $afterCursor) {
            edges {
                node {
                    id
                    question
                    isActive
                    totalResponses
                    engagementQuestionAnswers {
                        id
                        answer
                        responseCount
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
        getQuestionCount
    }
`

export const GET_QUESTIONS_COUNT_DATA = gql`
    query GetQuestionsCountData{
        getCandidateResponseDataCount
        getQuestionResponseCount
    }
`
