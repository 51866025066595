import Layout from '../../../template/Layout'
import { Box, Container, Grid} from '@mui/material'
import {
    TableHeading,
    Subtitle1Typography,
} from '../../../base/commonStyles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import PageMetaTags from '../../../base/PageMetaTags'
import Icons from '../../../components/Icons'
import BACK from '../../../assets/icons/BACK.svg'
import { useNavigate } from 'react-router-dom' 


const Imprint = () => {
    // const { loginDetails, loginInProgress, loginCheckAuthDetails } = useSelector(
    //     state => state.login
    // )
  const navigate = useNavigate()

    return (
        <Layout>
            <PageMetaTags title='Imprint | Bunton' />
            <Container
                disableGutters
                sx={{
                    margin: {
                        xs: '64px 20px',
                        sm: '64px 40px',
                        md: '64px 80px',
                        lg: '64px 80px',
                        xl: '64px 80px'
                    },
                    paddingBottom: '80px',
                    height: '100%'
                }}
            >
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box sx={{ display: 'flex', marginBottom: '28px' }}>
                            <TableHeading sx={{}}>Imprint</TableHeading>
                        </Box>
                        <Box>
                            <Subtitle1Typography
                                sx={{
                                    margin: '-3px 0px 32px 0px',
                                    width: {
                                        xs: 'min-content',
                                        sm: 'auto',
                                        md: 'auto',
                                        lg: 'auto'
                                    }
                                }}
                            >
                                <p class='p_tag'>
                                    {' '}
                                    Responsible for content according to § 55 Abs. 2 RStV:
                                    <List>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            bunton GmbH
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Deutschherrnufer 47
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            D-60594 Frankfurt am Main
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Tel. +49 176 728 53 156
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            Geschäftsführer: Janet Winkler und Debjit D. Chaudhuri
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            welcome@bunton.de
                                            <br />
                                            <a href='https://www.bunton.de'>www.bunton.de</a>
                                        </ListItem>
                                    </List>
                                </p>
                                <p class='p_tag'>
                                    Sales tax identification number according to § 27 a sales tax
                                    law:
                                    <br />
                                    DE351896511, tax office Frankfurt am Main, Commercial Court of Frankfurt HRB126865
                                </p>
                                <p class='p_tag'>
                                    This imprint also applies to the following social media
                                    channels:
                                </p>
                                <p class='p_tag'>
                                    Linkedin:{' '}
                                    <a href='https://www.linkedin.com/company/bunton-gmbh/'>
                                        https://www.linkedin.com/company/bunton-gmbh
                                    </a>
                                </p>
                                Image Sources:
                                <p class='p_tag'>iStockphoto LP</p>
                                References and links
                                <p class='p_tag'>
                                    In the case of direct or indirect references to third-party
                                    websites ("links") that are outside the publisher's area of
                                    ​​responsibility, liability would only come into effect if the
                                    publishers were aware of the content and it was technically
                                    possible and reasonable for them to do so to prevent the use of
                                    illegal content. The publisher therefore expressly declares that
                                    the corresponding linked pages were free of illegal content at
                                    the time the link was created. The publisher has no influence
                                    whatsoever on the current and future design and on the content
                                    of the linked/connected pages. He therefore hereby expressly
                                    distances himself from all content on all linked / connected
                                    pages. This statement applies to all links and references set
                                    within our own website as well as to third-party entries in
                                    guest books, discussion forums and mailing lists set up by the
                                    publisher. The provider of the page to which reference is made
                                    is solely liable for illegal, incorrect or incomplete content
                                    and in particular for damage resulting from the use or non-use
                                    of such information, not the person who merely refers to the
                                    respective publication via links.
                                </p>
                                Copyright and Trademark Law
                                <p class='p_tag'>
                                    The publisher endeavors to observe the copyrights of the
                                    photographs, graphics, sound documents, video sequences and
                                    texts used in all publications, to use photographs, graphics,
                                    sound documents, video sequences and texts he has created
                                    himself or to refer to license-free photographs, graphics, sound
                                    documents, video sequences and access texts. All brands and
                                    trademarks mentioned within the website and possibly protected
                                    by third parties are subject without restriction to the
                                    provisions of the applicable trademark law and the property
                                    rights of the respective registered owner. The conclusion that
                                    trademarks are not protected by the rights of third parties
                                    should not be drawn solely on the basis of the mere mention!
                                </p>
                                Legal validity of this disclaimer
                                <p class='p_tag'>
                                    This disclaimer / exclusion of liability is to be regarded as
                                    part of the internet offer from which reference was made to this
                                    page. If parts or individual formulations of this text do not,
                                    no longer or not completely correspond to the applicable legal
                                    situation, the remaining parts of the document remain unaffected
                                    in their content and validity.
                                </p>
                                Miscellaneous
                                <p class='p_tag'>
                                    If someone feels their rights have been violated by
                                    publications, naming or mentioning trademarks, please inform the
                                    publisher of this site so that they can make appropriate changes
                                    to the content.
                                </p>
                            </Subtitle1Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Imprint
