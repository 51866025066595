import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import BuntonRocketImage from '../../assets/images/BUN_Rocket.png'
import { SectionContainer } from '../../pages/HomePage'

const LandingPageJoinUs = props => {
    return (
        <SectionContainer maxWidth='xl' disableGutters>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{textAlign: 'center'}}>
                    <Box component='img' src={BuntonRocketImage} alt='rep' sx={{
                        width: {
                            xs: '250px',
                            sm: '350px',
                            md: '300px',
                            lg: '350px',
                            xl: '400px'
                        }
                    }}/>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{display:{
                    xs:'flex',
                    md: 'block'
                },
                justifyContent: 'center'}}>
                    <Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                pb: {
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 5
                                }
                            }}
                        >
                            <Typography variant='h1' className='section-heading'>
                                Begleiten Sie uns auf dem Weg zu
                            </Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    py: {
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 5
                                    },
                                    pl: { xs: 0, md: 4, lg: 8 },
                                    display:{
                                        xs:'flex',
                                        md: 'block'
                                    },
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    variant='h3'
                                    className='poppins-light'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    aktuell
                                    <Typography
                                        variant='h2'
                                        className='extrabold cyan-color'
                                        sx={{
                                            mx: 1
                                        }}
                                    >
                                        300
                                    </Typography>{' '}
                                    offene Führungsposition
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    py: {
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 5
                                    },
                                    pl: { xs: 0, md: 8, lg: 15 },
                                    display:{
                                        xs:'flex',
                                        md: 'block'
                                    },
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    variant='h3'
                                    className='poppins-light'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    über{' '}
                                    <Typography
                                        variant='h2'
                                        className='extrabold cyan-color'
                                        sx={{
                                            mx: 1
                                        }}
                                    >
                                        70%
                                    </Typography>{' '}
                                    Erfolgsquote bei Ihrer Bewerbung
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    py: {
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 5
                                    },
                                    display:{
                                        xs:'flex',
                                        md: 'block'
                                    },
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    variant='h3'
                                    className='poppins-light'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    mehr als{' '}
                                    <Typography
                                        variant='h2'
                                        className='extrabold cyan-color'
                                        sx={{
                                            mx: 1
                                        }}
                                    >
                                        800
                                    </Typography>{' '}
                                    Mitgliedsunternehmen
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </SectionContainer>
    )
}

export default LandingPageJoinUs
