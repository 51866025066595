import React from 'react'
import PageMetaTags from '../../../base/PageMetaTags'
import CompaniesList from '../../../components/CompaniesList'
import Layout from '../../../template/Layout'
import { Container } from '@mui/material'

const Companies = props => {
    return (
        <Layout>
            <PageMetaTags title='Companies | Bunton' />
            <Container maxWidth='lg'>
                <CompaniesList listAccess='admin' />
            </Container>
        </Layout>
    )
}

export default Companies
