import {
    Box,
    CircularProgress,
    Chip,
    Container,
    Grid,
    styled,
    Typography,
    Snackbar
} from '@mui/material'
import './styles.scss'

import { Button } from '..'
import CompanyRow from './CompanyRow'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import UserRestriction from '../UserRestriction'
import NoResultsFound from '../NoResultsFound'
import Loader from '../Loader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import variables from '../../settings/_variables.scss'
import { X } from 'react-feather'
import { companyStrength } from '../../utils/constantData'
import CompanySearch from '../CompanySearch'
import LoadMoreLink from '../elements/LoadMoreLink'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DownloadIcon from '@mui/icons-material/Download'
import { EXPORT_CSV_MUTATION } from '../../queries/commonQueries'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { renameNoPreferenceEntry } from '../../utils/commonFunctions'

const COMPANY_QUERY = gql`
    query Companies(
        $afterCursor: String
        $keyword: String
        $locationIds: String
        $industryIds: String
        $noOfEmployees: String
    ) {
        companies(
            first: 8
            after: $afterCursor
            keyword: $keyword
            locationIds: $locationIds
            industryIds: $industryIds
            noOfEmployees: $noOfEmployees
        ) {
            edges {
                node {
                    id
                    jobCount
                    companyName
                    noOfEmployees
                    companyLogoUrl
                    otherIndustry
                    states {
                        id
                        name
                    }
                    industries {
                        id
                        name
                    }
                    user {
                        id
                        profileImage
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
        companiesCount(
            keyword: $keyword
            locationIds: $locationIds
            industryIds: $industryIds
            noOfEmployees: $noOfEmployees
        )
    }
`
const StyledChip = styled(Chip)`
    padding: 16px;
    margin: 6px 16px 0px 0px;
    background-color: ${variables.WHITE1};
    border-color: ${variables.WHITE1};
    color: ${variables.BLUE};
    font-size: 15px;
`

const CompaniesList = props => {
    const { listAccess } = props
    const [listLoader, setListLoader] = useState(false)
    const [filterEnable, setFilterEnable] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { allStates, allIndustries } = useSelector(state => state.initial)
    let allIndustriesData = renameNoPreferenceEntry(allIndustries)
    let state = location.state
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const [companiesCount, setCompaniesCount] = useState(0)
    const { loading, data, fetchMore, refetch } = useQuery(COMPANY_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: '',
            ...state
        },
        onCompleted(data) {
            // setCompanies(data.companies)
            setFilterEnable(false)
            setListLoader(false)
            setCompaniesCount(data.companiesCount)
        },
        onError(error) {}
    })
    const [exportCsvMutation, { loading: saveLoading }] = useMutation(EXPORT_CSV_MUTATION, {
        onCompleted(data) {
            setExportLoading(false)
            setShowToast(true)
        },
        onError(error) {}
    })

    const companiesHeader = (companiesCount) => {
        return (
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    marginBottom: '50px',
                    width: 'auto',
                    minHeight: '60px'
                }}
            >
                <Grid container alignItems='center'>
                    <Grid item md={6} sm={6} xs={6}>
                        <Typography variant='h2' className='strong'>
                            Companies ({companiesCount})
                        </Typography>
                    </Grid>
                    {listAccess === 'admin' ? (
                        <Grid item md={6} sm={6} xs={6} sx={{textAlign: 'right'}}>
                            <Box sx={{display: 'flex', justifyContent:'end'}}>
                                <Button
                                label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddCircleOutlineIcon
                                                sx={{
                                                    color: variables.WHITE,
                                                    fontSize: {
                                                        xs: '25px',
                                                        md: '20px'
                                                    }, mr: {
                                                        xs: 0, md: 1
                                                    }
                                                }}
                                            />
                                            <Typography sx={{ display: {
                                                            xs: 'none',
                                                            md: 'block'
                                                        },fontSize: '16px', fontWeight: '900' }}>
                                                Add New Company
                                            </Typography>
                                        </Box>
                                    }
                                    component={Link}
                                    to='/admin-portal/add-company'
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{p: '14px 20px', fontSize: '16px', mr: 1}}
                                />
                                <Button
                                    label={
                                        exportLoading ? (
                                            <Box sx={{alignItems:'center', display: 'flex'}}>
                                                <CircularProgress
                                                    size={20}
                                                    sx={{ color: variables.BLUE, mr: {
                                                            xs: 0,
                                                            md: 2
                                                        } }}
                                                />{' '}
                                                <Typography
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block'
                                                        },
                                                        fontSize: '16px',
                                                        fontWeight: '900'
                                                    }}
                                                >
                                                    Exporting to CSV
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <DownloadIcon
                                                    sx={{
                                                        color: variables.WHITE,
                                                        fontSize: {
                                                            xs: '25px',
                                                            md: '20px'
                                                        },
                                                        mr: {
                                                            xs: 0,
                                                            md: 1
                                                        }
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block'
                                                        },
                                                        fontSize: '16px',
                                                        fontWeight: '900'
                                                    }}
                                                >
                                                    Export to CSV
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{ p: '14px 20px', alignItems: 'center' }}
                                    disabled={exportLoading}
                                    onClick={e => {
                                        e.preventDefault()
                                        setExportLoading(true)
                                        exportCsvMutation({
                                            variables: {
                                                requestType: 'company'
                                            }
                                        })
                                    }}
                                />
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
                {listAccess === 'admin' && <CompanySearch listAccess={listAccess} sx={{mb: 0}}/>}
            </Container>
        )
    }

    if (loading)
        return (
            <>
                <Loader />
            </>
        )
    const companies = data.companies.edges.map(edge => edge.node)
    const pageInfo = data.companies.pageInfo

    const appliedFilters = () => {
        return state &&
            (state.keyword || state.industryIds || state.locationIds || state.noOfEmployees) ? (
            <Grid container sx={{ paddingBottom: '40px' }}>
                <Grid item xs={9}>
                    {state && state.keyword && (
                        <StyledChip
                            icon={<X color={variables.BLUE} />}
                            label={state.keyword}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess==='admin' ? '/admin-portal/companies' : '/companies', { state: { ...state, keyword: '' } })
                            }}
                        />
                    )}
                    {state && state.locationIds && (
                        <StyledChip
                            icon={<X color={variables.BLUE} />}
                            label={allStates.find(obj => obj.id === state.locationIds)?.name}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess==='admin' ? '/admin-portal/companies' : '/companies', { state: { ...state, locationIds: '' } })
                            }}
                        />
                    )}
                    {state && state.industryIds && (
                        <StyledChip
                            icon={<X color={variables.BLUE} />}
                            label={allIndustriesData.find(obj => obj.id === state.industryIds)?.name}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess==='admin' ? '/admin-portal/companies' : '/companies', { state: { ...state, industryIds: '' } })
                            }}
                        />
                    )}
                    {state && state.noOfEmployees && (
                        <StyledChip
                            icon={<X color={variables.BLUE} />}
                            label={
                                companyStrength.find(obj => obj.value === state.noOfEmployees)
                                    ?.label
                            }
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess==='admin' ? '/admin-portal/companies' : '/companies', { state: { ...state, noOfEmployees: '' } })
                            }}
                        />
                    )}
                </Grid>
                {state &&
                    (state.keyword ||
                        state.industryIds ||
                        state.locationIds ||
                        state.noOfEmployees) && (
                        <Grid
                            item
                            xs={12}
                            textAlign={responsiveBreakpoint === 'mob' ? 'left' : 'right'}
                        >
                            <StyledChip
                                icon={<X color='red' />}
                                label={'Clear all filters'}
                                onClick={() => {
                                    setFilterEnable(false)
                                    navigate(listAccess==='admin' ? '/admin-portal/companies' : '/companies', {
                                        state: {
                                            ...state,
                                            keyword: '',
                                            industryIds: '',
                                            locationIds: '',
                                            noOfEmployees: ''
                                        }
                                    })
                                }}
                            />
                        </Grid>
                    )}
            </Grid>
        ) : null
    }

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowToast(false)
    }
    return (
        <Container maxWidth='xl'>
            {/* {listAccess === 'admin'
                ? companiesHeader(companiesCount, jobCount, listAccess)
                : companiesSearchBar(control, handleSubmit, onSubmit)} */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showToast}
                autoHideDuration={6000}
                onClose={handleToastClose}
            >
                <Box
                    sx={{
                        backgroundColor: variables.BLUE,
                        width: '300px',
                        p: 2,
                        borderRadius: '20px'
                    }}
                >
                    <Grid
                        container
                        columnSpacing={1}
                        alignItems='flex-start'
                        color={variables.WHITE}
                        sx={{alignItems:'center'}}
                    >
                        <Grid item md={1}>
                            <CheckCircleIcon sx={{ width: '20px', mt: '6px' }} />
                        </Grid>
                        <Grid item md={11}>
                            <strong>E-mail sent to admin.</strong>
                        </Grid>
                    </Grid>
                </Box>
            </Snackbar>
            <Box
                sx={{
                    // display: 'flex',
                    position:
                        !['admin', 'employer'].includes(userRole) && responsiveBreakpoint === 'mob'
                            ? 'relative'
                            : null
                }}
            >
                {companiesHeader(companiesCount)}
            </Box>
            
            <Grid container>
                {/* {listAccess === 'admin'
                    ? companiesSearchBar(control, handleSubmit, onSubmit)
                    : companiesHeader(companiesCount, jobCount, listAccess)} */}

                <Grid item md={userRole === 'admin' ? 12 : 12} xs={12} sm={12}>
                    {appliedFilters()}
                    {companies.length > 0 ? (
                        companies.map(data => (
                            <CompanyRow
                                data={data}
                                refetch={refetch}
                                listAccess={listAccess}
                                userRole={userRole}
                            />
                        ))
                    ) : (
                        <NoResultsFound content='No Company found.' />
                    )}
                    {isUserAuthenticated && pageInfo.hasNextPage ? (
                        listLoader ? (
                            <Box
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        height: '20px',
                                        width: '20px',
                                        color: variables.BLUE
                                    }}
                                />
                            </Box>
                        ) : (
                            <LoadMoreLink
                                sx={{ mb: responsiveBreakpoint === 'mob' ? '35px' : null }}
                                onClick={() => {
                                    if (pageInfo.hasNextPage) {
                                        setListLoader(true)
                                        fetchMore({
                                            variables: {
                                                afterCursor: pageInfo.endCursor
                                                    ? pageInfo.endCursor
                                                    : ''
                                            }
                                        })
                                    }
                                }}
                            />
                        )
                    ) : null}
                </Grid>
            </Grid>
            {isUserAuthenticated ? null : <UserRestriction />}
        </Container>
    )
}

export default CompaniesList
