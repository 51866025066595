import { gql } from '@apollo/client'

export const CANDIDATE_JOB_DETAILS_QUERY = gql`
    query JobDetails($id: ID!) {
        jobPost(id: $id) {
            id
            jobTitle
            candidateApplied
            jobDescription
            companyName
            companyLogoUrl
            companyId
            companyUserId
            experienceRequired
            externalLink
            invitedToApply
            isLiked
            likes
            otherDepartment
            otherIndustry
            otherBenefits
            status
            createdAt
            industries {
                id
                name
            }
            departments {
                id
                name
            }
            states {
                id
                name
            }
            benefits {
                id
                name
            }
            company {
                id
                companyWebsite
                noOfEmployees
                isConnectionRequested
                benefits {
                    id
                    name
                }
            }
            candidates {
                id
                userId
                name
            }
            jobTypes {
                id
                name
            }
        }
    }
`

export const JOB_DETAILS_QUERY = gql`
    query JobDetails($id: ID!) {
        jobPost(id: $id) {
            id
            jobTitle
            candidateApplied
            jobDescription
            companyName
            companyLogoUrl
            companyId
            companyUserId
            experienceRequired
            noOfCandidatesApplied
            externalLink
            otherDepartment
            otherIndustry
            otherBenefits
            likes
            status
            createdAt
            jobTypes {
                id
                name
            }
            industries {
                id
                name
            }
            departments {
                id
                name
            }
            states {
                id
                name
            }
            benefits {
                id
                name
            }
            company {
                id
                companyWebsite
                noOfEmployees
                isConnectionRequested
                benefits {
                    id
                    name
                }
            }
            candidates {
                id
                userId
                name
                isUserInvited
                isUserConnected
            }
        }
    }
`

export const DELETE_JOB_MUTATION = gql`
    mutation ManageJobPost($id: ID, $actionType: String!, $status: String!) {
        manageJobPost(id: $id, actionType: $actionType, status: $status) {
            actionType
        }
    }
`

export const APPLY_JOB_MUTATION = gql`
    mutation ApplyJob($jobId: ID!, $userId: ID!) {
        applyJob(jobId: $jobId, userId: $userId) {
            id
            hasResume
        }
    }
`

export const LIKE_JOB_MUTATION = gql`
    mutation LikeJobPost($jobId: ID!, $userId: ID!) {
        likeJobPost(jobId: $jobId, userId: $userId) {
            id
        }
    }
`

export const MANAGE_JOB_DETAILS_QUERY = gql`
    query JobDetails($id: ID!) {
        jobPost(id: $id) {
            id
            jobTitle
            candidateApplied
            jobDescription
            externalLink
            companyName
            companyId
            experienceRequired
            status
            createdAt
            jobTypes {
                id
                name
            }
            otherIndustry
            industries {
                id
                name
            }
            states {
                id
                name
            }
            company {
                id
                companyName
            }
            departments {
                id
                name
            }
            otherDepartment
            benefits {
                id
                name
            }
            otherBenefits
        }
    }
`


export const MANAGE_JOB_POST_MUTATION = gql`
    mutation ManageJobPost(
        $id: ID
        $jobTitle: String
        $jobDescription: String
        $companyId: ID
        $isActive: Boolean
        $jobTypeIds: String
        $language: String
        $isCompanyHidden: Boolean
        $experienceRequired: String
        $industryIds: String
        $otherIndustry: String
        $departmentIds: String
        $otherDepartment: String
        $stateIds: String!
        $externalLink: String
        $benefitIds: String
        $otherBenefits: String
        $actionType: String!
        $status: String!
    ) {
        manageJobPost(
            id: $id
            jobTitle: $jobTitle
            jobDescription: $jobDescription
            companyId: $companyId
            isActive: $isActive
            jobTypeIds: $jobTypeIds
            language: $language
            isCompanyHidden: $isCompanyHidden
            experienceRequired: $experienceRequired
            industryIds: $industryIds
            otherIndustry: $otherIndustry
            departmentIds: $departmentIds
            otherDepartment: $otherDepartment
            stateIds: $stateIds
            externalLink: $externalLink
            benefitIds: $benefitIds
            otherBenefits: $otherBenefits
            actionType: $actionType
            status: $status
        ) {
            job {
                id
                language
                status
                jobTypes{
                    id
                    name
                }
                jobTitle
                departments {
                    id
                    name
                }
                otherDepartment
                industries {
                    id
                    name
                }
                otherIndustry
                states {
                    id
                    name
                }
                benefits{
                    id
                    name
                }
                otherBenefits
                experienceRequired
                jobDescription
            }
        }
    }
`

export const MANAGE_JOB_POST_COMPANY_QUERY = gql`
    query Companies($afterCursor: String, $keyword: String, $showLatest: Boolean) {
        companies(first: 20, after: $afterCursor, keyword: $keyword, showLatest: $showLatest) {
            edges {
                node {
                    id
                    companyName
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

export const INVITE_RESPONSE_MUTATION = gql`
    mutation ConnectInviteResponse($jobId: ID!, $response: String!) {
        connectInviteResponse(jobId: $jobId, response: $response) {
            id
        }
    }
`