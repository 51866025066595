import { Box } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import { Close } from '@mui/icons-material'

const ModalCloseButton = props => {
    const {handleClose} = props
    return (
        <Box
            sx={{
                bgcolor: variables.ORANGE,
                p: 2,
                width: 'fit-content',
                borderRadius: '50px',
                textAlign: 'center',
                cursor: 'pointer',
                float: 'right'
            }}
            onClick={handleClose}
        >
            <Close
                sx={{
                    color: variables.WHITE,
                    verticalAlign: 'middle'
                }}
            />
        </Box>
    )
}

export default ModalCloseButton