import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const PasswordValidationSection = props => {
    const { value } = props
    const [containsNumber, setContainsNumbers] = useState(false)
    const [containsSymbols, setContainsSymbols] = useState(false)
    const [containsAlphabet, setContainsAlphabet] = useState(false)
    const [passwordLength, setPasswordLength] = useState(false)

    const checkNumbers = string => {
        const matches = string.match(/\d+/g)
        setContainsNumbers(matches !== null ? true : false)
    }

    const checkAlphabate = string => {
        const matches = string.match(/[A-Za-z]/)
        setContainsAlphabet(matches !== null ? true : false)
    }

    const checkSymbols = string => {
        const symbols = new RegExp(/[@$!%*#?&]/)
        setContainsSymbols(symbols.test(string) ? true : false)
    }

    useEffect(() => {
        checkNumbers(value)
        checkAlphabate(value)
        checkSymbols(value)
        setPasswordLength(value.length < 6 ? false : true)
    }, [value])

    const getValidationSection = (iconStatus, text) => {
        return (
            <Box sx={{ display: 'flex', textAlign: 'left' }}>
                {iconStatus ? (
                    <CheckCircleIcon sx={{ width: '15px' }} />
                ) : (
                    <CancelIcon sx={{ width: '15px' }} />
                )}
                <Typography sx={{ ml: 1 }}>{text}</Typography>
            </Box>
        )
    }

    return (
        <>
            {value !== '' ? (
                <Box
                    sx={{
                        border: `1px solid ${
                            passwordLength && containsAlphabet && containsNumber && containsSymbols
                                ? '#008900'
                                : '#ff0000'
                        }`,
                        borderRadius: '25px',
                        p: 1,
                        mt: 2,
                        maxWidth: '100%'
                    }}
                >
                    <Grid container spacing={1} sx={{ mt: 2, fontSize: ' 14px' }}>
                        <Grid
                            item
                            xl={6}
                            sm={6}
                            xs={12}
                            sx={{ color: passwordLength ? '#008900' : '#ff0000' }}
                        >
                            {getValidationSection(passwordLength, 'Minimum 6 character')}
                        </Grid>
                        <Grid
                            item
                            xl={6}
                            sm={6}
                            xs={12}
                            sx={{ color: containsAlphabet ? '#008900' : '#ff0000' }}
                        >
                            {getValidationSection(containsAlphabet, 'Minimum one alphabet ')}
                        </Grid>
                        <Grid
                            item
                            xl={6}
                            sm={6}
                            xs={12}
                            sx={{ color: containsNumber ? '#008900' : '#ff0000' }}
                        >
                            {getValidationSection(containsNumber, 'Minimum one number')}
                        </Grid>
                        <Grid
                            item
                            xl={6}
                            sm={6}
                            xs={12}
                            sx={{ color: containsSymbols ? '#008900' : '#ff0000' }}
                        >
                            {getValidationSection(
                                containsSymbols,
                                <>
                                    Include at least one of the special characters <b>@$!%*#?&</b>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            ) : null}
        </>
    )
}

export default PasswordValidationSection
