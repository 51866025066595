import React from 'react'
import PageMetaTags from '../../../base/PageMetaTags'
import { useParams } from 'react-router-dom'
import AddCompanyComponent from './AddCompanyComponent'
import Layout from '../../../template/Layout'

const AddCompany = props => {
    let urlParams = useParams()
    const isEditPage = urlParams.companyId ? true : false

    return (
        <Layout>
            <PageMetaTags title={`${isEditPage ? 'Edit' : 'Add New'} Company | Bunton`} />
            <AddCompanyComponent />
        </Layout>
    )
}

export default AddCompany
