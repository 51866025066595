import { Box, FormControl, FormGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import ControlledTextInput from './ControlledTextInput'
import CustomCheckboxChip from '../elements/CustomCheckboxChip'

const ControlledCheckboxGroup = props => {
    const {errors, otherFieldErrors, options, resetField} = props
    const filterOthersData = (options) => {
        const othersCount = props.handleMutliSelect ? options.filter(data => data.name === 'Others') : options.filter(data => data.value === 'Others')
        return othersCount
    }
    
    let othersObj = filterOthersData(options)
    let otherFieldName= `other${props.name.replace(/^./, props.name[0].toUpperCase())}`
    const [isOther, setIsOther] = useState(false)
    useEffect(() => {
        if(!isOther && resetField) {
            resetField()
        }
    }, [isOther])
    return <Box>
        <Box {...props.register(props.name)}>
            <Controller
                control={props.control}
                rules={{...props.rules}}
                render={({ field }) => {
                    setIsOther(othersObj.length > 0 && field.value && (( props.handleMutliSelect && ((props.handleByName && field.value.includes(othersObj[0].name)) || field.value.includes(othersObj[0].id))) || field.value.includes(othersObj[0].name)))
                    return (
                    <Box>
                        <FormControl error={errors ?true :false}>
                            <FormGroup>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {options.map((option, index) => {
                                        let val = props.handleMutliSelect ? props.handleByName ? option.name : option.id : option.value
                                        const {onChange, value} = field
                                        return (
                                        <CustomCheckboxChip
                                            index={index}
                                            key={index}
                                            register={props.register}
                                            label={option.name}
                                            name={props.name[val]}
                                            value={val}
                                            checked={props.handleMutliSelect ? props.handleByName ? value && value.includes(option.name) : value && value.includes(option.id) : value && value.includes(option.value)}
                                        
                                            onChange={e => {
                                                onChange(props.handleMutliSelect(val, props.name))
                                            }}
                                        />
                                    )})}
                                </Box>
                            </FormGroup>
                            
                            {/* {errors ? (
                                <FormHelperText id='my-helper-text'>
                                    {errors}
                                </FormHelperText>
                            ) : null} */}
                        </FormControl>
                    </Box>
                )}}
                name={props.name}
            />
        </Box>
        {
            isOther ? 
                <Box sx={{mt: 4}} {...props.register(otherFieldName)}>
                    <ControlledTextInput
                        control={props.control}
                        rules={{...props.otherFieldRules}}
                        name={otherFieldName}
                        type='text'
                        placeholder='Others'
                        errors={otherFieldErrors}
                        required={true}
                        fullWidth={true}
                        formContolSx={{width: '100%'}}
                        withoutLable={true}
                        register={props.register}
                    />
                </Box>

            : null
        }
    </Box>
}

export default ControlledCheckboxGroup