import { Tab } from '@mui/material'
import React from 'react'
import { a11yProps } from '../../utils/commonFunctions'
import variables from '../../settings/_variables.scss'
import { StyledStatusChip } from '../../base/commonStyles'
import NotificationBadge from './NotificationBadge'

const CustomTab = props => {
    const { notificationBadge, currentIndex, elementIndex, tabTitle } = props
    return (
        <Tab
            sx={{
                opacity: 1,
                ...props.tabSx,
                '&.MuiTab-root': {
                    p: 0,
                    width: {
                        xs: '48%',
                        sm: 'auto'
                    },
                    mr: 1,
                    maxWidth:'none',
                    ...props.tabRootSx
                }
            }}
            onClick={(e) => {
                props.handleIndexChange(e, elementIndex)
            }}
            label={
                <NotificationBadge showBadge={notificationBadge} content=''>
                    <StyledStatusChip
                        label={tabTitle}
                        variant='outlined'
                        sx={{
                            cursor: 'pointer',
                            width: '100%',

                            fontFamily:
                                elementIndex === currentIndex
                                    ? variables.poppinsExtraBold
                                    : variables.poppinsRegular,
                            fontWeight: elementIndex === currentIndex ? '600' : '',
                            backgroundColor: variables.CYAN1
                        }}
                        onClick = {props.onClick}
                    />
                </NotificationBadge>
            }
            disableFocusRipple={true}
            disableRipple={true}
            {...a11yProps(elementIndex)}
        />
    )
}

export default CustomTab
