import React from 'react'
import { SectionContainer } from '../../pages/HomePage'
import { Box, Grid, Typography } from '@mui/material'
import { companiesLogo } from '../../constants/TextContent'
import variables from '../../settings/_variables.scss'

const CompaniesNetwork = props => {
    return <SectionContainer maxWidth='xl' disableGutters>
        <Box sx={{mb: {
            xs: 4, md: 6,
            xl: 8
        }}}>
            <Typography variant='h1' className='extrabold' sx={{textAlign:'center'}}>
            Unternehmen in unserem Netzwerk
            </Typography>
        </Box>
        <Box>
            <Grid container spacing={{xs: 2, sm: 4, md: 6}}>
                {
                    companiesLogo.map((elem, index) => {
                        return <Grid item xs={4} sm={3} md={3} lg={2.4} xl={2.4}>
                            <Box component='img' src={elem.src} key={index} alt={elem.src} sx={{maxWidth:'197px', width: '100%', border:`2px solid ${variables.CYAN}`, borderRadius: '60px'}}/>
                        </Grid>
                    })
                }
            </Grid>
        </Box>
    </SectionContainer>
}

export default CompaniesNetwork