import { FormControl, FormHelperText } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import TextInput from '../TextInput'

const ControlledTextInput = props => {
    return <Controller
        control={props.control}
        rules={{...props.rules}}
        render={({ field: { onChange, value } }) => (
            <>
                <FormControl error={props.errors ? true : false} sx={{...props.formContolSx}} >
                    <TextInput
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        onChange={onChange}
                        formFieldError={props.errors}
                        required={props.required}
                        value={value}
                        sx={{...props.sx}}
                        fullWidth={props.fullWidth}
                        disabled={props.disabled}
                        inputProps={{...props.inputProps}}
                        InputProps={{...props.InputProps}}
                        multiline={props.multiline}
                        rows={props.rows}
                        withoutLable={props.withoutLable}
                    />
                </FormControl>
                {props.withoutLable && props.errors ? (
                    <FormHelperText id='my-helper-text' error={true} sx={{ml:2}}><strong>{props.errors}</strong></FormHelperText>
                ) : null}
            </>
        )}
        name={props.name}
    />
}

export default ControlledTextInput

