import {
    Box,
    createTheme,
    FormControl,
    getOffsetLeft,
    InputAdornment,
    MenuItem,
    Select,
    styled,
    ThemeProvider
} from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import { truncateDetails } from '../../utils/commonFunctions'
import DownArrow from '../../assets/icons/ARROW DOWN.svg'
import { Controller } from 'react-hook-form'

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    zIndex: '2'
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingRight: '0px !important',
                    paddingLeft: '15px',
                    fontSize: '18px',
                    color: variables.BLUE
                },
                notchedOutline: {
                    border: '3px solid !important',
                    borderColor: `${variables.CYAN} !important`,
                    borderRadius: '50px !important'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    width: 290,
                    maxHeight: '290px !important',
                    border: `3px solid ${variables.CYAN}`,
                    borderTop: '0',
                    boxShadow: 'none',
                    zIndex: 99
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionStart: {
                    marginLeft: '30px'
                },
                positionEnd: {
                    position: 'relative',
                    right: '30px',
                    zIndex: '1'
                }
            }
        }
    }
})

const StyledMenuItems = styled(MenuItem)`
    padding: 6px 30px;
    text-align: right;
    font-size: 18px;
    color: ${variables.BLUE};
`

const ControlledSelectBox = props => {
    const { control, name, rules, options } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const handleClose = () => {
        setAnchorEl(null)
        setDropdownOpen(false)
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <Controller
                    control={control}
                    name={name}
                    rules={{ ...rules }}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <FormControl
                                error={props.errors ? true : false}
                                sx={{
                                    ...props.formControllerSx,
                                    m: 0,
                                    zIndex: 1,
                                    marginTop: '0px',
                                    background: variables.WHITE1,
                                    borderRadius: '50px'
                                }}
                            >
                                <Select
                                    variant='outlined'
                                    onChange={onChange}
                                    onClose={handleClose}
                                    onOpen={e => {
                                        setAnchorEl(e.currentTarget)
                                        setDropdownOpen(true)
                                    }}
                                    displayEmpty={true}
                                    defaultValue={props.lab}
                                    placeholder={props.placeholder}
                                    value={value !== '' ? value : props.lab}
                                    renderValue={value => {
                                        if (options && options.length > 0) {
                                            const item = options.filter(
                                                val =>
                                                    val.id === value ||
                                                    val.value === value ||
                                                    val.name === value
                                            )
                                            return (
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Box
                                                        sx={{
                                                            position: 'relative',
                                                            margin: '0px 5px 0px 10px'
                                                        }}
                                                    >
                                                        {props.startAdornment}
                                                    </Box>
                                                    {value
                                                        ? item[0]
                                                            ? truncateDetails(
                                                                item[0].name
                                                                    ? item[0].name
                                                                    : item[0].label,
                                                                19
                                                            )
                                                            : value
                                                        : props.lab}
                                                </Box>
                                            )
                                        }
                                    }}
                                    IconComponent={() => (
                                        <InputAdornment position='end'>
                                            <Box sx={{ position: 'relative' }}>
                                                <Box
                                                    component='img'
                                                    src={DownArrow}
                                                    className={
                                                        dropdownOpen ? 'dropdown--icon_open' : ''
                                                    }
                                                />{' '}
                                            </Box>
                                        </InputAdornment>
                                    )}
                                    MenuProps={{
                                        anchorOrigin: {
                                            horizontal: getOffsetLeft(anchorEl) + 170
                                        },
                                        PaperProps: {
                                            sx: {
                                                maxWidth: {
                                                    md: 200,
                                                    lg: 265,
                                                    xl: 290
                                                },
                                                width: {
                                                    md: 200,
                                                    lg: 265,
                                                    xl: 290
                                                },
                                                borderRadius: '30px',
                                                border: `3px solid ${variables.CYAN}`,
                                                backgroundColor: variables.WHITE1,
                                                paddingTop: '20px',
                                                paddingRight: '8px !important',
                                                paddingBottom: '20px'
                                            }
                                        },
                                        MenuListProps: {
                                            sx: {
                                                maxHeight: '250px !important',
                                                overflow: 'hidden',
                                                overflowY: 'scroll'
                                            }
                                        }
                                    }}
                                >
                                    <StyledMenuItems
                                        disabled
                                        value=''
                                        sx={{
                                            width: '290px',
                                            opacity: '1 !important',
                                            color: 'rgba(0, 0, 0, 0.16)',
                                            borderRadius: '30px',
                                            backgroundColor: variables.WHITE1,
                                            padding: '14px 30px'
                                        }}
                                    >
                                        {props.placeholder}
                                        <InputAdornment
                                            position='end'
                                            sx={{
                                                right: {
                                                    md: '50px',
                                                    lg: '40px',
                                                    xl: '30px'
                                                },
                                                position: 'absolute'
                                            }}
                                        >
                                            <Box>
                                                <Box
                                                    component='img'
                                                    src={DownArrow}
                                                    className={
                                                        dropdownOpen ? 'dropdown--icon_open' : ''
                                                    }
                                                />{' '}
                                            </Box>
                                        </InputAdornment>
                                    </StyledMenuItems>

                                    {options &&
                                        options.map((option, index) =>
                                            option.id ? (
                                                <StyledMenuItems value={option.id}>
                                                    {option.name}
                                                </StyledMenuItems>
                                            ) : (
                                                <StyledMenuItems value={option.value}>
                                                    {option.label}
                                                </StyledMenuItems>
                                            )
                                        )}
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </ThemeProvider>
        </>
    )
}

export default ControlledSelectBox