import React from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, Typography } from '@mui/material'
import NotificationListRow from '../molecules/NotificationListRow'
import variables from '../../settings/_variables.scss'
import { useQuery } from '@apollo/client'
import { GET_USER_NOTIFICATION_DETAIL_QUERY } from '../../queries/userDetailsQuery'
import DataLoadingLoader from '../molecules/DataLoadingLoader'

const UserNotificationModal = props => {
    const { open, onClose, candidateUserId } = props
    const { loading, data, error } = useQuery(GET_USER_NOTIFICATION_DETAIL_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            data: btoa(
                JSON.stringify({
                    user_id: candidateUserId
                })
            )
        }
    })
    return (
        <BaseModal open={open} onClose={onClose} modalTitle={<Typography variant='h3' className='small-size-18px'>{`Manage User Notification`}</Typography>} boxSx={{minHeight: 'auto'}}>
            {loading ? (
                <DataLoadingLoader showLoadingRow={1}/>
            ) : (
                <Box>
                    <NotificationListRow
                        userId={candidateUserId}
                        notification={{
                            id: 'all',
                            title: 'All Notifications',
                            description: <><strong>Note:</strong> Switching off this setting will stop all notifications except for OTP and Apply Job emails.</>,
                            userPreference: {
                                want_notification: data.userNotificationDetail
                            }
                        }}
                        index='all'
                        rowSx={{
                            background: variables.CYAN
                        }}
                    />
                </Box>
            )}
        </BaseModal>
    )
}

export default UserNotificationModal
