import { useMutation, useQuery } from '@apollo/client'
import {
    Box,
    Chip,
    CircularProgress,
    Collapse,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    Snackbar,
    Typography
} from '@mui/material'

import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../Button'
import NoResultsFound from '../NoResultsFound'
import TextInput from '../TextInput'
import UserRestriction from '../UserRestriction'
import JobListRow from './JobListRow'
import FindIcon from '../../assets/icons/SEARCH.svg'
import FilterIcon from '../../assets/icons/FILTER.svg'
import SuggestedJobsSideSection from '../SuggestedJobsSideSection'
import Loader from '../Loader'

import { experiencesList } from '../../utils/constantData'
import variables from '../../settings/_variables.scss'
import { X } from 'react-feather'
import MoreJobsSideSection from '../MoreJobsSideSection'
import Icons from '../Icons'
import JobSearch from '../JobSearch'
import { JOB_LIST_QUERY } from '../../queries/jobListQueries'
import { EXPORT_CSV_MUTATION } from '../../queries/commonQueries'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { HomePageButtons } from '../../pages/HomePage'
import DownloadIcon from '@mui/icons-material/Download'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import LoadMoreLink from '../elements/LoadMoreLink'
import { renameNoPreferenceEntry } from '../../utils/commonFunctions'

const jobSearchBar = (control, handleSubmit, onSubmit) => {
    return (
        <Grid container sx={{ marginBottom: '47px' }} spacing={1} alignItems='center'>
            <Grid item md={11} sm={10} xs={10}>
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl sx={{ width: '100%', marginRight: '15px' }}>
                            <TextInput
                                type='search'
                                name='keyword'
                                placeholder='Search'
                                onChange={onChange}
                                formFieldError=''
                                required={true}
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position='start'
                                            sx={{ marginLeft: '10px' }}
                                        >
                                            <Box sx={{ position: 'relative' }}>
                                                <Box component='img' src={FindIcon} />{' '}
                                            </Box>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    )}
                    name='keyword'
                />
            </Grid>
            <Grid item md={1} sm={2} xs={2}>
                <Button
                    label='Go'
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                        width: '100%',
                        padding: '13px 30px',
                        fontSize: '14px',
                        fontWeight: '600'
                    }}
                />
            </Grid>
        </Grid>
    )
}


const JobList = props => {
    const { listAccess } = props
    const [listLoader, setListLoader] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const location = useLocation()
    const [companiesCount, setCompaniesCount] = useState(0)
    const [jobCount, setJobCount] = useState(0)
    let state = location.state
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const [filterEnable, setFilterEnable] = useState(false)
    const { responsiveBreakpoint, allStates, allIndustries, allDepartments } = useSelector(state => state.initial)
    let allIndustriesData = renameNoPreferenceEntry(allIndustries)
    const [showToast, setShowToast] = useState(false)

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    if (userRole === 'employer') {
        state = { ...state, companyUserId: userId }
    }
    const { loading, data, fetchMore, refetch } = useQuery(JOB_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: isUserAuthenticated ? {
            first: 10,
            afterCursor: '',
            ...state
        } : {
            first: 30,
            afterCursor: '',
            ...state
        },
        onCompleted(data) {
            setFilterEnable(false)
            setListLoader(false)
            setCompaniesCount(data.companiesCount)
            setJobCount(data.getJobCounts)
        },
        onError(error) {}
    })

    const [exportCsvMutation, { loading: saveLoading }] = useMutation(EXPORT_CSV_MUTATION, {
        onCompleted(data) {
            setExportLoading(false)
            setShowToast(true)
        },
        onError(error) {}
    })
    const navigate = useNavigate()

    const { control, handleSubmit } = useForm({
        defaultValues: {
            keyword: ''
        }
    })

    const onSubmit = data => {
        refetch({ ...data })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    window.addEventListener('beforeunload', function (event) {
        if (location.pathname === '/job-list' || location.pathname === '/admin-portal/job-list') navigate(location.pathname, { replace: true })
    })

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowToast(false)
    }

    const jobListHeader = (companiesCount, jobCount, userRole) => {
        return (
            <Grid
                container
                spacing={1}
                alignItems='center'
            >
                <Grid
                    item
                    md={['admin', 'employer'].includes(userRole) ? 6 : 12}
                    sm={['admin', 'employer'].includes(userRole) ? 6 : 12}
                    xs={['admin', 'employer'].includes(userRole) ? 6 : 9}
                    sx={{
                        position: 'relative',
                        textAlign: 'left'
                    }}
                >
                    <Typography variant='h2' className='strong'>
                        {['admin'].includes(userRole) ? 'Active jobs' : 'Jobs'} (
                        {jobCount})
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    sm={6}
                    xs={userRole === 'admin' ? 6 : 3}
                    sx={{ textAlign: 'right' }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        {['admin', 'employer'].includes(userRole) ? (
                            <Box>
                                <HomePageButtons
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddCircleOutlineIcon
                                                sx={{
                                                    color: variables.WHITE,
                                                    fontSize: {
                                                        xs: '25px',
                                                        md: '20px'
                                                    }, mr: {
                                                        xs: 0, md: 1
                                                    }
                                                }}
                                            />
                                            <Typography sx={{ display: {
                                                            xs: 'none',
                                                            md: 'block'
                                                        },fontSize: '16px', fontWeight: '900' }}>
                                                Add New Job
                                            </Typography>
                                        </Box>
                                    }
                                    component={Link}
                                    to={userRole === 'admin' ? '/admin-portal/add-job' : '/add-job'}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{ p: '14px 20px !important', fontSize: '16px' }}
                                />
                                {userRole === 'admin' && <Button
                                    label={
                                        exportLoading ? (
                                            <Box sx={{alignItems:'center', display: 'flex'}}>
                                                <CircularProgress
                                                    size={20}
                                                    sx={{ color: variables.BLUE, mr: 2 }}
                                                />
                                                <Typography
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block'
                                                        },
                                                        fontSize: '16px',
                                                        fontWeight: '900'
                                                    }}
                                                >
                                                    Exporting to CSV
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <DownloadIcon
                                                    sx={{
                                                        color: variables.WHITE,
                                                        fontSize: {
                                                            xs: '25px',
                                                            md: '20px'
                                                        },
                                                        mr: {
                                                            xs: 0,
                                                            md: 1
                                                        }
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block'
                                                        },
                                                        fontSize: '16px',
                                                        fontWeight: '900'
                                                    }}
                                                >
                                                    Export to CSV
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{ p: '14px 20px', alignItems: 'center' }}
                                    disabled={exportLoading}
                                    onClick={e => {
                                        e.preventDefault()
                                        setExportLoading(true)
                                        exportCsvMutation({
                                            variables: {
                                                requestType: 'jobpost'
                                            }
                                        })
                                    }}
                                />}
                            </Box>
                        ) : null}
                        {(!['employer', 'admin'].includes(userRole) && responsiveBreakpoint === 'mob') ? (
                        <Box onClick={() => setFilterEnable(!filterEnable)} sx={{ml: 2}}>
                            <Icons
                                src={FilterIcon}
                                sx={{ background: variables.CYAN, borderRadius: '50px', p: 2 }}
                            />
                        </Box>
                    ) : null}
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (loading) return <Loader />

    const jobsData = data.jobsListings.edges.map(edge => edge.node)
    const pageInfo = data.jobsListings.pageInfo

    const appliedFilters = () => {
        return state &&
            (state.keyword ||
                state.departmentIds ||
                state.experience ||
                state.industryIds ||
                state.locationIds) ? (
            <Grid container sx={{ paddingBottom: '40px' }}>
                <Grid item md={8} sm={9} xs={9}>
                    {state && state.keyword && (
                        <Chip
                            variant='selected-filter'
                            icon={<X color={variables.BLUE} />}
                            label={state.keyword}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess === 'admin' ? '/admin-portal/job-list' : '/job-list', { state: { ...state, keyword: '' } })
                            }}
                        />
                    )}
                    {state && state.locationIds && (
                        <Chip
                        variant='selected-filter'
                            icon={<X color={variables.BLUE} />}
                            label={allStates.find(obj => obj.id === state.locationIds)?.name}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess === 'admin' ? '/admin-portal/job-list' : '/job-list', { state: { ...state, locationIds: '' } })
                            }}
                        />
                    )}
                    {state && state.industryIds && (
                        <Chip
                        variant='selected-filter'
                            icon={<X color={variables.BLUE} />}
                            label={allIndustriesData.find(obj => obj.id === state.industryIds)?.name}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess === 'admin' ? '/admin-portal/job-list' : '/job-list', { state: { ...state, industryIds: '' } })
                            }}
                        />
                    )}
                    {state && state.departmentIds && (
                        <Chip
                        variant='selected-filter'
                            icon={<X color={variables.BLUE} />}
                            label={allDepartments.find(obj => obj.id === state.departmentIds)?.name}
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess === 'admin' ? '/admin-portal/job-list' : '/job-list', {
                                    state: { ...state, departmentIds: '' }
                                })
                            }}
                        />
                    )}
                    {state && state.experience && (
                        <Chip
                        variant='selected-filter'
                            icon={<X color={variables.BLUE} />}
                            label={
                                experiencesList.find(obj => obj.value === state.experience)?.label
                            }
                            onClick={() => {
                                setFilterEnable(false)
                                navigate(listAccess === 'admin' ? '/admin-portal/job-list' : '/job-list', { state: { ...state, experience: '' } })
                            }}
                        />
                    )}
                </Grid>
                {state &&
                    (state.keyword ||
                        state.departmentIds ||
                        state.experience ||
                        state.industryIds ||
                        state.locationIds) && (
                        <Grid
                            item
                            xs={responsiveBreakpoint === 'mob' ? 12 : 3}
                            md={4}
                            sm={12}
                            textAlign={
                                responsiveBreakpoint === 'mob' || responsiveBreakpoint === 'tab'
                                    ? 'left'
                                    : 'right'
                            }
                        >
                            <Chip
                            variant='selected-filter'
                                icon={<X color='red' />}
                                label={'Clear all filters'}
                                onClick={() => {
                                    setFilterEnable(false)
                                    navigate(listAccess === 'admin' ? '/admin-portal/job-list' : '/job-list', {
                                        state: {
                                            ...state,
                                            keyword: '',
                                            departmentIds: '',
                                            experience: '',
                                            industryIds: '',
                                            locationIds: '',
                                            jobType: ''
                                        }
                                    })
                                }}
                            />
                        </Grid>
                    )}
            </Grid>
        ) : null
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showToast}
                autoHideDuration={6000}
                onClose={handleToastClose}
            >
                <Box
                    sx={{
                        backgroundColor: variables.BLUE,
                        width: '300px',
                        p: 2,
                        borderRadius: '20px'
                    }}
                >
                    <Grid
                        container
                        columnSpacing={1}
                        alignItems='flex-start'
                        color={variables.WHITE}
                        sx={{alignItems:'center'}}
                    >
                        <Grid item md={1}>
                            <CheckCircleIcon sx={{ width: '20px', mt: '6px' }} />
                        </Grid>
                        <Grid item md={11}>
                            <strong>E-mail sent to admin</strong>
                        </Grid>
                    </Grid>
                </Box>
            </Snackbar>
            <Box>
                <Box sx={{my: 4}}>{jobListHeader(companiesCount, jobCount, userRole)}</Box>
                    {(!['employer', 'admin'].includes(userRole) && responsiveBreakpoint === 'mob') ? (
                        <Collapse in={filterEnable}>
                            <Container maxWidth={false} disableGutters>
                                <JobSearch redirectTo={listAccess === 'admin' ? '/admin-portal/job-list': '/job-list'} />
                            </Container>
                        </Collapse>
                    ) : null}
                    {
                        userRole === 'admin'? <JobSearch redirectTo={listAccess === 'admin' ? '/admin-portal/job-list': '/job-list'}/>: null
                    }
            </Box>
            <Grid container spacing={3} >
                <Grid
                    item
                    xl={['admin', 'employer'].includes(userRole) ? 12 : 9}
                    lg={['admin', 'employer'].includes(userRole) ? 12 : 9}
                    md={['admin', 'employer'].includes(userRole) ? 12 : 9}
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 12 : 8}
                >
                    
                    {['employer'].includes(userRole)
                        ? jobSearchBar(control, handleSubmit, onSubmit)
                        : null}

                    {appliedFilters()}

                    {jobsData.length > 0 ? (
                        jobsData.map(data => (
                            <JobListRow
                                data={data}
                                refetch={refetch}
                                listAccess={listAccess}
                                userRole={userRole}
                            />
                        ))
                    ) : (
                        <NoResultsFound content='No Jobs found with the selected criteria' />
                    )}
                    {isUserAuthenticated && pageInfo.hasNextPage ? (
                        listLoader ? (
                            <Box
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                />
                            </Box>
                        ) : (
                            <LoadMoreLink
                                onClick={() => {
                                    if (pageInfo.hasNextPage) {
                                        setListLoader(true)
                                        fetchMore({
                                            variables: {
                                                afterCursor: pageInfo.endCursor
                                                    ? pageInfo.endCursor
                                                    : ''
                                            }
                                        })
                                    }
                                }}
                            />
                        )
                    ) : null}
                </Grid>

                {responsiveBreakpoint !== 'mob' && isUserAuthenticated ? (
                    ['admin', 'employer'].includes(userRole) ? null : (
                        <>
                            <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                                <SuggestedJobsSideSection />
                            </Grid>
                        </>
                    )
                ) : responsiveBreakpoint !== 'mob' ? (
                    <>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                            <MoreJobsSideSection />
                        </Grid>
                    </>
                ) : null}
            </Grid>
            {isUserAuthenticated ? null : <UserRestriction />}
        </Container>
    )
}

export default JobList
