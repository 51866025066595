import { Box, Typography } from '@mui/material'
import React from 'react'

const IconTextBox = props => {
    const { iconSrc, text } = props
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ...props.sx }}>
            {iconSrc ? (
                <Box
                    component='img'
                    src={iconSrc}
                    sx={{
                        height: '18px',
                        width: '18px',
                        mr: 2
                    }}
                />
            ) : null}
            <Typography variant='paragraph' className='para-font-15px'>
                {text}
            </Typography>
        </Box>
    )
}

export default IconTextBox
