import React from 'react'
import MiniLogo from '../../assets/images/MiniLogo.svg'
import { CompanyJobLogoStyle } from '../../base/commonStyles'

const DefaultCompanyLogo = props => {
    return (
            <CompanyJobLogoStyle
                component='img'
                src={props.imgSrc ? props.imgSrc : MiniLogo}
                sx={{
                    backgroundColor: '#fff',
                    objectFit: 'contain',
                    ...props.sx
                }}
            />
    )
}

export default DefaultCompanyLogo
