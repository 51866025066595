/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Box, FormHelperText, Typography } from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { TextStrings, keys, ErrorMessage, Regex } from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { gql, useMutation, useQuery } from '@apollo/client'
import { MANAGE_USER_PROFILE_MUTATION } from '../../../gql/queries'
import { useDispatch, useSelector } from 'react-redux'
import { addProfileDetailSuccess } from '../../../redux/actions/profileDetail'
import { styles } from './styles'
import Loader from '../../../components/Loader'
import { useEffect, useState } from 'react'
import Layout from '../../../template/Layout'
import PageMetaTags from '../../../base/PageMetaTags'
import ControlledCheckboxGroup from '../../../components/molecules/ControlledCheckboxGroup'
import ControlledTextInput from '../../../components/molecules/ControlledTextInput'
import { stripHtml } from 'string-strip-html'
import WYSIWYGEditor from '../../../components/WYSIWYGEditor'
import CustomFormLabel from '../../../components/elements/CustomFormLabel'
import ControlledRadioGroup from '../../../components/molecules/ControlledRadioGroup'
import { getFormattedObjectForData, reorderOthersOptionToLast } from '../../../utils/commonFunctions'
import { GET_PROFESSIONAL_NETWORK_LIST_QUERY } from '../../../queries/commonQueries'


const AdditionalInfos = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { allBenefits } = useSelector(state => state.initial)

    const [apiError, setApiError] = useState('')

    let initialFormState = {
        about: '',
        benefit: '',
        linkedinUrl: '',
        professionalNetworkMember: '',
        professionalNetwork: '',
        otherProfessionalNetwork: ''
    }

    const {loading:professionalNetworkLoading, data: professionalNetworkData, error} = useQuery(GET_PROFESSIONAL_NETWORK_LIST_QUERY)

    /** api integration graphql */
    const [manageProfileDetailMutation, { data, loading }] = useMutation(
        MANAGE_USER_PROFILE_MUTATION,
        {
            onCompleted(data) {
                let formData = []
                formData['key'] = keys.additionalInfos
                formData['data'] = {
                    about: data?.manageUserProfile?.user?.candidate?.about,
                    benefits: data?.manageUserProfile?.user?.candidate?.benefits,
                    linkedinUrl: data?.manageUserProfile?.user?.linkedinUrl,
                    professionalNetworkMember:
                        data?.manageUserProfile?.user?.candidate?.professionalNetworkMember,
                    professionalNetwork: data?.manageUserProfile?.user?.candidate?.businessNetworks,
                    otherProfessionalNetwork: data?.manageUserProfile?.user?.candidate?.otherNetworks
                }
                dispatch(addProfileDetailSuccess(formData))
                navigate(-1)
            },
            onError(error) {
                setApiError(error)
            }
        }
    )

    /**
     * @method onSubmit
     * @description saving additional details data
     */
    const onSubmit = data => {
        setApiError('')
        let formData = []
        formData['key'] = keys.additionalInfos
        formData['data'] = {
            about: data.about ? data.about : '',
            benefitIds: data.benefit && data.benefit.length > 0 ? data.benefit.join() : '',
            title: data.profession ? data.profession : '',
            linkedinUrl: data.linkedinUrl,
            professionalNetworkMember: data.professionalNetworkMember,
            professionalNetwork: data.professionalNetwork && data.professionalNetwork.length > 0 ? data.professionalNetwork.join() : '',
            otherNetworks: data.otherProfessionalNetwork,
            actionType: 'create'
        }
        manageProfileDetailMutation({
            variables: {
                ...formData.data
            }
        })
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
        register
    } = useForm({
        defaultValues: initialFormState
    })

    const watchProfessionalNetworkMember = watch("professionalNetworkMember")

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSubmit(onSubmit)()
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    function handleMutliSelect(checkedName, fieldName) {
        const newNames = getValues(fieldName)?.includes(checkedName)
            ? getValues(fieldName)?.filter(name => name !== checkedName)
            : [...(getValues(fieldName) ?? []), checkedName]
        return newNames
    }

    const location = useLocation()

    if(professionalNetworkLoading) return <Loader/>
    const professionalNetworkList = getFormattedObjectForData(reorderOthersOptionToLast(professionalNetworkData.getProfessionalNetworkList))
    return (
        <Layout>
            <PageMetaTags
                title={`${
                    location.pathname.includes('profileSetup') ? 'Profile Setup | ' : ''
                }Bunton`}
            />
            <Box sx={styles.container}>
                <Box sx={styles.header}>
                    <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                        {`${TextStrings.additionalInfos}`}
                    </Typography>
                    <Box
                        sx={styles.backIconContainer}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <Icons src={BACK} />
                    </Box>
                </Box>
                <Box sx={styles.formContainer}>
                    <Box sx={{ paddingBottom: '40px' }}>
                        <CustomFormLabel
                            formLabel='About you*'
                            formFieldError={errors && errors.about && errors.about.message}
                        />
                        <Controller
                            control={control}
                            rules={{
                                validate: {
                                    required: v =>
                                        (v && stripHtml(v).result.length > 0) ||
                                        ErrorMessage.emptyProfileDescription
                                }
                            }}
                            defaultValue=''
                            render={({ field }) => (
                                <WYSIWYGEditor name='about' {...field} register={register} />
                            )}
                            name='about'
                        />
                    </Box>

                    <Box>
                        <CustomFormLabel
                            formLabel='Your Profession*'
                            formFieldError={
                                errors && errors.profession && errors.profession.message
                            }
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyProfession
                                }
                            }}
                            name='profession'
                            placeholder='E.g. Senior Software Engineer'
                            type='text'
                            errors={errors && errors.profession && errors.profession.message}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    <Box>
                        <CustomFormLabel
                            formLabel='Are you a member of a professional business network?*'
                            formFieldError={
                                errors &&
                                errors.professionalNetwork &&
                                errors.professionalNetwork.message
                            }
                        />
                        <ControlledRadioGroup
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyBusinessNetworkMember
                                }
                            }}
                            register={register}
                            name='professionalNetworkMember'
                            options={[
                                { name: 'Yes', label: 'Yes', value: true },
                                { name: 'No', label: 'No', value: false }
                            ]}
                            errors={errors && errors.professionalNetworkMember && errors.professionalNetworkMember.message}
                        />
                    </Box>
                    {
                        watchProfessionalNetworkMember === 'true' && <Box>
                            <CustomFormLabel
                                formLabel='Select your business network(s).*'
                                formFieldError={
                                    errors &&
                                    errors.professionalNetwork &&
                                    errors.professionalNetwork.message
                                }
                            />
                            <ControlledCheckboxGroup
                                control={control}
                                register={register}
                                rules={{
                                    validate: value => {
                                        return value.length > 0 || ErrorMessage.emptyBusinessNetwork
                                    }
                                }}
                                name='professionalNetwork'
                                options={professionalNetworkList}
                                errors={errors && errors.professionalNetwork && errors.professionalNetwork.message}
                                handleMutliSelect={handleMutliSelect}
                                otherFieldRules={{
                                    required: {
                                        value: true,
                                        message: ErrorMessage.emptyOthers
                                    },
                                    validate: (value) => { return !!value.trim() || ErrorMessage.emptyOthers}
                                }}
                                otherFieldErrors={
                                    errors && errors.otherProfessionalNetwork && errors.otherProfessionalNetwork.message
                                }
                            />
                        </Box>
                    }

                    <Box>
                        <CustomFormLabel
                            formLabel='Your Preferences'
                            formFieldError={
                                (errors && errors.benefit && errors.benefit.message) ||
                                (errors && errors.otherBenefit && errors.otherBenefit.message)
                            }
                        />
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            // rules={{
                            //     validate: (value) => {
                            //         return value.length > 0 || ErrorMessage.emptyBenefits
                            //     }
                            // }}
                            otherFieldRules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyOthers
                                }
                            }}
                            name='benefit'
                            options={allBenefits}
                            errors={errors && errors.benefit && errors.benefit.message}
                            otherFieldErrors={
                                errors && errors.otherBenefit && errors.otherBenefit.message
                            }
                            handleMutliSelect={handleMutliSelect}
                        />
                    </Box>
                    <Box>
                        <CustomFormLabel
                            formLabel='LinkedIn Profile URL'
                            formFieldError={
                                errors && errors.linkedinUrl && errors.linkedinUrl.message
                            }
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                pattern: {
                                    value: Regex.linkedInRegex,
                                    message: ErrorMessage.validLinkedInUrl
                                }
                            }}
                            name='linkedinUrl'
                            type='text'
                            errors={errors && errors.linkedinUrl && errors.linkedinUrl.message}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>
                    {apiError && apiError?.message ? (
                        <FormHelperText id='my-helper-text' error={true}>
                            <strong>{apiError?.message}</strong>
                        </FormHelperText>
                    ) : null}
                    <Box sx={styles.buttonContainer}>
                        <Button
                            label={TextStrings.save}
                            color='primary'
                            variant='contained'
                            size='large'
                            // disabled={!isDirty || !isValid}
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                            sx={styles.button}
                        />
                    </Box>
                </Box>
                {loading && <Loader />}
            </Box>
        </Layout>
    )
}

export default AdditionalInfos
