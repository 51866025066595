import React from 'react'

import EsgLogo from '../assets/images/partnersLogo/esg.png'
import FaeLogo from '../assets/images/partnersLogo/fae.png'
import MaLogo from '../assets/images/partnersLogo/managementalliance.png'
import AtelyayLogo from '../assets/images/partnersLogo/atelyay.png'
import InfoBeansLogo from '../assets/images/partnersLogo/infobeans.png'
import ParrkLogo from '../assets/images/partnersLogo/parrk.png'
import FinLogo from '../assets/images/partnersLogo/FIN.png'
import KarrierefuerhrerLogo from '../assets/images/partnersLogo/karrierefuerhrer.png'

import StartUpValley from '../assets/images/startUpValley.png'
import StationFra from '../assets/images/station_fra.png'
import DeutscheStartups from '../assets/images/deutscheStartups.png'
import FazLogo from '../assets/images/faz_logo.png'

import dbBahn from '../assets/images/companies/DBahn.jpg'
import expleo from '../assets/images/companies/expleo.jpg'
import garlaGru from '../assets/images/companies/GarlaGruppe.jpg'
import getcap from '../assets/images/companies/GETcapital.jpg'
import idVerde from '../assets/images/companies/idVerde.jpg'
import imap from '../assets/images/companies/imap.jpg'
import kranken from '../assets/images/companies/KrankenhausNordwest.jpg'
import mbccGroup from '../assets/images/companies/MBCCGroup.jpg'
import schunk from '../assets/images/companies/schunk.jpg'


//--------------- Home Page Text Contents: ------------------------- //

// 1. Introduction Section

export const IntroHeading = 'bringing balance to the boardroom'
export const IntroContent = (
    <>
        Bunton was founded on the experiences and interactions with several <br/>
        leading women executives and entrepreneurs, many of whom many of whom <br/>
        remain an integral part of our company today.
    </>
)

// 2. Our Parners Logos

export const partnersLogos = [
    // {src: EsgLogo, link: "https://esg-powerhouse.com/"},
    // {src: FaeLogo, link: "https://www.fae-consulting.de/"}, 
    {src: MaLogo, link: "https://www.managementalliance.de/"}, 
    // {src: AtelyayLogo, link: "https://www.atelyay.at/"}, 
    // {src: InfoBeansLogo, link: "https://www.infobeans.com/"},
    // {src: ParrkLogo, link: 'http://www.parrkommunikation.de/'},
    {src: FinLogo, link: 'https://female-investors-network.com/'},
    {src: KarrierefuerhrerLogo, link: 'https://www.karrierefuehrer.de/'}
]

export const companiesLogo = [
    {src: EsgLogo, link: "https://esg-powerhouse.com/"},
    {src: FaeLogo, link: "https://www.fae-consulting.de/"},
    {src: KarrierefuerhrerLogo, link: 'https://www.karrierefuehrer.de/'},
    {src: schunk, link: '#'},
    {src: expleo, link: '#'},
    {src: ParrkLogo, link: 'http://www.parrkommunikation.de/'},
    {src: FinLogo, link: 'https://female-investors-network.com/'},
    {src: MaLogo, link: "https://www.managementalliance.de/"}, 
    {src: idVerde, link: '#'},
    {src: imap, link: '#'},
    {src: dbBahn, link: '#'},
    {src: getcap, link: '#'},
    {src: kranken, link: '#'},
    {src: garlaGru, link: '#'},
    {src: mbccGroup, link: '#'}  
    

    
    
    
]

// 3. Featured on section
export const featuredOnLinksAndLogos = [
    {src: FazLogo, link: "https://www.faz.net/aktuell/rhein-main/wirtschaft/frauen-in-fuehrungsposten-der-headhunter-hielt-mich-fuer-die-sekretaerin-19879376.html"},
    {src: StartUpValley, link: "https://startupvalley.news/de/bunton/"}, 
    {src: DeutscheStartups, link: "https://www.deutsche-startups.de/2023/11/16/neue-startups-holi-wasteant/"},  
    {src: StationFra, link: 'https://station-frankfurt.de/2024/02/22/ki-startup-bunton-schliesst-erfolgreich-erste-finanzierungsrunde-ab/'}   
]


//--------------- Home Page Text Contents: ------------------------- //

// Footer CopyRight Text

export const CopyRightText = new Date().getFullYear() + ' | Bunton GmbH'
export const BuntonContactEmail = 'welcome@bunton.de'
