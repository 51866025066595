import React from 'react'
import variables from '../../settings/_variables.scss'
import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: variables.CYAN,
    boxShadow: 10,
    p:4,
    borderRadius: '65px'
}

const ThankyouModal = props => {
    const {open, handleClose, message} = props
    return (
    <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    disableScrollLock={true}
    slotProps={{
        backdrop: {
            sx: {
                background: variables.WHITE,
                opacity: '0.7 !important'
            },
            timeout: 500
        }
    }}
>
    <Fade in={open}>
        <Box sx={{height:'auto', ...style}}>
            <Typography variant='h2' className='modal-heading'>
            {props.message}
            </Typography>
        </Box>
    </Fade>
</Modal>)
}

export default ThankyouModal