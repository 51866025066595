import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englandFlag from '../assets/images/England_flag.png'
import germanFlag from '../assets/images/German_flag.png'

export const supportedLngs = {
  en: {
    flag: englandFlag,
    title: "English"},
  de: {
    flag: germanFlag,
    title: "German"
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'de',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    de: {
      translations: require('./locales/de/translations.json')
    }
  },
  supportedLngs: Object.keys(supportedLngs),
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'de'];

export default i18n;