import { Box, styled } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import ReactFlipCard from 'reactjs-flip-card'

const InformaticBoxStyle = styled(Box)`
    border: 2px solid ${variables.CYAN};
    border-radius: 30px;
`

const InformaticBoxBackStyle = styled(Box)`
    border: 2px solid ${variables.CYAN};
    border-radius: 30px;
    background: ${variables.CYAN1}
`

const InformaticBox = props => {
    const frontCard = () => {
        return <InformaticBoxStyle>{props.frontCardComponent}</InformaticBoxStyle>
    }
    const backCard = () => {
        return <InformaticBoxBackStyle>{props.backCardComponent}</InformaticBoxBackStyle>
    }

    return (
        <ReactFlipCard
            // frontStyle={styles.card}
            // backStyle={styles.card}
            flipCardCss={'transitionDuration2s'}
            containerStyle={{
                maxWidth: '100%', 
                width: '100%',
                height: '350px'
            }}
            frontComponent={frontCard()}
            backComponent={backCard()}
        />
    )
}

export default InformaticBox
