import { Box, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'

const ShareModalButton = props => {
    const {title, icon} = props
    return <Box sx={{background: variables.CYAN, py: {
        xs:3,
        sm:3,
        md: 4,
        lg: 6
    }, textAlign: 'center', borderRadius: '65px', cursor: 'pointer'}} onClick={props.onClick}>
        {
            icon ? <Box>
                {icon}
            </Box>: null
        }
        <Box sx={{mt: 3}}>
            <Typography variant='h2' className='share-buttons-title'>
            {title}
            </Typography>
        </Box>
    </Box>
}

export default ShareModalButton