import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import BuntonLogo from '../../assets/images/Logo.svg'

const BuntonPageHeadingLogo = props => {
    return (
        <Box
            sx={{
                width: '122px',
                height: '64px',
                m: '0 auto',
                mb: 4,
                mt: 7
            }}
        >
            <Link to='/'>
                <Box
                    component='img'
                    src={BuntonLogo}
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                />
            </Link>
        </Box>
    )
}

export default BuntonPageHeadingLogo
