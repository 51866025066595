import React, { useState } from 'react'
import Share from '../../assets/icons/share-blue.svg'
import { Box } from '@mui/material'
import Button from '../Button'
import variables from '../../settings/_variables.scss'

const ShareJobButton = props => {
    const [isTextVisible, setIsTextVisible] = useState(false);
    return <Button
        color='primary'
        variant='contained'
        size='large'
        onMouseEnter={() => setIsTextVisible(true)}
        onMouseLeave={() => setIsTextVisible(false)}
        {...props}
        sx={{ width: '120px', height: '51px', fontWeight: 'bold', background: variables.WHITE1,  '&:hover': {
                                                                background: variables.WHITE1
                                                            }, ...props.sx}}
        
    >
        <>
        {props.textEnable ? <Box sx={{fontWeight: 'bolder'}}>
            Share
        </Box> : null}
        {props.iconEnable ? <Box component='img' src={Share} height='25px' sx={{ml:1, ...props.iconStyle}}/>: null}
        </>
    </Button>
}

export default ShareJobButton