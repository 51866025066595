import { gql } from "@apollo/client"

export const MANAGE_COMPANY_MUTATION = gql`
    mutation ManageCompany(
        $id: ID
        $companyName: String
        $description: String
        $companyWebsite: String
        $firstName: String
        $lastName: String
        $name: String
        $email: String
        $alternateEmail: String,
        $departmentIds: String
        $otherDepartment: String
        $locationIds: String
        $industryIds: String
        $otherIndustry: String
        $otherBenefits: String
        $noOfEmployees: String
        $actionType: String!
        $benefitIds: String
        $companyLogo: String
        $linkedinUrl: String,
        $companyType: String
    ) {
        manageCompany(
            id: $id
            companyName: $companyName
            description: $description
            companyWebsite: $companyWebsite
            firstName: $firstName
            lastName: $lastName
            name: $name
            email: $email
            alternateEmail: $alternateEmail,
            departmentIds: $departmentIds
            otherDepartment: $otherDepartment
            locationIds: $locationIds
            industryIds: $industryIds
            otherIndustry: $otherIndustry
            noOfEmployees: $noOfEmployees
            actionType: $actionType
            benefitIds: $benefitIds
            companyLogo: $companyLogo
            linkedinUrl: $linkedinUrl,
            companyType: $companyType
            otherBenefits: $otherBenefits
        ) {
            company {
                id
            }
            actionType
        }
    }
`

export const GET_COMPANY_DATA_MUTATION = gql`
    query Company($id: ID!) {
        company(id: $id) {
            id
            companyName
            companyWebsite
            companyLogoUrl
            companyLogoFilename
            companyLogoCreatedat
            companyEmail
            description
            alternateEmail
            companyType
            states {
                id
                name
            }
            user {
                name
                profileImage
                linkedinUrl
            }
            industries {
                id
                name
            }
            benefits {
                id
                name
            }
            noOfEmployees
            otherIndustry
            otherDepartment
            otherBenefits
        }
    }
`