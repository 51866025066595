import React from 'react'
import { SectionContainer } from '../../pages/HomePage'
import { Box, Typography } from '@mui/material'
import BuntonHomeVideoWebm from '../../assets/videos/BUN_LandingPage_Header_1.webm'
import BuntonHomeVideoMp4 from '../../assets/videos/BUN_LandingPage_Header_1.mp4'
import Button from '../Button'
import { Link } from 'react-router-dom'
import ReactGA4 from 'react-ga4'
import { useSelector } from 'react-redux'
import HeaderImage from '../../assets/images/BUN_LandingPage_Header.webp'

const LandingPageHeader = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    return (
        <SectionContainer
            id='home-page-vide-container'
            disableGutters
            maxWidth={false}
            sx={{
                maxHeight: '665px',
                minHeight: {
                    xs: '300px',
                    md: '450px',
                    xl: '450px'
                },
                height: {
                    xs: '40vH',
                    md: '450px',
                    xl: '450px'
                },
                position: 'relative',
                mt: '0 !important',
                p: '0 !important',
                mb: '200px'
            }}
        >
            {responsiveBreakpoint === 'mob' ? <Box component='img' src={HeaderImage} sx={{
                    position: 'absolute',
                    zIndex: 0,
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    displayPrint: 'block'
                }}/> : <Box
                component='video'
                preload='auto'
                autoPlay
                muted
                loop
                sx={{
                    position: 'absolute',
                    zIndex: 0,
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    displayPrint: 'block'
                }}
            >
                <source src={BuntonHomeVideoWebm} type='video/webm' />
                <source src={BuntonHomeVideoMp4} type='video/mp4' />
            </Box>}
            <Box
                sx={{
                    zIndex: 1,
                    position: 'relative',
                    top: {
                        xs: '60%',
                        md: '60%',
                        xl: '65%'
                    },

                    maxWidth: '800px',
                    width: {
                        xs: '80%',
                        md: '75%',
                        xl: '50%'
                    },
                    margin: '0 auto',
                    height: '300px',
                    textAlign: 'center'
                }}
            >
                <Box
                    sx={{
                        background: 'rgba(255,255,255, 0.8)',
                        borderRadius: '30px',
                        height: '200px',
                        p: {
                            xs: 1,
                            sm: 4
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:'center'
                    }}
                >
                    <Box>
                        <Typography
                            variant='h1'
                            className='candidate-home-page-heading blue-color'
                            sx={{ textAlign: 'center' }}
                        >
                            Wir bringen Sie in<br/>Top Executive Positionen!
                        </Typography>
                        <Typography variant='h2' sx={{ mt: 2, textAlign: 'center' }}>
                            DIE KARRIEREPLATTFORM FÜR FRAUEN
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: { xs: '60%', sm: '75%' },
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        label='Jetzt kostenlos registrieren'
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to='/registration'
                        sx={{ mt: 1, position: 'relative', top: '100%' }}
                        onClick={() => {
                            ReactGA4.event({
                                category: 'Header Registration',
                                action: 'Click',
                                label: 'Landing Page Header'
                            })
                        }}
                    />
                </Box>
            </Box>
        </SectionContainer>
    )
}

export default LandingPageHeader
