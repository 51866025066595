import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_LOADER,
    LOGIN_CHECK_AUTH,
    LOGOUT_SUCCESS,
    SET_AVATAR
} from '../actions/login/actionTypes'

const initialState = {
    loginInProgress: false,
    loginDetails: null,
    loginError: null,
    loginCheckAuthDetails: null,
    isUserAuthenticated: false
}

const setLoginLoader = (state, action) => {
    return {
        ...state,
        loginInProgress: true
    }
}

const loginSuccess = (state, action) => {
    return {
        ...state,
        loginInProgress: false,
        loginError: null,
        loginDetails: action.payload.signIn
            ? action.payload.signIn
            : action.payload.validateOtp
            ? action.payload.validateOtp
            : null,
        isUserAuthenticated: action.payload.signIn || action.payload.validateOtp ? true : false,
        loginCheckAuthDetails: action.payload.signIn && action.payload.signIn.currentUser
            ? { ...action.payload.signIn.currentUser }
            : action.payload.validateOtp
            ? { ...action.payload.validateOtp }
            : {}
    }
}

const loginFailure = (state, action) => {
    return {
        ...state,
        loginInProgress: false,
        loginDetails: null,
        loginError: action.payload
    }
}

const loginCheckAuth = (state, action) => {
    return {
        ...state,
        loginCheckAuthDetails: action.payload.currentUser,
        isUserAuthenticated: action.payload.currentUser ? true : false
    }
}

const logoutSuccess = (state, action) => {
    return {
        ...initialState
    }
}

const setAvatar = (state, action) => {
    return {
        ...state,
        loginCheckAuthDetails: {
            ...state.loginCheckAuthDetails,
            profileImage: action.payload
        }
    }
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_LOADER:
            return setLoginLoader(state, action)
        case LOGIN_SUCCESS:
            return loginSuccess(state, action)
        case LOGIN_FAILURE:
            return loginFailure(state, action)
        case LOGIN_CHECK_AUTH:
            return loginCheckAuth(state, action)
        case LOGOUT_SUCCESS:
            return logoutSuccess(state, action)
        case SET_AVATAR:
            return setAvatar(state, action)
        default:
            return state
    }
}

export default loginReducer
