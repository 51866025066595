import { useMutation } from '@apollo/client'
import {
    Box,
    FormHelperText,
    Typography,
    Link as MuiLink,
    CircularProgress,
    Container
} from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import OtpInput from 'react18-input-otp'
import PageMetaTags from '../base/PageMetaTags'
import variables from '../settings/_variables.scss'
import { TextStrings } from '../constants'
import StatusBox from '../components/StatusBox'
import { RESENT_OTP_MUTATION, VALIDATE_MUTATION } from '../queries/commonQueries'
import BuntonPageHeadingLogo from '../components/elements/BuntonPageHeadingLogo'
import { useSelector } from 'react-redux'

const ValidateOtp = props => {
    const {responsiveBreakpoint} = useSelector(state => state.initial)
    const navigate = useNavigate()
    const params = useParams()
    const [otp, setOtp] = useState('')
    const [isValidatingLoading, setIsValidatingLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [apiError, setApiError] = useState('')
    const [second, setSecond] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (second === 0) {
                clearInterval(interval)
            } else {
                setSecond(second - 1)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    const [validateMutation, { data }] = useMutation(VALIDATE_MUTATION, {
        onCompleted(data) {
            setIsSuccess(true)
            setIsValidatingLoading(false)
            setTimeout(() => {
                setIsSuccess(false)
                navigate(`/reset-password/${btoa(data.validateOtp.id)}`)
            }, 3000)
        },
        onError(error) {
            console.log(error, 'error')
            setIsValidatingLoading(false)
            setApiError(error)
        }
    })
    const handleChange = enteredOtp => {
        setApiError('')
        setOtp(enteredOtp)
        if (enteredOtp.length === 5) {
            setIsValidatingLoading(true)
            validateMutation({
                variables: {
                    userId: atob(params.key),
                    otp: enteredOtp
                }
            })
        }
    }

    const resendOTP = () => {
        setSecond(60)
        resendOTPMutation({
            variables: {
                userId: atob(params.key)
            }
        })
    }

    const webStyle = {
        width: '50px',
        height: '70px',
        margin: '5px',
        borderRadius: '25px',
        fontSize: '30px',
        fontFamily: variables.poppinsRegular,
        border: `3px solid ${apiError ? variables.ERROR : variables.CYAN}`
    }

    const mobileStyle = {
        width: '35px',
        height: '50px',
        margin: '5px',
        borderRadius: '25px',
        fontSize: '20px',
        fontFamily: variables.poppinsRegular,
        border: `3px solid ${apiError ? variables.ERROR : variables.CYAN}`
    }

    const [resendOTPMutation, { resend_data }] = useMutation(RESENT_OTP_MUTATION, {
        onCompleted(resend_data) {
            console.log('success')
        },
        onError(error) {
            console.log(error, 'error')
            setApiError(error)
        }
    })

    return (
        <Container maxWidth='xl' disableGutters>
            <PageMetaTags title='Validation | Bunton' />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    minHeight:'600px'
                }}
            >
                <Box>
                    <BuntonPageHeadingLogo />
                    <Box>
                        <Typography variant='h3' className='section-heading'>
                            {TextStrings.resetPassword}
                        </Typography>
                    </Box>
                    <Box>
                        {isValidatingLoading ? (
                            <Box sx={{ mt: '60px' }}>
                                <CircularProgress sx={{ color: variables.BLUE }} />
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    width: 'fit-content',
                                    margin: '0 auto',
                                    mt: 4
                                }}
                            >
                                <Typography variant='h5' className='small-size'>
                                    Enter reset code*
                                </Typography>
                                <OtpInput
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={5}
                                    isInputNum={true}
                                    separator={<span> </span>}
                                    containerStyle={{ padding: '20px' }}
                                    inputStyle={responsiveBreakpoint === 'mob' ? mobileStyle : webStyle}
                                />
                                {apiError && apiError?.message ? (
                                    <FormHelperText
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                        id='my-helper-text'
                                        error={true}
                                    >
                                        <strong>{apiError?.message}</strong>
                                    </FormHelperText>
                                ) : null}
                                <Typography
                                    variant='h5'
                                    className='small-size'
                                    sx={{
                                        my: 3,
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}
                                >
                                    <Box component='span' sx={{mr: 1}}>
                                    Received no Email?{' '}
                                    </Box>
                                    <MuiLink
                                        component='button'
                                        variant='h5'
                                        className='small-size'
                                        disabled={second > 0}
                                        onClick={() => {
                                            resendOTP()
                                        }}
                                        style={
                                            second
                                                ? {
                                                      pointerEvents: 'none',
                                                      color: variables.LOADING
                                                  }
                                                : null
                                        }
                                    >
                                        {'Send again'}
                                    </MuiLink>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            {isSuccess ? <StatusBox statusText='Verified' /> : null}
        </Container>
    )
}

export default ValidateOtp
