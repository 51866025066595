import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { publicRoutes, privateRoutes } from './routes'

const checkRoutesAfterLogin = [
    '/login',
    '/forgot-password',
    '/validate-email',
    '/reset-password',
    '/registration'
]

const useAuth = () => {
    const { isUserAuthenticated } = useSelector(state => state.login)
    if (isUserAuthenticated) {
        return true
    } else {
        return false
    }
}

const checkIfPublicRoute = (pathname) => {
    let checkResult = false
    checkRoutesAfterLogin.forEach(redirectRoute => {
        if(pathname.includes(redirectRoute)) {
            checkResult = true
        }
    })
    return checkResult
}

const ProtectedRoutes = props => {
    const auth = useAuth()
    const location = useLocation()
    const { loginDetails, loginCheckAuthDetails } = useSelector(
        state => state.login
    )
    const { roles } = props
    const shouldRedirect = checkIfPublicRoute(location.pathname)
    const hasAnswered = loginCheckAuthDetails && loginCheckAuthDetails.hasAnswered
        ? loginCheckAuthDetails.hasAnswered
        : loginDetails && loginDetails.hasAnswered
        ? loginDetails.hasAnswered
        : false 
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null
    return (location.pathname === '/') || shouldRedirect ? (
        <Navigate replace to={userRole === 'admin' ? 'admin-portal' : 'dashboard'} />
    ) : auth && roles.includes(userRole) ? (
        userRole === 'user' && !hasAnswered && !['/dashboard', '/logout'].includes(location.pathname)? <Navigate replace to='/dashboard' /> : <>{props.children}</>
    ) : (
        <Navigate to='/404' replace />
    )
}

const NavigationRouters = () => {
    const {  isUserAuthenticated } = useSelector(
        state => state.login
    )
    
    return ( 
        <>
            <BrowserRouter>
                <Routes>
                    {isUserAuthenticated ? (
                        <>
                            {privateRoutes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    element={
                                        <ProtectedRoutes roles={route.roles}>
                                            {route.element}
                                        </ProtectedRoutes>
                                    }
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {publicRoutes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    element={route.element}
                                />
                            ))}
                        </>
                    )}
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default NavigationRouters
