import variables from '../../settings/_variables.scss'
export const styles = {
    progressContainer: {
        width: '100%',
        mt: '30px'
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        mt: '9px',
        ml: '15px'
    },
    labelTextBold: {
        fontSize: '18px',
        fontWeight: '800 !important',
        color: variables.BLUE,
        fontFamily: variables.poppinsBlack
    },
    labelText: {
        fontSize: '18px',
        color: variables.BLUE,
        fontFamily: variables.poppinsBlack
    }
}
