import React from 'react'
import PageMetaTags from '../../../base/PageMetaTags'
import { useParams } from 'react-router-dom'
import AddJobComponent from './AddJobComponent'
import Layout from '../../../template/Layout'
import { useSelector } from 'react-redux'
import { USER_DETAILS } from '../../../queries/userDetailsQuery'
import { useQuery } from '@apollo/client'
import Loader from '../../../components/Loader'

const EmployerAddJob = props => {
    let urlParams = useParams()
    const isEditPage = urlParams.jobId ? true : false

    const { loginCheckAuthDetails, loginDetails } = useSelector(state => state.login)

    const userId = loginCheckAuthDetails.id
        ? loginCheckAuthDetails.id
        : loginDetails.id
        ? loginDetails.id
        : 0

    const { loading, data, error } = useQuery(USER_DETAILS, {
        variables: {
            id: userId
        }
    })
    if (loading) return <Loader/>
    const userDetails = data.user
    const { company } = userDetails

    return (
        <Layout>
            <PageMetaTags title={`${isEditPage ? 'Edit' : 'Add New'} Job | Bunton`} />
            <AddJobComponent companyId={company?.id} />
        </Layout>
    )
}

export default EmployerAddJob
