import { Avatar, Box, Chip, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { getAvatrURL } from '../../utils/utilities'
import AvatarDefault from '../../assets/images/avatar.png'
import Industry from '../../assets/icons/INDUSTRY.svg'
import DepartmentIcon from '../../assets/icons/DEPARTMENT.svg'
import ExperienceIcon from '../../assets/icons/EXPERIENCE.svg'
import Arrow from '../../assets/icons/ARROW.svg'
import {
    getExperienceLabel,
    getStringFromArray,
    truncateDetails
} from '../../utils/commonFunctions'
import { Link } from 'react-router-dom'
import moment from 'moment'
import CustomCheckboxChip from '../elements/CustomCheckboxChip'
import { StyledIcons } from '../../base/commonStyles'
import { DateDefaultFromat } from '../../constants/constants'
import variables from '../../settings/_variables.scss'

const UsersListRow = props => {
    const { userDetails } = props
    return (
        <Container variant='table-rows'>
            <Grid container spacing={2} alignItems='center'>
                <Grid item md={1} lg={1} sm={2} xs={2} xl={1}>
                    <CustomCheckboxChip
                        chipSx={{
                            background: 'transparent',
                            m: 0,
                            p: 0
                        }}
                        // label={option.name}
                        // name={props.name[val]}
                        value={userDetails.userId}
                        onChange={() => props.onChecked()}
                        checked={props.checked}
                    />
                </Grid>
                <Grid item md={11} lg={11} sm={10} xs={10} xl={11}>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item lg={2} md={2} sm={3} xs={12}>
                            <Avatar
                                variant='user-images'
                                src={
                                    userDetails && userDetails.candidateAvatar
                                        ? getAvatrURL(userDetails.candidateAvatar)
                                        : AvatarDefault
                                }
                                sx={{
                                    height: {
                                        xs: '70px',
                                        md: '110px'
                                    },
                                    width: {
                                        xs: '70px',
                                        md: '110px'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box sx={{ mb: 1.5 }}>
                                <Box sx={{display:'flex'}}>
                                    <Typography
                                        variant='h4'
                                        className='table-row-heading'
                                        sx={{ mb: 1 }}
                                    >
                                        {userDetails.name}
                                    </Typography>
                                    <Chip
                                        variant='status-chip'
                                        label={<em>{`${userDetails.profileCompletionStatus}% Profile Completed`}</em>}
                                        sx={{ ml: 1,  height: '25px', fontFamily: variables.poppinsRegular }}
                                    />
                                </Box>
                                {userDetails.title ? (
                                    <Typography variant='h5' className='small-size'>
                                        {userDetails.title}
                                    </Typography>
                                ) : null}
                            </Box>
                            <Box>
                                {userDetails.experience ? (
                                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                        <StyledIcons component='img' src={ExperienceIcon} />
                                        <Typography variant='h5' className='small-size'>
                                            {getExperienceLabel(userDetails.experience)}
                                        </Typography>
                                    </Box>
                                ) : null}
                                {userDetails.departments && userDetails.departments.length > 0 ? (
                                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                        <StyledIcons component='img' src={DepartmentIcon} />
                                        <Typography variant='h5' className='small-size'>
                                            {truncateDetails(
                                                getStringFromArray(
                                                    userDetails.departments,
                                                    userDetails.otherDepartment
                                                ),
                                                50
                                            )}
                                        </Typography>
                                    </Box>
                                ) : null}
                                {userDetails.industries && userDetails.industries.length > 0 ? (
                                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                        <StyledIcons component='img' src={Industry} />
                                        <Typography variant='h5' className='small-size'>
                                            {truncateDetails(
                                                getStringFromArray(
                                                    userDetails.industries,
                                                    userDetails.otherIndustry
                                                ),
                                                50
                                            )}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={2}
                            md={2}
                            sm={0}
                            xs={12}
                            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
                        >
                            <Box>
                                <Typography>Registration Date</Typography>
                                <Typography>
                                    {moment(new Date(userDetails.createdAt)).format(
                                        `${DateDefaultFromat} HH:mm`
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                
                                <Link to={'/candidate-details/' + userDetails.userId}>
                                    <Avatar
                                        src={Arrow}
                                        component='span'
                                        variant='action-icons-blue'
                                        imgProps={{
                                            style: {
                                                height: '20px',
                                                width: '20px'
                                            }
                                        }}
                                    />
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default UsersListRow
