import { Box } from '@mui/material'
import React from 'react'
import './headerimages.scss'

const HeaderImages = props => {
    return <Box component='img' src={props.src} className={props.className} {...props} sx={{...props.sx, height: {
        xs: '139px',
        sm: '120px',
        md: '135px',
        lg: '165px'
    }, borderRadius: 20}}></Box>
}

export default HeaderImages
