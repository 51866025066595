import React from 'react'
import { SectionContainer } from '../../pages/HomePage'
import { Box, Typography } from '@mui/material'
import BannerImage from '../../assets/images/BUN_Banner_registrieren.webp'
import ArrowIcon from '../../assets/icons/ARROW.svg'
import { Link } from 'react-router-dom'
import ReactGA4 from 'react-ga4'

const BannerRegistrationSection = props => {
    return (
        <SectionContainer maxWidth='xl' disableGutters>
            <Box
                component={Link}
                to='/registration'
                onClick={() => {
                    ReactGA4.event({
                        category: 'Banner Registration',
                        action: 'Click',
                        label: 'Landing Page Banner'
                    })
                }}
                sx={{
                    position: 'relative',
                    display: 'flex',
                    cursor: 'pointer',
                    '&:hover': {
                        zoom: '1.1',
                        transition: 'zoom 2s',
                        transitionDelay: '1s'
                    }
                }}
            >
                <Box
                    component='img'
                    src={BannerImage}
                    alt='Registration Banner'
                    sx={{
                        maxWidth: ' 1520px',
                        maxHeight: '275px',
                        width: '100%',
                        height: '100%',
                        borderRadius: '70px',
                        objectFit: {
                            xs: 'cover',
                            md: 'none'
                        }
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0',
                        width: '100%',
                        maxHeight: '275px',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        background: 'rgba(0, 0, 0, 0.4)',
                        borderRadius: '70px',
                        color: '#fff'
                    }}
                >
                    <Typography variant='h2' className='extrabold white-color'>
                        Jetzt kostenlos registrieren!
                    </Typography>
                    <Box
                        component='img'
                        src={ArrowIcon}
                        alt='Registration'
                        sx={{ height: '25px', width: '25px', ml: 3 }}
                    />
                </Box>
            </Box>
        </SectionContainer>
    )
}

export default BannerRegistrationSection
