import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Box } from '@mui/material'

const WYSIWYGEditor = ({ name, onChange, value, register, editorStyle }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [updated, setUpdated] = useState(false)
    const editorRef = useRef(null)

    useEffect(() => {
        if (!updated) {
            const defaultValue = value ? value : ''
            const blocksFromHtml = htmlToDraft(defaultValue)
            const contentState = ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
            )
            const newEditorState = EditorState.createWithContent(contentState)
            setEditorState(newEditorState)
        }
    }, [value])

    const onEditorStateChange = editorState => {
        setUpdated(true)
        setEditorState(editorState)
        let htmlJdContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        // const contentDiv = document.querySelector(".rdw-editor-main"); contentDiv.scrollTop = contentDiv.scrollHeight;
        return onChange(htmlJdContent)
    }
    

    return (
        <React.Fragment>
            <Box className='editor'>
                <Editor
                    spellCheck
                    // editorRef={editorRef}
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    // onContentStateChange={() => {  }}
                    editorClassName='bunton-custom-editor'
                    wrapperStyle={{
                        border: '2px solid #bcddd2',
                        borderRadius: '20px'
                    }}
                    toolbarStyle={{
                        backgroundColor: 'transparent',
                        borderRadius: '20px 20px 0 0'
                    }}
                    editorStyle={{
                        padding: '15px',
                        maxHeight: '300px',
                        overflowY: 'scroll',
                        margin: '8px',
                        ...editorStyle
                    }}
                    toolbar={{
                        options: ['inline', 'list'],
                        inline: {
                            options: ['italic', 'bold']
                        }
                    }}
                    stripPastedStyles={true}
                />
            </Box>
        </React.Fragment>
    )
}

export default WYSIWYGEditor
