import { Container } from '@mui/material'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import BasicPageHeader from './BasicPageHeader'
import Header from './Header'
import AdminLayout from './AdminLayout'
import Footer from '../components/organisams/footer'
import ResponsiveAppBar from '../components/organisams/ResponsiveAppBar'

const Layout = props => {
    const { loginDetails, loginCheckAuthDetails } = useSelector(state => state.login)
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null
    return userRole === 'admin' ? (
        <AdminLayout>
            <Fragment>{props.children}</Fragment>
        </AdminLayout>
    ) : (
        <Container
            maxWidth={false}
            disableGutters={true}
            sx={{
                backgroundColor: 'rgba(255, 250, 244, 1)',
                overflowX: 'hidden',
                height: 'inherit'
            }}
            id='container'
        >
            <ResponsiveAppBar />
            {props.headerRequired ? (
                props.page === 'home' ? (
                    <Header />
                ) : (
                    <BasicPageHeader {...props} />
                )
            ) : null}
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    my: '90px',
                    paddingBottom: {
                        sm: '30px',
                        md: '60px'
                    },

                    pt: 1,
                    minHeight: '700px',
                    ...props.containerSx
                }}
            >
                {/* <Header /> */}
                <Fragment>{props.children}</Fragment>
            </Container>
            <Footer />
        </Container>
    )
}

export default Layout
