import { Box, FormControl, FormHelperText, RadioGroup } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import CheckBoxChip from '../elements/CheckBoxChip'

const ControlledRadioGroup = props => {
    const { errors, options } = props

    return (
        <Controller
            control={props.control}
            rules={{ ...props.rules }}
            render={({ field }) => {
                return (
                    <Box>
                        <FormControl error={errors ?true : false}>
                            <RadioGroup
                                name={props.name}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                value={field.value}
                                onChange={e => {
                                    field.onChange(e.target.value)
                                }}
                            >
                                {options.map((option, index) => {
                                    let val = props.handleById ? option.id : option.value
                                    return (
                                        <CheckBoxChip
                                            key={index}
                                            register={props.register}
                                            parentName={props.name}
                                            label={option.name}
                                            type={props.type}
                                            value={val}
                                            // checked={field.value === option.value}
                                        />
                                    )
                                })}
                            </RadioGroup>
                            {/* {errors ? (
                                <FormHelperText id='my-helper-text' error={true}>{errors}</FormHelperText>
                            ) : null} */}
                        </FormControl>
                    </Box>
                )
            }}
            name={props.name}
        />
    )
}

export default ControlledRadioGroup
