import React from 'react'
import { Button as MuiButton } from '@mui/material'
import './Button.scss'

const Button = React.forwardRef((props, ref) => {
    const { label, sx, variant, size, startIcon, endIcon, disabled, color, classes, onClick } =
        props
    return (
        <MuiButton
            ref={ref}
            variant={variant}
            size={size}
            disabled={disabled}
            color={color}
            classes={classes}
            endIcon={endIcon}
            sx={sx}
            startIcon={startIcon}
            {...props}
            onClick={onClick}
        >
            {label ? label : {...props.children}}
        </MuiButton>
    )
})

export default Button
