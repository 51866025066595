import React from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, Grid, Typography } from '@mui/material'
import ReactHtmlParser from 'react-html-parser'
import variable from '../../settings/_variables.scss'

const ContactUsRequestModal = props => {
    const { open, onClose, data } = props
    return (
        <BaseModal
            open={open}
            onClose={onClose}
            modalTitle={
                <Typography
                    variant='h3'
                    className='small-size-18px'
                >{`Request Details by ${data.name}`}</Typography>
            }
            boxSx={{ minHeight: 'auto' }}
        >
            <Box>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ p: 1 }}>
                        <Typography variant='h5' className='small-size-strong'>
                            Name
                        </Typography>
                        <Typography variant='h5' className='small-size-16px' sx={{wordWrap: 'break-word'}}>
                                {data.name}
                            </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ p: 1 }}>
                        <Typography variant='h5' className='small-size-strong'>
                            Email
                        </Typography>
                        <Typography variant='h5' className='small-size-16px' >
                                {data.email}
                            </Typography>
                    </Grid>
                </Grid>

                {/* {data.user && (
                    <Box
                        sx={{
                            mt: 2
                        }}
                    >
                        <Box>
                            <Typography>User Profile Link: </Typography>
                        </Box>
                        <Box sx={{ ml: 2 }}><Typography>{data.user}</Typography></Box>
                    </Box>
                )} */}
                <Box
                    sx={{
                        mt: 2,
                        p: 1,
                        width: 'auto',
                        maxHeight: {
                            xs: '250px',
                            sm: '400px'
                        }
                    }}
                >
                    <Box>
                        <Typography variant='h5' className='small-size-strong'>
                            Message
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: variable.CYAN1,
                            width: 'auto',
                            maxHeight: {
                                xs: '200px',
                                sm: '350px'
                            },
                            overflowY: 'scroll',
                            p: 2,
                            mt: 2
                        }}
                    >
                        <Box>{ReactHtmlParser(data.message)}</Box>
                    </Box>
                </Box>
            </Box>
        </BaseModal>
    )
}

export default ContactUsRequestModal
