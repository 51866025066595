import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import Icons from '../Icons'
import BACK from '../../assets/icons/BACK.svg'
import { useNavigate } from 'react-router-dom'

const PageHeaderWithBackButton = props => {
    const navigate = useNavigate()
    return <Grid container alignItems='center' sx={{px: {xs: 2, sm:5, md: 5}, py: 3}}>
    <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center'}}>
        <Typography variant='h2' className='strong pageHeader'>
            {props.pageTitle}
        </Typography>
    </Grid>
</Grid>
}

export default PageHeaderWithBackButton