/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
    Box,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material'
import { Button, TextInput } from '../../../components'
import Icons from '../../../components/Icons'
import { Placeholder, TextStrings } from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import './styles.scss'
import variables from '../../../settings/_variables.scss'
import { useEffect, useRef, useState } from 'react'
import { keys } from '../../../constants/strings'
import SearchIcon from '@mui/icons-material/Search'
import { LANGUAGES } from '../../../constants/constants'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import {
    addLanguageDataSuccess,
    addProfileDetailFailure
} from '../../../redux/actions/profileDetail'
import { styles } from './styles'
import { useMutation } from '@apollo/client'
import { MANAGE_USER_PROFILE_MUTATION } from '../../../gql/queries'
import Loader from '../../../components/Loader'
import PageMetaTags from '../../../base/PageMetaTags'

const LanguagesDetail = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [search, setSearch] = useState()
    const [languageData, setLanguageData] = useState(LANGUAGES)
    const [languageDataClone, setLanguageDataClone] = useState(LANGUAGES)
    const [apiError, setApiError] = useState('')
    const formRef = useRef(null)

    /** updating list of languages on search change */
    useEffect(() => {
        if (search) {
            const updatedLanguageData = languageDataClone.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
            setLanguageDataClone([...updatedLanguageData])
        } else {
            setLanguageDataClone([...languageData])
        }
    }, [search])

    /** api integration graphql */
    const [manageProfileDetailMutation, { data, error, loading }] = useMutation(
        MANAGE_USER_PROFILE_MUTATION,
        {
            onCompleted(data) {
                let formData = []
                formData['key'] = keys.languages
                formData['data'] = data.manageUserProfile.user.candidate
                dispatch(addLanguageDataSuccess(formData))
                navigate(-1)
            },
            onError(error) {
                setApiError(error)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    /**
     * @method onSaveClick
     * @description saving language selected data
     */
    const onSaveClick = () => {
        setApiError('')
        const filteredLang = languageData.filter(roles => roles.selected)
        const filteredLangCommaSeprated = Array.prototype.map
            .call(filteredLang, function (item) {
                if (item.selected) {
                    return item.name
                }
            })
            .join(',')
        let formData = []
        formData['key'] = keys.languages
        formData['data'] = { language: filteredLangCommaSeprated }
        manageProfileDetailMutation({
            variables: {
                ...formData.data
            }
        })
        // dispatch(addLanguageDataSuccess(formData))
        // navigate(-1)
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (languageDataClone.filter(e => e.selected).length > 0) {
                    formRef.current.click()
                }
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [languageDataClone])

    /**
     * @method handleToggle
     * @description handling list click
     * @param value selected language value
     */
    const handleToggle = (value, event) => {
        const index = languageData.findIndex(obj => obj.name === value)
        const tempData = [...languageData]
        tempData[index] = {
            ...tempData[index],
            selected: tempData[index].selected !== undefined ? !tempData[index].selected : true
        }
        setLanguageData(tempData)
        if (search) {
            const updatedLanguageData = tempData.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
            setLanguageDataClone([...updatedLanguageData])
        } else {
            setLanguageDataClone(tempData)
        }
    }
    const location = useLocation()

    return (
        <Box sx={styles.container}>
            <PageMetaTags
                title={`${
                    location.pathname.includes('profileSetup') ? 'Profile Setup | ' : ''
                }Bunton`}
            />
            <Box sx={styles.header}>
                <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                    {`${TextStrings.select} ${TextStrings.languages.toLowerCase()}`}
                </Typography>
                <Box
                    sx={styles.backIconContainer}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <Icons src={BACK} />
                </Box>
            </Box>
            <Box sx={styles.searchContainer}>
                {/* <TextInput
                    type='text'
                    name='university'
                    placeholder={Placeholder.search}
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    sx={styles.textInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start' sx={{}}>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                /> */}
                <Box className='language-list' sx={styles.listWrapper}>
                    <List sx={styles.listContainer}>
                        <Box>
                            {languageDataClone.map((item, index) => {
                                return (
                                    <ListItem
                                        key={item.name}
                                        disablePadding
                                        sx={[
                                            styles.listItem,
                                            {
                                                backgroundColor: item.selected
                                                    ? variables.CYAN
                                                    : variables.WHITE1
                                            }
                                        ]}
                                    >
                                        <ListItemButton
                                            // onClick={event => {
                                            //     console.log('top suggestion clicked')
                                            //     handleToggle(item.name)
                                            // }}

                                            onClick={e => {
                                                if (e.key !== 'Enter') {
                                                    handleToggle(item.name)
                                                }
                                            }}
                                            sx={styles.listItemButton}
                                        >
                                            <ListItemIcon sx={{ flex: 1 }}>
                                                <CloseIcon
                                                    style={{
                                                        color: item.selected
                                                            ? 'white'
                                                            : 'transparent'
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                id={`checkbox-list-label-${item.name}`}
                                                primary={`${item.name}`}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </Box>
                    </List>
                </Box>
                {apiError && apiError?.message ? (
                    <FormHelperText 
                        id='my-helper-text'
                        error={true}
                    >
                        <strong>{apiError?.message}</strong>
                    </FormHelperText>
                ) : null}
                <Button
                    ref={formRef}
                    label={TextStrings.save}
                    onClick={onSaveClick}
                    color='primary'
                    disabled={!languageDataClone.filter(e => e.selected).length > 0}
                    variant='contained'
                    size='large'
                    type='submit'
                    sx={styles.button}
                />
            </Box>
            {loading && <Loader />}
        </Box>
    )
}

export default LanguagesDetail
