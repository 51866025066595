import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { React, useState } from 'react'
import { StyledIconBox, StyledListRow } from '../../base/commonStyles'
import Icons from '../Icons'
import EducationIcon from '../../assets/icons/EDUCATION.svg'
import EDITICON from '../../assets/icons/EDIT.svg'
import DeleteIcon from '../../assets/icons/DELETE.svg'

import variables from '../../settings/_variables.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { MANAGE_EDUCATION_MUTATION } from '../../gql/queries'
import { keys } from '../../constants'
import { deleteProfileDetailSuccess } from '../../redux/actions/profileDetail'
import DeleteEntityBox from '../DeleteEntityBox'
import ManageEducationModal from '../organisams/ManageEducationModal'

const EducationList = props => {
    const { location } = useLocation()
    const [deleteEducation, setDeleteEducationModal] = useState(false)
    const [manageEduModalOpen, setManageEduModalOpen] = useState(false)
    const { navigate } = useNavigate()
    const dispatch = useDispatch()
    const { data, isEdit } = props

    const handleDeleteClose = () => {
        setDeleteEducationModal(false)
    }

    const [deleteMutation, { loading }] = useMutation(MANAGE_EDUCATION_MUTATION, {
        onCompleted(data) {
            setDeleteEducationModal(false)
            let formData = []
            formData['key'] = keys.education
            formData['data'] = {
                id: data.manageEducation.id,
                actionType: data.manageEducation.actionType
            }
            dispatch(deleteProfileDetailSuccess(formData))
            navigate(location.pathname, { replace: true })
        }
    })
    return (
        <StyledListRow
            maxWidth={false}
            disableGutters
            sx={{
                py: {
                    xs: 2,
                    sm: '30px'
                }
            }}
        >
            <Grid container spacing={{ xs: 1, sm: 2 }} alignItems='center'>
                <Grid item xl={2} lg={2} md={4} xs={12} sm={3} sx={{ textAlign: 'center' }}>
                    <Box
                        component='img'
                        src={EducationIcon}
                        sx={{
                            height: '75px',
                            width: '75px',
                            border: '3px solid #fff',
                            borderRadius: '50px'
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xl={isEdit ? 7 : 10}
                    lg={isEdit ? 7 : 10}
                    md={isEdit ? 8 : 10}
                    xs={12}
                    sm={isEdit ? 9 : 8}
                >
                    <Box>
                        <Typography variant='h5' className='small-size'>
                            {data && data.certificateDegreeName ? data.certificateDegreeName : ''}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='h5' className='small-size-strong'>
                            {data && data.major ? data.major : ''}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Typography variant='h3' className='strong small-size-18px'>
                            {data && data.yearOfCompletion
                                ? moment(new Date(data.yearOfCompletion)).format('YYYY')
                                : 'N/A'}
                        </Typography>
                    </Box>
                </Grid>
                {isEdit ? (
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                            textAlign: {
                                xs: 'center',
                                lg: 'right'
                            },
                            marginTop: '12px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: {
                                    xs: 'center',
                                    lg: 'right'
                                }
                            }}
                        >
                            <Tooltip title='Edit'>
                                <StyledIconBox
                                    sx={{
                                        background: '#fff',
                                        marginLeft: '15px'
                                    }}
                                    onClick={() => {
                                        setManageEduModalOpen(true)
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '5px',
                                            color: variables.BLUE
                                        }}
                                    >
                                        <Icons
                                            src={EDITICON}
                                            sx={{
                                                height: '30px',
                                                width: '30px'
                                            }}
                                        />
                                    </Box>
                                </StyledIconBox>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <StyledIconBox
                                    sx={{
                                        background: `${variables.ORANGE}`,
                                        marginLeft: '15px'
                                    }}
                                    onClick={() => {
                                        setDeleteEducationModal(true)
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '25%',
                                            color: '#ffffff'
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={DeleteIcon}
                                            sx={{ height: '20px' }}
                                        />
                                    </Box>
                                </StyledIconBox>
                            </Tooltip>
                        </Box>
                    </Grid>
                ) : null}
            </Grid>
            <DeleteEntityBox
                open={deleteEducation}
                deleteMutation={deleteMutation}
                entityId={props.data.id}
                deleteLoading={loading}
                closeDelete={handleDeleteClose}
            />
            <ManageEducationModal
                open={manageEduModalOpen}
                onClose={() => {
                    setManageEduModalOpen(false)
                }}
                detailId={props.data.id}
            />
        </StyledListRow>
    )
}

export default EducationList
