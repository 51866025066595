import { Box, Fade, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import variables from '../settings/_variables.scss'
import { Close } from '@mui/icons-material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '70%',
        sm: '60%',
        md: '50%',
        lg: '40%'
    },
    minHeight: '300px',
    bgcolor: variables.WHITE1,
    border: `1px solid ${variables.BLUE}`,
    boxShadow: 10,
    p: 3,
    borderRadius: 5
}

const BaseModal = props => {
    const { open, onClose, modalTitle, hideClose } = props
    return (
        <Modal
            open={open}
            backgroundColor='#fff'
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: variables.WHITEOPACITY,
                position: 'fixed',
                top: '0',
                left: '0',
                backdropFilter: 'blur(2px)',
                zIndex: 9
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        background: variables.CYAN,
                        opacity: '0.7 !important'
                    },
                    timeout: 500
                }
            }}
        >
            <Fade in={open}>
                <Box sx={{ height: 'auto', ...style, ...props.boxSx }}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid
                            item
                            md={hideClose ? 12 : 10}
                            sm={hideClose ? 12 : 10}
                            xs={hideClose ? 12 : 10}
                            sx={{ ...props.titleSx }}
                        >
                            {modalTitle && (
                                <Box sx={{ width: '100%' }} alignItems='center'>
                                    {modalTitle}
                                </Box>
                            )}
                        </Grid>
                        {!hideClose && (
                            <Grid item md={2} sm={2} xs={2} sx={{ textAlign: 'right' }}>
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        width: 'fit-content',
                                        float: 'right'
                                    }}
                                    onClick={() => {
                                        onClose()
                                    }}
                                >
                                    <Close
                                        sx={{
                                            color: variables.ORANGE,
                                            verticalAlign: 'middle',
                                            fontSize: '35px'
                                        }}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{ mt: 3, px: 1, pb: 1, ...props.contentBoxSx }}>{props.children}</Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default BaseModal
