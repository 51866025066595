import React from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, Typography } from '@mui/material'
import variable from '../../settings/_variables.scss'
const CandidateResponseModal = props => {
    const { open, onClose, data } = props
    return (
        <BaseModal
            open={open}
            onClose={onClose}
            modalTitle={
                <Typography
                    variant='h3'
                    className='small-size-18px'
                >{`${data.name} Response`}</Typography>
            }
            boxSx={{ minHeight: 'auto', maxHeight: '500px' }}
            contentBoxSx={{maxHeight: '400px', overflowY:'scroll'}}
        >
            <Box >
                {
                    data.candidateResponses.map((response, index) =>  <Box sx={{my: 3}} key={index}>
                            <Typography variant='h3' className='small-size-18px strong'>
                                {index+1}. {response.engagementQuestion && response.engagementQuestion.question}
                            </Typography>
                            <Box sx={{display:'flex', p: 2, background: variable.CYAN1, borderRadius:'10px', my: 1, alignItems :'center'}}>
                                <Typography variant='h3' className='small-size-18px'>
                                    {response.engagementQuestionAnswer && response.engagementQuestionAnswer.answer}
                                </Typography>
                            </Box>
                        </Box>
                    )
                }
            </Box>
        </BaseModal>
    )
}

export default CandidateResponseModal
