import { Box, CircularProgress, Container, Typography } from '@mui/material'
import variables from '../../settings/_variables.scss'
import React from 'react'

const SliderLoadMoreCard = props => {
    const { onClick, listLoader } = props
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                background: variables.CYAN1,
                borderRadius: '50px',
                p: '20px',
                mr: { xs: 0, sm: 2 },
                mb: { xs: 2, sm: 0 },
                minWidth: '300px',
                width: {
                    xs: '100%',
                    sm: '332px'
                },
                minHeight: {
                    xs: '100px',
                    sm: '337px'
                },
                height: {
                    xs: '100px',
                    sm: '100%'
                }
            }}
        >
            <Box
                sx={{
                    maxWidth: '200px',
                    textAlign: 'center',
                    m: 'auto',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    verticalAlign: 'middle'
                }}
                onClick={() => onClick()}
            >
                {listLoader ? (
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <CircularProgress
                            size={20}
                            sx={{
                                height: '20px',
                                width: '20px',
                                color: variables.BLUE
                            }}
                        />
                    </Box>
                ) : (
                    <Typography
                        variant='h3'
                        className='strong'
                        sx={{ fontSize: '20px !important' }}
                    >
                        {props.text}
                    </Typography>
                )}
            </Box>
        </Container>
    )
}

export default SliderLoadMoreCard
