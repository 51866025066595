import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'

import variables from '../../settings/_variables.scss'
import UserSettingsIcon from '../../assets/icons/user-settings.svg'
import UserNotificationModal from '../organisams/UserNotificationModal'
import { useMutation } from '@apollo/client'
import { DELETE_USER_MUTATION } from '../../gql/queries'
import DeleteEntityBox from '../DeleteEntityBox'
import { useNavigate } from 'react-router-dom'

const ManageUserSettings = props => {
    const {candidateUserId} = props
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showStatus, setShowStatus]= useState(false)
    const [openNotificationModal, setOpenNotificationModal] = useState(false)
    const [deleteProfileModal, setDeleteProfileModal] = useState(false)
    const open = Boolean(anchorEl)
    const handleMoreOptionClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleMoreOptionClose = () => {
        setAnchorEl(null)
    }

    const [deleteUserMutation, { loading: deleteLoading }] = useMutation(DELETE_USER_MUTATION, {
        onCompleted(delete_data) {
            setShowStatus(true)
            setTimeout(() => {
                setDeleteProfileModal(false)
                navigate('/admin-portal/users')
            }, 3000)
        },
        onError(error) {
            // setApiError(error)
            // dispatch(addProfileDetailFailure(error.message))
        }
    })

    const deleteProfile = value => {
        deleteUserMutation({ variables: { id: candidateUserId } }) 
    }
    return <Box sx={{ textAlign: 'left' }}>
        <Button
            id='more-options-button'
            aria-controls={open ? 'more-options-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            color='secondary'
            sx={{
                p: '10px 30px',
                background: 'transparent',
                '&:hover': {
                    background: 'transparent'
                }
            }}
            disableElevation
            onClick={handleMoreOptionClick}
            label=''
            // endIcon={<KeyboardArrowDownIcon />}
        >
            <Box
                component='img'
                sx={{
                    width: '22x',
                    height: '22px'
                }}
                src={UserSettingsIcon}
            />
        </Button>
        <Menu
            id='more-options-menu'
            MenuListProps={{
                'aria-labelledby': 'more-options-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMoreOptionClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    background: variables.CYAN,
                    width: '200px',
                    maxWidth: '300px'
                }
            }}
        >
            <MenuItem
                onClick={() => {
                    setOpenNotificationModal(true)
                    handleMoreOptionClose()
                }}
                disableRipple
                sx={{
                    p: 2,
                    textAlign: 'center'
                }}
            >
                <Typography
                    variant='h5'
                    className='small-size-strong'
                    sx={{ width: '100%' }}
                >
                    Notifications
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setDeleteProfileModal(true)
                    handleMoreOptionClose()
                }}
                disableRipple
                sx={{
                    p: 2,
                    textAlign: 'center'
                }}
            >
                <Typography
                    variant='h5'
                    className='small-size-strong'
                    sx={{ width: '100%' }}
                >
                    Delete Profile
                </Typography>
            </MenuItem>
        </Menu>
        {
            openNotificationModal && <UserNotificationModal open={openNotificationModal} onClose={() => setOpenNotificationModal(false)} candidateUserId={candidateUserId}/>
        }
        {
            deleteProfileModal && <DeleteEntityBox
                open={deleteProfileModal}
                deleteMutation={() => deleteProfile('')}
                entityId={1}
                deleteLoading={deleteLoading}
                closeDelete={() => setDeleteProfileModal(false)}
                showStatus={showStatus}
            />
        }
    </Box>
}

export default ManageUserSettings