import React, { useEffect } from 'react'
import { LOGOUT_MUTATION } from '../queries/signInSignOutQueries'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { logoutSuccess } from '../redux/actions/login'
import { deleteAllProfileDetails } from '../redux/actions/profileDetail'
import Cookies from 'universal-cookie'
import Loader from '../components/Loader'

const cookies = new Cookies()
const Logout = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [logoutMutation, { data }] = useMutation(LOGOUT_MUTATION, {
        onCompleted(data) {
            dispatch(logoutSuccess(data))
            dispatch(deleteAllProfileDetails(data))
            cookies.remove('BUNTON_ACCESS_TOKEN')
            cookies.remove('BUNTON_CLIENT_TOKEN')
            cookies.remove('BUNTON_EXPIRY')
            cookies.remove('BUNTON_UID')
            // navigate('/login')
        }
    })
    useEffect(() => {
        async  function logout(){
            await logoutMutation()
            navigate('/login')
        }
        logout()
    }, [])
    return <>
        <Loader/>
    </>
}

export default Logout