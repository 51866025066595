import { gql } from "@apollo/client";

export const GET_ACTIVE_JOBS_QUERY = gql`
query GetCompaniesActiveJobs($id: ID!, $afterCursor: String) {
    getCompaniesActiveJobs(first: 3, id: $id, after: $afterCursor) {
        edges {
            node {
                id
                jobTitle
                companyName
                experienceRequired
                companyLogoUrl
                status
                otherDepartment
                otherIndustry
                departments {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                states {
                    id
                    name
                }
            }
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
    }
}
`