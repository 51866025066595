import { TablePagination } from '@mui/material'
import React from 'react'

const CustomTablePagination = props => {
    const { count, batchSize, page, handleChangePage } = props
    return (
        <TablePagination
            component='div'
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={batchSize}
            rowsPerPageOptions={false}
            // showFirstButton={true}
            // showLastButton={true}
        />
    )
}

export default CustomTablePagination
