import React, { useState } from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import variables from '../../settings/_variables.scss'
import ModalStatusSection from '../elements/ModalStatusSection'
import SuccessIcon from '../../assets/icons/success_green.svg'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { SEND_JOB_REVIEW_MUTATION } from '../../queries/commonQueries'
import CustomFormLabel from '../elements/CustomFormLabel'
import ControlledTextInput from '../molecules/ControlledTextInput'
import { ErrorMessage, Regex } from '../../constants'
import Button from '../Button'
import { stripHtml } from 'string-strip-html'
import WYSIWYGEditor from '../WYSIWYGEditor'

const SendJobForReviewModal = props => {
    const { open, handleClose, jobPostId } = props
    const [showStatus, setShowStatus] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    let initialFormState = {
        reviewerName: '',
        reviewerEmail: ''
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        register
    } = useForm({
        defaultValues: initialFormState
    })

    const [sendForReviewMutation, { loading, data, error }] = useMutation(
        SEND_JOB_REVIEW_MUTATION,
        {
            onCompleted(data) {
                setIsSaving(false)
                setShowStatus(true)
                setTimeout(() => {
                    reset()
                    handleClose()
                }, 3000)
            },
            onError(error) {
                console.log(error, 'error')
            }
        }
    )

    const formSubmit = async data => {
        setIsSaving(true)
        try {
            let formData = {}
            formData = {
                reviewerName: data.reviewerName,
                reviewerEmail: data.reviewerEmail,
                personalizedMessage: data.personalizedMessage
            }
            sendForReviewMutation({
                variables: {
                    jobId: jobPostId,
                    ...formData
                }
            })
        } catch (err) {
            // setApiError(err)
        }
    }


    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            modalTitle={
                <Typography variant='h3' className='small-size-18px'>
                    Send Job Details for Review
                </Typography>
            }
            boxSx={{
                width: {
                    xs: '80%',
                    sm: '70%',
                    md: '60%',
                    lg: '50%'
                },
                maxHeight: {
                    xs:'400px',
                    sm: '500px'
                },
                overflowY: 'none',
                height: '80%'
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <CircularProgress size={50} sx={{ color: variables.BLUE }} />
                </Box>
            ) : showStatus ? (
                <ModalStatusSection
                    statusIcon={SuccessIcon}
                    statusText='Job Description sent for review successfully.'
                />
            ) : (
                <Box sx={{ maxHeight: {
                        xs: '300px',
                        sm: '400px',
                        md: '400px'
                    },
                    height: '60%',
                    p: 1,
                    overflowY: {
                        xs: 'scroll',
                        md: 'scroll'
                    },
                    overflowX: 'hidden',
                }}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item md={12} sm={12} xs={12}>
                                <CustomFormLabel
                                    labelSx={{mt: 1}}
                                    formLabel='Reviewer Name*'
                                    formFieldError={
                                        errors &&
                                        errors.reviewerName &&
                                        errors.reviewerName.message
                                    }
                                />

                                <ControlledTextInput
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                ErrorMessage.jobDescriptionReviewerName
                                        },
                                        validate: value => {
                                            return (
                                                !!value.trim() ||
                                                ErrorMessage.jobDescriptionReviewerName
                                            )
                                        }
                                    }}
                                    name='reviewerName'
                                    type='text'
                                    errors={
                                        errors &&
                                        errors.reviewerName &&
                                        errors.reviewerName.message
                                    }
                                    required={true}
                                    fullWidth={true}
                                    formContolSx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <CustomFormLabel
                                    labelSx={{mt: 1}}
                                    formLabel='Reviewer Email*'
                                    formFieldError={
                                        errors &&
                                        errors.reviewerEmail &&
                                        errors.reviewerEmail.message
                                    }
                                />

                                <ControlledTextInput
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                ErrorMessage.jobDescriptionReviewerEmail
                                        },
                                        pattern: {
                                            value: Regex.emailRegex,
                                            message:
                                                ErrorMessage.validEmail
                                        }
                                    }}
                                    name='reviewerEmail'
                                    type='text'
                                    errors={
                                        errors &&
                                        errors.reviewerEmail &&
                                        errors.reviewerEmail.message
                                    }
                                    required={true}
                                    fullWidth={true}
                                    formContolSx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{mt: 1}}>
                            <CustomFormLabel
                                labelSx={{mt: 1}}
                                formLabel='Personalized Message*'
                                formFieldError={
                                    errors &&
                                    errors.personalizedMessage &&
                                    errors.personalizedMessage.message
                                }
                            />
                            <Controller
                                control={control}
                                rules={{
                                    validate: {
                                        required: v =>
                                            (v && stripHtml(v).result.length > 0) ||
                                            ErrorMessage.emptyCustomNotificationBody
                                    }
                                }}
                                defaultValue=''
                                render={({ field }) => (
                                    <WYSIWYGEditor
                                        name='personalizedMessage'
                                        {...field}
                                        register={register}
                                        editorStyle={{ maxHeight: '100px' }}
                                    />
                                )}
                                name='personalizedMessage'
                            />
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <Button
                            label={
                                isSaving ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CircularProgress
                                            size={20}
                                            sx={{ mr: 2 }}
                                        />{' '}
                                        Sending...
                                    </Box>
                                ) : (
                                    'Send for Review'
                                )
                            }
                            color='primary'
                            variant='contained'
                            disabled={isSaving}
                            size='large'
                            sx={{ mr: 2, py: 2, px: 4 }}
                            onClick={handleSubmit(formSubmit)}
                        />
                    </Box>
                </Box>
            )}
        </BaseModal>
    )
}

export default SendJobForReviewModal
