import React, { useEffect, useRef, useState } from 'react'
import BaseModal from '../../template/BaseModal'
import {
    Box,
    FormHelperText,
    IconButton,
    ImageList,
    ImageListItem,
    Typography
} from '@mui/material'
import Icons from '../Icons'
import variables from '../../settings/_variables.scss'
import { AVATAR, keys, TextStrings } from '../../constants'
import { useMutation } from '@apollo/client'
import { MANAGE_USER_PROFILE_IMAGE_MUTATION } from '../../gql/queries'
import { addAvatarDetailSuccess, addProfileDetailFailure } from '../../redux/actions/profileDetail'
import { setAvatar } from '../../redux/actions/login'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Button'
import ModalStatusSection from '../elements/ModalStatusSection'
import SuccessIcon from '../../assets/icons/success_green.svg'

const SelectAvatarModal = props => {
    const dispatch = useDispatch()
    const { open, onClose } = props
    const [selectedAvatar, setSelectedAvatar] = useState()
    const [apiError, setApiError] = useState('')
    const [showThankyouMessage, setShowThankyouMessage] = useState(false)
    const formRef = useRef(null)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (selectedAvatar) {
                    formRef.current.click()
                }
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [selectedAvatar])

    const onSaveClick = () => {
        setApiError('')
        let formData = []
        formData['key'] = keys.selectAnAvatar
        formData['data'] = { profileImage: selectedAvatar, actionType: 'create' }
        manageProfileImageMutation({
            variables: {
                ...formData.data
            }
        })
    }

    const [manageProfileImageMutation, { data, error, loading }] = useMutation(
        MANAGE_USER_PROFILE_IMAGE_MUTATION,
        {
            onCompleted(data) {
                setShowThankyouMessage(true)
                setTimeout(() => {
                    let formData = []
                    formData['key'] = keys.selectAnAvatar
                    formData['data'] = { profileImage: data.manageProfileImage.user.profileImage }
                    dispatch(addAvatarDetailSuccess(formData))
                    dispatch(setAvatar(data.manageProfileImage.user.profileImage))
                    props.onClose()
                    setShowThankyouMessage(false)
                }, 3000)
            },
            onError(error) {
                setApiError(error)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    return (
        <BaseModal
            open={open}
            onClose={onClose}
            modalTitle={
                <Typography
                    variant='h3'
                    className='small-size-18px'
                >{`Select an Avatar`}</Typography>
            }
            boxSx={{ minHeight: '300px' }}
        >
            {showThankyouMessage ? (
                <ModalStatusSection
                    statusIcon={SuccessIcon}
                    statusText='Thank you for selecting your avatar.'
                />
            ) : (
                <Box>
                    <ImageList
                        cols={
                            responsiveBreakpoint === 'mob'
                                ? 3
                                : responsiveBreakpoint === 'tab'
                                ? 4
                                : 5
                        }
                        rowHeight={110}
                        sx={{ maxHeight: '400px' }}
                    >
                        {AVATAR.map(item => (
                            <ImageListItem key={item.name}>
                                <IconButton
                                    onClick={() => setSelectedAvatar(item.name)}
                                    sx={{
                                        height: {
                                            xs: '65px',
                                            sm: '75px',
                                            md: '85px'
                                        },
                                        width: {
                                            xs: '65px',
                                            sm: '75px',
                                            md: '85px'
                                        },
                                        '&:hover': {
                                            backgroundColor: variables.CYAN1,
                                            height: {
                                                xs: '65px',
                                                sm: '75px',
                                                md: '85px'
                                            },
                                            width: {
                                                xs: '65px',
                                                sm: '75px',
                                                md: '85px'
                                            }
                                        }
                                    }}
                                >
                                    <Icons
                                        sx={{
                                            height: {
                                                xs: '60px',
                                                sm: '70px',
                                                md: '80px'
                                            },
                                            width: {
                                                xs: '60px',
                                                sm: '70px',
                                                md: '80px'
                                            },
                                            borderRadius: '70px',
                                            border:
                                                selectedAvatar === item.name
                                                    ? `3px solid ${variables.BLUE}`
                                                    : 0
                                        }}
                                        src={require(`../../assets/icons/AVATAR/${item.name}.png`)}
                                    />
                                </IconButton>
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <Box>
                        {apiError && apiError?.message ? (
                            <FormHelperText id='my-helper-text' error={true}>
                                <strong>{apiError?.message}</strong>
                            </FormHelperText>
                        ) : null}
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                ref={formRef}
                                label={TextStrings.save}
                                onClick={onSaveClick}
                                color='primary'
                                disabled={!selectedAvatar}
                                variant='contained'
                                size='large'
                                type='submit'
                                sx={{ width: '122px', p: '10px 14px' }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </BaseModal>
    )
}

export default SelectAvatarModal
