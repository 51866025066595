import {
    Box,
    CircularProgress,
    Container,
    FormHelperText,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Layout from '../../template/Layout'
import variables from '../../../src/settings/_variables.scss'
import { Button } from '../../components'
import { StyledTabHeading } from '../../base/commonStyles'
import PageMetaTags from '../../base/PageMetaTags'
import { Controller, useForm } from 'react-hook-form'

import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorMessage, Regex } from '../../constants'
import Loader from '../../components/Loader'
import StatusBox from '../../components/StatusBox'
import CustomFormLabel from '../../components/elements/CustomFormLabel'
import ControlledTextInput from '../../components/molecules/ControlledTextInput'
import { stripHtml } from 'string-strip-html'
import WYSIWYGEditor from '../../components/WYSIWYGEditor'

const MANAGE_CONTACT_US_MUTATION = gql`
    mutation manageContactUs(
        $name: String
        $email: String
        $message: String
        $userId: ID
        $actionType: String!
        $id: ID
    ) {
        manageContactUs(
            name: $name
            email: $email
            message: $message
            userId: $userId
            actionType: $actionType
            id: $id
        ) {
            contactUs {
                id
                name
                email
                message
                user {
                    id
                    firstName
                    email
                }
            }
            actionType
        }
    }
`

const getContactUsDataMutation = gql`
    query ContactUs($id: ID!) {
        contactUs(id: $id) {
            id
            name
            message
            email
            user {
                name
            }
        }
    }
`

const ContactUs = props => {
    let urlParams = useParams()
    const navigate = useNavigate()
    const isEditPage = urlParams.contactUsId ? true : false
    const [isSaving, setIsSaving] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage] = useState(
        'Thanks for contacting us, we will get back to you as soon as possible!'
    )
    // const { allIndustries, allStates, allBenefits } = useSelector(state => state.initial)
    const [apiError, setApiError] = useState('')

    let initialFormState = {
        email: '',
        name: '',
        message: ''
    }

    const { loading, data, error } = useQuery(getContactUsDataMutation, {
        variables: { id: urlParams.contactUsID },
        fetchPolicy: 'network-only',
        skip: !isEditPage,
        onCompleted(data) {
            let formData = {
                name: data.contactUs.name,
                email: data.contactUs.email,
                message: data.contactUs.companyWebsite,
                name: data?.contactUs?.user?.name
            }

            reset(formData)
        }
    })
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        register
    } = useForm({
        defaultValues: initialFormState
    })
    const message = watch('message')

    useEffect(() => {
        if (!isEditPage) {
            reset(initialFormState)
        }
    }, [isEditPage])


    const [manageContactUsMutation, { loading: saveLoading }] = useMutation(
        MANAGE_CONTACT_US_MUTATION,
        {
            onCompleted(data) {
                setIsSaving(false)
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    reset(initialFormState)

                    navigate(-1)
                }, 3000)
            },
            onError(error) {
                setIsSaving(false)
                setApiError(error)
            }
        }
    )

    const formSubmit = data => {
        setApiError('')
        let formData = {}
        setIsSaving(true)
        if (urlParams.contactUsID) {
            formData = {
                id: urlParams.contactUsID,
                name: data.name,
                email: data.email,
                message: data.message,
                actionType: 'update'
            }
        } else {
            formData = {
                name: data.name,
                email: data.email,
                message: data.message,
                actionType: 'create'
            }
        }

        manageContactUsMutation({
            variables: {
                ...formData
            }
        })
    }
    return (
        <Layout>
            <PageMetaTags title={`${isEditPage ? 'Edit' : 'Add New'} Contact Us | Bunton`} />
            <Container
                maxWidth='988px'
                disableGutters
                sx={{
                    maxWidth: {
                        xs: '100vw',
                        sm: '100vw',
                        md: '988px',
                        lg: '988px',
                        xl: '988px'
                    },
                    paddingTop: '76.5px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        padding: {
                            xs: '0px 20px',
                            sm: '0px 80px',
                            md: '0px 0px',
                            lg: '0px 0px',
                            xl: '0px 0px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            margin: '0 auto'
                        }}
                    >
                        <StyledTabHeading
                            gutterBottom
                            sx={{
                                fontWeight: '100',
                                fontFamily: variables.poppinsRegular,
                                // textAlign: 'center',
                                fontSize: '30px',
                                color: variables.BLUE,
                                display: 'inline'
                            }}
                        >
                            Contact Us
                        </StyledTabHeading>
                    </Box>
                </Box>
                <Box
                    sx={{
                        padding: {
                            xs: '0px 30px 30px 30px',
                            sm: '0px 73px 30px 73px',
                            md: '0px 146px 30px 146px',
                            lg: '0px 146px 30px 146px'
                        }
                    }}
                >
                    <Box {...register('name')}>
                        <CustomFormLabel
                            labelSx={{mt:2}}
                            formLabel='Your name*'
                            formFieldError={errors && errors.name && errors.name.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyContactUsName
                                },
                                validate: value => {
                                    return !(value.trim() === '') || 'Name must not be empty string'
                                }
                            }}
                            name='name'
                            type='name'
                            errors={errors && errors.name && errors.name.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>

                    <Box {...register('email')}>
                        <CustomFormLabel
                            labelSx={{mt:2}}
                            formLabel='Your e-mail*'
                            formFieldError={errors && errors.email && errors.email.message}
                        />
                        <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyContactUsEmail
                                },
                                pattern: {
                                    value: Regex.emailRegex,
                                    message: ErrorMessage.validEmail
                                }
                            }}
                            name='email'
                            type='email'
                            errors={errors && errors.email && errors.email.message}
                            required={true}
                            fullWidth={true}
                            formContolSx={{ width: '100%' }}
                        />
                    </Box>
                    <Box {...register('message')}>
                        <CustomFormLabel
                            labelSx={{mt:2}}
                            formLabel='Your message*'
                            formFieldError={errors && errors.message && errors.message.message}
                        />
                        <Controller
                            control={control}
                            rules={{
                                validate: {
                                    required: v =>
                                        (v && stripHtml(v).result.length > 0) ||
                                        'Please enter your message.'
                                }
                            }}
                            defaultValue=''
                            render={({ field }) => (
                                <WYSIWYGEditor
                                    name='message'
                                    {...field}
                                    register={register}
                                    // editorStyle={{ maxHeight: '100px' }}
                                />
                            )}
                            name='message'
                        />
                        {/* <ControlledTextInput
                            control={control}
                            rules={{
                                required: {
                                    value: true,

                                    message: 
                                },
                                validate: value => {
                                    return (
                                        !(value.trim() === '') || 'Message must not be empty string'
                                    )
                                }
                            }}
                            name='message'
                            type='text'
                            placeholder='Enter your message'
                            errors={errors && errors.message && errors.message.message}
                            required={true}
                            fullWidth={true}
                            multiline={true}
                            formContolSx={{ width: '100%' }}
                            inputProps={{
                                maxLength: 2500,
                                style: {
                                    height: '220px',
                                    overflow: 'auto'
                                }
                            }}
                            InputProps={{
                                maxLength: 2,
                                endAdornment: (
                                    <InputAdornment position='start' sx={{ marginLeft: '10px' }}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                top: '-89px',
                                                left: '-18px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    ml: '15px',
                                                    fontSize: '15px',
                                                    color: variables.BLUE
                                                }}
                                            >
                                                {`${message.length ? message.length : '0'}`}
                                                /2500
                                            </Box>
                                        </Box>
                                    </InputAdornment>
                                )
                            }}
                        /> */}
                    </Box>
                    {apiError && apiError?.message ? (
                        <FormHelperText 
                            id='my-helper-text'
                            error={true}
                        >
                            <strong>{apiError?.message}</strong>
                        </FormHelperText>
                    ) : null}

                    <Box
                        sx={{
                            marginTop: '74px',
                            textAlign: 'center'
                        }}
                    >
                        <Button
                            label={
                                isSaving ? (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: variables.BLUE
                                        }}
                                    />
                                ) : (
                                    'Send'
                                )
                            }
                            disabled={isSaving}
                            color='primary'
                            variant='contained'
                            size='large'
                            onClick={handleSubmit(formSubmit)}
                            sx={{
                                padding: '13px 30px',
                                fontSize: '18px',
                                fontWeight: '600',
                                width: '120px',
                                height: '60px'
                            }}
                        />
                    </Box>
                </Box>
                {loading && <Loader />}
                {isSuccess ? (
                    <StatusBox
                        statusText={
                            <Box component='div' sx={{ fontSize: '21px' }}>
                                {' '}
                                {successMessage}{' '}
                            </Box>
                        }
                    />
                ) : null}
            </Container>
        </Layout>
    )
}

export default ContactUs
