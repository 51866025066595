import React from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, Typography } from '@mui/material'
import DataLoadingLoader from '../molecules/DataLoadingLoader'
import UploadResumeSection from '../molecules/UploadResumeSection'

const UploadResumeModal = props => {
    const {open, onClose, loading} = props
    return <BaseModal open={open} onClose={onClose} modalTitle={<Typography variant='h3' className='small-size-18px'>Upload Resume</Typography>} boxSx={{minHeight: 'auto'}}>
    {loading ? (
        <DataLoadingLoader showLoadingRow={1}/>
    ) : (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
            <UploadResumeSection modalClose={onClose} hideTitle={true}/>
        </Box>
    )}
</BaseModal>
}

export default UploadResumeModal