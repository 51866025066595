import { Avatar, Box, Container, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import AvatarDefault from '../../assets/images/avatar.png'
import { DateDefaultFromat } from '../../constants/constants'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import { useTranslation } from 'react-i18next'
import ContactUsRequestModal from '../organisams/ContactUsRequestModal'

const ContactUsListRow = props => {
    const { data } = props
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const { t } = useTranslation()
    return (
        <Container variant='table-rows'>
            <Grid container spacing={2} alignItems='center'>
                <Grid item lg={2} md={2} sm={2} xs={12}>
                    <Avatar
                        variant='user-images'
                        src={AvatarDefault}
                        sx={{
                            height: {
                                xs: '70px',
                                md: '110px'
                            },
                            width: {
                                xs: '70px',
                                md: '110px'
                            }
                        }}
                    />
                </Grid>
                <Grid item lg={5} md={5} sm={5} xs={6}>
                    <Box>
                        <Typography variant='h4' className='table-row-heading' sx={{ mb: 1, wordWrap:'break-word' }} >
                            {data.name}
                        </Typography>
                        {data.email ? (
                            <Typography variant='h5' className='small-size' sx={{wordWrap:'break-word'}}>
                                {data.email}
                            </Typography>
                        ) : null}
                    </Box>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={6}>
                    <Box>
                        <Typography variant='h5' className='small-size' sx={{ mb: 1, wordWrap:'break-word' }}>
                            Contacted On
                        </Typography>
                        <Typography variant='h5' className='small-size' >
                            {moment(new Date(data.createdAt)).format(`${DateDefaultFromat} HH:mm`)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    sm={3}
                    xs={12}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            sm: 'end'
                        }
                    }}
                >
                    <Button
                        label={
                            <Typography
                                variant='h5'
                                className='small-size-14px extrabold'
                                sx={{
                                    color: `${variables.WHITE1} !important`,
                                    fontWeight: 'bold'
                                }}
                            >
                                {t('buttons.viewDetails')}
                            </Typography>
                        }
                        onClick={() => setShowDetailsModal(true)}
                        color='primary'
                        variant='contained'
                        size='large'
                        sx={{
                            padding: '13px 23px',
                            fontSize: '14px',
                            fontWeight: '600'
                        }}
                    />
                </Grid>
            </Grid>
            <ContactUsRequestModal
                open={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                data={data}
            />
        </Container>
    )
}

export default ContactUsListRow
