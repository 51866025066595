import React from 'react'
import { Box, Typography } from '@mui/material'
import { styles } from './styles'
import BUN_loadingBalance from '../../assets/gif/BUN_loadingBalance.gif'
import variables from '../../settings/_variables.scss'

function Loader() {
    return (
        <div style={styles.loaderContainer}>
            <Box>
                {/* <CircularProgress disableShrink={false} sx={styles.Loader} /> */}
                <div style={styles.loaderBackground} />
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    textAlign: 'center'
                }}
            >
                <Box
                    component='img'
                    src={BUN_loadingBalance}
                    sx={{
                        height: '166px'
                    }}
                />
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='h2' className='extrabold' sx={{ color: variables.CYAN }}>
                        Loading balance…
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}

export default Loader
