import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import Button from '../Button'
import { useMutation, useQuery } from '@apollo/client'
import SuccessIcon from '../../assets/icons/success_green.svg'
import BaseModal from '../../template/BaseModal'
import ModalStatusSection from '../elements/ModalStatusSection'
import { CANDIDATE_RESPONSE_MUTATION, GET_QUESTIONS_QUERY } from '../../queries/engagementQuestionsQueries'

const EngagemnetQuestionsModal = props => {
    const { open, handleClose, userId } = props
    const [answered, setAnswered] = useState(0)
    const [questionsData, setQuestionsData] = useState([])
    const [showThankyouMessage, setShowThankyouMessage] = useState(false)
    const { loading, data, error } = useQuery(GET_QUESTIONS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: userId
        },
        onCompleted(data) {
            setQuestionsData(data.getEngagemnetQuestions)
        }
    })

    const [
        responseMutation,
        { loading: mutationLoading, data: mutationData, error: mutationError }
    ] = useMutation(CANDIDATE_RESPONSE_MUTATION, {
        onCompleted(data) {
            if (answered + 1 === questionsData.length) {
                setShowThankyouMessage(true)
                setTimeout(() => {
                    handleClose()
                }, 3000)
            } else {
                setAnswered(answered + 1)
            }
        },
        onError(error) {
            console.log(error, 'error')
        }
    })
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            modalTitle={<Typography variant='h3' className='small-size-18px'>{`Question ${answered + 1} of ${questionsData.length}`}</Typography>}
        >
            {loading ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <CircularProgress size={50} sx={{ color: variables.BLUE }} />
                </Box>
            ) : showThankyouMessage ? (
                <ModalStatusSection statusIcon={SuccessIcon} statusText='Thank You for Your Response'/>
            ) : (
                <Box>
                    {questionsData[answered] && (
                        <Box>
                            <Box>
                                <Typography variant='h3' className='strong small-size-18px'> 
                                    {questionsData[answered].question}
                                </Typography>
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                {questionsData[answered].engagementQuestionAnswers.map(answer => (
                                    <Box sx={{ mt: 1 }}>
                                        <Button
                                            label={
                                                <Typography
                                                    variant='h5'
                                                    className='small-size-16px'
                                                >
                                                    {answer.answer}
                                                </Typography>
                                            }
                                            color='secondary'
                                            variant='contained'
                                            size='large'
                                            sx={{
                                                width: '100%',
                                                justifyContent: 'left',
                                                borderRadius: '15px',
                                                textAlign: 'left',
                                                backgroundColor: variables.CYAN1
                                            }}
                                            onClick={e => {
                                                e.preventDefault()
                                                responseMutation({
                                                    variables: {
                                                        userId: userId,
                                                        questionId: questionsData[answered].id,
                                                        answerId: answer.id
                                                    }
                                                })
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </BaseModal>
    )
}

export default EngagemnetQuestionsModal
