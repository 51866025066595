import { reorderOthersOptionToLast } from '../../utils/commonFunctions'
import {
    SAVE_PROFESSIONS,
    SAVE_INDUSTRIES,
    SAVE_DEPARTMENTS,
    SAVE_STATES,
    SAVE_BENEFITS,
    SAVE_JOB_TYPES,
    RESPONSIVE_BREAKPOINT
} from '../actions/initialData/actionTypes'

const initialState = {
    allProfessions: null,
    allIndustries: null,
    allDepartments: null,
    allStates: null,
    allBenefits: null,
    responsiveBreakpoint: 'web'
}

const saveProfessions = (state, action) => {
    return {
        ...state,
        allProfessions: action.payload.getAllProfessions
    }
}

const saveDepartments = (state, action) => {
    return {
        ...state,
        allDepartments: reorderOthersOptionToLast(action.payload.getAllDepartments)
    }
}

const saveIndustries = (state, action) => {
    return {
        ...state,
        allIndustries: reorderOthersOptionToLast(action.payload.getAllIndustries)
    }
}

const saveStates = (state, action) => {
    return {
        ...state,
        allStates: action.payload.getAllStates
    }
}

const saveBenefits = (state, action) => {
    return {
        ...state,
        allBenefits: reorderOthersOptionToLast(action.payload.benefits)
    }
}

const saveJobTypes = (state, action) => {
    return {
        ...state,
        allJobTypes: action.payload.jobTypes
    }
}

const responsiveBreakpoint = (state, action) => {
    return {
        ...state,
        responsiveBreakpoint: action.payload
    }
}

const initialDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PROFESSIONS:
            return saveProfessions(state, action)
        case SAVE_INDUSTRIES:
            return saveIndustries(state, action)
        case SAVE_DEPARTMENTS:
            return saveDepartments(state, action)
        case SAVE_STATES:
            return saveStates(state, action)
        case SAVE_BENEFITS:
            return saveBenefits(state, action)
        case SAVE_JOB_TYPES:
            return saveJobTypes(state, action)
        case RESPONSIVE_BREAKPOINT:
            return responsiveBreakpoint(state, action)
        default:
            return state
    }
}

export default initialDataReducer
