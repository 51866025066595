import React from 'react'
import PageMetaTags from '../../../base/PageMetaTags'
import { useParams } from 'react-router-dom'
import AddCompanyComponent from './AddCompanyComponent'
import Layout from '../../../template/Layout'
import PageHeaderWithBackButton from '../../../components/elements/PageHeaderWithBackButton'

const EmployerAddCompany = props => {
    let urlParams = useParams()
    const isEditPage = urlParams.companyId ? true : false

    return (
        <Layout>
            <PageMetaTags title={`${isEditPage ? 'Edit' : 'Add New'} Company | Bunton`} />
            <PageHeaderWithBackButton pageTitle={isEditPage ? 'Edit company on Bunton' : 'Add a new company to Bunton'}/>
            <AddCompanyComponent isEmployer={true} />
        </Layout>
    )
}

export default EmployerAddCompany
