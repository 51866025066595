import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import variables from '../../settings/_variables.scss'
import { PieChart } from '@mui/x-charts/PieChart'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from 'react-redux'
import Button from '../Button'
import { urlSlug } from '../../utils/commonFunctions'

const EngagementQuestionsListRow = props => {
    const { data } = props
    const { engagementQuestionAnswers, totalResponses } = data
    const chartData = []
    engagementQuestionAnswers.map(answer => {
        const ansData = {}
        ansData.value = answer.responseCount
        let ansPercent = (100 * answer.responseCount) / totalResponses
        ansData.label = `${ansPercent === 100 ? ansPercent : ansPercent.toFixed(1)}% ${
            answer.answer
        }`
        chartData.push(ansData)
    })
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const svgRef = useRef(null);

  // Function to convert SVG to PNG and trigger download
  const downloadPng = () => {
    const svgElement = svgRef.current;
    if (!svgElement) return;

    // Get the SVG as a string
    const svgString = new XMLSerializer().serializeToString(svgElement);

    // Create a new canvas element
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");

    // Set canvas size to match the SVG size
    const svgBBox = svgElement.getBBox();
    canvas.width = svgBBox.width;
    canvas.height = 275;
    canvasContext.fillStyle = variables.WHITE1
    canvasContext.fillRect(0, 0, canvas.width, canvas.height);

    // Create an image from the SVG string
    const img = new Image();
    const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      // Draw the image (SVG) onto the canvas
      canvasContext.drawImage(img, 0, 0);

      // Create a PNG data URL from the canvas
      const pngUrl = canvas.toDataURL("image/png");

      // Create a link element and trigger download
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${urlSlug(data.question)}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // Clean up URL
      URL.revokeObjectURL(url);
    };

    // Set the image source to the Blob URL of the SVG
    img.src = url;
  };
    return (
        <Accordion sx={{ mb: 2 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
            >
                <Grid container alignItems='center' spacing={1}>
                    <Grid item md={7} xs={12} sm={7} lg={7} xl={7}>
                        <Typography variant='h5' className='small-size-16px extrabold'>
                            {data.question}
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={12} sm={2} lg={2} xl={2} sx={{textAlign:'center'}}>
                        <Typography variant='h5' className='small-size'>
                            {`${data.totalResponses} Reponse(s)`}
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12} sm={3} lg={3} xl={3} sx={{textAlign:{
                        xs:'center',
                        sm:'right'
                    }}}>
                        <Button
                            label='Download'
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{ py: 1, px: 2, alignItems: 'center', fontSize: '12px', maxHeight: '37px' }}
                            onClick={downloadPng}
                        />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {totalResponses > 0 ? (
                    <Box sx={{margin:'auto', width:'fit-content'}}>
                        <PieChart
                            ref={svgRef}
                            colors={[
                                variables.BLUE,
                                variables.CYAN,
                                variables.SOFTORANGE,
                                variables.GREEN
                            ]}
                            series={[
                                {
                                    data: chartData,
                                    highlightScope: { fade: 'global', highlight: 'item' },
                                    cornerRadius: 5,
                                    cx: ['mob'].includes(responsiveBreakpoint)
                                        ? 80
                                        : ['tab'].includes(responsiveBreakpoint)
                                        ? 100
                                        : 150
                                }
                            ]}
                            margin={{ bottom: 2, left: 2, right: 3, top: 5 }}
                            tooltip={{ trigger: 'item' }}
                            slotProps={{
                                legend: {
                                    classes:{
                                        '.MuiChartsLegend-series': {
                                            transform: 'translate(500px, 93.5px)'
                                        }
                                    },
                                    itemMarkWidth: ['mob', 'tab'].includes(responsiveBreakpoint)
                                        ? 10
                                        : 20,
                                    itemMarkHeight: ['mob', 'tab'].includes(responsiveBreakpoint)
                                        ? 10
                                        : 20,
                                    labelStyle: {
                                        fontSize: ['mob'].includes(responsiveBreakpoint)
                                            ? 10
                                            : ['tab'].includes(responsiveBreakpoint)
                                            ? 12
                                            : 14,
                                        fill: variables.BLUE
                                    }
                                }
                            }}
                            width={['mob'].includes(responsiveBreakpoint)
                                ? 300
                                : ['tab'].includes(responsiveBreakpoint)
                                ? 500
                                : 900}
                            height={
                                ['mob'].includes(responsiveBreakpoint)
                                    ? 100
                                    : ['tab'].includes(responsiveBreakpoint)
                                    ? 150
                                    : 275
                            }
                        />
                    </Box>
                ) : (
                    <Box>
                        <Typography>No Response Available</Typography>
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default EngagementQuestionsListRow
