import { Box, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import SuccessIcon from '../../assets/icons/success.svg'

const StatusBox = props => {

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: variables.WHITEOPACITY,
                position: 'fixed',
                top: '0',
                left: '0',
                backdropFilter: 'blur(2px)',
                zIndex: '1',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: '260px',
                        sm: '350px',
                        lg: '350px'
                    },
                    height: 'auto',
                    backgroundColor: variables.CYAN,
                    borderRadius: '50px',
                    margin: 'auto',
                    textAlign: 'center',
                    p: 3
                }}
            >
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Box
                        component='img'
                        src={SuccessIcon}
                        sx={{
                            width: '60px',
                            height: '60px'
                        }}
                    />
                </Box>
                <Box>
                    <Typography
                        variant='h2'
                        className='font-size-28px extrabold'
                    >
                        {props.statusText}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default StatusBox
