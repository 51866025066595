import { gql } from '@apollo/client'

export const GET_NOTIFICATION_TYPE_LIST_QUERY = gql`
    query GetNotificationTypeList($data: String!) {
        getNotificationType(data: $data) {
            id
            title
            description
            notificationType
            userPreference
        }
    }
`

export const MANAGE_NOTIFICATION_PREFERENCE_MUTATION = gql`
    mutation ManageNotificationPrefrence($data: String!) {
        manageNotificationPreference(data: $data) {
            id
        }
    }
`


export const UNSUBSCRIBE_MUTATION = gql`
    mutation UnsubscribeNotification($data: String!) {
        unsubscribeNotification(data: $data) {
            name
        }
    }
`
