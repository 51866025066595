import variables from '../../settings/_variables.scss'
export const styles = {
    loaderContainer: {
        top: '0%',
        left: '0%',
        zIndex: '1',
        position: 'fixed',
        display: 'flex',
        backgroundColor: variables.WHITE1,
        height: '100%',
        width: '100%'
    },
    loaderBackground: {
        backgroundColor: variables.WHITE1,
        position: 'fixed',
        opacity: '0.3'
    },
    Loader: {
        color: variables.BLUE
    },
    loaderContainerText: {
        color: variables.CYAN,
        fontSize: '25px',
        fontWeight: '600'
    }
}
